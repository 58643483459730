import { unref, watch } from "vue";

export default {
  methods: {
    makeModalLarge(targetClass = "modal-content") {
      let items = document.getElementsByClassName(targetClass);
      for (let i = 0; i < items.length; i++) {
        items[i].classList.add("is-large");
      }
    },
  },
};

export const useLargeModal = inModal => {
  const makeModalLarge = (targetClass = "modal-content") => {
    let items = document.getElementsByClassName(targetClass);
    for (let i = 0; i < items.length; i++) {
      items[i].classList.add("is-large");
    }
  };

  if (unref(inModal)) {
    makeModalLarge();
  }

  watch(
    () => inModal,
    value => {
      if (value) {
        makeModalLarge();
      }
    },
  );

  return {
    makeModalLarge,
  };
};
