import { GenexSISModel } from "@/models/sis/GenexSISModel";
import { ProgressPhase } from "@/models/sis/ProgressPhase";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export class ProgressSubject extends GenexSISModel {
  static entity = "progress-subjects";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      phase_ids: this.attr(() => []),
      phases: this.hasManyBy(ProgressPhase, "phase_ids"),
    };
  }

  static FetchById(id, relationships = [], persistBy = "insertOrUpdate") {
    return this.api().get(`users/${id}/insights`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      persistBy,
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
