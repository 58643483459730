import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class Activity extends GenexSISModel {
  static entity = "activity";

  static fields() {
    return {
      id: this.attr(null),
      note: this.attr(""),
      created_at: this.attr(null),
    };
  }
}
