import { computed, ref } from "vue";

export const usePagination = (meta, options = {}) => {
  if (!options.enabled)
    return {
      page: ref(1),
      limit: ref(15),
      limits: computed(() => options),
    };

  const page = ref(options.page ?? 1);
  const limit = ref(options.limit ?? 3);

  const total = computed(() => meta.value?.total ?? 0);

  const paginate = newPage => {
    newPage = newPage ?? 1;
    newPage = newPage < 1 ? 1 : newPage;
    page.value = newPage;
  };

  const limits = computed(() => ({
    page: page.value,
    limit: limit.value,
  }));

  return {
    page,
    limit,
    limits,
    total,
    paginate,
  };
};
