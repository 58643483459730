import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A report group for the final report
 * @property {number} id
 * @property {string} name - The name of the group
 * @property {number} report_id - The id of the parent report
 * @property {number} subject_id - the id of the related subject
 * @property {number} class_group_id - the id of the related class group
 * @property {number} type_id - TYPE_PREVIOUS_REPORT_MARK_GROUP = 1; TYPE_PREVIOUS_REPORT_TOTAL = 2; TYPE_CURRENT_REPORT_MARK_GROUP = 3;
 * @property {number} weighting - the weighting
 */
export default class ReportFinalGroup extends GenexSISModel {
  static entity = "reportFinalGroups";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      report_id: this.attr(null),
      subject_id: this.attr(null),
      weighting: this.attr(null),
      class_group_id: this.attr(null),
      type_id: this.attr(null),
      previous_mark_group_id: this.attr(null),
      current_mark_group_id: this.attr(null),
      previous_report_id: this.attr(null),
    };
  }

  /**
   * Returns all final groups for a given report
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.subject_id] - search by subject_id
   * @param {number} [query.class_group_id] - search by class_group_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {number} report_id - The id of the parent report
   * @returns {Promise<Response>} All the Reports
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], report_id) {
    return this.api().get(`/reports/${report_id}/final-groups`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(markGroup => {
          return { ...markGroup, ...markGroup.attributes };
        });
      },
    });
  }

  /**
   * Returns a final group
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} id - The id of the group
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(report_id, id, relationships = []) {
    return this.api().get(`/reports/${report_id}/final-groups/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new final group
   * @function
   * @param {Object} finalGroup - The finalGroup object
   * @returns {Promise<Response>} - The newly created group
   */

  static Store(finalGroup) {
    return this.api().post(`/reports/${finalGroup.report_id}/final-groups`, finalGroup, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a final-group
   * @function
   * @param {Object} finalGroup - The finalGroup object
   * @returns {Promise<Response>} - The updated report
   */

  static Update(finalGroup) {
    return this.api().patch(`/reports/${finalGroup.report_id}/final-groups/${finalGroup.id}`, finalGroup, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a final group
   * @function
   * @param {Object} finalGroup - The finalGroup object
   */

  static Delete(finalGroup) {
    return this.api().delete(`/reports/${finalGroup.report_id}/final-groups/${finalGroup.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: finalGroup.id,
    });
  }
}
