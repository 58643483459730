import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class UserRecordType extends GenexSISModel {
  static entity = "user-record-types";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      groupName: this.attr(null),
    };
  }
}
