import { useBuefy } from "@/compositions/utils/useBuefy";
import { startCase, upperFirst } from "lodash";

export const useErrorHandler = () => {
  const $buefy = useBuefy();

  const processMessage = message => {
    if (!message) return "An error occurred";
    return upperFirst(message);
  };

  const processErrors = errors => {
    if (!errors) return "";
    return Object.keys(errors)
      .map(key => {
        return errors[key].map(error => {
          return `${startCase(key)} : ${upperFirst(error)}`;
        });
      })
      .join("<br>");
  };

  const messageStatusCodes = [400, 403, 404, 422, 500];

  const handleError = (error, show = true) => {
    try {
      const { response, message } = error;
      if (response) {
        const { data, status, statusText } = response;
        if (messageStatusCodes.includes(status)) {
          if (!data) return message;
          const { message, errors } = data;
          const messageText = processMessage(message);
          const errorText = processErrors(errors);
          const fullText = errorText ? `${errorText}` : messageText;
          if (show) {
            $buefy.toast.open({
              message: fullText,
              type: "is-danger",
            });
          }
          return fullText;
        } else {
          const fullText = `Status: ${status} ${statusText}`;
          if (show) {
            $buefy.toast.open({
              message: fullText,
              type: "is-danger",
            });
          }
          return fullText;
        }
      }
      const fullText = processMessage(message);
      if (show) {
        $buefy.toast.open({
          message: fullText,
          type: "is-danger",
        });
      }
      return fullText;
    } catch (e) {
      const fullText = "An error occurred";
      if (show) {
        $buefy.toast.open({
          message: fullText,
          type: "is-danger",
        });
      }
      return fullText;
    }
  };

  return {
    handleError,
  };
};
