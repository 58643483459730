import { render, staticRenderFns } from "./GenexMathEditor.vue?vue&type=template&id=bcfd0f92&scoped=true"
import script from "./GenexMathEditor.vue?vue&type=script&setup=true&lang=js"
export * from "./GenexMathEditor.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bcfd0f92",
  null
  
)

export default component.exports