import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class ReportMarkGroupAssessment extends GenexSISModel {
  static entity = "ReportMarkGroupTests";

  static fields() {
    return {};
  }

  /**
   * Attach an assessment to a markGroup
   * @function
   * @param {number} report_id - The report_id
   * @param {number} mark_group_id - The mark_group_id
   * @param {number} assessment_id - The assessment_id
   * @param {number} weighting - The weighting
   * @returns {Promise<Response>} - The newly updated mark_group
   */

  static Attach(report_id, mark_group_id, assessment_id, weighting) {
    return this.GenexAxiosPost(
      "Report.Attach",
      `/reports/${report_id}/mark-groups/${mark_group_id}/assessments/${assessment_id}`,
      {
        ...(weighting === null ? {} : { weighting: weighting }),
      },
      {},
    );
  }

  /**
   * Detach an assessment from a markGroup
   * @function
   * @param {number} report_id - The report_id
   * @param {number} mark_group_id - The mark_group_id
   * @param {number} assessment_id - The assessment_id
   * @returns {Promise<Response>} - The newly updated mark_group
   */

  static Detach(report_id, mark_group_id, assessment_id) {
    return this.GenexAxiosDelete(
      "Report.Detach",
      `/reports/${report_id}/mark-groups/${mark_group_id}/assessments/${assessment_id}`,
      {},
    );
  }
}
