import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A reportExtramuralComment
 * @property {number} id
 * @property {number} report_extramural_id
 * @property {number} user_id
 * @property {number} creator_id
 * @property {string} comment
 * @property {Array<string>} json_comments
 */
export default class ReportExtramuralComment extends GenexSISModel {
  static entity = "reportExtramuralComments";

  static fields() {
    return {
      id: this.attr(null),
      report_extramural_id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      comment: this.attr(null),
      // json_comments:this.attr(()=>{}),
    };
  }

  // static mutators() {
  //     return {
  //         json_comments(value) {
  //             if (value === []) {
  //                 return {
  //
  //                 }
  //             }
  //             return value
  //         },
  //
  //     }
  //
  // }
  /**
   * Returns all reportExtramuralComments
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.report_extramural_id] - Query terms for the request
   * @param {number} [report_id] - the parent report_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the comments
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], report_id) {
    return this.api().get(`/reports/${report_id}/extramural-comments`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(extramural => {
          if (relationships.includes("users")) {
            extramural.attributes.users.map(user => {
              Object.assign(user, user.attributes);
            });
          }

          return { ...extramural, ...extramural.attributes };
        });
      },
    });
  }

  /**
   * Returns a reportExtramuralComment
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} extramural_comment_id - The id of the extramuralComment
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the comment
   */

  static FetchById(report_id, extramural_comment_id, relationships = []) {
    return this.api().get(`/reports/${report_id}/extramural-comments/${extramural_comment_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          Object.assign(data.attributes.user, data.attributes.user.attributes);
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new reportExtramuralComment
   * @function
   * @param {Object} reportExtramuralComment - The reportExtramuralComment object
   * @returns {Promise<Response>} - The newly created comment
   */

  static Store(reportExtramuralComment) {
    return this.api().post(
      `/reports/${reportExtramuralComment.report_id}/extramural-comments`,
      reportExtramuralComment,
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Update an reportExtramuralComment
   * @function
   * @param {Object} reportExtramuralComment - The reportExtramuralComment object
   * @returns {Promise<Response>} - The updated report
   */

  static Update(reportExtramuralComment) {
    return this.api().patch(
      `/reports/${reportExtramuralComment.report_id}/extramural-comments/${reportExtramuralComment.id}`,
      reportExtramuralComment,
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Delete a reportExtramuralComment
   * @function
   * @param {Object} reportExtramuralComment - The reportExtramuralComment
   */

  static Delete(reportExtramuralComment) {
    return this.api().delete(
      `/reports/${reportExtramuralComment.report_id}/extramural-comments/${reportExtramuralComment.id}`,
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        delete: reportExtramuralComment.id,
      },
    );
  }
}
