import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A enrolment
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class EnrolmentStatus extends GenexSISModel {
  static entity = "enrolment-statuses";

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      name: this.attr(null),
    };
  }

  /**
   * Returns all enrolment statuses
   * @function
   * @property {?Object} override - Rename status
   * @returns {Promise<Response>}
   */

  static FetchAll(override = {}) {
    return this.api().get(`/enrolment-statuses`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(status => {
          if (Object.keys(override).includes(status.attributes.name)) {
            status.attributes.name = override[status.attributes.name];
          }
          return { ...status, ...status.attributes };
        });
      },
    });
  }
}
