import { useStore } from "@/compositions/useStore";
import { computed } from "vue";

export const useCurrent = () => {
  const $store = useStore();
  const tenant_id = computed(() => $store?.state?.auth?.tenantId);

  return {
    tenant_id,
  };
};
