import { GenexSISModel } from "@/models/sis/GenexSISModel";
import { normalize } from "@/utils/data";

export default class ApplicationInProgress extends GenexSISModel {
  static entity = "application_in_progress";

  static fields() {
    return {
      guardian_id: this.attr(null),
      step: this.attr(null),
      data: this.attr(null),
      created_at: this.attr(null),
      id: this.attr(null),
    };
  }

  /**
   * Returns all Assessments
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @returns {Promise | Promise<unknown>}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("ApplicationInProgress.FetchAll", "/applications-in-progress", {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns an Assessment
   * @param id
   * @param relationships
   * @returns {Promise | Promise<unknown>}
   * @constructor
   */
  static FetchById(guardian_id, relationships = []) {
    return this.GenexAxiosGet("ApplicationInProgress.FetchById", `/guardians/${guardian_id}/application-in-progress`, {
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Store a new assessment
   * @param application
   * @returns {Promise | Promise<unknown>}
   * @constructor
   */
  static Store(application) {
    return this.GenexAxiosPost("ApplicationInProgress.Store", `/applications-in-progress`, application, {
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Update an application
   * @param application
   * @returns {Promise | Promise<unknown>}
   * @constructor
   */
  static Update(application) {
    return this.GenexAxiosPatch(
      "ApplicationInProgress.Update",
      `/applications-in-progress/${application.id}`,
      application,
      {
        dataTransformer: ({ data: { data } }) => {
          return normalize(data);
        },
        save: true,
      },
    );
  }

  /**
   * Delete an application
   * @param id
   * @returns {Promise | Promise<unknown>}
   * @constructor
   */
  static Delete(id) {
    return this.GenexAxiosDelete("ApplicationInProgress.Delete", `/applications-in-progress/${id}`, {
      delete: id,
    });
  }
}
