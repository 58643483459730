import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * All Currencies
 * @property {number} id
 * @property {string} code -  The Alphabetical code for each currency
 * @property {string} name -  The full name for each currency
 * @property {string} symbol - Symbol that represents the currency
 */
export default class Currency extends GenexSISModel {
  static entity = "currency";

  static fields() {
    return {
      id: this.attr(null),
      code: this.attr(null),
      name: this.attr({}),
      symbol: this.attr(null),
    };
  }

  /**
   * Returns all currencies
   * @function
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.user_id]
   * @param {string} [query.date]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll(query = {}, relationships = []) {
    return this.api().get(`/currencies`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(item => {
          return {
            code: item.attributes.code,
            name: item.attributes.name,
            symbol: item.attributes.symbol,
          };
        });
      },
    });
  }

  /**
   * Returns a currency by its id
   * @function
   * @param {number} id The id of the currency
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */
  static FetchById(id, relationships = []) {
    return this.specificCurrencyFetch(id, relationships);
  }

  /**
   * Returns a currency by its code
   * @function
   * @param {string} code The code of the currency
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */
  static FetchByCode(code, relationships = []) {
    return this.specificCurrencyFetch(code, relationships);
  }

  specificCurrencyFetch(filter, relationships = []) {
    return this.api().get(`/currencies/${filter}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => data,
    });
  }
}
