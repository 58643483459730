<template>
  <div v-if="fileContent" class="content px-3 docs-display" v-html="fileContent"></div>
</template>

<script>
  export default {
    name: "DocsDisplay",
    data() {
      return {};
    },
    props: {
      path: {
        type: String,
        required: true,
      },
      docsType: {
        type: String,
        default() {
          return "staff";
        },
      },
    },
    computed: {
      trimmedPath() {
        return this.path.slice(2);
      },
      fileContent() {
        try {
          if (this.docsType && this.trimmedPath)
            return require(`../../assets/docs/${this.docsType}/${this.trimmedPath}`);
          return false;
        } catch (e) {
          return require(`../../assets/docs/NoFile.md`);
        }
      },
    },
  };
</script>
