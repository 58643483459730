import User from "./User";
import { normalize } from "@/utils/data";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import JournalEntry from "@/models/sis/JournalEntry";

export default class JournalFeedback extends GenexSISModel {
  static entity = "journal_feedback";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      content: this.attr(""),
      journal_id: this.attr(null),
      journal_entry: this.belongsTo(JournalEntry, "journal_id"),
      student_id: this.attr(null),
      creator_id: this.attr(null),
      published_at: this.attr(null),
      is_published: this.attr(null),
      updated_at: this.attr(null),
      created_at: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      student: this.belongsTo(User, "student_id"),
    };
  }

  /**
   * Returns all JournalFeedback
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {string} [mode="insertOrUpdate"] - How to persist the response
   * @returns {Promise<Response>} All the JournalFeedback
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get("/journal-feedback", {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        page,
        limit,
        with: relationships,
        ...query,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns a single JournalFeedback
   * @param {number} id - The id of the JournalFeedback
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} The JournalFeedback
   */
  static FetchById(id, relationships = []) {
    return this.api().get(`/journal-feedback/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  /**
   * Store a new JournalFeedback
   * @param {Object} journalFeedback - The JournalFeedback object
   * @returns {Promise<Response>} The newly created JournalFeedback
   */
  static Store(journalFeedback) {
    return this.api().post("/journal-feedback", journalFeedback, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Update an existing JournalFeedback
   * @param {Object} journalFeedback - The JournalFeedback object
   * @returns {Promise<Response>} The updated JournalFeedback
   */
  static Update(journalFeedback) {
    return this.api().patch(`/journal-feedback/${journalFeedback.id}`, journalFeedback, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Delete an existing JournalFeedback
   * @param {number} id - The id of the JournalFeedback
   */
  static Delete(id) {
    return this.api().delete(`/journal-feedback/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
