import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Course from "@/models/lms/LMSCourses";
import LMSModule from "@/models/lms/LMSModule";
import LMSUser from "@/models/lms/LMSUser";
import { LMSAssessmentQuestion } from "@/models/lms/LMSAssessmentQuestion";
export default class LMSAssessment extends GenexLMSModel {
  static entity = "lms-assessment";

  /**
   *
   * @returns {{id: *, display_name: *, modules: *}}
   */
  static fields() {
    return {
      id: this.attr(null),
      max_attempts: this.attr(null),
      updater_id: this.attr(null),
      tenant_id: this.attr(null),
      course_id: this.attr(null),
      course: this.belongsTo(Course, "course_id"),
      title: this.attr(""),
      is_checkpoint: this.attr(1),
      module_id: this.attr(null),
      module: this.belongsTo(LMSModule, "module_id"),
      course_scope_id: this.attr(null),
      course_scope_code: this.attr(""),
      difficulty_1_count: this.attr(0),
      difficulty_2_count: this.attr(0),
      difficulty_3_count: this.attr(0),
      assessment_questions_count: this.attr(0),
      assessment_questions_learning_objectives_count: this.attr(0),
      assessment_questions_total_marks: this.attr(0),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      creator_id: this.attr(null),
      creator: this.belongsTo(LMSUser, "creator_id"),
      order: this.attr(0),
      completed: this.attr(0),
      assessment_questions: this.hasMany(LMSAssessmentQuestion, "assessment_id"),
      answer_display_handling: this.attr(0),
      pass_mark: this.attr(0),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/assessments`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
