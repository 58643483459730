<template>
  <div>
    <b-dropdown
      :disabled="ComputedDisabled"
      :loading="loading"
      :required="required"
      :value="valueComputed"
      :placeholder="placeholder"
      @input="onInput"
      @active-change="setState($event)"
      append-to-body
      position="is-bottom-left">
      <template #trigger>
        <b-button
          outlined
          type="is-primary"
          :disabled="ComputedDisabled"
          :loading="loading"
          @input="onInput"
          :icon-right="isOpen ? 'chevron-up' : 'chevron-down'">
          <span v-if="valueComputed">
            {{ options.filter(item => item[returnValueKey] === valueComputed)?.[0]?.name }}
          </span>
          <span v-else>
            {{ placeholder }}
          </span>
        </b-button>
      </template>
      <b-dropdown-item class="p-2" v-if="searchEnabled">
        <b-input class="search-box" @click.native.stop placeholder="Search Classes" v-model="searchText" />
      </b-dropdown-item>
      <b-dropdown-item v-if="optionsAllowAll" :value="null">{{ placeholder }}</b-dropdown-item>
      <b-dropdown-item v-for="option in searchedOptions" :key="option.id" :value="returnValue(option)">
        {{ option.name }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  import ClassGroup from "@/models/sis/ClassGroup";
  import { format } from "date-fns";
  import MatchFilters from "@/mixins/MatchFilters";

  export default {
    name: "SelectClassGroup",
    components: {},
    data() {
      return {
        loading: true,
        isOpen: false,
        searchText: "",
      };
    },
    props: {
      value: [Number, String],
      searchEnabled: {
        type: Boolean,
        default() {
          return false;
        },
      },
      required: {
        type: Boolean,
        default() {
          return false;
        },
      },
      disabled: {
        type: Boolean,
        default() {
          return false;
        },
      },
      filters: {
        type: Object,
        default() {
          return {};
        },
      },
      optionsAllowAll: {
        type: Boolean,
        default() {
          return true;
        },
      },
      classNames: {
        type: String,
        default() {
          return "";
        },
      },
      fetchOnMounted: {
        type: Boolean,
        default() {
          return true;
        },
      },
      returnValueKey: {
        type: String,
        default() {
          return "id";
        },
      },
      forceDefault: {
        type: Boolean,
        default() {
          return false;
        },
      },
      forceSelectFirstClassGroup: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    watch: {
      "filters.subject_id"() {
        this.refreshData();
      },
      optionsAllowAll() {
        this.forceDefaultFunc();
      },
    },
    methods: {
      setState(state) {
        this.isOpen = state;
        this.searchText = this.isOpen ? "" : this.searchText;
      },
      onInput(e) {
        this.$emit("input", e);
        if (this.returnValueKey !== "id")
          this.emitCampusId(this.options.find(item => parseInt(e) === item.lms_group_id)?.id);
      },
      returnValue(option) {
        if (this.returnValueKey) {
          return option[this.returnValueKey];
        }
        return option.id;
      },
      SanitizeQuery(query) {
        let result = {};
        for (const queryKey in query) {
          if (query[queryKey]) {
            if (Object.prototype.toString.call(query[queryKey]) === "[object Date]") {
              result[queryKey] = format(new Date(query[queryKey]), "yyyy-MM-dd");
            } else {
              result[queryKey] = query[queryKey];
            }
          }
        }
        return result;
      },
      refreshData() {
        this.loading = true;
        ClassGroup.FetchAll({ page: 1, limit: 99 }, this.SanitizeQuery(this.filters)).then(() => {
          this.ClearValueIfNotAnOption();
          this.forceDefaultFunc();

          this.loading = false;
        });
      },
      ClearValueIfNotAnOption() {
        const check = this.options.filter(item => {
          return item.id == this.valueComputed;
        }, this.valueComputed);
        if (check.length == 0) {
          this.$emit("input", this.options[0]?.id);
        }
      },
      forceDefaultFunc() {
        if (this.forceDefault) {
          if (this.options.length !== 0 && this.options[0]?.id && this.value == null) {
            this.$emit("input", this.returnValueKey ? this.options[0][this.returnValueKey] : this.options[0].id);
            if (this.returnValueKey !== "id") this.emitCampusId(this.options[0].id);
          }
        }
        if (this.forceSelectFirstClassGroup && this.value == null && this.options.length > 0) {
          this.$emit("input", this.returnValueKey ? this.options[0][this.returnValueKey] : this.options[0].id);
          if (this.returnValueKey !== "id") this.emitCampusId(this.options[0].id);
        }
      },
      emitCampusId(id) {
        // emits the id of the selected campus if the return value is not id and the value is not null
        if (id) this.$emit("selectedCampusID", id);
      },
    },
    computed: {
      valueComputed() {
        let result = null;
        if (this.value) {
          if (typeof this.value == "string") {
            result = +this.value;
          } else {
            result = this.value;
          }
        }
        return result;
      },
      placeholder() {
        if (this.optionsAllowAll) {
          return "All Classes";
        } else {
          return "Select a class group";
        }
      },
      options() {
        if (typeof this.filters.subject_id == "undefined" || this.filters.subject_id) {
          let result = ClassGroup.query().where(item => {
            return MatchFilters.methods.getIfMatchesAllChecks(item, this.filters);
          });
          result = result.get();
          if (result.length !== 0) {
            return result;
          }
        }
        return [];
      },
      searchedOptions() {
        if (this.searchText) {
          return this.options.filter(item => {
            return item.name.toLowerCase().includes(this.searchText.toLowerCase());
          });
        } else {
          return this.options;
        }
      },
      ComputedDisabled() {
        if (this.options.length == 0 && !this.searchText) {
          return true;
        } else {
          return this.disabled;
        }
      },
    },
    mounted() {
      if (!this.fetchOnMounted) this.loading = false;
      if (this.fetchOnMounted) {
        this.refreshData();
      }
      if (this.forceSelectFirstClassGroup && this.value == null && this.options.length > 0) {
        this.$emit("input", this.returnValueKey ? this.options[0][this.returnValueKey] : this.options[0].id);
        if (this.returnValueKey !== "id") this.emitCampusId(this.options[0].id);
      }
    },
  };
</script>

<style scoped>
  .search-box {
    min-width: 200px;
  }
</style>
