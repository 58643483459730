import { format, isValid } from "date-fns";
import Year from "../../models/sis/Year";

export const DateSelectModule = {
  namespaced: true,

  state: {
    dateMin: null,
    dateMax: null,
    semester_id: null,
    year: null,
  },
  mutations: {
    SET_YEAR(state, year) {
      state.year = year;
    },
  },
  actions: {
    setYear({ commit }, year) {
      commit("SET_YEAR", year);
    },
  },
  getters: {
    currentYear() {
      return Year.query()
        .where("start", val => new Date(val) <= new Date())
        .where("end", val => new Date(val) >= new Date())
        .exists()
        ? Year.query()
            .where("start", val => new Date(val) <= new Date())
            .where("end", val => new Date(val) >= new Date())
            .first().id ?? null
        : null;
    },
    nextYear() {
      return Year.query()
        .where("start", val => new Date(val) <= new Date().setFullYear(new Date().getFullYear() + 1))
        .where("end", val => new Date(val) >= new Date().setFullYear(new Date().getFullYear() + 1))
        .exists()
        ? Year.query()
            .where("start", val => new Date(val) <= new Date().setFullYear(new Date().getFullYear() + 1))
            .where("end", val => new Date(val) >= new Date().setFullYear(new Date().getFullYear() + 1))
            .first().id ?? null
        : null;
    },
    previousYears: () => {
      return Year.query()
        .where("end", val => new Date(val) < new Date())
        .get();
    },
    years: () => {
      return Year.query().orderBy("id").get();
    },
    application_years: () => {
      return Year.query()
        .where("end", val => new Date(val) > new Date())
        .get();
    },
    formattedDateMin: state => {
      if (isValid(new Date(state.dateMin))) {
        return format(state.dateMin, "yyyy-MM-dd");
      }
      return null;
    },
    formattedDateMax: state => {
      if (isValid(new Date(state.dateMax))) {
        return format(state.dateMax, "yyyy-MM-dd");
      }
      return null;
    },
  },
};
