<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(submit)">
      <div :class="{ 'modal-card': inModal }" style="width: auto">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">
            {{ modalHeader }}
          </p>
        </header>
        <section :class="{ 'modal-card-body': inModal }">
          <slot name="body"></slot>
        </section>
        <div :class="{ 'modal-card-foot': inModal }">
          <slot name="footer"></slot>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
  import { ValidationObserver } from "vee-validate";

  export default {
    name: "GenexForm",
    components: {
      ValidationObserver,
    },
    props: {
      inModal: {
        type: Boolean,
        default() {
          return false;
        },
      },
      modalHeader: {
        type: String,
        default: "",
      },
    },
    methods: {
      submit() {
        this.$emit("submit");
      },
    },
  };
</script>
