import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

import User from "@/models/sis/User";
import Media from "@/models/sis/Media";
import Package from "@/models/sis/Package";
import Phase from "@/models/sis/Phase";

/**
 * A service
 * @property {Number} id
 * @property {String} name - The name of the service eg aftercare
 * @property {Number} cost - The cost of the service
 * @property {Boolean} has_attendances - Whether this service is pulled into an attendance register
 * @property {Boolean} has_ad_hoc_attendants - Whether this service can have adhoc attendants added
 * @property {Array.<Object>} media - Media associated with the service
 * @property {Array.<Number>} media_ids - The relational array of ids
 * @property {Array.<Object>} teachers - Teachers associated with the service, those who will take attendance
 * @property {Array.<Number>} teachers_ids - The relational array of ids
 * @property {Boolean} is_open - Whether this service can be signed up to in the applications flow
 * @property {String} notice_period - How much notice to give for downgrading. Values = ['term', 'month', null]
 * @property {Number} adhoc_cost - How much it costs to add adhoc to this service
 * @property {String} billing_period - How much notice to give for downgrading. Values = ['hourly', 'daily', 'weekly', 'monthly', 'termly', 'annually']
 * @property {Number} order
 */
export default class Service extends GenexSISModel {
  static entity = "services";

  static fields() {
    return {
      id: this.attr(null),
      campus_id: this.attr(null),
      cost: this.attr(null),
      name: this.attr(""),
      has_attendances: this.attr(0),
      has_ad_hoc_attendants: this.attr(0),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      is_open: this.attr(0),
      notice_period: this.attr(null),
      package_id: this.attr(null),
      new_package_id: this.attr(null),
      in_package: this.attr(null),
      package: this.belongsTo(Package, "package_id"),
      adhoc_cost: this.attr(null),
      billing_period: this.attr(null),
      year: this.attr(null),
      teachers_ids: this.attr(() => []),
      teachers: this.hasManyBy(User, "teachers_ids"),
      status_id: this.attr(null),
      subscribed_at: this.attr(null),
      subscribe_at: this.attr(null),
      unsubscribed_at: this.attr(null),
      unsubscribe_at: this.attr(null),
      notice_period_type: this.attr(null),
      notice_cut_day: this.attr(null),
      preferred_unsubscribe_at: this.attr(null),
      phases: this.hasManyBy(Phase, "phase_ids"),
      phase_ids: this.attr([]),
      ordinality: this.attr(0),
      order_index: this.attr(0),
      moved: this.attr(0),
    };
  }

  /**
   * Returns all services
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.campus_id]
   * @param {string} [query.search]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 9999 }, query = {}, relationships = [], save = true) {
    return this.api().get(`/services`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      save: save,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(service => {
          if (relationships.includes("media")) {
            if (Object.prototype.hasOwnProperty.call(service.attributes, "media")) {
              service.attributes.media.map(item => {
                Object.assign(item, item.attributes);
              });
            }
          }
          if (relationships.includes("phases")) {
            if (Object.prototype.hasOwnProperty.call(service.attributes, "phases")) {
              service.attributes.phases.map(phase => {
                Object.assign(phase, phase.attributes);
              });
            }
          }
          if (relationships.includes("teachers")) {
            service.attributes.teachers.map(teacher => {
              Object.assign(teacher, teacher.attributes);
            });
          }

          if (service.attributes.ordinality) {
            service.attributes.order_index = service.attributes.ordinality;
          }
          return { ...service, ...service.attributes };
        });
      },
    });
  }

  /**
   * Returns all services
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {number} user_id
   * @param {Object} [query={}] - Query terms for the request
   * @param {Boolean} [query.latest] - If true will only return the latest subscriptions. False will return all
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllByUser(
    { page = 1, limit = 9999 },
    user_id,
    query = { latest: true },
    relationships = [],
    save = true,
  ) {
    return this.api().get(`/users/${user_id}/services`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      save: save,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(service => {
          if (relationships.includes("media")) {
            if (Object.prototype.hasOwnProperty.call(service.attributes, "media")) {
              service.attributes.media.map(item => {
                Object.assign(item, item.attributes);
              });
            }
          }
          if (relationships.includes("phases")) {
            if (Object.prototype.hasOwnProperty.call(service.attributes, "phases")) {
              service.attributes.phases.map(phase => {
                Object.assign(phase, phase.attributes);
              });
            }
          }
          if (relationships.includes("teachers")) {
            service.attributes.teachers.map(teacher => {
              Object.assign(teacher, teacher.attributes);
            });
          }
          return { ...service, ...service.attributes };
        });
      },
    });
  }

  /**
   * Store a new service
   * @function
   * @param {Object} service - The service object
   * @param {string} service.name - The name of the service
   * @param {number} service.campus_id - The id of the campus the service is associated with
   * @param {string} service.cost
   * @param {array} service.teachers
   * @param {Boolean} service.has_attendances
   * @param {Boolean} service.has_ad_hoc_attendants
   * @returns {Promise<Response>} - The newly created service
   */

  static Store(service) {
    return this.api().post(`/services`, service, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Order the subjects
   * @function
   * @param {number} service_id - The service
   * @param {Array} services - The ordering objects
   * @returns {Promise<Response>} - The newly ordered services
   */

  static Order(services) {
    return this.api().patch(
      `/services/ordinality`,
      { services: services },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        persistBy: "update",
        dataTransformer: ({ data: { data } }) => {
          return data.map(service => {
            return {
              id: service.id,
              ordinality: service.attributes.ordinality,
              order_index: service.attributes.ordinality,
            };
          });
        },
      },
    );
  }

  /**
   * change package
   * @function
   * @param {Number} user_id - The user id
   * @param {Number} current_package_id - The package id
   * @param {Number} new_package_id - The package id
   * @param {string} preferred_date   - The preferred date
   * @param {Boolean} force
   * @returns {Promise<Response>} - The services
   */

  static ChangePackage(user_id, current_package_id, new_package_id, force = false, preferred_date = null) {
    return this.api().post(
      `/users/${user_id}/change-package`,
      {
        force: force,
        current_package_id: current_package_id,
        new_package_id: new_package_id,
        ...(preferred_date ? { preferred_date: preferred_date } : {}),
      },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return data.map(service => {
            return { ...service, ...service.attributes };
          });
        },
      },
    );
  }

  /**
   * change package subscribe date
   * @function
   * @param {Number} user_id - The user id
   * @param {Number} package_id - The package id
   * @param {string} unsubscribe_date   - The preferred date
   * @returns {Promise<Response>} - The services
   */

  static ChangePackageUnsubscribe(user_id, package_id, unsubscribe_date) {
    return this.api().post(
      `/users/${user_id}/change-package-unsubscribe-at`,
      { package_id: package_id, unsubscribe_date: unsubscribe_date },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return data.map(service => {
            return { ...service, ...service.attributes };
          });
        },
      },
    );
  }

  /**
   * Subscribe service
   * @function
   * @param {number} user_id
   * @param {number} service_id - The service object
   * @returns {Promise<Response>} - The newly created service
   */

  static Subscribe(user_id, service_id, future_subscribe_date) {
    return this.api().post(
      `/users/${user_id}/services/${service_id}/subscribe`,
      {
        ...(future_subscribe_date ? { future_subscribe_date } : {}),
      },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return data.map(service => {
            return { ...service, ...service.attributes };
          });
        },
      },
    );
  }

  /**
   * Unsubscribe a service
   * @function
   * @param {number} user_id
   * @param {number} service_id
   * @param {Boolean} force - unsubscribe without notice period
   * @param {Array<Object>} services - The service object
   * @returns {Promise<Response>} - The newly created service
   */

  static Unsubscribe(user_id, service_id, force = false) {
    return this.api().post(
      `/users/${user_id}/services/${service_id}/unsubscribe`,
      { force: force },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Update a  service
   * @function
   * @param {Object} service - The service object
   * @param {string} service.name - The name of the service
   * @param {number} service.campus_id - The id of the campus the service is associated with
   * @param {string} service.cost
   * @param {array} service.teachers
   * @param {Boolean} service.has_attendances
   * @param {Boolean} service.has_ad_hoc_attendants
   * @param {number} service.id
   * @returns {Promise<Response>} - The newly created service
   */

  static Update(service) {
    return this.api().patch(`/services/${service.id}`, service, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Returns a service by its id
   * @function
   * @param {Object} service
   * @param {number} service.id The id of the service
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(service, relationships = []) {
    return this.api().get(`/services/${service.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("teachers")) {
          data.attributes.teachers.map(teacher => {
            Object.assign(teacher, teacher.attributes);
          });
        }
        if (relationships.includes("phases")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "phases")) {
            data.attributes.phases.map(phase => {
              Object.assign(phase, phase.attributes);
            });
          }
        }
        if (relationships.includes("media")) {
          data.attributes.media.map(item => {
            Object.assign(item, item.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an existing service
   * @function
   * @param {number} service_id - The id of the service
   * @returns {Promise<Response>} - service
   */

  static Delete(service_id) {
    return this.api().delete(`/services/${service_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: service_id,
    });
  }
}
