import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";
import LMSAssessment from "@/models/lms/LMSAssessment";
import LMSUser from "@/models/lms/LMSUser";
import { toNumber } from "lodash";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export class LMSAssessmentQuestion extends GenexLMSModel {
  static entity = "lms-assessment-questions";
  static classifications = ["knowledge", "comprehension", "application", "analysis", "synthesis", "evaluation"];
  static difficulties = ["A*-B", "C-D", "E-G"];
  static objectives = ["AO1", "AO2", "AO3", "AO4", "AO5", "AO6", "AO7", "AO8", "AO9", "AO10"];

  static answerOptions = [
    { value: 1, label: "True or False" },
    { value: 2, label: "Multiple Choice" },
    { value: 3, label: "Short Answer" },
    { value: 4, label: "Long Answer" },
    { value: 5, label: "Fill in the Blanks" },
    { value: 6, label: "Numerical" },
  ];

  static getClassificationId(classification) {
    return this.classifications.findIndex(c => c === classification) + 1;
  }

  static getDifficultyId(difficulty) {
    return this.difficulties.findIndex(d => d === difficulty) + 1;
  }

  static getObjectiveId(objective) {
    return this.objectives.findIndex(o => o === objective) + 1;
  }

  static mapIds(data) {
    data.learning_classifications = data.learning_classifications.map(c => this.getClassificationId(c));
    data.difficulty = this.getDifficultyId(data.difficulty);
    return data;
  }

  static makeTrueFalse(value) {
    const values = ["False", "True"];
    return values.map((answer, index) => {
      return {
        answer,
        is_correct: toNumber(value) === index,
        is_case_sensitive: false,
        ordinality: index,
      };
    });
  }

  static fields() {
    return {
      id: this.attr(null),
      assessment_id: this.attr(null),
      assessment: this.belongsTo(LMSAssessment, "assessment_id"),
      marks: this.attr(0),
      difficulty: this.attr(""),
      course_scope_id: this.attr(null),
      course_scope_code: this.attr(""),
      course_scope: this.attr(null),
      course_scope_topic_ids: this.attr(() => []),
      course_scope_topic_unit_ids: this.attr([]),
      learning_classifications: this.attr([]),
      learning_objectives_ids: this.attr([]),
      learning_objectives: this.attr([]),
      assessment_objectives: this.attr([]),
      answer_type: this.attr(0),
      title: this.attr(""),
      body: this.attr(""),
      max_attempts: this.attr(1),
      is_required_for_pass: this.attr(0),
      has_negative_marking: this.attr(0),
      penalty: this.attr({ hints: [], unit_percentage: 0 }),
      randomise_answer_order: this.attr(0),
      allowed_attempts: this.attr(1),
      feedback: this.attr([]),
      creator_id: this.attr(null),
      updater_id: this.attr(null),
      maximum_grade: this.attr(null),
      unit_handling: this.attr(null),
      unit_penalty: this.attr(null),
      unit_placement: this.attr(null),
      units: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      answers: this.attr([]),

      type: this.attr(""),
      creator: this.belongsTo(LMSUser, "creator_id"),
      updater: this.belongsTo(LMSUser, "updater_id"),
    };
  }

  static async FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    await this.deleteAll();
    return this.api().get(`/assessment-questions`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/assessment-questions/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Store(data) {
    return this.api().post(`/assessment-questions`, data, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Update(data) {
    return this.api().patch(`/assessment-questions/${data.id}`, data, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  static Delete(id) {
    return this.api().delete(`/assessment-questions/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      delete: id,
    });
  }
}
