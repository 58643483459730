import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class UnitObjective extends GenexSISModel {
  static entity = "unitObjective";

  static primaryKey = ["objective_id", "unit_id"];

  static fields() {
    return {
      objective_id: this.attr(null),
      unit_id: this.attr(null),
    };
  }
}
