import { GenexSISModel } from "@/models/sis/GenexSISModel";

/**
 * A collection of dashboard waiting stages
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardAdmissionsNewMonth extends GenexSISModel {
  static entity = "dashboard-admissions-new-month";
  static primaryKey = "month";

  static fields() {
    return {
      month: this.attr(null),
      counts: this.attr(() => {
        return {};
      }),
    };
  }
}
