<template>
  <div>
    <div :class="{ 'modal-card': inModal }" class="documentation-window">
      <header class="modal-card-head">
        <span
          class="modal-card-title has-text-weight-semibold is-size-3 is-flex is-align-items-center is-justify-content-space-between"
          >{{ headerText }}
        </span>
      </header>

      <PerfectScrollbarWrapper class="has-background-white">
        <section :class="{ 'modal-card-body': inModal }">
          <b-tabs v-model="$store.state.support.activeSupportTab" destroy-on-hide expanded type="is-boxed">
            <b-tab-item label="Log">
              <SupportTicketForm
                ref="supportTicketForm"
                :edit="$store.state.support.editingTicket"
                :in-modal="false"
                tenant-mode
                @submitted="cancel" />
            </b-tab-item>
            <b-tab-item label="My Tickets">
              <SupportTicketsTable tenant-mode />
            </b-tab-item>
          </b-tabs>
        </section>
      </PerfectScrollbarWrapper>
      <footer v-if="inModal" class="modal-card-foot">
        <b-field class="mt-3 is-fullwidth" grouped position="is-centered">
          <p class="control">
            <b-button type="is-warning" @click.prevent="cancel"> Close</b-button>
          </p>
          <p v-if="editingTicket" class="control">
            <b-button type="is-secondary" @click.prevent="setNewIssue"> Log new issue</b-button>
          </p>
          <p v-if="!editingTicket && activeSupportTab === 0" class="control">
            <b-button type="is-primary" @click="$refs.supportTicketForm.parentSubmit()"> Submit</b-button>
          </p>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
  import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
  import SupportTicketForm from "@/components/support/SupportTicketForm";
  import SupportTicketsTable from "@/components/support/SupportTicketsTable";
  import { mapState } from "vuex";

  export default {
    name: "TenantSupportModal",
    components: {
      PerfectScrollbarWrapper,
      SupportTicketForm,
      SupportTicketsTable,
    },
    data() {
      return {
        activeTab: 0,
        isActive: true,
        loading: false,
        submitMedia: false,
        mediaRecorder: null,
      };
    },
    props: {
      inModal: {
        type: Boolean,
        default() {
          return true;
        },
      },
      tenantMode: {
        type: Boolean,
        default() {
          return true;
        },
      },
      edit: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    watch: {
      supportWindowOpen(newVal) {
        if (newVal === false) {
          this.$emit("close");
        }
      },
    },
    computed: {
      ...mapState("support", ["activeSupportTab", "editingTicket", "supportWindowOpen"]),
      headerText() {
        if (this.tenantMode) {
          if (this.edit) {
            return "Edit Support Ticket";
          }
          return "Submit a Support Ticket";
        }
        if (this.edit) {
          return "Edit Support Ticket";
        }
        return "Create a Support Ticket";
      },
    },
    methods: {
      setNewIssue() {
        this.$store.dispatch("support/setNew").then(() => {
          this.$refs.supportTicketForm.setNew();
        });
      },
      cancel() {
        this.$store.dispatch("support/closeSupportWindow", true).then(() => {
          this.$emit("close");
        });
      },
    },
    mounted() {
      if (this.inModal) {
        this.makeModalLarge("animation-content");
      }
    },
  };
</script>
