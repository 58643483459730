import { useRoute, useRouter } from "vue-router/composables";
import { isEqual } from "lodash";
import { useErrorHandler } from "@/compositions/useErrorHandler";

export default {
  setup() {
    const $router = useRouter();
    const $route = useRoute();
    const { handleError } = useErrorHandler();
    const setQuery = async query => {
      let obj = Object.assign({}, $route.query);

      Object.keys(query).forEach(key => {
        let value = query[key];
        if (value) {
          obj[key] = value.toString();
        } else if (value == 0 || value == null) {
          obj[key] = "0";
        } else {
          delete obj[key];
        }
      });
      if (!isEqual(obj, $route.query)) {
        await $router.replace({
          ...$route,
          query: obj,
        });
      }
    };
    const removeQuery = async queryNameArray => {
      let obj = Object.assign({}, $route.query);
      queryNameArray.forEach(queryName => {
        delete obj[queryName];
      });
      await $router.replace({
        ...$route,
        query: obj,
      });
    };

    const getNumberFromRouteQuery = key => {
      let result = null;
      if ($route?.query && $route.query[key] !== undefined && !isNaN($route.query[key])) {
        result = +$route.query[key];
      }
      return result;
    };

    const getQuery = key => {
      if ($route?.query && $route.query[key] !== undefined) {
        return $route.query[key];
      }
    };

    return {
      getQuery,
      getNumberFromRouteQuery,
      removeQuery,
      setQuery,
      handleError,
    };
  },
  methods: {
    async handleResponse(call) {
      try {
        const data = await call();
        return [null, data];
      } catch (error) {
        return [error, null];
      }
    },
  },
};
