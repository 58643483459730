export const ReportModule = {
  namespaced: true,

  state: {
    statuses: [
      {
        id: 1,
        name: "Report Open",
      },
      {
        id: 2,
        name: "Build Report",
      },
      {
        id: 3,
        name: "Report Comments",
      },
      {
        id: 4,
        name: "General Comment",
      },
      {
        id: 5,
        name: "HoD Check",
      },
      {
        id: 6,
        name: "HoC Check",
      },
      {
        id: 7,
        name: "Report Closed",
        description: "Please note this will save and publish reports",
      },
      {
        id: 8,
        name: "Complete",
      },
      {
        id: 9,
        name: "Reports Sent",
      },
    ],
    subject_display_type: [
      { id: 1, name: "Percentage" },
      { id: 2, name: "Symbol" },
      { id: 3, name: "Individual Marks" },
      { id: 4, name: "None" },
    ],
    educator_display_type: [
      { id: 1, name: "None" },
      { id: 2, name: "Primary Educators" },
      { id: 3, name: "All Educators" },
    ],

    templates: [
      {
        id: 1,
        name: "Template 1",
        description: "Has student pathway on cover page",
      },
      {
        id: 2,
        name: "Template 2",
        description: "No summary, includes HomeClass name and Age on cover",
      },
    ],
    school_levels: [
      { name: "High School", id: 1 },
      { name: "Primary School", id: 2 },
    ],
  },
};
