<template>
  <div>
    <div :class="{ 'modal-card': inModal }" class="whats-new-window">
      <header class="modal-card-head px-6 py-0">
        <img :src="releasesLogoUrl" alt="GENEX: What's New" class="m-auto" style="max-width: 300px" />
      </header>

      <PerfectScrollbarWrapper class="has-background-white">
        <section :class="{ 'modal-card-body': inModal }">
          <div class="px-4">
            <b-field class="px-3">
              <div class="is-flex is-justify-content-end">
                <b-datepicker
                  v-model="releasesFilters.releaseMonth"
                  icon="calendar"
                  placeholder="Select month"
                  position="is-bottom-left"
                  trap-focus
                  type="month">
                </b-datepicker>
              </div>
            </b-field>
          </div>
          <p v-if="!releases.length" class="has-text-centered mb-6">No Updates Available</p>
          <ReleaseListItem v-for="release in releases" :key="release.id" :inModal="true" :release="release" />
        </section>
      </PerfectScrollbarWrapper>

      <footer v-if="inModal" class="modal-card-foot">
        <b-field class="mt-3 is-fullwidth" grouped>
          <b-checkbox v-model="updateOption">Show updates on next login</b-checkbox>
        </b-field>
      </footer>
    </div>
  </div>
</template>

<script>
  import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";
  import ReleaseListItem from "@/components/releases/ReleaseListItem";
  import { mapGetters, mapState } from "vuex";
  import Release from "@/models/sis/Release";
  import { endOfMonth, format } from "date-fns";

  export default {
    name: "TenantReleaseModal",
    components: {
      PerfectScrollbarWrapper,
      ReleaseListItem,
    },
    data() {
      return {
        loading: false,
        releasesHold: null,
        updateOption: this.$cookies.get("update_on_next_login") === "true",
        releasesFilters: {
          releaseMonth: null,
        },
      };
    },
    props: {
      inModal: {
        type: Boolean,
        default() {
          return true;
        },
      },
      type: {
        type: String,
        default: () => "",
      },
    },
    watch: {
      supportWindowOpen(newVal) {
        if (newVal === false) {
          this.$emit("close");
        }
      },
      filters() {
        this.setPage(this.page, true);
      },
      updateOption(newVal) {
        this.$cookies.set("update_on_next_login", newVal);
      },
    },
    computed: {
      ...mapState("release", ["releaseWindowOpen"]),
      ...mapGetters("tenant", ["releasesLogoUrl"]),
      releases() {
        if (this.releasesHold === null) {
          return Release.query().orderBy("release_date", "desc").get();
        }
        return this.releasesHold;
      },
      filters() {
        return {
          ...(this.releasesFilters.releaseMonth
            ? {
                // Formatting date and get the last day in month of selected date
                release_date: format(endOfMonth(this.releasesFilters.releaseMonth), "yyyy-MM-dd"),
              }
            : {}),
        };
      },
    },
    methods: {
      setPage(pageNumber, reload = false) {
        this.$store.dispatch("loader/show");
        this.releasesHold = this.releases;
        // DELETE ALL RELEASES IF RELOAD OPTION IS SET
        if (reload) {
          Release.deleteAll();
        }
        this.page = pageNumber;

        Release.FetchAll(
          {
            page: this.page,
            limit: this.limit,
            order_direction: "desc",
            order_by: "release_date",
          },
          this.filters,
          ["user"],
        ).then(() => {
          // if (!result.entities.releases?.length) {
          //   // alert("no modal")
          //   this.$emit("close");
          // }

          this.$store.dispatch("loader/hide");
          this.releasesHold = null;
        });
      },
    },
    mounted() {
      if (this.inModal) {
        this.makeModalLarge("animation-content");
      }
      this.setPage();
    },
  };
</script>

<style lang="scss" scoped>
  .whats-new-window {
    .modal-card-body {
      min-height: 350px;
    }
  }
</style>
