/** Styles */
import "@/assets/scss/main.scss";
import "@mdi/font/css/materialdesignicons.css";

/**  Core
 * */
import Vue, { provide } from "vue";
import Buefy from "buefy";
import VueCookies from "vue-cookies";
import App from "./App.vue";
import i18n from "@/icons/index";
import vueDebounce from "vue-debounce";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import SvgIcon from "@/components/icons/SvgIcon";
import mixpanel from "mixpanel-browser";

import AsideMenuList from "@/components/layout/AsideMenuList";
/** Router */
import router from "./router";
/**
 * Router Guards
 */
import "@/router/guards/Tenant";
import "@/router/guards/AuthGuard";
import "@/router/guards/Terms";
import "@/router/guards/Role";
import "@/router/guards/Permission";
import "@/router/guards/MobileMenu";
import "@/router/guards/Loader";
import "@/router/guards/Title";
import "@/router/guards/SideMenuGuard";

/** Store */
import store from "./store";
import makeModalLarge from "@/mixins/LargeModal";
import track from "@/mixins/Tracking";
import resetTracking from "@/mixins/Tracking";
import MatchFilters from "@/mixins/MatchFilters";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import PermissionMixin from "@/mixins/PermissionMixin";
import WidgetManager from "@/mixins/WidgetManager";

import VueSignaturePad from "vue-signature-pad";
import { EmojiPickerPlugin } from "vue-emoji-picker";
import OldMixin from "@/mixins/OldMixin";
import "froala-editor/js/plugins.pkgd.min.js";
//Import third party plugins
import "froala-editor/js/third_party/embedly.min";
import "froala-editor/js/third_party/font_awesome.min";
import "froala-editor/js/third_party/image_tui.min";
// Import Froala Editor css files.
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import VueMathjax from "vue-mathjax";
import VueFroala from "vue-froala-wysiwyg";

Vue.use(VueFroala);
Vue.use(VueMathjax);

Vue.use(EmojiPickerPlugin);

Vue.use(vueDebounce, {
  defaultTime: "300ms",
});
Vue.use(VueTelInput);

Vue.use(VueCookies);

Vue.use(Buefy, {
  defaultIconPack: null,
  defaultIconComponent: SvgIcon,
  defaultSnackbarPosition: "is-top",
});

//Vue.use(VueQueryPlugin);

mixpanel.init(process.env.VUE_APP_MIXPANEL_PROJECT_TOKEN, { debug: false });
mixpanel.register({
  Platform: GenexHelperMixins.methods.isMobileMixin() ? "Mobile" : "Web",
});

Vue.use(VueTelInput);

Vue.use(VueCookies);

Vue.use(Buefy, {
  defaultIconPack: null,
  defaultIconComponent: SvgIcon,
});

Vue.component("AsideMenuList", AsideMenuList);

Vue.use(VueSignaturePad);
Vue.$cookies.config(-1, "", "", true);

/** Prototypes */
// Vue.prototype.$cookie = VueCookies;
Vue.mixin(makeModalLarge);
Vue.mixin(track);
Vue.mixin(resetTracking);
Vue.mixin(MatchFilters);
Vue.mixin(GenexHelperMixins);
Vue.mixin(PermissionMixin);
Vue.mixin(WidgetManager);
Vue.mixin(OldMixin);

//Vue.use(VueQueryPlugin);

Vue.config.productionTip = false;

export default new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
  setup() {
    const featureFlagsEnv = process.env.VUE_APP_FEATURE_FLAGS;
    const featureFlags = featureFlagsEnv ? JSON.parse(featureFlagsEnv) : {};
    provide("featureFlags", featureFlags);
  },
}).$mount("#app");
