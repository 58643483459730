<template>
  <div v-if="value.length > 0" class="upload-file-list column is-12">
    <div class="notification p-3">
      <form @submit.prevent="submitMedia">
        <template v-for="(dropFile, index) of value">
          <div :key="index" class="mb-3 box notification p-3">
            <div class="is-flex is-fullwidth is-justify-content-space-between is-align-items-center">
              <div>
                <p class="is-flex is-align-items-center">
                  <b-icon icon="document-upload" custom-size="default" class="has-text-grey mr-2"></b-icon>
                  <span>{{ dropFile.name }}</span>
                </p>
              </div>
              <div @click="deleteDropFile(index)">
                <b-icon icon="close-circle"></b-icon>
              </div>
            </div>
            <b-field group-multiline grouped>
              <b-field v-if="!fieldDisableCaption" class="control">
                <b-input v-model="dropFile.caption" name="caption" placeholder="Describe the upload" />
              </b-field>
              <b-field v-if="hasTag" class="control">
                <b-select v-model="dropFile.tag" placeholder="Select a tag" required>
                  <option v-for="(tag, index) in tags" :key="index" :value="tag">
                    {{ tag }}
                  </option>
                </b-select>
              </b-field>
              <b-field class="control">
                <b-switch
                  v-if="showGuardianVisibility"
                  v-model="dropFile.uploadVisibility"
                  false-value="0"
                  true-value="1">
                  Guardian Visibility
                </b-switch>
              </b-field>
              <b-field class="control">
                <b-switch v-if="canViewSensitiveMedia" v-model="dropFile.isSensitive" false-value="0" true-value="1">
                  Sensitive Document
                </b-switch>
              </b-field>
            </b-field>
          </div>
        </template>
        <b-field v-if="submit_button" class="">
          <b-field grouped>
            <div class="control">
              <b-button native-type="submit" type="is-primary">
                Submit upload{{ value.length > 1 ? "s" : "" }}
              </b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </div>
  </div>
</template>

<script>
  import Media from "@/models/sis/Media";

  export default {
    name: "MediaPanelFileToUpload",
    props: {
      value: {
        type: Array,
        default() {
          return [];
        },
      },
      fieldDisableCaption: {
        type: Boolean,
        default: true,
      },
      hasTag: {
        type: Boolean,
        default: true,
      },
      showGuardianVisibility: {
        type: Boolean,
        default: false,
      },
      canViewSensitiveMedia: {
        type: Boolean,
        default: false,
      },
      submit_button: {
        type: Boolean,
        default: true,
      },
      model_id: {
        type: Number,
        required: true,
      },
      model: {
        type: String,
        required: true,
      },
      mediable_type: {
        type: String,
        required: false,
      },
      tags: {
        type: Array,
        default() {
          return [];
        },
      },
      default_tag: {
        type: String,
        default: null,
      },
      updatingMedia: {
        type: Boolean,
        default: false,
      },
      isCentral: {
        type: Boolean,
        default: false,
      },
      submit_flip: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      deleteDropFile(index) {
        const e = this.value.slice();
        e.splice(index, 1);
        this.$emit("input", e);
      },

      submitMedia() {
        this.$emit("setUpdatingMedia", true);
        let uploads = [];

        if (isNaN(this.model_id)) {
          this.$emit("submitted");
          this.$emit("input", []);
          this.$emit("setUpdatingMedia", false);
        } else {
          this.value.map(file => {
            let formData = new FormData();
            formData.append("file", file);
            if (this.default_tag === null) {
              formData.append("tag", file.tag);
            } else {
              formData.append("tag", this.default_tag);
            }
            formData.append("caption", file.caption ?? "");

            const submitIsSensitive = file.isSensitive ?? 0;
            formData.append("is_sensitive", submitIsSensitive);

            let submitRequestGuardianVis = 1;
            if (this.showGuardianVisibility) {
              submitRequestGuardianVis = typeof file.uploadVisibility !== "undefined" ? file.uploadVisibility : 0;
            }
            formData.append("visibility", submitRequestGuardianVis);
            uploads.push(
              Media.Store(
                {
                  model: this.model,
                  model_id: this.model_id,
                },
                formData,
                this.isCentral,
              ),
            );
          });

          Promise.all(uploads)
            .then(() => {
              if (this.value.length > 0) {
                this.$buefy.snackbar.open(`Media uploaded!`);
              }
              this.$emit("input", []);
              this.$emit("submitted");
              this.$emit("setUpdatingMedia", false);
            })
            .catch(err => {
              this.$emit("setUpdatingMedia", false);
              this.handleError(err);
            });
        }
      },
    },
    watch: {
      submit_flip(val) {
        if (val === true) {
          this.submitMedia();
        }
      },
    },
  };
</script>
