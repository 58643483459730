import { normalize } from "@/utils/data";
import Tenant from "@/models/sis/Tenant";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An Alert
 * @property {number} duration - The duration of the alert
 * @property {string} message - The message of the alert
 * @property {number} audience_id - The id of the target audience
 * @property {number} is_lms - The toggle for LMS
 * @property {number} tenant_ids - The tenant id
 * @property {object} tenant_ids.* - The ???
 * @property {number} creator_id - The creator id
 */

export default class Alert extends GenexSISModel {
  static entity = "alerts";

  static fields() {
    return {
      id: this.attr(null),
      duration_hours: this.attr(null),
      message: this.attr(null),
      audience_id: this.attr(null),
      is_lms: this.attr(null),
      tenant_ids: this.attr(() => []),
      tenants: this.hasManyBy(Tenant, "tenant_ids"),
      creator_id: this.attr(null),
    };
  }

  /**
   * Returns Alerts
   * @function
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get(`/alerts`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/alerts/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Store a new Alert
   * @function
   * @param {Object} alert - The alert object
   * @returns {Promise<Response>} - The newly created alert
   */

  static Store(alert) {
    return this.api().post(`/alerts`, alert, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Update(alert) {
    return this.api().patch(`/alerts/${alert.id}`, alert, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  /**
   * Delete an existing Alert
   * @function
   * @param {number} alert_id - The id of the alert
   * @returns {Promise<Response>} - The status of deleting
   */

  static Delete(alert_id) {
    return this.api().delete(`/alerts/${alert_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: alert_id,
    });
  }
}
