<template>
  <ul :class="{ 'menu-list': !isSubmenuList, 'submenu-list': isSubmenuList }" class="is-relative">
    <template v-for="(item, index) in menu">
      <aside-menu-item
        v-if="!item.hide"
        :key="index"
        :has-fade-in="hasFadeIn"
        :has-fade-out="hasFadeOut"
        :isSub="isSubmenuList"
        :item="item"
        @menu-click="menuClick" />
    </template>
  </ul>
</template>

<script>
  import AsideMenuItem from "@/components/layout/AsideMenuItem";

  export default {
    name: "AsideMenuList",
    components: {
      AsideMenuItem,
    },
    props: {
      hasFadeIn: {
        type: Boolean,
        default: false,
      },
      hasFadeOut: {
        type: Boolean,
        default: false,
      },
      isSubmenuList: {
        type: Boolean,
        default: false,
      },
      menu: {
        type: Array,
        default: () => [],
      },
    },
    methods: {
      menuClick(item) {
        this.$emit("menu-click", item);
      },
    },
  };
</script>

<style scoped></style>
