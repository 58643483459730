<template>
  <div>
    <b-field v-if="hasTag" label="Category">
      <b-select v-model="selectedTag" placeholder="All" @input="setPage(1)">
        <option :value="null">All</option>
        <option v-for="tag in tags" :key="tag" :value="tag">
          {{ tag }}
        </option>
      </b-select>
    </b-field>
    <PerfectScrollbarWrapper>
      <b-table
        :bordered="false"
        :data="media"
        :hoverable="true"
        :striped="true"
        class="margin-top"
        @cellclick="selectRow">
        <template #empty>
          <div class="has-text-centered">No files.</div>
        </template>
        <b-table-column v-slot="props" :sortable="media.length > 0" field="display_name" label="Name">
          {{ props.row.display_name }}
        </b-table-column>
        <b-table-column v-if="hasTag" v-slot="props" :sortable="media.length > 0" field="tag" label="Tag">
          <div @click.stop>
            <b-select v-model="props.row.tag" :disabled="!canEdit" placeholder="All" @input="updateMedia(props.row)">
              <option v-for="tag in tags" :key="tag" :value="tag">
                {{ tag }}
              </option>
            </b-select>
          </div>
        </b-table-column>
        <b-table-column
          v-if="!fieldDisableCaption"
          v-slot="props"
          :sortable="media.length > 0"
          field="caption"
          label="Caption">
          {{ props.row.caption }}
        </b-table-column>
        <b-table-column
          v-if="!fieldDisableExtension"
          v-slot="props"
          :sortable="media.length > 0"
          field="extension"
          label="Type">
          {{ props.row.extension.toUpperCase() }}
        </b-table-column>
        <b-table-column v-if="fieldEnableSize" v-slot="props" :sortable="media.length > 0" field="size" label="Size">
          {{ makeStorageSizeHumanReadable(props.row.size) }}
        </b-table-column>
        <b-table-column
          v-if="fieldEnableUpdateDate"
          v-slot="props"
          :sortable="media.length > 0"
          field="updated_at"
          label="Updated">
          {{ getHumanReadableDate(props.row.updated_at) }}
        </b-table-column>
        <b-table-column
          v-if="fieldEnableCreationDate"
          v-slot="props"
          :sortable="media.length > 0"
          field="created_at"
          label="Created">
          {{ getHumanReadableDate(props.row.created_at) }}
        </b-table-column>
        <b-table-column
          v-if="showGuardianVisibility"
          v-slot="props"
          field="visibility"
          label="Guardian Visibility"
          sortable>
          <div @click.stop>
            <b-switch
              v-model="props.row.visibility"
              :disabled="hasNoPermissions(['edit media'])"
              :false-value="0"
              :true-value="1"
              @input="updateMedia(props.row)">
              {{ props.row.visibility === 1 ? "Visible" : "Not Visible" }}
            </b-switch>
          </div>
        </b-table-column>
        <b-table-column
          v-if="canViewSensitiveMedia"
          v-slot="props"
          field="is_sensitive"
          label="Sensitive Document"
          sortable>
          <div @click.stop>
            <b-switch
              v-model="props.row.is_sensitive"
              :disabled="hasNoPermissions(['edit media'])"
              :false-value="0"
              :true-value="1"
              @input="updateMedia(props.row)">
              {{ props.row.is_sensitive === 1 ? "Sensitive" : "Not Sensitive" }}
            </b-switch>
          </div>
        </b-table-column>
        <b-table-column v-if="canEdit || canDelete" v-slot="props" custom-key="actions">
          <b-field grouped>
            <b-dropdown append-to-body aria-role="list" position="is-bottom-left">
              <template #trigger>
                <div>
                  <b-icon icon="more" class="ml-1 has-text-grey is-pulled-left" />
                </div>
              </template>
              <b-dropdown-item v-if="canEdit" aria-role="listitem" @click="rename(props.row)"> Rename</b-dropdown-item>
              <slot :entity="props.row"></slot>
              <b-dropdown-item v-if="canDelete" aria-role="listitem" @click="startDelete(props.row)">
                Delete
              </b-dropdown-item>
            </b-dropdown>
          </b-field>
        </b-table-column>
      </b-table>
    </PerfectScrollbarWrapper>

    <b-pagination
      :current="page"
      :per-page="limit"
      :range-after="2"
      :range-before="2"
      :total="meta.total"
      aria-current-label="Current page"
      aria-next-label="Next page"
      aria-page-label="Page"
      aria-previous-label="Previous page"
      class="mt-4"
      @change="setPage" />
  </div>
</template>

<script>
  import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper.vue";
  import Media from "@/models/sis/Media";
  import MediaPanelModalViewImage from "./MediaPanelModalViewImage.vue";
  import MediaPanelModalRename from "./MediaPanelModalRename.vue";
  import GenexHelperMixins from "@/mixins/GenexHelperMixins";
  import GenexUtils from "@/utils/GenexUtils";

  export default {
    name: "MediaPanelStagedFiles",

    components: {
      PerfectScrollbarWrapper,
    },
    props: {
      canEdit: {
        type: Boolean,
        default: false,
      },
      hasTag: {
        type: Boolean,
        default: true,
      },
      tags: {
        type: Array,
        default() {
          return [];
        },
      },
      fieldDisableCaption: {
        type: Boolean,
        default: true,
      },
      fieldDisableExtension: {
        type: Boolean,
        default: true,
      },
      showGuardianVisibility: {
        type: Boolean,
        default: false,
      },
      canViewSensitiveMedia: {
        type: Boolean,
        default: false,
      },
      canDelete: {
        type: Boolean,
        default: false,
      },
      fetchOnMount: {
        type: Boolean,
        default: true,
      },
      dropFiles: {
        type: Array,
        default() {
          return [];
        },
      },
      model_id: {
        type: Number,
        required: true,
      },
      model: {
        type: String,
        required: true,
      },
      mediable_type: {
        type: String,
        required: false,
      },
      isCentral: {
        type: Boolean,
        default: false,
      },
      fieldEnableSize: {
        type: Boolean,
        required: false,
      },
      fieldEnableUpdateDate: {
        type: Boolean,
        required: false,
      },
      fieldEnableCreationDate: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        page: 1,
        selectedTag: null,
        limit: 15,
        offset: 0,
        meta: {},
        visibility: 0,
        paginationLimit: 15,
        order_by: "created_at",
        order_direction: "desc",
        mediaDataHold: null,
      };
    },
    watch: {
      entity() {
        this.setPage(1);
      },
      selectedTag(newVal) {
        this.$emit("setSelectedTag", newVal);
        this.setPage(1);
      },
      dropFiles(newValue) {
        if (newValue.length == 0) {
          this.setPage(1);
        }
      },
    },
    methods: {
      updateMedia(media) {
        this.$emit("setUpdatingMedia", true);
        Media.Update(
          {
            id: parseInt(media.id),
            filename: media.filename,
            tag: media.tag,
            visibility: media.visibility,
            caption: media.caption,
            is_sensitive: media.is_sensitive,
          },
          this.isCentral,
        )
          .then(() => {
            this.$buefy.snackbar.open(`Media updated!`);
            this.$emit("setUpdatingMedia", false);
          })
          .catch(err => {
            this.handleError(err);
            this.$emit("setUpdatingMedia", false);
          });
      },
      makeStorageSizeHumanReadable(arg) {
        return GenexUtils.makeStorageSizeHumanReadable(arg);
      },
      getHumanReadableDate(arg) {
        return GenexHelperMixins.methods.getHumanReadableDate(arg);
      },
      selectRow(entity, cell = null) {
        if (cell !== null) {
          if (cell._props.customKey === "actions") {
            return;
          }
        }
        if (entity.aggregate_type == "image") {
          this.$buefy.modal.open({
            parent: this,
            props: {
              entity: entity,
            },
            component: MediaPanelModalViewImage,
            customClass: "modalEnableWidthChange",
            fullScreen: false,
            trapFocus: true,
            events: {},
          });
        } else {
          window.open(entity.temporary_url, "_blank").focus();
        }
      },
      rename(entity) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            entityProp: entity,
            isCentral: this.isCentral,
          },
          component: MediaPanelModalRename,
          fullScreen: false,
          trapFocus: true,
          events: {
            setUpdatingMedia: () => {
              this.$emit("setUpdatingMedia", false);
            },
          },
        });
      },
      setPage(pageNumber) {
        this.$emit("setUpdatingMedia", true);
        this.page = pageNumber;
        Media.FetchAll(
          { page: this.page, limit: this.limit },
          {
            mediable_type: this.model,
            model: this.model_id,
            tag: this.selectedTag,
            order_by: this.order_by,
            order_direction: this.order_direction,
          },
          [],
          this.isCentral,
        )
          .then(response => {
            this.meta = response?.response.data.meta;
            this.$emit("setUpdatingMedia", false);
          })
          .catch(err => {
            this.$emit("setUpdatingMedia", false);
            this.handleError(err);
          });
      },
      delete(media) {
        this.$emit("setUpdatingMedia", true);
        Media.Delete(media.id)
          .then(() => {
            this.$buefy.snackbar.open("Media item deleted!");
            this.$emit("setUpdatingMedia", false);
          })
          .catch(err => {
            this.$emit("setUpdatingMedia", false);
            this.handleError(err);
          });
      },
      startDelete(media) {
        this.$buefy.dialog.confirm({
          title: "Deleting media",
          confirmText: "Delete Media",
          hasIcon: true,
          type: "is-danger",
          message: "Are you sure you want to delete this media item?",
          onConfirm: () => this.delete(media),
        });
      },
    },
    computed: {
      entity() {
        return {
          type: this.model,
          id: this.model_id,
        };
      },
      media() {
        return Media.query()
          .where("mediable_type", this.mediable_type ?? this.model)
          .where("mediable_id", this.model_id)
          .where(media => {
            if (!this.selectedTag) {
              return true;
            }
            return this.selectedTag === media.tag;
          })
          .offset((this.page - 1) * this.limit)
          .limit(this.limit)
          .get();
      },
    },
    mounted() {
      if (this.fetchOnMount) {
        this.setPage(1);
      }
    },
  };
</script>

<style lang="scss"></style>
