import Assessment from "./Assessment";
import User from "./User";
import AssessmentFeedback from "./AssessmentFeedback";
import { normalize } from "@/utils/data";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Activity from "@/models/sis/Activity";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class AssessmentSubmission extends GenexSISModel {
  static entity = "assessmentSubmissions";

  static fields() {
    return {
      id: this.attr(null),
      content: this.attr(""),
      marks: this.attr(null),
      student_id: this.attr(null),
      assessment_id: this.attr(null),
      status_id: this.attr(null),
      status: this.attr(null),
      is_published: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      assessment: this.belongsTo(Assessment, "assessment_id"),
      student: this.belongsTo(User, "student_id"),
      feedback: this.hasMany(AssessmentFeedback, "assessment_submission_id"),
      activity_ids: this.attr([]),
      activities: this.hasManyBy(Activity, "activity_ids"),
      is_overdue: this.attr(0),
      grade: this.attr(null),
    };
  }

  /**
   * Returns all AssessmentSubmissions
   * @param {Object} pagination - Pagination parameters
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {string} [mode="insertOrUpdate"] - How to persist the response
   * @returns {Promise<Response>} - Promise containing the AssessmentSubmissions
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get("/assessment-submissions", {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns a single AssessmentSubmission by ID
   * @param {number} id - The ID of the AssessmentSubmission
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} - Promise containing the AssessmentSubmission
   */
  static FetchById(id, relationships = []) {
    return this.api().get(`/assessment-submissions/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Stores a new AssessmentSubmission
   * @param {Object} assessmentSubmission - The AssessmentSubmission object to store
   * @returns {Promise<Response>} - Promise containing the newly created AssessmentSubmission
   */
  static Store(assessmentSubmission) {
    return this.api().post("/assessment-submissions", assessmentSubmission, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Updates an existing AssessmentSubmission
   * @param {Object} assessmentSubmission - The AssessmentSubmission object to update
   * @returns {Promise<Response>} - Promise containing the updated AssessmentSubmission
   */
  static Update(assessmentSubmission) {
    return this.api().patch(`/assessment-submissions/${assessmentSubmission.id}`, assessmentSubmission, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  /**
   * Deletes an existing AssessmentSubmission
   * @param {number} assessmentSubmission_id - The ID of the AssessmentSubmission to delete
   * @returns {Promise<Response>} - Promise representing the delete operation
   */
  static Delete(assessmentSubmission_id) {
    return this.api().delete(`/assessment-submissions/${assessmentSubmission_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: assessmentSubmission_id,
    });
  }
}
