import LessonPlanAi from "@/models/sis/LessonPlanAi";

function formatText(text) {
  let output = text
    .replace(/^```html\s*/, "")
    .replace(/\s*```$/, "")
    .replace(/\n/g, "<br>");
  return output;
}

export const LessonPlanAiModule = {
  namespaced: true,
  state: {
    isGeneratedPlan: false,
    isGeneratedContent: false,
    lessonPlan: null,
    lessonContent: null,
    planText: "",
    contentText: "",
    loading: false,
    error: null,
  },
  getters: {
    getIsGeneratedPlan: state => state.isGeneratedPlan,
    getIsGeneratedContent: state => state.isGeneratedContent,
    getLessonPlan: state => state.lessonPlan,
    getLessonContent: state => state.lessonContent,
    getPlanText: state => state.planText,
    getContentText: state => state.contentText,
    getLoading: state => state.loading,
    getError: state => state.error,
  },
  mutations: {
    set_generated_plan(state, generated) {
      state.isGeneratedPlan = generated;
    },
    set_generated_content(state, generated) {
      state.isGeneratedContent = generated;
    },
    set_lesson_plan(state, lesson) {
      state.lessonPlan = lesson;
    },
    set_lesson_content(state, lesson) {
      state.lessonContent = lesson;
    },
    set_plan_text(state, text) {
      state.planText = text;
    },
    set_content_text(state, text) {
      state.contentText = text;
    },
    set_loading(state, loading) {
      state.loading = loading;
    },
    set_error(state, error) {
      state.error = error;
    },
  },
  actions: {
    generateLessonPlan({ commit }, lessonPlan) {
      commit("set_loading", true);
      //transform lessonPlan to the correct the object format
      let strategies = lessonPlan.teaching_strategies?.map(plan => plan.id);
      let objectives = lessonPlan.objectives?.map(obj => obj.id);
      lessonPlan.teaching_strategies = strategies;
      lessonPlan.objectives = objectives;
      lessonPlan.differentiation = lessonPlan.differentiation == "Yes" ? true : false;
      lessonPlan.technological_integration = lessonPlan.technological_integration.includes("Yes") ? true : false;

      LessonPlanAi.Post(`/ai/lesson-plans/generate`, lessonPlan)
        .then(res => {
          const result = res.response;
          if (result.status === 200) {
            commit("set_lesson_plan", {
              planOutput: formatText(result.data.text),
              prompt_plan_id: result.data.prompt_id,
            });
            commit("set_plan_text", result.data.text);
          } else {
            //handle errors
            commit("set_error", result.status);
            commit("set_loading", false);
          }
          commit("set_generated_plan", true);
          commit("set_loading", false);
        })
        .catch(error => {
          commit("set_loading", true);
          commit("set_error", error);
          commit("set_loading", false);
        });
    },
    generateLessonContent({ commit }, lessonContent) {
      commit("set_loading", true);
      //transform lessonContent the correct the object format
      let stages = lessonContent.knowledge_stages?.map(stage => stage.id);
      let objectives = lessonContent.objectives?.map(obj => obj.id);
      lessonContent.knowledge_stages = stages;
      lessonContent.objectives = objectives;
      lessonContent.multimedia = lessonContent.multimedia == "Yes" ? true : false;
      lessonContent.keywords = lessonContent.keywords == "Yes" ? true : false;

      LessonPlanAi.Post(`/ai/lessons/generate`, lessonContent)
        .then(res => {
          const result = res.response;
          if (result.status === 200) {
            commit("set_lesson_content", {
              contentOutput: formatText(result.data.text),
              prompt_content_id: result.data.prompt_id,
            });
            commit("set_content_text", result.data.text);
          } else {
            //handle errors
            commit("set_error", result.status);
            commit("set_loading", false);
          }
          commit("set_generated_content", true);
          commit("set_loading", false);
        })
        .catch(error => {
          commit("set_loading", true);
          commit("set_error", error);
          commit("set_loading", false);
        });
    },
    acceptLessonPlan({ commit }) {
      commit("set_generated_plan", false);
      commit("set_plan_text", "");
    },
    acceptLessonContent({ commit }) {
      commit("set_generated_content", false);
      commit("set_content_text", "");
    },
    closeLessonAi({ commit }) {
      commit("set_generated_plan", false);
      commit("set_generated_content", false);
      commit("set_plan_text", "");
      commit("set_content_text", "");
    },
  },
};
