<template>
  <form id="supportTicket" @submit.prevent="submit">
    <div :class="{ 'modal-card': inModal }" class="documentation-window">
      <header v-if="inModal" class="modal-card-head">
        <span
          class="modal-card-title has-text-weight-semibold is-size-3 is-flex is-align-items-center is-justify-content-space-between"
          >{{ headerText }}</span
        >
      </header>

      <section :class="{ 'modal-card-body': inModal }">
        <b-field v-if="edit" label="Logged At">
          <b-datetimepicker
            :disabled="true"
            icon="calendar"
            :value="new Date(ticketObject.created_at)"
            locale="en-GB"
            placeholder="Click to select..."
            trap-focus>
          </b-datetimepicker>
        </b-field>
        <b-field v-if="edit && ticketObject.completed_at !== null" label="Completed At">
          <b-datetimepicker
            :disabled="true"
            icon="calendar"
            :value="new Date(ticketObject.completed_at)"
            locale="en-GB"
            placeholder="Click to select..."
            trap-focus
            horizontal-time-picker>
          </b-datetimepicker>
        </b-field>
        <b-field label="Category" message="What seems to be the issue?">
          <b-select
            v-model="ticketObject.category"
            :disabled="tenantDisabled"
            expanded
            placeholder="Select Category"
            required>
            <option v-for="category of this.$store.state.support.categories" :key="category" :value="category">
              {{ category }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Page URL" message="Is this the page you are on?">
          <b-input
            v-model="ticketObject.url"
            :disabled="tenantDisabled"
            placeholder="URL of Page With Issue"
            required
            type="url">
          </b-input>
        </b-field>
        <b-field label="Description" message="Describe the issue, detailing what you clicked when you encountered it.">
          <b-input
            v-model="ticketObject.description"
            :disabled="tenantDisabled"
            placeholder="Describe the issue"
            required
            type="textarea">
          </b-input>
        </b-field>
        <b-field v-if="!tenantMode" label="Tenant">
          <b-select v-model="ticketObject.tenant_id" disabled="" expanded placeholder="Tenant" required>
            <option v-for="tenant in tenants" :key="tenant.id" :value="tenant.id">
              {{ tenant.school_name }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="!tenantMode && ticketObject.tenant_campus" label="Campus">
          <b-input
            :value="`${ticketObject.campus_id} | ${ticketObject.tenant_campus.attributes.name}`"
            disabled></b-input>
        </b-field>

        <b-field v-if="!tenantMode" label="User">
          <UsersFilter
            :disabled="true"
            :selected_user_id="ticketObject.user_id"
            :type="'autocomplete'"
            existing-user
            @selected="option => (ticketObject.user_id = option.id)"></UsersFilter>
        </b-field>

        <b-field v-if="!tenantMode" label="Support Agent">
          <UsersFilter
            :selected_user_id="ticketObject.support_agent_id"
            :type="'autocomplete'"
            existing-user
            with_permission="view support-tickets"
            @selected="option => (ticketObject.support_agent_id = option.id)"></UsersFilter>
        </b-field>
        <b-field
          v-if="campuses.length > 1"
          label="Campus"
          message="Which campus's data were you looking at when you encountered the issue?">
          <b-select
            v-model="ticketObject.campus_id"
            :disabled="edit && tenantMode"
            expanded
            placeholder="Campus"
            required>
            <option v-for="campus in campuses" :key="campus.id" :value="campus.id">
              {{ campus.name }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="!tenantMode || edit" label="Status" message="Where your ticket is at in the support process">
          <b-select
            v-model="ticketObject.status_id"
            :disabled="tenantDisabled"
            expanded
            placeholder="Status of the ticket"
            required>
            <option v-for="status in $store.state.support.statuses" :key="status.id" :value="status.id">
              {{ status.name }}
            </option>
          </b-select>
        </b-field>
        <b-field
          v-if="tenantMode"
          label="Screen Recording"
          message="Show us what you were doing when you encountered the issue and reproduce it if you can.">
          <b-button type="is-primary" @click.prevent="startCapture">Start Capture</b-button>
        </b-field>
        <MediaPanel
          v-if="showMedia"
          ref="mediaPanel"
          :canCreate="true"
          :canDelete="!tenantDisabled"
          :fetch-on-mount="edit"
          :has-tag="false"
          :message="'Please upload a file with the applicable file name. For the caption, you may expand on the file description with a few words.'"
          :model="'tickets'"
          :model_id="parseInt(ticketObject.id)"
          :programmatic-file="$store.state.support.file"
          :submit_button="tenantMode && edit"
          :submit_flip="tenantMode && edit ? null : submitMedia"
          :title="'Description/Screen Recording/etc'"
          class="headerStyling"
          is-central
          @submitted="handleMediaSubmit"
          fieldEnableIsSensitive
          fieldDisableExtension />
        <NotesPanel
          v-if="showNotes"
          :canCreate="true"
          :canDelete="!tenantDisabled"
          :disable_delete="tenantDisabled"
          :disabled_edit="tenantDisabled"
          :fetch-on-mount="edit"
          :model="'Ticket'"
          :model_id="parseInt(ticketObject.id)"
          :placeholder="'Write your message here'"
          :show-types="false"
          :submit_button="false"
          :submit_flip="submitNotes"
          :submit_text="'Send'"
          :title="$store.getters['tenant/isCentral'] ? 'Talk to the tenant' : 'Talk to our support team'"
          conversation-mode
          is-central
          required
          @submitted="
            submitNotes = false;
            $emit('close');
          " />
        <NotesPanel
          v-if="showNotes && !tenantMode"
          :canCreate="true"
          :canDelete="!tenantDisabled"
          :fetch-on-mount="edit"
          :is-internal="true"
          :model="'Ticket'"
          :model_id="parseInt(ticketObject.id)"
          :show-types="false"
          :submit_button="false"
          :submit_flip="submitNotes"
          :title="'Internal Notes'"
          is-central
          required
          @submitted="
            submitNotes = false;
            $emit('close');
          " />
      </section>
      <input ref="parentSubmit" class="is-hidden" type="submit" />

      <footer v-if="inModal" class="modal-card-foot">
        <b-field class="mt-3 is-fullwidth" grouped position="is-centered">
          <p class="control">
            <b-button type="is-warning" @click.prevent="cancel"> Cancel</b-button>
          </p>

          <p class="control">
            <b-button
              :disabled="!this.ticketObject.category || !this.ticketObject.url || !this.ticketObject.description"
              native-type="submit"
              type="is-primary">
              Submit
            </b-button>
          </p>
        </b-field>
      </footer>
    </div>
  </form>
</template>

<script>
  import MediaPanel from "@/components/media/MediaPanel";
  import SupportTicket from "@/models/sis/SupportTicket";
  import NotesPanel from "@/components/users/NotesPanel";
  import UsersFilter from "@/components/panelled-dash/UsersFilter";
  import Tenant from "@/models/sis/Tenant";
  import Campus from "@/models/sis/Campus";

  export default {
    name: "SubmitSupportTicketWindow",
    components: {
      MediaPanel,
      NotesPanel,
      UsersFilter,
    },
    data() {
      return {
        isActive: true,
        loading: false,
        submitMedia: false,
        submitNotes: false,
        showMedia: !this.edit,
        showNotes: false,
        mediaRecorder: null,
        ticketObject: {
          id: null,
          category: null,
          url: null,
          description: null,
          campus_id: null,
          tenant_id: null,
          user_id: this.tenantMode ? this.$store.state.user.id : null,
          user_name: null,
          status_id: 1,
          support_agent_id: null,
        },
      };
    },
    props: {
      inModal: {
        type: Boolean,
        default() {
          return true;
        },
      },
      tenantMode: {
        type: Boolean,
        default() {
          return true;
        },
      },
      edit: {
        type: Boolean,
        default() {
          return false;
        },
      },
      ticket: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    computed: {
      campuses() {
        return Campus.query()
          .where("name", value => {
            return value !== "";
          })
          .orderBy("ordinality")
          .get();
      },
      tenantDisabled() {
        return this.tenantMode && this.edit;
      },
      tenants() {
        return Tenant.query().orderBy("name").get();
      },
      headerText() {
        if (this.tenantMode) {
          if (this.edit) {
            return "Edit Support Ticket";
          }
          return "Submit a Support Ticket";
        }
        if (this.edit) {
          return "Edit Support Ticket";
        }
        return "Create a Support Ticket";
      },
    },
    methods: {
      setNew() {
        this.ticketObject = this.$store.state.support.issue;
        this.showNotes = false;
        this.showMedia = false;
        this.showMedia = true;
      },
      parentSubmit() {
        this.$refs.parentSubmit.click();
      },
      cancel() {
        this.$store.dispatch("support/closeSupportWindow", true).then(() => {
          this.$emit("close");
        });
      },
      startCapture() {
        this.$store
          .dispatch("support/startCapture")
          .then(() => {
            this.$store.dispatch("support/closeSupportWindow", false);
          })
          .catch(() => {
            this.$store.dispatch("toast/createToast", {
              message: "Capture permission denied",
            });
          });
      },
      handleMediaSubmit() {
        this.submitMedia = false;
        this.$store.state.support.file = null;
        if (this.tenantMode && !this.edit) {
          this.$emit("close");
          this.$emit("submitted");
        }
      },
      submit() {
        if (!this.loading) {
          this.loading = true;
          if (this.tenantMode && this.edit) {
            this.submitMedia = true;
            this.submitNotes = true;
          } else if (!this.edit) {
            this.ticketObject.user_name = this.$store.state.user.first_name + " " + this.$store.state.user.last_name;
            SupportTicket.Store(this.ticketObject)
              .then(({ entities: { tickets } }) => {
                this.$buefy.snackbar.open(`Ticket created!`);
                // this.$emit('update-tickets')
                this.ticketObject.id = tickets[0].id;
                // this.showMedia = true
                this.loading = false;
                this.submitMedia = true;
                this.submitNotes = true;
                if (this.$refs.mediaPanel.dropFiles.length === 0) {
                  this.$emit("submitted");
                }
              })
              .catch(err => {
                this.handleError(err);
                this.loading = false;
              });
          } else {
            SupportTicket.Update(this.ticketObject)
              .then(() => {
                this.$buefy.snackbar.open(`Ticket updated!`);
                // this.$emit('update-tickets')
                // this.showMedia = true
                this.loading = false;
                this.submitMedia = true;
                this.submitNotes = true;
                if (this.$refs.mediaPanel.dropFiles.length === 0) {
                  this.$emit("submitted");
                  this.$emit("close");
                }
              })
              .catch(err => {
                this.handleError(err);
                this.loading = false;
              });
          }
        }
      },
    },
    mounted() {
      if (this.inModal) {
        this.makeModalLarge("animation-content");
      }
      if (this.tenantMode) {
        this.ticketObject = this.$store.state.support.issue;
        this.showMedia = true;
        if (this.edit) {
          this.showNotes = true;
        }
      } else {
        this.ticketObject = JSON.parse(JSON.stringify(this.ticket));
        this.showMedia = true;
        if (this.edit) {
          this.showNotes = true;
        }
      }
    },
  };
</script>
<style scoped>
  #supportTicket .card-divider {
    margin: -0.5rem;
  }

  .headerStyling {
    font-size: 14px;
  }
</style>
