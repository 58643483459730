<template>
  <div>
    <template v-if="replaceDropzoneWithButton">
      <div class="is-flex">
        <div class="ml-auto mr-2 mb-2">
          <b-upload
            :value="value"
            @input="onInput"
            :disabled="mediaDisabled"
            :multiple="multiple"
            :accept="fileExtensionsString">
            <GenexDisableableTooltip
              :label="this.fileAggrigateTypesString"
              position="is-left"
              :disabled="!this.fileAggrigateTypesString.length">
              <span
                type="button"
                class="button p-4 is-default is-rounded has-text-grey"
                :style="mediaDisabled ? 'cursor: not-allowed;' : ''">
                <b-icon icon="plus" class="has-text-grey mr-1" />New File
                <template v-if="fileLimit">({{ fileLimit }} max)</template>
              </span>
            </GenexDisableableTooltip>
          </b-upload>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="columns is-multiline is-fullwidth">
        <div class="column is-12">
          <div class="columns is-centered">
            <div class="column is-12 has-text-centered">
              <b-upload
                :value="value"
                @input="onInput"
                :disabled="mediaDisabled"
                :multiple="multiple"
                drag-drop
                :accept="fileExtensionsString">
                <section class="section">
                  <div class="content has-text-centered">
                    <p class="is-align-items-center">
                      <b-icon icon="document-upload" size="is-large" />
                    </p>
                    <p>Drop your file here or click to upload</p>
                    <p v-if="message">
                      {{ message }}
                    </p>
                    <p>Max file size of 10mb</p>
                    <template v-if="fileLimit">
                      <p>File limit: {{ fileLimit }}</p>
                    </template>
                    <template v-if="this.fileAggrigateTypesString.length">
                      <p>Allowed file types: {{ this.fileAggrigateTypesString }}</p>
                    </template>
                  </div>
                </section>
              </b-upload>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import GenexDisableableTooltip from "@/components/global/GenexDisableableTooltip.vue";

  export default {
    name: "MediaPanelDropZone",
    components: {
      GenexDisableableTooltip,
    },
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      value: {
        type: Array,
      },
      fileLimit: {
        type: Number,
        default: null,
      },
      multiple: {
        type: Boolean,
        default: false,
      },
      message: {
        type: String,
        required: false,
      },
      replaceDropzoneWithButton: {
        type: Boolean,
        required: false,
      },
      fileAggrigateTypes: {
        type: Array,
        default: () => [],
      },
      media_count: {
        type: Number,
        default: 0,
      },
    },
    methods: {
      onInput(e) {
        this.$emit("input", e);
      },
      fileExtensionsMethod(arg) {
        let result = [];
        if (arg?.length) {
          for (const argElement of arg) {
            result.push(...argElement.extensions);
          }
        }
        return result;
      },
    },
    computed: {
      fileExtensionsString() {
        let result = "";
        if (this.fileExtensions?.length) {
          result = "." + this.fileExtensions.join(", .");
        }
        return result;
      },
      fileAggrigateTypesString() {
        let result = "";
        if (this.fileAggrigateTypes?.length) {
          result = [];
          for (const argElement of this.fileAggrigateTypes) {
            result.push(argElement.name);
          }
          result = result.join(", ");
        }
        return result;
      },
      fileExtensions() {
        let result = [];
        result = this.fileExtensionsMethod(this.fileAggrigateTypes);
        return result;
      },
      mediaDisabled() {
        let result = false;
        if (this.disabled) {
          result = true;
        } else if (this.fileLimit !== null) {
          result = this.media_count >= this.fileLimit;
        }
        return result;
      },
    },
  };
</script>
