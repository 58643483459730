import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import Phase from "@/models/sis/Phase";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";

/**
 * A home class
 * @property {number} id
 * @property {number} campus_id - The id of the campus this home class belongs to
 * @property {number} phase_id - The id of the phase this home class belongs to
 * @property {number} teachers_ids - The ids of the teachers this home class belongs to
 * @property {string} name - the name
 */
export default class HomeClass extends GenexSISModel {
  static entity = "homeClasses";

  static fields() {
    return {
      id: this.attr(null),
      teachers_ids: this.attr(() => []),
      teachers: this.hasManyBy(User, "teachers_ids"),
      users_ids: this.attr(() => []),
      users: this.hasManyBy(User, "users_ids"),
      enroled_users: this.attr([]),
      phase_id: this.attr(null),
      phase: this.belongsTo(Phase, "phase_id"),
      campus_id: this.attr(null),
      name: this.attr(null),
      year: this.attr(null),
      max_capacity: this.attr(null),
    };
  }

  /**
   * Returns all home classes
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.search]
   * @param {number} [query.campus_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], clearPrimaryModelOnly = false) {
    return this.api().get(`/home-classes`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        // return data.map(home_class => {
        //   if (relationships.includes("teachers")) {
        //     home_class.attributes.teachers.map(teacher => {
        //       Object.assign(teacher, teacher.attributes);
        //     });
        //   }
        //   if (relationships.includes("phase")) {
        //     Object.assign(home_class.attributes.phase, home_class.attributes.phase.attributes);
        //   }
        //   return { ...home_class, ...home_class.attributes };
        // });

        if (clearPrimaryModelOnly) {
          this.deleteAll();
        }
        return normalize(data);
      },
    });
  }

  /**
   * Returns a home class by its id
   * @function
   * @param {number} id The id of the home class
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/home-classes/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("teachers")) {
          data.attributes.teachers.map(teacher => {
            Object.assign(teacher, teacher.attributes);
          });
        }
        if (relationships.includes("users")) {
          data.attributes.users.map(user => {
            Object.assign(user, user.attributes);
          });
        }
        if (relationships.includes("users:enrolled")) {
          data.attributes.users.map(user => {
            Object.assign(user, user.attributes);
          });
        }
        if (relationships.includes("phase")) {
          Object.assign(data.attributes.phase, data.attributes.phase.attributes);
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new home class
   * @function
   * @param {Object} home_class - The home_class object
   * @param {number} home_class.campus_id - The id of the campus the home_class is associated with
   * @param {number} home_class.phase_id - The id of the phase the home_class is associated with
   * @param {number} home_class.teacher_user_id - The id of the user the home_class is associated with
   * @param {string} home_class.name - The name of the home_class
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static Store(home_class) {
    return this.api().post(`/home-classes`, home_class, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('subjects')) {
        //     data.attributes.subjects.map(subject => {
        //         Object.assign(subject, subject.attributes)
        //     })
        // }
        // if (relationships.includes('user')) {
        //     Object.assign(data.attributes.user, data.attributes.user.attributes)
        // }
        // if (relationships.includes('stage')) {
        //     Object.assign(data.attributes.stage, data.attributes.stage.attributes)
        // }
        // if (relationships.includes('phase')) {
        //     Object.assign(data.attributes.phase, data.attributes.phase.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing home class
   * @function
   * @param {Object} home_class - The home_class object
   * @param {number} home_class.campus_id - The id of the campus the home_class is associated with
   * @param {number} home_class.phase_id - The id of the phase the home_class is associated with
   * @param {number} home_class.teacher_user_id - The id of the user the home_class is associated with
   * @param {string} home_class.name - The name of the home_class
   * @param {number} home_class.id - The id of the home_class
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static Update(home_class, saved) {
    return this.api().patch(`/home-classes/${home_class.id}`, home_class, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing home class
   * @function
   * @param {number} home_class_id - The id of the home_class
   */

  static Delete(home_class_id) {
    return this.api().delete(`/home-classes/${home_class_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: home_class_id,
    });
  }

  /**
   * Download an existing home class's user list
   * @function
   * @param {number} home_class_id - The id of the home_class
   */

  static Download(home_class_id) {
    return this.api().get(`/home-classes/${home_class_id}/download-class-list`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      responseType: "arraybuffer",

      save: false,
    });
  }
}
