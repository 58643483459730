import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of dashboard data
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardAcademicLessonPlanEngagement extends GenexSISModel {
  static entity = "dashboard-academic-lesson-plan-engagement";

  static fields() {
    return {
      subject_name: this.attr(null),
      class_count: this.attr(null),
      lesson_plans_created: this.attr(null),
      percent_engagement: this.attr(null),
    };
  }

  /**
   * Returns the lesson plan engagement subjects for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @returns {Promise<Response>}
   */

  static Fetch(query) {
    return this.api().get(`/dashboard/lesson-plan-engagement`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data;
      },
    });
  }
}
