import { GenexSISModel } from "@/models/sis/GenexSISModel";
import ILPUnit from "@/models/sis/ILPUnit";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPTopic extends GenexSISModel {
  static entity = "ILPTopics";

  static fields() {
    return {
      id: this.uid(),
      name: this.attr(null),
      units: this.hasManyBy(ILPUnit, "unit_ids"),
      unit_ids: this.attr(() => []),
    };
  }
}
