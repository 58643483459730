import { GenexSISModel } from "@/models/sis/GenexSISModel";

export class ProgressYear extends GenexSISModel {
  static entity = "progress-year";

  static primaryKey = "uid";

  static fields() {
    return {
      uid: this.uid(),
      year: this.attr(null),
      progress_percent: this.attr(null),
      expected_progress_percent: this.attr(null),
      course_scope_id: this.attr(null),
    };
  }
}
