import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LMSUser extends GenexLMSModel {
  static entity = "lms-users";

  static fields() {
    return {
      type: this.attr(""),
      id: this.attr(null),
      profile_image: this.attr(""),
      full_name: this.attr(""),
      first_name: this.attr(""),
      last_name: this.attr(""),
      email: this.attr(""),
    };
  }

  static FetchMe() {
    return this.api().get(`/users/me`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }
}
