import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 *
 */

export default class DashboardHRDashboardStaffRetention extends GenexSISModel {
  static entity = "dashboard-hr-dashboard-staff-retention";

  static fields() {
    return {
      active: this.attr(null),
      withdrawn: this.attr(null),
    };
  }

  /**
   *
   * @param query
   * @returns {*}
   * @constructor
   */

  static Fetch(query) {
    return this.api().get(`/dashboard/staff-retention`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        return data;
      },
    });
  }
}
