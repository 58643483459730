import { GenexSISModel } from "@/models/sis/GenexSISModel";
import { normalize } from "@/utils/data";

export default class Widget extends GenexSISModel {
  static entity = "user-widgets";

  static fields() {
    return {
      user_id: this.attr(null),
      order: this.attr(null),
      widget_id: this.attr(null),
    };
  }

  /**
   * @param {Object} Object.page page number to retrieve
   * @param {Object} Object.limit limit of items to retrieve
   * @param {Object} query query object
   * @param {Array} relationships array of relationships to include
   * @returns widgets data based on pagination, query and relationship
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Widget.FetchAll", "/user-widgets", {
      params: {
        page: page,
        limit: limit,
        ...(Object.keys(query).length > 0 ? query : {}),
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * @param {Array} widgets array of ids to store
   * @returns stored widget data
   */
  static Store(widgets) {
    return this.GenexAxiosPost("Widget.Store", `/user-widgets`, widgets, {
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
