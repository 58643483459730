import Router from "@/router/index";
import Store from "@/store";

/**
 * Class representing an error in the navigation
 * @extends ErrorConstructor
 * @property error.status
 * @returns Error
 */
export class RouterError extends Error {
  /**
   *
   * @param message
   * @param status
   * @param from
   */
  constructor(message, status, from) {
    super(message);
    this.status = status;
    this.from = from;
  }
}

function routerErrorHandler(error) {
  if (error.status === 401) {
    Router.replace({ name: "login" }).then(() => {
      Store.dispatch("toast/createToast", {
        message: "Please login to continue",
      }).then();
    });
  }
  if (error.status === 403) {
    Router.push({
      name: error.from.name ? error.from.name : "home",
    })
      .then(() => {
        Store.dispatch("toast/createToast", {
          message: "You don't have the necessary permission to access this resource",
        }).then();
      })
      .catch(() => {
        Store.dispatch("toast/createToast", {
          message: "You don't have the necessary permission to access this resource",
        }).then();
      });
  }
  if (error.status === 404) {
    Router.push({
      name: error.from.name ? error.from.name : "home",
    })
      .then(() => {})
      .catch(() => {});
  }
}

export default Router.onError(error => {
  routerErrorHandler(error);
});
