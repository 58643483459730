<template>
  <b-field>
    <b-input
      v-if="type === 'filter'"
      v-model="searchTerm"
      v-debounce:300ms="getFiltered"
      :disabled="disabled"
      icon="magnifying-glass"
      icon-right="close-circle"
      :icon-right-clickable="true"
      :loading="loading"
      :placeholder="placeholder"
      v-on:icon-right-click="clear()"></b-input>
    <b-autocomplete
      v-if="type === 'autocomplete'"
      :id="new Date()"
      ref="autocomplete"
      v-model="searchTerm"
      v-debounce:300ms="getFiltered"
      :append-to-body="append"
      :clear-on-select="clearOnSelect"
      :clearable="!disabled"
      :custom-formatter="thing => name(thing)"
      :data="searchResponse"
      :disabled="disabled"
      :dropdown-position="dropdownDirection"
      :icon="isStaffProfile && selected_user_id ? '' : 'magnifying-glass'"
      :icon-right="isStaffProfile ? 'close-circle' : ''"
      :icon-right-clickable="true"
      :loading="loading"
      :placeholder="placeholder"
      autocomplete="off"
      @select="option => select(option)">
      <template slot-scope="props">
        <p>{{ props.option.first_name }} {{ props.option.last_name }}</p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
  import User from "@/models/sis/User";

  export default {
    name: "UsersFilter",
    data: function () {
      return {
        loading: false,
        searchTerm: "",
        searchResponse: null,
        activeUserId: null,
      };
    },
    props: {
      clearUser: {
        type: Boolean,
        default() {
          return false;
        },
      },
      disabled: {
        type: Boolean,
        default() {
          return false;
        },
      },
      persistSearch: {
        type: Boolean,
        default() {
          return true;
        },
      },
      append: {
        type: Boolean,
        default() {
          return true;
        },
      },
      clearOnSelect: {
        type: Boolean,
        default() {
          return false;
        },
      },
      existingUser: {
        type: Boolean,
        default() {
          return false;
        },
      },
      dropdownDirection: {
        type: String,
        default() {
          return "auto";
        },
      },
      role: {
        type: String,
        default() {
          return null;
        },
      },
      with_permission: {
        type: String,
        default() {
          return null;
        },
      },
      placeholder: {
        type: String,
        default() {
          return "Search Users";
        },
      },
      without_roles: {
        type: Array,
        default() {
          return [];
        },
      },
      selected_user_id: {
        type: Number,
        required: false,
      },
      type: {
        type: String,
        default() {
          return "filter";
        },
      },
      campus_id: {
        type: Number,
        default() {
          return null;
        },
      },
      phase_id: {
        type: Number,
        default() {
          return null;
        },
      },
      stage_id: {
        type: Number,
        default() {
          return null;
        },
      },
      status_id: {
        type: Number,
        default() {
          return null;
        },
      },
      year: {
        type: Number,
        default() {
          return null;
        },
      },
      isStaffProfile: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    watch: {
      selected_user_id(newValue) {
        this.clearFieldBackground();
        if (newValue !== null) {
          if (this.activeUserId !== this.selected_user_id) {
            if (this.existingUser && User.query().whereId(this.selected_user_id).exists()) {
              let user = User.query().find(this.selected_user_id);
              this.searchResponse = [user];
              this.$refs.autocomplete.setSelected(user);
            } else {
              User.FetchById(this.selected_user_id).then(({ entities }) => {
                this.searchResponse = entities.users;
                this.$refs.autocomplete.setSelected(entities.users[0]);
              });
            }
          }
        }
      },
      clearUser(user) {
        if (user) {
          this.clear();
        }
      },
    },
    mounted() {
      this.clearFieldBackground();
      this.addIconCloseEvent();
      if (this.selected_user_id) {
        if (this.activeUserId !== this.selected_user_id) {
          if (this.existingUser && User.query().whereId(this.selected_user_id).exists()) {
            let user = User.query().find(this.selected_user_id);
            this.searchResponse = [user];
            this.$refs.autocomplete.setSelected(user);
          } else {
            User.FetchById(this.selected_user_id).then(({ entities }) => {
              this.searchResponse = entities.users;
              this.$refs.autocomplete.setSelected(entities.users[0]);
            });
          }
        }
      }
    },
    beforeDestroy() {
      // Remove the event listener when the component is destroyed
      const closeIcon = this.$refs.autocomplete.$el.querySelector(".icon.is-right.is-clickable");
      if (closeIcon) {
        closeIcon.removeEventListener("click", this.clear);
      }
    },
    methods: {
      clearFieldBackground() {
        let field = this.$refs.autocomplete.$el.querySelector(".input");
        if (field && this.isStaffProfile && this.selected_user_id) {
          field.style.backgroundColor = "transparent";
          field.style.border = "0px";
        } else {
          field.style.backgroundColor = "#f8f8f8";
        }
      },
      addIconCloseEvent() {
        // add event listener to the close icon
        const closeIcon = this.$refs.autocomplete.$el.querySelector(".icon.is-right.is-clickable");

        if (closeIcon) {
          closeIcon.addEventListener("click", this.clear);
        }
      },
      name(thing) {
        return `${thing.first_name} | ${thing.last_name}`;
      },
      select(option) {
        if (this.isStaffProfile) {
          this.$nextTick(() => {
            this.addIconCloseEvent();
          });
        }
        if (option === null) {
          if (this.isStaffProfile) {
            this.$emit("clearedManager");
          } else {
            this.clear();
          }
        } else {
          this.activeUserId = option.id;
          this.$emit("selected", option);
        }
      },

      clear() {
        this.searchTerm = "";
        this.searchResponse = [];
        this.$emit("cleared");
      },
      getFiltered(text) {
        if (text.length > 0) {
          this.loading = true;

          User.FetchAll(
            { page: 1, limit: 100 },
            {
              ...{ search: text },
              ...(this.campus_id
                ? {
                    campus_id: this.campus_id,
                  }
                : {}),
              ...(this.stage_id
                ? {
                    stage_id: this.stage_id,
                  }
                : {}),
              ...(this.status_id
                ? {
                    status_id: this.status_id,
                  }
                : {}),
              ...(this.year
                ? {
                    year: this.year,
                  }
                : {}),
              ...(this.phase_id
                ? {
                    phase_id: this.phase_id,
                  }
                : {}),
              ...(this.without_roles
                ? {
                    without_roles: this.without_roles,
                  }
                : {}),
              ...(this.role
                ? {
                    user_role: this.role,
                  }
                : {}),
              ...(this.with_permission
                ? {
                    user_permission: this.with_permission,
                  }
                : {}),
            },
            [],
            this.persistSearch,
          ).then(result => {
            this.loading = false;

            if (result.entities) {
              this.searchResponse = result.entities.users;
              if (this.type === "filter") {
                this.$emit("filtered", { result: result, term: this.searchTerm });
              }
            } else if (this.persistSearch) {
              this.$emit("filtered", { term: this.searchTerm, result: result });

              this.searchResponse = [];
            } else {
              this.searchResponse = result.response.data.data.map(user => {
                return { ...user, ...user.attributes };
              });
            }
          });
        } else {
          if (!this.isStaffProfile) {
            this.clear();
          }
        }
      },
    },
  };
</script>

<style scoped></style>
