import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An assessment grade threshold
 * @property {number} id
 * @property {number} assessment_id
 * @property {number} lowest
 * @property {number} highest
 * @property {string} symbol
 */
export default class AssessmentGradeThreshold extends GenexSISModel {
  static entity = "assessmentGradeThresholds";

  static fields() {
    return {
      id: this.attr(null),
      assessment_id: this.attr(null),
      lowest: this.attr(null),
      highest: this.attr(null),
      symbol: this.attr(null),
    };
  }

  /**
   * Returns all assessment thresholds for a given assessment
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {number} assessment_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the thresholds
   */

  static FetchAll({ page = 1, limit = 15 }, relationships = [], assessment_id) {
    return this.api().get(`/assessments/${assessment_id}/thresholds`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(threshold => {
          return { ...threshold, ...threshold.attributes };
        });
      },
    });
  }

  /**
   * Returns a threshold
   * @function
   * @param {number} assessment_id - The id of the assessment
   * @param {number} threshold_id - The id of the threshold
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the threshold
   */

  static FetchById(assessment_id, threshold_id, relationships = []) {
    return this.api().get(`/assessments/${assessment_id}/thresholds/${threshold_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new threshold
   * @function
   * @param {Object} threshold - The assessment threshold
   * @returns {Promise<Response>} - The newly created threshold
   */

  static Store(threshold) {
    return this.api().post(`/assessments/${threshold.assessment_id}/thresholds`, threshold, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a threshold
   * @function
   * @param {Object} threshold - The threshold object
   * @returns {Promise<Response>} - The updated threshold
   */

  static Update(threshold) {
    return this.api().patch(`/assessments/${threshold.assessment_id}/thresholds/${threshold.id}`, threshold, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a threshold
   * @function
   * @param {Object} threshold
   */

  static Delete(threshold) {
    return this.api().delete(`/assessments/${threshold.assessment_id}/thresholds/${threshold.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: threshold.id,
    });
  }
}
