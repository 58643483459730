import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";

export default class LMSCheckpointsProgress extends GenexLMSModel {
  static entity = "lms-checkpoints-progress";

  static fields() {
    return {
      slug: this.attr(null),
      user_assessments: this.attr(() => []),
      averages: this.attr(() => []),
    };
  }

  static FetchAll({ page = 1, limit = 999 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.apiWithHeaders().get(`/dashboards/checkpoints`, {
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
