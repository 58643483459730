import { ToastProgrammatic as Toast } from "buefy";

export const ToastModule = {
  namespaced: true,

  state: {},
  // Default dispatch will create permission denied message with is-danger background. Colours are derived from bulma presets.
  actions: {
    createToast({ commit }, payload) {
      if (payload) {
        commit("CREATE_TOAST", {
          message: payload.message,
          type: payload.type,
          duration: 8000,
        });
      } else {
        commit("CREATE_TOAST");
      }
    },
  },

  mutations: {
    CREATE_TOAST(state, { message = `You don't have permission to do that`, type = `is-danger` } = {}) {
      Toast.open({
        message: message,
        type: type,
        duration: 5000,
      });
    },
  },
};
