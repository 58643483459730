import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * Type of Leave
 * @property {number} id
 * @property {string} name
 * @property {string} slug
 // * @property {number} max_days
 * @property {number} default_days
 * @property {number} period
 * @property {boolean} allow_absenteeism
 * @property {boolean} has_leave_entry
 * @property {boolean} is_archived
 */
export default class LeaveType extends GenexSISModel {
  static entity = "leaveTypes";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      slug: this.attr(null),
      // max_days: this.attr(null),
      has_leave_entry: this.attr(null),
      default_days: this.attr(null),
      period: this.attr(null),
      allow_absenteeism: this.attr(false),
      is_archived: this.attr(false),
    };
  }

  /**
   * Returns all leave types
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, archived = null) {
    return this.api().get(`/leave-types`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        page: page,
        limit: limit,
        ...(archived !== null
          ? {
              archived,
            }
          : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(leave_type => {
          return { ...leave_type, ...leave_type.attributes };
        });
      },
    });
  }

  /**
   * Returns a leave type
   * @function
   * @param {number} id - Which type to retrieve
   * @returns {Promise<Response>}
   */

  static FetchById(id) {
    return this.api().get(`/leave-types/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {},
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new leave type
   * @function
   * @param {Object} leaveType - The leave_type object
   * @returns {Promise<Response>} - The newly created type
   */

  static Store(leaveType) {
    return this.api().post(`/leave-types`, leaveType, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing leave type
   * @function
   * @param {Object} leave_type - The leave_type object
   * @returns {Promise<Response>} - The newly updated leave type
   */

  static Update(leave_type) {
    return this.api().put(`/leave-types/${leave_type.id}`, leave_type, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an existing leave_type
   * @function
   * @param {number} leave_type_id - The id of the leave_type
   */

  static Delete(leave_type_id) {
    return this.api().delete(`/leave-types/${leave_type_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: leave_type_id,
    });
  }
}
