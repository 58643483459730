<template>
  <GenexForm :inModal="true" modalHeader="Rename" @submit="submit">
    <template v-slot:body>
      <div class="columns is-multiline">
        <div class="column is-12">
          <b-field label="Name">
            <b-input :placeholder="placeholder" v-model="entity.display_name" />
          </b-field>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <b-button :loading="loading" native-type="submit" type="is-primary"> Submit</b-button>
      <b-button @click="$emit('close')" type="is-default">Cancel</b-button>
    </template>
  </GenexForm>
</template>
<script>
  import GenexForm from "@/components/global/GenexForm";
  import Media from "@/models/sis/Media";

  export default {
    name: "MediaPanelModalRename",
    components: {
      GenexForm,
    },
    props: {
      isCentral: {
        type: Boolean,
        default() {
          return false;
        },
      },
      entityProp: {
        type: Object,
        default() {
          return {};
        },
      },
      placeholder: {
        type: String,
        default() {
          return "Untitled Folder";
        },
      },
    },
    data() {
      return {
        loading: false,
        entity: {},
      };
    },
    computed: {},
    methods: {
      submit() {
        this.$emit("setUpdatingMedia", true);
        this.loading = true;
        Media.Update(this.entity, this.isCentral)
          .then(() => {
            this.loading = false;
            this.$buefy.snackbar.open(`Media updated!`);
            this.$emit("setUpdatingMedia", false);
            this.$emit("close");
          })
          .catch(err => {
            this.loading = false;
            this.handleError(err);
            this.$emit("setUpdatingMedia", false);
            this.$emit("close");
          });
      },
    },
    mounted() {
      this.entity = { ...this.entityProp };
    },
  };
</script>
