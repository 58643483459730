export const ReleaseModule = {
  namespaced: true,

  state: {
    releaseWindowOpen: false,
  },

  actions: {
    openReleaseWindow({ commit }) {
      commit("OPEN_WINDOW");
    },
    closeReleaseWindow({ commit }) {
      commit("CLOSE_WINDOW");
    },
  },
  mutations: {
    OPEN_WINDOW(state) {
      state.releaseWindowOpen = true;
    },
    CLOSE_WINDOW(state) {
      state.releaseWindowOpen = false;
    },
  },
};
