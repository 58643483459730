import Vue from "vue";
import { ref } from "vue";
import Widget from "@/models/sis/Widget";
import { widgetsModule } from "@/store/modules/widgets";

let loading = ref(false);
const $store = widgetsModule;

if (!$store.state.loaded) {
  Widget.FetchAll({ page: 1, limit: 999 }).then(() => {
    $store.state.loaded = true;
  });
}

/**
 * @param {String} message feedback message to display to user
 */
function handleFeedback(message) {
  Vue.prototype.$buefy.snackbar.open(message);
}

export default {
  methods: {
    /**
     * @returns loading value to determine if widget is loading.
     */
    loadingWidget() {
      return loading.value;
    },

    /**
     * loads user widgets from backend
     */
    async loadWidgets() {
      await Widget.deleteAll();
      Widget.FetchAll({ page: 1, limit: 999 }).then(() => {
        this.$store.state.widgets.loaded = true;
      });
    },

    /**
     * @returns stored user widgets from backend
     */
    user_widgets() {
      return Widget.query().orderBy("order").get();
    },

    /**
     * @returns array of stored widgets from vuex store
     */
    getWidgets() {
      return $store.state.widgets;
    },

    /**
     * @param {String} name widget's name
     * @returns title specifying whether to add or remove widget
     */
    widgetTitle(name) {
      let isExistWidget = this.getWidgets()
        .filter(widget => {
          const ids = this.user_widgets().map(w => w.widget_id);
          return ids.includes(widget.id);
        })
        .some(widget => widget.name.toLowerCase() === name.toLowerCase());

      return isExistWidget ? "Remove from My Dashboard" : "Add to My Dashboard";
    },

    /**
     * @param {String} name name of widget to store
     */
    async addToWidgets(name) {
      loading.value = true;
      const widget = this.getWidgets().find(widget => widget.name.toLowerCase() === name.toLowerCase());

      if (widget) {
        let ids = this.user_widgets().map(w => w.widget_id);
        ids.unshift(widget.id);

        await Widget.deleteAll(); //deletes current stored data to avoid data caching
        Widget.Store({
          widgets: ids,
        })
          .then(() => {
            handleFeedback("Widget added to My Dashboard");
            loading.value = false;
          })
          .catch(error => {
            this.handleError(error);
            loading.value = false;
          });
      }
    },

    /**
     * @param {String} name name of widget to remove/delete
     */
    async removeFromWidgets(name) {
      loading.value = true;
      const widget = this.getWidgets().find(widget => widget.name.toLowerCase() === name.toLowerCase());

      if (widget) {
        let index = this.user_widgets()
          .map(widget => widget.widget_id)
          .indexOf(widget.id);
        let ids = this.user_widgets().map(widget => widget.widget_id);
        ids.splice(index, 1);

        await Widget.deleteAll(); //deletes current stored data to avoid data caching
        Widget.Store({
          widgets: ids,
        })
          .then(() => {
            handleFeedback("Removed Widget from My Dashboard");
            loading.value = false;
          })
          .catch(error => {
            this.handleError(error);
            loading.value = false;
          });
      }
    },

    /**
     * @param {Boolean} addWidget checks whether to add or remove a widget
     * @param {Object} query object name of the widget
     */
    handelWidget(addWidget, query) {
      switch (query.name) {
        case "Enrolment Status Changes":
          if (addWidget) {
            this.addToWidgets("Enrolment Status Changes");
          } else {
            this.removeFromWidgets("Enrolment Status Changes");
          }
          break;
        case "Staff Totals":
          if (addWidget) {
            this.addToWidgets("Staff Totals");
          } else {
            this.removeFromWidgets("Staff Totals");
          }
          break;
        case "Stage Student Ratio":
          if (addWidget) {
            this.addToWidgets("Stage Student Ratio");
          } else {
            this.removeFromWidgets("Stage Student Ratio");
          }
          break;
        case "Educator Student Ratio":
          if (addWidget) {
            this.addToWidgets("Educator Student Ratio");
          } else {
            this.removeFromWidgets("Educator Student Ratio");
          }
          break;
        case "Student Services":
          if (addWidget) {
            this.addToWidgets("Student Services");
          } else {
            this.removeFromWidgets("Student Services");
          }
          break;
        case "Application To Enrolment":
          if (addWidget) {
            this.addToWidgets("Application To Enrolment");
          } else {
            this.removeFromWidgets("Application To Enrolment");
          }
          break;
        case "Applications By Status":
          if (addWidget) {
            this.addToWidgets("Applications By Status");
          } else {
            this.removeFromWidgets("Applications By Status");
          }
          break;
        case "Applications By Stage":
          if (addWidget) {
            this.addToWidgets("Applications By Stage");
          } else {
            this.removeFromWidgets("Applications By Stage");
          }
          break;
        case "Application Stats":
          if (addWidget) {
            this.addToWidgets("Application Stats");
          } else {
            this.removeFromWidgets("Application Stats");
          }
          break;
        case "Engagements":
          if (addWidget) {
            this.addToWidgets("Engagements");
          } else {
            this.removeFromWidgets("Engagements");
          }
          break;
        case "Staff Retention":
          if (addWidget) {
            this.addToWidgets("Staff Retention");
          } else {
            this.removeFromWidgets("Staff Retention");
          }
          break;
        case "Pending Reviews":
          if (addWidget) {
            this.addToWidgets("Pending Reviews");
          } else {
            this.removeFromWidgets("Pending Reviews");
          }
          break;
        default:
          break;
      }
    },

    /**
     * @param {Number} id of the widget
     * @returns component name of the widget based on its id
     */
    getWidgetComponent(id) {
      switch (id) {
        case 1:
          return "Enrolments";
        case 2:
          return "StaffTotalsPanel";
        case 3:
          return "EducatorPhases";
        case 4:
          return "DashboardRatio";
        case 5:
          return "StudentServices";
        case 6:
          return "ApplicationToEnrolment";
        case 7:
          return "ApplicationsByStatus";
        case 8:
          return "ApplicationsByStages";
        case 9:
          return "ApplicationStats";
        case 10:
          return "AcademicLessonPlanEngagements";
        case 11:
          return "StaffRetention";
        case 12:
          return "ReviewsWidget";
        default:
          return "";
      }
    },
  },
};
