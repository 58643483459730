import { Model } from "@vuex-orm/core";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LessonPlanAi extends Model {
  static entity = "lessonPlanAi";

  static Post(url, lessonPlan) {
    return new Promise((resolve, reject) => {
      this.api()
        .post(url, lessonPlan, {
          ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
          dataTransformer: ({ data: { data } }) => {
            return { ...data };
          },
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
