import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import Campus from "@/models/sis/Campus";

/**
 * An attendance
 * @property {number} id
 * @property {number} campus_id - The id of the campus this attendance belongs to
 * @property {number} user_id - The id of the user this attendance belongs to
 */
export default class Attendance extends GenexSISModel {
  static entity = "attendances";

  static fields() {
    return {
      is_new: this.attr(false),
      related_entity_id: this.attr(null),
      type_id: this.attr(null),
      created_at: this.attr(null),
      id: this.attr(null),
      attended_date: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      is_present: this.attr(null),
      is_tardy: this.attr(0),
      absent_reason_id: this.attr(1),
      early_release_at: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      campus_id: this.attr(1),
      note: this.attr(null),
    };
  }

  /**
   * Returns all attendances
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.attended_date]
   * @param {number} [query.search]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Attendance.FetchAll", `/attendances`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(attendance => {
          if (relationships.includes("user")) {
            Object.assign(attendance.attributes.user, attendance.attributes.user.attributes);
          }

          return { ...attendance, ...attendance.attributes };
        });
      },
    });
  }

  /**
   * Returns an attendance by its id
   * @function
   * @param {number} id The id of the attendance
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.GenexAxiosGet("Attendance.FetchById", `/attendances/${id}`, {
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          Object.assign(data.attributes.user, data.attributes.user.attributes);
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new attendance
   * @function
   * @param {Object} attendance - The attendance object
   * @param {number} attendance.campus_id - The id of the campus the attendance is associated with
   * @param {number} attendance.type_id - The id of the type of class the attendance is associated with
   * @param {number} attendance.related_entity_id - The id of the entity the attendance is associated with
   * @param {number} attendance.user_id - The id of the user the attendance is associated with
   * @param {string} attendance.attended_date - The date of the attendance
   * @param {string} attendance.early_release_at - The date of the early release if there was one
   * @param {boolean} attendance.is_present - Whether the student was present or absent
   * @param {boolean} attendance.is_tardy - Whether the student was tardy
   * @param {number} attendance.absent_reason_id - The id of the reason for which the student was absent
   * @returns {Promise<Response>} - The newly created attendance
   */

  static Store(attendance) {
    return this.GenexAxiosPost("Attendance.Store", `/attendances`, attendance, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new attendance
   * @function
   * @param {Object} attendance - The attendance object
   * @param {number} attendance.campus_id - The id of the campus the attendance is associated with
   * @param {number} attendance.type_id - The id of the type of class the attendance is associated with
   * @param {number} attendance.related_entity_id - The id of the entity the attendance is associated with
   * @param {number} attendance.user_id - The id of the user the attendance is associated with
   * @param {string} attendance.attended_date - The date of the attendance
   * @param {string} attendance.early_release_at - The date of the early release if there was one
   * @param {boolean} attendance.is_present - Whether the student was present or absent
   * @param {boolean} attendance.is_tardy - Whether the student was tardy
   * @param {number} attendance.absent_reason_id - The id of the reason for which the student was absent
   * @returns {Promise<Response>} - The newly created attendance
   */

  static StoreBulk(attendance) {
    return this.GenexAxiosPost("Attendance.StoreBulk", `/attendances/create-for-lesson`, attendance, {
      dataTransformer: ({ data: { data } }) => {
        return data.map(attendance => {
          return { ...attendance, ...attendance.attributes };
        });
      },
    });
  }

  /**
   * Update an existing attendance
   * @function
   * @param {Object} attendance - The attendance object
   * @param {Object} attendance - The attendance object
   * @param {number} attendance.id - The id of the attendance
   * @param {number} attendance.campus_id - The id of the campus the attendance is associated with
   * @param {number} attendance.type_id - The id of the type of class the attendance is associated with
   * @param {number} attendance.related_entity_id - The id of the entity the attendance is associated with
   * @param {number} attendance.user_id - The id of the user the attendance is associated with
   * @param {string} attendance.attended_date - The date of the attendance
   * @param {string} attendance.early_release_at - The date of the early release if there was one
   * @param {boolean} attendance.is_present - Whether the student was present or absent
   * @param {boolean} attendance.is_tardy - Whether the student was tardy
   * @param {number} attendance.absent_reason_id - The id of the reason for which the student was absent
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created attendance
   */

  static Update(attendance, saved = true) {
    return this.GenexAxiosPatch("Attendance.Update", `/attendances/${attendance.id}`, attendance, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing attendance
   * @function
   * @param {number} attendance_id - The id of the attendance
   * @returns {Promise<Response>} - The newly created attendance
   */

  static Delete(attendance_id) {
    return this.GenexAxiosDelete("Attendance.Delete", `/attendances/${attendance_id}`, {
      delete: attendance_id,
    });
  }
}
