import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Stage from "@/models/sis/Stage";

/**
 * A collection of educators in a stage
 * @property {number} id
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardEducatorStage extends GenexSISModel {
  static entity = "dashboard-educator-stage";

  static fields() {
    return {
      id: this.attr(null),
      stage_id: this.attr(null),
      stage: this.belongsTo(Stage, "stage_id"),
      total: this.attr(null),
      percentage_of_total: this.attr(null),
      percentage_of_phase: this.attr(null),
      educators: this.attr(() => {
        return [];
      }),
    };
  }
}
