import { GenexSISModel } from "@/models/sis/GenexSISModel";
import LessonPlan from "@/models/sis/LessonPlan";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class TeacherReflection extends GenexSISModel {
  static entity = "teacherReflections";

  static fields() {
    return {
      id: this.attr(null),
      lesson_plan_id: this.attr(null),
      lesson_plan: this.belongsTo(LessonPlan, "lesson_plan_id"),
      were_teaching_methods_effective: this.attr(null),
      were_learning_activities_effective: this.attr(null),
      were_formative_assessment_strategies_used_throughout: this.attr(null),
      were_instructional_materials_used_effectively: this.attr(null),
      were_prior_knowledge_links_available: this.attr(null),
      was_lesson_outcome_achieved: this.attr(null),
      did_encourage_active_learning: this.attr(null),
      were_differentiation_opportunities_available: this.attr(null),
      were_students_allowed_to_reflect: this.attr(null),
      collaboration_strategies: this.attr(null),
      comment: this.attr(null),
      next_steps: this.attr(null),
      type_id: this.attr(null),
      were_planning_documents_and_prep_sufficient: this.attr(null),
      did_lecturer_planning_meet_requirements_of_syllabus: this.attr(null),
      did_planning_reference_assessment_or_objectives: this.attr(null),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/teacher-reflections`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(topic => {
          return { ...topic, ...topic.attributes };
        });
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/teacher-reflections/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Store(teachcerReflection) {
    return this.api().post(`/teacher-reflections`, teachcerReflection, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(teacherReflection) {
    return this.api().patch(`/teacher-reflections/${teacherReflection.id}`, teacherReflection, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Delete(id) {
    return this.api().delete(`/teacher-reflections/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
