import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A subject
 * @property {number} id
 * @property {string} name - The name of the subject
 * @property {string} name - The name of the subject with other details
 * @property {string} code - The code of the subject
 * @property {number} campus_id - The id of the campus this subject belongs to
 * @property {number} stage_id - The id of the stage this subject belongs to
 * @property {number} ordinality - The order to display this subject
 */
export default class SubjectConfig extends GenexSISModel {
  static entity = "subjectConfigs";

  static fields() {
    return {
      id: this.attr(null),
      subject_id: this.attr(null),
      has_starter_activity: this.attr(null),
      has_checkpoints: this.attr(null),
      has_engagement: this.attr(null),
      has_resources: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
    };
  }

  /**
   * Store a new config
   * @function
   * @param {Object} config - The subject object
   * @returns {Promise<Response>} - The newly created subject
   */

  static Store(config) {
    return this.api().post(`/subject-configs`, config, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing subject
   * @function
   * @param {Object} config - The subject object
   * @returns {Promise<Response>} - The newly created subject
   */

  static Update(config) {
    return this.api().patch(`/subject-configs/${config.id}`, config, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an existing config
   * @function
   * @param {number} config_id - The id of the subject
   * @returns {Promise<Response>} - The newly created subject
   */

  static Delete(config_id) {
    return this.api().delete(`/subject-configs/${config_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: config_id,
    });
  }
}
