import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LearnerReflection extends GenexSISModel {
  static entity = "learnerReflections";

  static fields() {
    return {
      id: this.attr(null),
      lesson_plan_id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      how_comfortable: this.attr(null),
      easiest_concept: this.attr(null),
      hardest_concept: this.attr(null),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/learner-reflections`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(reflection => {
          if (relationships.includes("user")) {
            Object.assign(reflection.attributes.user, reflection.attributes.user.attributes);
          }
          return { ...reflection, ...reflection.attributes };
        });
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/learner-reflections/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }
}
