import Router from "@/router/index";
import Store from "@/store/index";
// Subscribe to Vuex store changes for the tenant school name
Store.watch(
  () => Store.state.tenant.school_name,
  schoolName => {
    if (schoolName) {
      document.title = `${Router.currentRoute.meta.title} - SIS - ${schoolName}`;
    }
  },
);

export default Router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} - SIS - ${Store.state.tenant.school_name}`;
  next();
});
