import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A staff members HR profile
 * @property {number} id
 * @property {number} user_id



 */
export default class StaffProfile extends GenexSISModel {
  static entity = "staff-profiles";
  static primaryKey = "user_id";

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      department_id: this.attr(null),
      employment_type: this.attr(null),
      employment_status: this.attr(null),
      contract_type: this.attr(null),
      designation: this.attr(null),
      annual_accrual_rate: this.attr(0),
      annual_carryover_months: this.attr(0),
      rolled_over_annual_leave: this.attr(0),
      accrued_annual_leave: this.attr(0),
      available_annual_leave: this.attr(0),
      annual_leave_taken_count: this.attr(null),
      remaining_rolled_over_annual_leave: this.attr(0),
      rolled_over_annual_leave_expiry_date: this.attr(null, value => (value === false ? null : value)),
      hours_per_week: this.attr(0),
      extension_code: this.attr(null),
      leave_days_per_year: this.attr(() => {
        return {
          annual: null,
        };
      }),
      outstanding_leave_days: this.attr(() => {
        return {
          annual: null,
        };
      }),
      leave_offsets: this.attr(
        () => [],
        value => {
          if (value === null) {
            return [];
          }
          return value;
        },
      ),
      managers: this.attr([]),
      probation_start_date: this.attr(null),
      probation_end_date: this.attr(null),
      employment_start_date: this.attr(null),
      employment_end_date: this.attr(null),
    };
  }

  /**
   * Returns a profile by the user_id
   * @function
   * @param {number} user_id The id of the user
   * @returns {Promise<Response>}
   */

  static FetchById(user_id, relationship = []) {
    return this.api().get(`/users/${user_id}/staff-profile`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationship,
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new profile
   * @function
   * @param {Object} profile - The user object
   * @param {number} user_id
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(profile, user_id) {
    return this.api().post(`/users/${user_id}/staff-profile`, profile, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a profile
   * @function
   * @param {Object} profile - The user object
   * @returns {Promise<Response>} - The newly created user
   */

  static Update(profile) {
    return this.api().patch(`/users/${profile.user_id}/staff-profile`, profile, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }
}
