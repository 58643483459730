import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import LMSUser from "@/models/lms/LMSUser";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LMSForumSubComment extends GenexLMSModel {
  static entity = "lms-forum-sub-comments";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      user_id: this.attr(null),
      creator: this.belongsTo(LMSUser, "user_id", "id"),
      forum_comment_id: this.attr(null),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      created_at_human: this.attr(""),
      body: this.attr(""),
      forum_post_id: this.attr(null),
    };
  }

  static Store(entity) {
    return this.api().post(`/forum-comments`, entity, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Delete(commentId) {
    return this.api().delete(`/forum-comments/${commentId}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
    });
  }
}
