import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";

export default class CentralPasswordReset extends GenexSISModel {
  static entity = "CentralPasswordReset";

  static fields() {
    return {
      id: this.attr(null),
      school_name: this.attr(""),
    };
  }

  static ResetStep1(payload) {
    return this.api().post(`/central/password/create`, payload, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
  static ResetStep2(tenant) {
    return this.api().post(`/central/password/reset`, tenant, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
