import VueCookies from "vue-cookies";

const DefaultLMSHeaders = () => {
  const lmsAccessToken = VueCookies.get("lmsAccessToken");
  const lmsTenantId = VueCookies.get("lmsTenantId");

  let defaultHeaders = {
    "X-Requested-With": "XMLHttpRequest",
    Accept: "application/json",
    "Content-Type": "application/json",
  };
  if (lmsAccessToken) {
    defaultHeaders["X-Is-LMS-Request"] = 1;
    defaultHeaders["Authorization"] = "Bearer " + lmsAccessToken;
  }

  if (lmsTenantId) {
    defaultHeaders["X-Tenant"] = lmsTenantId;
  }

  return defaultHeaders;
};
const DefaultLMSHeadersAndBaseUrl = () => {
  return {
    baseURL: process.env.VUE_APP_LMS_API_BASE_URL + "/api/v4",
    headers: {
      ...DefaultLMSHeaders(),
    },
  };
};

export { DefaultLMSHeaders, DefaultLMSHeadersAndBaseUrl };
