import { GenexSISModel } from "@/models/sis/GenexSISModel";

/**
 * An announcement model
 * @property {number} id
 * @property {number} model_id
 * @property {string} model_type
 */
export default class AnnouncementModel extends GenexSISModel {
  static entity = "announcementModels";
  static primaryKey = ["announcement_id", "model_type", "model_id"];

  static fields() {
    return {
      id: this.uid(),
      announcement_id: this.attr(null),
      model_type: this.attr(null),
      model_id: this.attr(null),
    };
  }
}
