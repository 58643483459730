import { v4 as uuid } from "uuid";
import { computed, isReactive, isRef, nextTick, onMounted, ref, unref, watch } from "vue";
import { unwrap } from "@/utils/data";
import useDependencies from "@/compositions/utils/useDependencies";
import { isEqual, isFunction } from "lodash";
import { usePagination } from "@/compositions/usePagination";

const useQueryChanged = query => {
  const oldQuery = ref({
    ...query,
  });
  const hasChanged = ref(false);
  watch(query, value => {
    if (!isEqual(unref(value), unref(oldQuery.value))) {
      hasChanged.value = true;
      oldQuery.value = {
        ...value,
      };
      nextTick(() => {
        hasChanged.value = false;
      });
    }
  });
  return {
    hasChanged,
  };
};

export const useModels = ({
  model,
  query = {},
  filter = null,
  limits = { page: 1, limit: 15 },
  relationships = [],
  counts = [],
  enabled = true,
  dependencies = {},
  paging = false,
  persistBy = "insertOrUpdate",
  search = null,
  searchFields = ["name"],
  events: { onRemoved = null, beforeLoad = null, afterLoad } = {},
}) => {
  const share = Object.keys(query).length === 0;

  const metaData = ref(null);
  const {
    page,
    paginate,
    total,
    limits: pageLimits,
  } = usePagination(metaData, {
    page: limits.page,
    limit: limits.limit,
    enabled: paging,
  });

  const queryKeys = Object.keys(query).join(",");

  const queryKey = [model.entity, "all", share ? "shared" : uuid(), !share ? queryKeys : undefined];

  const fetchF = async () => await model.FetchAll(unref(pageLimits), unwrap(query), relationships, persistBy, counts);

  const loading = ref(false);

  const refetch = async () => {
    loading.value = true;
    beforeLoad && (await beforeLoad());
    const {
      response: {
        data: { meta },
      },
    } = await fetchF();
    metaData.value = meta;
    afterLoad && (await afterLoad());
    loading.value = false;
  };

  const { shouldRun, hasChanged, hasDependencies } = useDependencies(dependencies);

  const filterF = computed(() => {
    const filters = filter ? unwrap(filter) : unwrap(query);
    return value => {
      return Object.keys(filters).every(key => {
        const filterValue = unref(filters[key]);
        if (filterValue === undefined) {
          return true;
        }
        if (Object.keys(value).includes(key) === false) {
          return true;
        }
        const ormValue = unref(value[key]);
        if (Array.isArray(filterValue)) {
          return filterValue.includes(ormValue);
        }
        if (filterValue && typeof filterValue === "object") {
          if (key === "$or") {
            return Object.keys(filterValue).some(key => {
              const orValue = unref(filterValue[key]);
              const ormValue = unref(value[key]);
              if (Array.isArray(orValue)) {
                return orValue.includes(ormValue);
              }
              return orValue === ormValue;
            });
          }
        }
        return filterValue === ormValue;
      });
    };
  });

  const queryEnabled = computed(() => {
    return unref(shouldRun) && unref(enabled);
  });

  // TODO - Reimplement when VueQuery is back
  // const { isFetching } = useQuery({
  //   queryKey,
  //   queryFn: fetchF,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  //   enabled: queryEnabled,
  // });

  if (isRef(query) || isReactive(query) || isFunction(query)) {
    const { hasChanged } = useQueryChanged(query);
    watch(hasChanged, async value => {
      if (value && unref(queryEnabled)) {
        await refetch();
      }
    });
    watch(queryEnabled, async value => {
      if (value && !unref(hasChanged)) {
        await refetch();
      }
    });
  } else {
    watch(queryEnabled, async value => {
      if (value) {
        await refetch();
      }
    });
  }

  watch(pageLimits, async (value, oldValue) => {
    if (isEqual(value, oldValue)) return;
    if (unref(queryEnabled)) {
      await refetch();
    }
  });

  onMounted(async () => {
    if (queryEnabled.value) {
      await refetch();
    }
  });

  const data = computed(() => {
    if (search) {
      const searchValue = isFunction(search) ? search() : unref(search);
      if (searchValue && searchValue.length > 0) {
        return model
          .query()
          .where(value => {
            return searchFields.some(field => {
              return value[field]?.toLowerCase().includes(searchValue.toLowerCase());
            });
          })
          .with(relationships)
          .get();
      } else {
        return model.query().with(relationships).get();
      }
    }
    const where = model.query().where(filterF.value);
    return where?.with(relationships).get();
  });

  const dataRef = ref(data.value);

  watch(data, value => {
    dataRef.value = value;
  });

  const remove = async id => {
    const response = await model.Delete(id);
    onRemoved && onRemoved(response);
    return response;
  };

  // TODO - Reimplement when VueQuery is back
  // if (!share) {
  //   onUnmounted(() => {
  //     queryClient.removeQueries(queryKey);
  //   });
  // }

  const debugOptions = {
    queryKey,
    queryEnabled,
    share,
    shouldRun,
    dependencies,
    hasDependencies,
    hasChanged,
    search,
  };

  return {
    dataRef,
    remove,
    data,
    loading,
    debug: debugOptions,
    metaData,
    refetch,
    limit: limits.limit,
    page,
    total,
    paginate,
  };
};
