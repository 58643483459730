<template>
  <div class="GenexMiniLoader"></div>
</template>

<script>
  export default {
    name: "GenexMiniLoader",
  };
</script>

<style scoped>
  .GenexMiniLoader:after {
    -webkit-animation: spinAround 500ms infinite linear;
    animation: spinAround 500ms infinite linear;
    border: 2px solid #dbdbdb;
    border-radius: 9999px;
    border-right-color: transparent;
    border-top-color: transparent;
    content: "";
    display: block;
    height: 1em;
    width: 1em;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    width: 1em;
    height: 1em;
    border-width: 0.25em;
  }
  .GenexMiniLoader {
    display: inline;
    width: 1em;
    height: 1em;
  }
</style>
