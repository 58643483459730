import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A setting
 * @property {number} id
 * @property {string} key - the key of the setting
 * @property {string} value - the value of the setting

 */
export default class Setting extends GenexSISModel {
  static entity = "settings";

  static fields() {
    return {
      id: this.attr(null),
      key: this.attr(null),
      value: this.attr(null),
    };
  }

  /**
   * Returns all settings
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {Array<string>} [query.keys]
   * @param {Array<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/settings`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(setting => {
          return { ...setting, ...setting.attributes };
        });
      },
    });
  }

  /**
   * Returns a setting
   * @function
   * @param {number} id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/settings/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new setting
   * @function
   * @param {Object} setting - The setting object
   * @param {string} setting.key - The key
   * @param {string} setting.value - The value
   * @returns {Promise<Response>} - The newly created setting
   */

  static Store(setting) {
    return this.api().post(`/settings`, setting, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing setting
   * @function
   * @param {Object} setting - The setting object
   * @param {string} setting.key - The key
   * @param {string} setting.value - The value
   * @param {string} setting.id - The id of the setting
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The updated settings
   */

  static Update(setting, saved) {
    return this.api().patch(`/settings/${setting.id}`, setting, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Bulk store settings
   * @function
   * @param {Array<Object>} settings - The settings array
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The updated settings
   */

  static BulkStore(settings, saved) {
    return this.api().post(
      `/settings/bulk-insert`,
      { data: settings },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return data.map(setting => {
            return { ...setting, ...setting.attributes };
          });
        },
        save: saved,
      },
    );
  }

  /**
   * Bulk update settings
   * @function
   * @param {Array<Object>} settings - The settings array
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The updated settings
   */

  static BulkUpdate(settings, saved) {
    return this.api().patch(
      `/settings/bulk-update`,
      { data: settings },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return data.map(setting => {
            return { ...setting, ...setting.attributes };
          });
        },
        save: saved,
      },
    );
  }

  /**
   * Delete an existing setting
   * @function
   * @param {number} setting_id - The id of the setting
   * @returns {Promise<Response>}
   */

  static Delete(setting_id) {
    return this.api().delete(`/settings/${setting_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: setting_id,
    });
  }

  static PreviewSettingsPdf() {
    return this.api().get(`/settings/pdf-preview/`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      // responseType: "arraybuffer",
      // save: false
    });
  }
}
