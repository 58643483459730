import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "../../mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";

/**
 * A Year
 * @property {number} id
 * @property {string} name
 * @property {string} start
 * @property {string} end
 */

export default class Year extends GenexSISModel {
  static entity = "years";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      start: this.attr(null),
      end: this.attr(null),
    };
  }

  /**
   * Returns all years
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {Boolean} save
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], clearPrimaryModelOnly = false) {
    return this.api().get(`/years`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        // return data.map(home_class => {
        //   if (relationships.includes("teachers")) {
        //     home_class.attributes.teachers.map(teacher => {
        //       Object.assign(teacher, teacher.attributes);
        //     });
        //   }
        //   if (relationships.includes("phase")) {
        //     Object.assign(home_class.attributes.phase, home_class.attributes.phase.attributes);
        //   }
        //   return { ...home_class, ...home_class.attributes };
        // });

        if (clearPrimaryModelOnly) {
          this.deleteAll();
        }
        return normalize(data);
      },
    });
  }

  /**
   * Store a new year
   * @function
   * @param {Object} year - The year object
   * @param {string} year.name - The name of the year
   * @param {string} year.start - The start of the year period
   * @param {string} year.end - The end of the year period
   * @returns {Promise<Response>} - The newly created year
   */

  static Store(year) {
    return this.GenexAxiosPost("Year.Store", `/years`, year, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing year
   * @function
   * @param {Object} year - The year object
   * @param {string} year.name - The name of the year
   * @param {string} year.start - The start of the year period
   * @param {string} year.end - The end of the year period
   * @param {string} year.id - The id of the year
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created year
   */

  static Update(year, saved) {
    return this.GenexAxiosPatch("Year.Update", `/years/${year.id}`, year, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing year
   * @function
   * @param {number} year_id - The id of the year
   * @returns {Promise<Response>} - The newly deleted year
   */

  static Delete(year_id) {
    return this.GenexAxiosDelete("Year.Delete", `/years/${year_id}`, {
      delete: year_id,
    });
  }
}
