<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Select the campus helpdesk you’d like to contact.</p>
      </header>
      <section class="modal-card-body">
        <b-field v-for="campus in campuses" :key="campus.id">
          <b-radio v-model="campus_selection" :native-value="campus.id" :required="campus_selection === null">
            {{ campus.name }}
          </b-radio>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <b-button icon-right="message-question" native-type="submit" type="is-primary"> Start Chatting</b-button>
      </footer>
    </div>
    <b-loading v-model="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
  import Campus from "@/models/sis/Campus";

  export default {
    name: "HelpdeskCampusSelectForm",
    data() {
      return {
        loading: false,
        campus_selection: this.$store.state.campus?.selected_campus_id ?? null,
      };
    },
    methods: {
      submit() {
        this.$emit("selectedCampus", this.campus_selection);
        this.$emit("close");
      },
    },
    computed: {
      campuses() {
        return Campus.query().where("has_helpdesk", 1).get();
      },
    },
  };
</script>
