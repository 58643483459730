<script setup>
  import { onMounted, ref, watch } from "vue";
  import { MathfieldElement } from "mathlive";
  import { v4 as uuidv4 } from "uuid";

  let mathfield = ref(null);
  let output = ref("");
  const uuid = uuidv4();

  onMounted(() => {
    mathfield.value = new MathfieldElement();
    mathfield.value.virtualKeyboardMode = "manual";
    document.getElementById(`math-field${uuid}`).appendChild(mathfield.value);
    mathfield.value.style.width = "100%";

    watch(output, (newVal, oldVal) => {
      if (newVal !== oldVal) {
        mathfield.value.latex = newVal; // Set the new latex value to the Mathfield element
      }
    });

    mathfield.value.addEventListener("input", ev => {
      output.value = ev.target.value; // Set the new latex value to the output variable
    });
  });

  const emit = defineEmits(["add", "close"]);

  const add = () => {
    emit("close");
    emit("add", ` $$${output.value}$$ `);
  };

  const addInline = () => {
    emit("close");
    emit("add", " `" + output.value + "` ");
  };
</script>

<template>
  <div class="modal-card modal-card-body is-large overflow-hidden">
    <p class="card-header-title">Math Editor</p>
    <div :id="`math-field${uuid}`"></div>
    <hr />
    <div class="is-flex is-justify-content-end">
      <b-button class="mr-2" type="is-danger" @click="emit('close')">Cancel</b-button>
      <b-button class="mr-2" type="is-primary" @click="add" icon-right="plus">Add</b-button>
      <b-button type="is-primary" @click="addInline" icon-right="plus">Add In-Line</b-button>
    </div>
  </div>
</template>
<style scoped></style>
