import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Topic from "@/models/sis/Topic";
import LearningStream from "@/models/sis/LearningStream";
import Tenant from "@/models/sis/Tenant";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Phase from "@/models/sis/Phase";
import { transform } from "@/utils/data";

/**
 * A CourseScope
 * @property {number} id
 * @property {number} campus_id - The id of the campus this CourseScope belongs to
 * @property {Object} campus - The campus this CourseScope belongs to
 * @property {number} stage_ids - The id of the stages this CourseScope belongs to
 * @property {Object} stages - The stages this CourseScope belongs to
 * @property {number} phase_id - The id of the phase this CourseScope belongs to
 * @property {Object} phase - The phase this CourseScope belongs to
 * @property {number} learning_stream_id - The id of the learning stream (curriculum) this CourseScope belongs to
 * @property {Object} learning_stream - The learning stream (curriculum) this CourseScope belongs to
 * @property {number} ordinality - The order to display this stage
 * @property {string} syllabus - The syllabus of the CourseScope
 * @property {string} code - The code of the CourseScope
 * @property {number} hours - The number of hours in this CourseScope
 * @property {number} terms - The number of terms in this CourseScope
 * @property {number} week - The number of week in this CourseScope
 * @property {number} lessons - The number of lessons in this CourseScope
 * @property {string} time - The time length of the lessons of this CourseScope (in minutes)
 * @property {string} examination_year - The examination year of this CourseScope
 * @property {number} ordinality - The order of this CourseScope
 * @property {Array.<number>} topic_ids - The ids of the topics this CourseScope has
 * @property {Array.<Object>} topics - The topics this CourseScope has
 */
export default class CourseScope extends GenexSISModel {
  static entity = "coursesScopes";

  static fields() {
    return {
      id: this.attr(null),
      learning_stream_id: this.attr(null),
      learning_stream: this.belongsTo(LearningStream, "learning_stream_id"),
      // campus: this.belongsTo(Campus, 'campus_id'),
      campus_id: this.attr(0),
      phase_id: this.attr(0),
      phase_ids: this.attr(() => []),
      phases: this.hasManyBy(Phase, "phase_ids"),
      // phase: this.belongsTo(Phase, 'phase_id'),
      stage_ids: this.attr(() => []),
      // stages: this.hasManyBy(Stage, 'stage_ids'),
      syllabus: this.attr(null),
      code: this.attr(null),
      hours: this.attr(null),
      terms: this.attr(null),
      weeks: this.attr(null),
      year: this.attr(0),
      quarter: this.attr(0),
      lessons: this.attr(null),
      time: this.attr(null),
      examination_year: this.attr(null),
      ordinality: this.attr(0),
      topic_ids: this.attr(() => []),
      topics: this.hasManyBy(Topic, "topic_ids"),
      tenant_id: this.attr(null),
      tenant: this.belongsTo(Tenant, "tenant_id"),
      age_id: this.attr(null),
    };
  }

  /**
   * Returns all CourseScopes
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.campus_id] - search by campus_id
   * @param {number} [query.stage_ids] - search by stage_ids
   * @param {number} [query.phase_id] - search by phase_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the CourseScopes
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/course-scopes`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: transform,
    });
  }

  /**
   * Returns a CourseScopes
   * @function
   * @param {number} id - The id of the courseScope
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the CourseScopes
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/course-scopes/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: transform,
    });
  }

  /**
   * Store a new courseScope
   * @function
   * @param {Object} courseScope - The courseScope object
   * @returns {Promise<Response>} - The newly created courseScope
   */

  static Store(courseScope) {
    return this.api().post(`/course-scopes`, courseScope, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: transform,
    });
  }

  static Update(courseScope) {
    return this.api().patch(`/course-scopes/${courseScope.id}`, courseScope, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: transform,
    });
  }

  /**
   * Delete a courseScope
   * @function
   * @param {number} id - The id of the courseScope
   */

  static Delete(id) {
    return this.api().delete(`/course-scopes/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
