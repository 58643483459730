import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import LMSAssessment from "@/models/lms/LMSAssessment";
import { normalize } from "@/utils/data";

export default class LMSModule extends GenexLMSModel {
  static entity = "lms-modules";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      assessments: this.hasMany(LMSAssessment, "module_id"),
      completed: this.attr(null),
    };
  }

  /**
   * Returns a module
   * @function
   * @param {number} module_id - The module_id
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.course_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(module_id, relationships = [], query = {}) {
    return this.api().get(`/modules/${module_id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
