import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Stage from "@/models/sis/Stage";
import Campus from "@/models/sis/Campus";
import LearningStream from "@/models/sis/LearningStream";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";

/**
 * A phase
 * @property {number} id
 * @property {string} name - The name of the phase
 * @property {string} long_name - The name of the phase for display on applications
 * @property {Boolean} is_open_for_enrolment - If the phase can be enrolled
 * @property {number} campus_id - The id of the campus this phase belongs to
 * @property {number} learning_stream_id - The id of the learning stream this phase belongs to
 * @property {number} ordinality - The order to display this phase

 */
export default class Phase extends GenexSISModel {
  static entity = "phases";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      learning_stream_id: this.attr(null),
      year: this.attr(null),
      max_capacity: this.attr(null),
      level_id: this.attr(null),
      long_name: this.attr(null),
      is_open_for_enrolment: this.attr(null),
      learning_stream: this.belongsTo(LearningStream, "learning_stream_id"),
      stages: this.hasMany(Stage, "phase_id"),
      ordinality: this.attr(0),
      hod_id: this.attr(null),
      hod: this.belongsTo(User, "hod_id"),
      sub_head_id: this.attr(null),
      sub_head: this.belongsTo(User, "sub_head_id"),
      isCentral: this.attr(false),
      central_phase_id: this.attr(null),
    };
  }

  /**
   * Returns all phases
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.campus_id]
   * @param {string} [query.search]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/phases`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static FetchCentralPhases({ page = 1, limit = 15 }, query = {}) {
    return this.api().get(`/phases`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
        },
        ...(query !== {} ? query : {}),
        for_central: 1,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data).map(phase => ({
          ...phase,
          isCentral: true,
        }));
      },
    });
  }

  /**
   * Returns a phase by its id
   * @function
   * @param {number} id The id of the phase
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/phases/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new phase
   * @function
   * @param {Object} phase - The phase object
   * @param {string} phase.name - The name of the phase
   * @param {string} phase.long_name - The long name of the phase
   * @param {Boolean} phase.is_open_for_enrolment - If the phase is open for enrolment
   * @param {number} phase.campus_id - The id of the campus the phase is associated with
   * @returns {Promise<Response>} - The newly created phase
   */

  static Store(phase) {
    return this.api().post(`/phases`, phase, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing phase
   * @function
   * @param {Object} phase - The phase object
   * @param {string} phase.name - The name of the phase
   * @param {number} phase.campus_id - The id of the campus the phase is associated with
   * @param {string} phase.id - The id of the phase
   * @param {string} phase.long_name - The long name of the phase
   * @param {Boolean} phase.is_open_for_enrolment - If the phase is open for enrolment
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created phase
   */

  static Update(phase, saved) {
    return this.api().patch(`/phases/${phase.id}`, phase, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing phase
   * @function
   * @param {number} phase_id - The id of the phase
   * @returns {Promise<Response>} - phase
   */

  static Delete(phase_id) {
    return this.api().delete(`/phases/${phase_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: phase_id,
    });
  }
}
