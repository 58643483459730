<script setup>
  import Alert from "@/models/sis/Alert";
  import { computed, ref, watch } from "vue";
  import { useModels } from "@/compositions/useModels";

  const { data: alerts } = useModels({
    model: Alert,
    query: {
      is_lms: 0,
      enabled: 1,
    },
  });

  const text = computed(() => {
    return alerts.value.map(alert => alert.message).join(" | ");
  });

  const textSpan = ref(null);
  const wraps = ref(true);
  const animationDuration = ref("0s");
  watch([text, textSpan], () => {
    const elementWidth = textSpan.value?.offsetWidth;
    const contentLength = text.value.length;
    const averageCharacterWidth = 10;

    const speed = 50;
    const time = elementWidth / speed;

    wraps.value = contentLength * averageCharacterWidth > elementWidth;
    animationDuration.value = `${time}s`;
  });

  const transform = computed(() => `-${textSpan.value?.offsetWidth * 2 ?? 0}px`);
</script>

<template>
  <div
    v-if="text.length > 0"
    class="banner banner-footer has-text-weight-semibold has-text-info has-background-primary-light is-fullwidth">
    <div
      ref="textSpan"
      :class="{
        'move-text': wraps,
      }">
      {{ text }}
    </div>
  </div>
</template>

<style scoped lang="scss">
  .banner {
    white-space: nowrap;

    .move-text {
      animation: scrollLeft v-bind(animationDuration) linear infinite;
      @keyframes scrollLeft {
        0% {
          transform: translateX(100%);
        }
        100% {
          transform: translateX(v-bind(transform));
        }
      }
    }
  }

  .banner-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0.5em;
    z-index: 999;
  }
</style>
