import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { transform } from "@/utils/data";

export default class FileType extends GenexSISModel {
  static entity = "file-types";

  static fields() {
    return {
      type: this.attr(""),
      id: this.attr(null),
      name: this.attr(""),
      extensions: this.attr([]),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/file-types`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: transform,
    });
  }
}
