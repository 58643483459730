import Router from "@/router/index";
import Store from "@/store/index";
import { RouterError } from "@/router/errors";

export default Router.beforeEach((to, from, next) => {
  if (Store.getters["tenant/isCentral"] && to.meta.central) {
    next();
    return;
  }
  if (!Store.getters["tenant/isCentral"] && to.meta.tenant) {
    next();
    return;
  }
  next(new RouterError("Forbidden", 404, from));
});
