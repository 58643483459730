import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import TaskType from "@/models/sis/TaskType";

/**
 * A role
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {number} type_id - the id of the type of task it is
 * @property {Object} task_type - the type of task it is
 * @property {string} note - the description of the task
 * @property {number} completed_user_id - the id of the user who completed the task
 * @property {Object} completed_user - the user who completed the task
 * @property {number} creator_id - the id of the user who created the task
 * @property {Object} creator - the user who created the task
 * @property {number} assigned_to_user_id - the id of the user the task is assigned to
 * @property {Object} assigned_to_user - the user who the task is assigned to
 * @property {string} completed_at - the time the task was completed
 * @property {boolean} is_complete - Whether or not the task is complete
 * @property {string} due_at - When the task is due
 * @property {string} created_at - When the task was created
 */
export default class Task extends GenexSISModel {
  static entity = "tasks";

  static fields() {
    return {
      type: this.attr("tasks"),
      id: this.attr(null),
      name: this.attr(""),
      type_id: this.attr(null),
      task_type: this.belongsTo(TaskType, "type_id"),
      note: this.attr(null),
      completed_user_id: this.attr(""),
      completed_user: this.belongsTo(User, "completed_user_id"),
      creator_id: this.attr(""),
      creator: this.belongsTo(User, "creator_id"),
      assigned_to_user: this.belongsTo(User, "assigned_to_user_id"),
      assigned_to_user_id: this.attr(""),
      completed_at: this.attr(""),
      is_complete: this.attr(false),
      taskable_type: this.attr(null),
      taskable_id: this.attr(null),
      due_at: this.attr(""),
      created_at: this.attr(null),
    };
  }

  /**
   * Returns all tasks
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {string} [query.name] - fuzzy search
   * @param {string} [query.search] - fuzzy search
   * @param {string} [query.note] - fuzzy search
   * @param {string} [query.model] - Singular type of model
   * @param {number} [query.task_type] - search by task_type id
   * @param {number} [query.creator] - search by creator id
   * @param {number} [query.completed_user] - search by completed_user id
   * @param {number} [query.assigned_to_user] - search by assigned_to_user id
   * @param {boolean} [query.is_complete] - search by complete status
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Task.FetchAll", `/tasks`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(task => {
          if (relationships.includes("creator")) {
            Object.assign(task.attributes.creator, task.attributes.creator.attributes);
          }
          return { ...task, ...task.attributes };
        });
      },
    });
  }

  /**
   * Returns all tasks for a given entity
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} model
   * @param {string} model.type - the singular model type
   * @param {number} model.id - The model id
   * @param {?Object} [query={}] - Query terms for the request
   * @param {string} [query.name] - fuzzy search
   * @param {string} [query.search] - fuzzy search
   * @param {string} [query.note] - fuzzy search
   * @param {number} [query.task_type] - search by task_type id
   * @param {number} [query.creator] - search by creator id
   * @param {number} [query.completed_user] - search by completed_user id
   * @param {number} [query.assigned_to_user] - search by assigned_to_user id
   * @param {boolean} [query.is_complete] - search by complete status
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllByModel({ page = 1, limit = 15 }, query = {}, relationships = [], { type = "", id = 0 }) {
    return this.GenexAxiosGet("Task.FetchAllByModel", `/${type}/${id}/tasks`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(task => {
          if (relationships.includes("creator")) {
            if (Object.prototype.hasOwnProperty.call(task.attributes, "creator"))
              if (task.attributes.creator !== null) {
                Object.assign(task.attributes.creator, task.attributes.creator.attributes);
              }
          }
          if (relationships.includes("assignedToUser")) {
            if (Object.prototype.hasOwnProperty.call(task.attributes, "assigned_to_user"))
              if (task.attributes.assigned_to_user !== null) {
                Object.assign(task.attributes.assigned_to_user, task.attributes.assigned_to_user.attributes);
              }
          }
          if (relationships.includes("completedUser")) {
            if (Object.prototype.hasOwnProperty.call(task.attributes, "completed_user"))
              if (task.attributes.completed_user !== null) {
                Object.assign(task.attributes.completed_user, task.attributes.completed_user.attributes);
              }
          }
          return { ...task, ...task.attributes };
        });
      },
    });
  }

  /**
   * Returns a specific task
   * @function
   * @param {Object} model
   * @param {string} model.type - the singular model type
   * @param {number} model.id - The model id
   * @param {number} task_id - The task id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(task_id, relationships = [], { type = "", id = 0 }) {
    return this.GenexAxiosGet("Task.FetchById", `/${type}/${id}/tasks/${task_id}`, {
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('user')) {
        //     Object.assign(task.attributes.user, task.attributes.user.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new task
   * @function
   * @param {Object} task - The task object
   * @param {Object} model - the associated entity
   * @param {string} model.type - the associated entity type
   * @param {number} model.id - the associated entity id
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(task, { type = "", id = 0 }) {
    return this.GenexAxiosPost("Task.Store", `/${type}/${id}/tasks`, task, {
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('user')) {
        //     Object.assign(task.attributes.user, task.attributes.user.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a task
   * @function
   * @param {Object} task - The task object
   * @param {Object} model - the associated entity
   * @param {string} model.type - the associated entity type
   * @param {number} model.id - the associated entity id
   * @returns {Promise<Response>} - The newly created user
   */

  static Update(task, { type = "", id = 0 }) {
    return this.GenexAxiosPut("Task.Update", `/${type}/${id}/tasks/${task.id}`, task, {
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('user')) {
        //     Object.assign(task.attributes.user, task.attributes.user.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Toggle a tasks complete status
   * @function
   * @param {number} task_id - The id of the task
   * @param {Object} model - the associated entity
   * @param {string} model.type - the associated entity type
   * @param {number} model.id - the associated entity id
   * @returns {Promise<Response>}
   */

  static Toggle(task_id, { type = "", id = 0 }) {
    return this.GenexAxiosPatch(
      "Task.Toggle",
      `/${type}/${id}/tasks/${task_id}/toggle-complete`,
      {},
      {
        dataTransformer: ({ data: { data } }) => {
          // if (relationships.includes('user')) {
          //     Object.assign(task.attributes.user, task.attributes.user.attributes)
          // }
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Delete a task
   * @function
   * @param {number} task_id - The id of the task
   * @param {Object} model - the associated entity
   * @param {string} model.type - the associated entity type
   * @param {number} model.id - the associated entity id
   * @returns {Promise<Response>}
   */

  static Delete(task_id, { type = "", id = 0 }) {
    return this.GenexAxiosDelete("Task.Delete", `/${type}/${id}/tasks/${task_id}`, {
      delete: task_id,
    });
  }
}
