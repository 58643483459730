<template>
  <b-menu-item :active="isActive" @click="selectFile">
    <template #label>
      <div :class="{ handle: hasPermission(['edit documents']) }" class="is-flex is-justify-content-space-between">
        <!-- Left side -->

        <div class="is-flex is-align-content-center">
          <b-icon icon="document-edit" class="is-pulled-left mr-1"></b-icon>
          <!--          </div>-->
          <!--          <div class="level-item item-name-container">-->
          <span class="is-flex is-align-items-center has-text-black">{{ item.name }}</span>
        </div>
        <!-- Right side -->

        <b-dropdown
          v-if="hasAnyPermissions(['edit documents', 'delete documents'])"
          :position="'is-bottom-left'"
          append-to-body
          aria-role="list"
          @click.native.stop>
          <div slot="trigger" class="is-pulled-right">
            <b-icon icon="more-vertical"></b-icon>
          </div>

          <b-dropdown-item v-if="hasPermission(['delete documents'])" aria-role="listitem" @click="startDeleteDocument">
            <div class="media">
              <b-icon icon="trash" class="media-left"></b-icon>
              <div class="media-content">
                <h3>Delete</h3>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="hasPermission(['edit documents'])" aria-role="listitem" @click="startEditDocument">
            <div class="media">
              <b-icon icon="document-edit" class="media-left"></b-icon>
              <div class="media-content">
                <h3>Edit</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!--          <div class="level-item">-->
      <!--            <b-icon :icon="props.expanded ? 'menu-down' : 'menu-up'" class="is-pulled-right"></b-icon>-->

      <!--          </div>-->
    </template>

    <!--    <b-menu-item icon="account" label="Users"></b-menu-item>-->
    <!--    <b-menu-item icon="cellphone-link">-->
    <!--      <template #label>-->
    <!--        Devices-->
    <!--        <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">-->
    <!--          <template #trigger>-->
    <!--          TEST ICON WHEN YOU UNCOMMENT   -->
    <!--            <b-icon icon="more-vertical"></b-icon>-->
    <!--          </template>-->
    <!--          <b-dropdown-item aria-role="listitem">Action</b-dropdown-item>-->
    <!--          <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item>-->
    <!--          <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item>-->
    <!--        </b-dropdown>-->
    <!--      </template>-->
    <!--    </b-menu-item>-->
  </b-menu-item>
</template>

<script>
  import Document from "@/models/sis/Document";

  export default {
    name: "DocumentMenuItem",

    data() {
      return {};
    },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      selectFile() {
        this.$store.dispatch("documentation/selectDocument", this.item.id);
        this.$store.state.documentation.active_folder = this.item.folder_id;
        this.setQuery({ documentSlug: this.item.slug });
      },
      startDeleteDocument() {
        this.$buefy.dialog.confirm({
          title: `Deleting document`,
          confirmText: `Delete document`,
          hasIcon: true,
          type: "is-danger",
          message: `Are you sure you want to delete this document?`,
          onConfirm: () =>
            Document.Delete(this.item.id)
              .then(() => {
                this.$buefy.snackbar.open(`document deleted!`);
              })
              .catch(err => {
                this.handleError(err);
              }),
        });
      },

      startEditDocument() {
        this.$store.dispatch("documentation/startEditing", this.item.id);
      },
    },
    computed: {
      isActive() {
        return this.$store.state.documentation.active_document === this.item.id;
      },
    },
  };
</script>
