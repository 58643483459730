import Vue from "vue";
import Router from "vue-router";
import Store from "@/store/index";
import { handlebreadCrumbs } from "@/router/breadCrumbs";

Vue.use(Router);

const router = new Router({
  countNumId: 104,
  mode: "history",
  base: process.env.VUE_APP_URL,
  routes: [
    {
      path: "/",
      name: "home",
      meta: {
        title: "Home",
        docPath: "./Home/Home.md",
        central: true,
        tenant: true,
        numId: 1,
      },
      component: () => import("@/views/dashboard/Staff"),
    },
    {
      path: "/dashboard",
      name: "dashboard",
      meta: {
        title: "Dashboard",
        tenant: true,
        permissions: ["view analytics"],
        numId: 2,
      },
      component: () => import("@/views/dashboard/Admin"),
    },
    {
      name: "dashboard.attendances",
      path: "/dashboard/attendances",
      meta: {
        allow: false,
        highlight: "dashboard",
        title: "Attendances Dashboard",
        tenant: true,
        permissions: ["view analytics"],
        numId: 3,
      },
      component: () => import("@/views/dashboard/Attendances"),
    },
    {
      name: "dashboard.enrolments",
      path: "/dashboard/enrolments",
      meta: {
        allow: false,
        docPath: "./Dashboard/Enrolments.md",
        highlight: "dashboard",
        title: "Enrolments Dashboard",
        tenant: true,
        permissions: ["view analytics"],
        numId: 4,
      },
      component: () => import("@/views/dashboard/Enrolments"),
    },
    {
      name: "dashboard.reviews",
      path: "/dashboard/reviews",
      meta: {
        allow: false,
        highlight: "dashboard",
        title: "Reviews",
        tenant: true,
        permissions: ["view analytics"],
        numId: 4,
      },
      component: () => import("@/views/dashboard/Reviews"),
    },
    {
      name: "dashboard.applications",
      path: "/dashboard/applications",
      meta: {
        allow: false,
        docPath: "./Dashboard/Applications.md",
        highlight: "dashboard",
        title: "Applications Dashboard",
        tenant: true,
        permissions: ["view analytics"],
        numId: 5,
      },
      component: () => import("@/views/dashboard/Applications"),
    },
    {
      name: "dashboard.engagements",
      path: "/dashboard/engagements",
      meta: {
        allow: false,
        docPath: "./Dashboard/Engagements.md",
        highlight: "dashboard",
        title: "Engagements Dashboard",
        tenant: true,
        permissions: ["view analytics"],
        numId: 6,
      },
      component: () => import("@/views/dashboard/Engagements"),
    },
    {
      name: "dashboard.LmsWidgets",
      path: "/dashboard/lms-widgets",
      meta: {
        allow: false,
        docPath: "./Dashboard/LmsWidgets.md",
        highlight: "dashboard",
        title: "LMS Widget Dashboard",
        central: false,
        tenant: true,
        permissions: ["view analytics"],
      },
      component: () => import("@/views/dashboard/LmsWidgets"),
    },

    {
      name: "dashboard.HRDashboard",
      path: "/dashboard/hr-dashboard",
      meta: {
        allow: false,
        highlight: "dashboard",
        title: "HR Dashboard",
        central: false,
        tenant: true,
        numId: 7,
      },
      component: () => import("@/views/dashboard/HRDashboard"),
    },
    {
      path: "/announcements",
      name: "operations.announcements.index",
      meta: {
        title: "Announcements",
        docPath: "./Operations/Announcements.md",
        tenant: true,
        numId: 8,
      },
      component: () => import("@/views/announcements/Index"),
    },
    {
      path: "/chat",
      name: "chat.index",
      meta: {
        title: "Chat",
        docPath: "./Announcements.md",
        tenant: true,
        allowChatBubble: false,
        numId: 9,
      },
      component: () => import("@/views/chat/Index"),
    },
    {
      path: "/exports/",
      name: "operations.exports.index",
      meta: {
        permissions: ["view exports"],
        allow: false,
        docPath: "./Operations/Exports.md",
        title: "Exports Index",
        tenant: true,
        numId: 10,
      },
      component: () => import("@/views/exports/Index.vue"),
      children: [
        {
          name: "operations.exports.covid-assessments",
          path: "covid-assessments",
          meta: {
            permissions: ["view covid assessments export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Covid Assessments Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 11,
          },
          component: () => import("@/views/exports/CovidAssessments.vue"),
        },
        {
          name: "operations.exports.homeclass-attendances",
          path: "attendances-homeclasses",
          meta: {
            permissions: ["view homeclass attendances export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "HomeClass Attendances Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 12,
          },
          component: () => import("@/views/exports/HomeclassAttendances.vue"),
        },
        {
          name: "operations.exports.service-attendances",
          path: "attendances-services",
          meta: {
            permissions: ["view service attendances export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Services Attendances Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 13,
          },
          component: () => import("@/views/exports/ServicesAttendances.vue"),
        },
        {
          name: "operations.exports.packages",
          path: "packages",
          meta: {
            // Todo: add permission
            permissions: ["view packages export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Packages Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 14,
          },
          component: () => import("@/views/exports/Packages.vue"),
        },
        {
          name: "operations.exports.family",
          path: "family",
          meta: {
            // Todo: add permission
            permissions: ["view packages export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Family Export",
            tenant: true,
            numId: 15,
          },
          component: () => import("@/views/exports/Family.vue"),
        },
        {
          name: "operations.exports.reports",
          path: "reports",
          meta: {
            permissions: ["view reports export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Reports Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 16,
          },
          component: () => import("@/views/exports/Reports.vue"),
        },
        {
          name: "operations.exports.marksheets",
          path: "marksheets",
          meta: {
            permissions: ["view marksheets export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Marksheets Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 17,
          },
          component: () => import("@/views/exports/Marksheets.vue"),
        },
        {
          name: "operations.exports.enrolments",
          path: "enrolments",
          meta: {
            permissions: ["view enrolments export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Enrolments Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 18,
          },
          component: () => import("@/views/exports/Enrolments.vue"),
        },
        {
          name: "operations.exports.applications",
          path: "applications",
          meta: {
            permissions: ["view applications export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Applications Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 19,
          },
          component: () => import("@/views/exports/Applications.vue"),
        },
        {
          name: "operations.exports.sports-houses",
          path: "sports-houses",
          meta: {
            permissions: ["view sportshouses export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Sports Houses Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 20,
          },
          component: () => import("@/views/exports/SportsHouses.vue"),
        },
        {
          name: "operations.exports.users",
          path: "users",
          meta: {
            permissions: ["view users export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Users Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 21,
          },
          component: () => import("@/views/exports/Users.vue"),
        },
        {
          name: "operations.exports.staff",
          path: "staff",
          meta: {
            permissions: ["view staff export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Staff Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 22,
          },
          component: () => import("@/views/exports/Staff.vue"),
        },
        {
          name: "operations.exports.leave",
          path: "leave",
          meta: {
            permissions: ["view leave requests export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Leave Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 23,
          },
          component: () => import("@/views/exports/Leave.vue"),
        },
        {
          name: "operations.exports.logins",
          path: "logins",
          meta: {
            permissions: ["view logins export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Logins Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 24,
          },
          component: () => import("@/views/exports/Logins.vue"),
        },
        {
          name: "operations.exports.login-all-users",
          path: "login-all-users",
          meta: {
            permissions: ["view logins all users export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "All Users Logins Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 25,
          },
          component: () => import("@/views/exports/AllUsersLogins.vue"),
        },
        {
          name: "operations.exports.attendances-taken",
          path: "attendances-taken",
          meta: {
            permissions: ["view attendances taken export"],
            allow: false,
            highlight: "operations.exports.index",
            title: "Attendances Taken Export",
            docPath: "./Operations/Exports.md",
            tenant: true,
            numId: 26,
          },
          component: () => import("@/views/exports/AttendancesTaken.vue"),
        },
      ],
    },
    {
      path: "/leave",
      name: "human-resources.leave.index",
      meta: {
        title: "Leave Manager",
        docPath: "./Human Resources/Leave Requests.md",
        permissions: ["manage leave requests"],
        tenant: true,
        numId: 27,
      },
      component: () => import("@/views/leave/Index"),
    },
    {
      path: "/incidents",
      name: "human-resources.incidents.index",
      meta: {
        title: "Incident Manager",
        docPath: "./Human Resources/Incidents.md",
        permissions: ["administrate incidents"],
        tenant: true,
        numId: 28,
      },
      component: () => import("@/views/incidents/Index"),
    },
    {
      path: "/meetings",
      name: "operations.meetings.index",
      meta: {
        title: "Meetings",
        docPath: "./Operations/Meetings.md",
        permissions: ["administrate meetings"],
        tenant: true,
        numId: 29,
      },
      component: () => import("@/views/meetings/Index"),
    },
    {
      path: "/objectives",
      name: "academics.objectives.index",
      meta: {
        title: "Objectives Manager",
        docPath: "./Operations/Objective Manager.md",
        permissions: ["view objectives"],
        central: true,
        numId: 30,
      },
      component: () => import("@/views/objectives/Index.vue"),
    },
    {
      path: "/objectives/edit/:id",
      name: "academics.objectives.edit",
      props: true,
      meta: {
        title: "Edit Objective",
        docPath: "./Operations/Objective Manager.md",
        permissions: ["view objectives"],
        central: true,
        numId: 31,
      },
      component: () => import("@/views/objectives/Edit.vue"),
    },
    {
      path: "/analysis",
      name: "academics.analysis.index",
      meta: {
        title: "Data Analysis",
        docPath: "./Operations/My Subjects.md",
        permissions: ["view analytics"],
        tenant: true,
        numId: 32,
      },
      component: () => import("@/views/analysis/Index.vue"),
    },
    {
      path: "/lesson-plans/subjects",
      name: "/lesson-plans/subjects",
      meta: {
        title: "Subjects",
        docPath: "./Academics/Subjects/Subjects.md",
        permissions: ["view lesson-plans"],
        tenant: true,
        breadcrumb_name: "Subjects",
        numId: 33,
      },
      component: () => import("@/views/lessonPlans/subjectsList.vue"),
      children: [
        {
          path: "/lesson-plans/subjects/:sId/:sName",
          name: "/lesson-plans/subjects/:sId/:sName",
          meta: {
            title: "Lesson Plans",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":sName",
            breadcrumbs_parent: "/lesson-plans/subjects",
            numId: 34,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
          redirect: {
            name: "/lesson-plans/subjects/:sId/:sName/lesson-plans",
          },
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/lesson-plans",
          name: "/lesson-plans/subjects/:sId/:sName/lesson-plans",
          meta: {
            title: "Lesson Plans",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Lesson Plans",
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName",
            numId: 35,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/lessons",
          name: "/lesson-plans/subjects/:sId/:sName/lessons",
          meta: {
            title: "Lessons",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Lessons",
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName",
            numId: 36,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/progress",
          name: "/lesson-plans/subjects/:sId/:sName/progress",
          meta: {
            title: "Progress Tracker",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Progress Tracker",
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName",
            numId: 37,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/assessments",
          name: "/lesson-plans/subjects/:sId/:sName/assessments",
          meta: {
            title: "Assessments",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: "Assessments",
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName",
            numId: 38,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/assessments/:aId/:aName",
          name: "/lesson-plans/subjects/:sId/:sName/assessments/:aId/:aName",
          meta: {
            title: "Assessments",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: ":aName",
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName/assessments",
            numId: 39,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews",
          name: "/lesson-plans/subjects/:sId/:sName/reviews",
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: "Review",
            breadcrumbs_ancestry: ["/lesson-plans/subjects", "/lesson-plans/subjects/:sId/:sName"],
            numId: 40,
          },
          redirect: route => {
            return {
              name: "/lesson-plans/subjects/:sId/:sName/reviews/tasks",
              params: {
                sId: route.params.sId,
                sName: route.params.sName,
              },
            };
          },
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/tasks",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/tasks",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: "Tasks",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
            ],
            numId: 41,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: ":name",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
              "/lesson-plans/subjects/:sId/:sName/reviews/tasks",
            ],
            numId: 42,
          },
          redirect: route => {
            return {
              name: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name/submissions",
              params: {
                sId: route.params.sId,
                sName: route.params.sName,
                mode: route.params.mode,
                id: route.params.id,
                name: route.params.name,
              },
            };
          },
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name/submissions",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name/submissions",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: "Submissions",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
              "/lesson-plans/subjects/:sId/:sName/reviews/tasks",
              "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name",
            ],
            numId: 43,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name/submissions/:reviewId/:subName",
          // path: "/lesson-plans/subjects/:sId/:sName/reviews/:mode/:id/review/:reviewId",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name/submissions/:reviewId/:subName",
          // name: "/lesson-plans/subjects/:sId/:sName/reviews/:mode/:id/:name/submissions/:reviewId/:subName",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: ":subName",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
              "/lesson-plans/subjects/:sId/:sName/reviews/tasks",
              "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name",
              "/lesson-plans/subjects/:sId/:sName/reviews/tasks/:id/:name/submissions",
            ],
            numId: 44,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/journals",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/journals",
          // name: "/lesson-plans/subjects/:sId/:sName/reviews/:mode",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: "Journals",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
            ],
            numId: 45,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name",
          // name: "/lesson-plans/subjects/:sId/:sName/reviews/:mode/:id/:name",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: ":name",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
              "/lesson-plans/subjects/:sId/:sName/reviews/journals",
            ],
            numId: 46,
          },
          redirect: route => {
            return {
              name: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name/submissions",
              params: {
                sId: route.params.sId,
                sName: route.params.sName,
                mode: route.params.mode,
                id: route.params.id,
                name: route.params.name,
              },
            };
          },
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name/submissions",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name/submissions",
          // name: "/lesson-plans/subjects/:sId/:sName/reviews/:mode/:id/:name/submissions",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: "Entries",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
              "/lesson-plans/subjects/:sId/:sName/reviews/journals",
              "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name",
            ],
            numId: 47,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name/submissions/:reviewId/:subName",
          // path: "/lesson-plans/subjects/:sId/:sName/reviews/:mode/:id/review/:reviewId",
          name: "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name/submissions/:reviewId/:subName",
          // name: "/lesson-plans/subjects/:sId/:sName/reviews/:mode/:id/:name/submissions/:reviewId/:subName",
          props: true,
          meta: {
            title: "Reviews",
            permissions: ["view lesson-plans", "view assessments"],
            tenant: true,
            breadcrumb_name: ":subName",
            breadcrumbs_ancestry: [
              "/lesson-plans/subjects",
              "/lesson-plans/subjects/:sId/:sName",
              "/lesson-plans/subjects/:sId/:sName/reviews",
              "/lesson-plans/subjects/:sId/:sName/reviews/journals",
              "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name",
              "/lesson-plans/subjects/:sId/:sName/reviews/journals/:id/:name/submissions",
            ],
            numId: 48,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/marksheet",
          name: "/lesson-plans/subjects/:sId/:sName/marksheet",
          meta: {
            title: "Marksheet",
            permissions: ["view lesson-plans", "view grades"],
            tenant: true,
            breadcrumb_name: "Marksheet",
            breadcrumbs_ancestry: ["/lesson-plans/subjects", "/lesson-plans/subjects/:sId/:sName"],
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName",
            numId: 49,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/forums",
          name: "/lesson-plans/subjects/:sId/:sName/forums",
          meta: {
            title: "Forums",
            permissions: ["view lesson-plans", "view forums"],
            tenant: true,
            breadcrumb_name: "Forums",
            breadcrumbs_parent: "/lesson-plans/subjects/:sId/:sName",
            numId: 50,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/resources",
          name: "/lesson-plans/subjects/:sId/:sName/resources",
          meta: {
            title: "Resources",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Resources",
            breadcrumbs_ancestry: ["/lesson-plans/subjects", "/lesson-plans/subjects/:sId/:sName"],
            numId: 51,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
        {
          path: "/lesson-plans/subjects/:sId/:sName/resources/:rPath",
          name: "/lesson-plans/subjects/:sId/:sName/resources/:rPath",
          meta: {
            title: "Resources",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Resources",
            breadcrumbs_ancestry: ["/lesson-plans/subjects", "/lesson-plans/subjects/:sId/:sName"],
            numId: 52,
          },
          component: () => import("@/views/lessonPlans/subject.vue"),
        },
      ],
    },
    {
      path: "/progress-tracker-parent",
      name: "/progress-tracker-parent",
      component: () => import("@/components/FeatureFlag.vue"),
      props: {
        flag: "progress-tracker",
      },
      meta: {
        title: "Progress Tracker",
        permissions: ["view lesson-plans"],
        tenant: true,
        numId: 103,
      },
      children: [
        {
          path: "/progress-tracker",
          name: "/progress-tracker",
          component: () => import("@/components/global/ContainerView.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Progress Tracker",
            numId: 104,
          },
          redirect: {
            name: "/progress-tracker/home-classes",
          },
        },
        {
          path: "/progress-tracker/home-classes",
          name: "/progress-tracker/home-classes",
          component: () => import("@/components/academicsProgessTracker/homeClasses/HomeClassesList.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Home Classes",
            breadcrumbs_parent: "/progress-tracker",
            numId: 105,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName",
          name: "/progress-tracker/home-classes/:hId/:hName",
          component: () => import("@/components/academicsProgessTracker/homeClasses/HomeClassesRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":hName",
            breadcrumbs_parent: "/progress-tracker/home-classes",
            numId: 106,
          },
          redirect: {
            name: "/progress-tracker/home-classes/:hId/:hName/subjects",
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects",
          component: () => import("@/components/academicsProgessTracker/homeClasses/HomeClassesRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Subjects",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName",
            numId: 108,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName",
          component: () => import("@/components/academicsProgessTracker/homeClasses/subjects/SubjectsRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":sName",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects",
            numId: 109,
          },
          redirect: {
            name: "ProgressTracker-HomeClasses-Subjects-Tab-task-sheets",
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/old-tracker",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/old-tracker",
          component: () => import("@/components/academicsProgessTracker/OldTrackerRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":sName",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects",
            numId: 109,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets",
          name: "ProgressTracker-HomeClasses-Subjects-Tab-task-sheets",
          component: () => import("@/components/academicsProgessTracker/homeClasses/subjects/SubjectsRead.vue"),
          props: {
            tab: "task-sheets",
          },
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Task Sheets",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName",
            numId: 110,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets/:tsId/:tsName",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets/:tsId/:tsName",
          component: () =>
            import("@/components/academicsProgessTracker/homeClasses/subjects/taskSheets/TaskSheetsRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":tsName",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets",
            numId: 111,
          },
          redirect: {
            name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets/:tsId/:tsName/tasks",
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets/:tsId/:tsName/tasks",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets/:tsId/:tsName/tasks",
          component: () =>
            import("@/components/academicsProgessTracker/homeClasses/subjects/taskSheets/TaskSheetsReadTasks.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Tasks",
            breadcrumbs_parent:
              "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/task-sheets/:tsId/:tsName",
            numId: 112,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects",
          component: () => import("@/components/academicsProgessTracker/homeClasses/HomeClassesRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Subjects",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName",
            numId: 114,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName",
          component: () => import("@/components/academicsProgessTracker/homeClasses/subjects/SubjectsRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":sName",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects",
            numId: 115,
          },
          redirect: {
            name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics",
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics",
          name: "ProgressTracker-HomeClasses-Subjects-Tab-topics",
          component: () => import("@/components/academicsProgessTracker/homeClasses/subjects/SubjectsRead.vue"),
          props: {
            tab: "topics",
          },
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Topics",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName",
            numId: 116,
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics/:tId/:tName",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics/:tId/:tName",
          component: () => import("@/components/academicsProgessTracker/homeClasses/subjects/topics/TopicsRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: ":tName",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics",
            numId: 117,
          },
          redirect: {
            name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics/:tId/:tName/tasks",
          },
        },
        {
          path: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics/:tId/:tName/tasks",
          name: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics/:tId/:tName/tasks",
          component: () => import("@/components/academicsProgessTracker/homeClasses/subjects/topics/TopicsRead.vue"),
          meta: {
            title: "Progress Tracker",
            // docPath: "",
            permissions: ["view lesson-plans"],
            tenant: true,
            breadcrumb_name: "Tasks",
            breadcrumbs_parent: "/progress-tracker/home-classes/:hId/:hName/subjects/:sId/:sName/topics/:tId/:tName",
            numId: 117,
          },
        },
      ],
    },
    {
      path: "/lessons",
      name: "academics.lessons.index",
      meta: {
        title: "Lesson Planner",
        permissions: ["view lesson-plans"],
        numId: 53,
      },
      component: () => import("@/views/lessons/Index.vue"),
    },
    {
      path: "/reports",
      name: "academics.reports.index",
      meta: {
        title: "Reports Index",
        docPath: "./Academics/Reports/Reports.md",
        tabDocPaths: {
          reports: "./Academics/Reports/Reports.md",
        },
        permissions: ["view reports"],
        tenant: true,
        numId: 54,
      },
      component: () => import("@/views/reports/Index.vue"),
    },
    {
      path: "/reports/edit/:id",
      name: "academics.reports.index.edit",
      props: true,
      meta: {
        title: "Reports Index New",
        tabDocPaths: {
          reports: "./Academics/Reports/Reports.md",
          subjects: "./Academics/Reports/Report Subjects.md",
          extramurals: "./Academics/Reports/Report EMAs or Other.md",
          "extramural-comments": "./Academics/Reports/Report EMAs or Other.md",
          students: "./Academics/Reports/Students.md",
          "mark-groups": "./Academics/Reports/Report Data.md",
        },
        permissions: ["view reports"],
        tenant: true,
        numId: 55,
      },
      component: () => import("@/views/reports/Edit.vue"),
    },
    {
      path: "/academy/my-videos",
      name: "academy.my-videos",
      meta: {
        title: "GenEx Academy",
        tenant: true,
        numId: 56,
      },
      component: () => import("@/views/academy/TenantIndex"),
    },
    {
      path: "/academy/videos",
      name: "academy.videos",
      meta: {
        title: "GenEx Academy Content",
        central: true,
        numId: 57,
      },
      component: () => import("@/views/academy/CentralIndex"),
    },
    {
      path: "/courses",
      name: "academics.courses.index",
      meta: {
        title: "Course Index",
        docPath: "./Operations/Courses.md",
        permissions: ["view course-scopes"],
        central: true,
        numId: 58,
      },
      component: () => import("@/views/courses/Index.vue"),
    },
    {
      path: "/courses/edit/:id",
      props: true,
      name: "academics.courses.edit",
      meta: {
        title: "Edit Course",
        docPath: "./Operations/Courses.md",
        permissions: ["edit course-scopes"],
        central: true,
        tenant: true,
        numId: 59,
      },
      component: () => import("@/views/courses/Edit.vue"),
    },
    {
      path: "/global/courses/edit/:id",
      props: true,
      name: "admin.tenants.dashboard.course",
      meta: {
        title: "Edit Course",
        docPath: "./Operations/Courses.md",
        permissions: ["edit course-scopes"],
        central: true,
        tenant: true,
        numId: 60,
      },
      component: () => import("@/views/courses/Edit.vue"),
    },
    {
      path: "/my-classes",
      name: "classes.index",
      meta: {
        title: "My HomeClasses",
        docPath: "./My HomeClasses/My HomeClasses.md",
        permissions: ["view homeclass"],
        tenant: true,
        numId: 61,
      },
      component: () => import("@/views/classLists/Index.vue"),
    },
    {
      path: "/invites",
      name: "users.invite",
      meta: {
        title: "User Invites",
        docPath: "./Users/User Invites.md",
        permissions: ["view invites"],
        tenant: true,
        numId: 62,
      },
      component: () => import("@/views/invites/Index"),
    },
    {
      path: "/invites/create",
      name: "users.invite.create",
      meta: {
        title: "New User Invite",
        docPath: "./Human Resources/Incident Manager.md",
        permissions: ["create invites"],
        highlight: "users.invite",
        tenant: true,
        numId: 63,
      },
      component: () => import("@/views/invites/Create"),
    },
    {
      path: "/invites/edit/:id",
      name: "users.invite.edit",
      props: true,
      meta: {
        title: "User Invite",
        allowSelf: true,
        docPath: "./Users.md",
        highlight: "users.invite",
        permissions: ["view users"],
        tenant: true,
        numId: 64,
      },
      component: () => import("@/views/invites/Edit.vue"),
    },
    {
      path: "/staff",
      name: "users.staff",
      meta: {
        title: "Staff Index",
        docPath: "./Users/Staff.md",
        permissions: ["view users"],
        central: true,
        tenant: true,
        numId: 65,
      },
      component: () => import("@/views/users/staff/Index.vue"),
    },
    {
      path: "/staff/edit/:id",
      name: "users.staff.edit",
      props: true,
      meta: {
        title: "Staff",
        allowSelf: true,
        docPath: "./Users.md",
        highlight: "users.staff",
        permissions: ["view users"],
        central: true,
        tenant: true,
        numId: 66,
      },
      component: () => import("@/views/users/staff/Edit.vue"),
    },
    {
      path: "/guardians",
      name: "users.guardians",
      meta: {
        title: "Guardians",
        docPath: "./Users/Guardians.md",
        permissions: ["view guardians"],
        tenant: true,
        numId: 67,
      },
      component: () => import("@/views/users/guardians/Index"),
    },
    {
      path: "/guardians/edit/:id",
      name: "users.guardians.edit",
      props: true,
      meta: {
        title: "Guardian",
        docPath: "./Users.md",
        highlight: "users.guardians",
        permissions: ["view guardians"],
        tenant: true,
        numId: 68,
      },
      component: () => import("@/views/users/guardians/Edit"),
    },
    {
      path: "/user-import",
      name: "users.import",
      meta: {
        title: "User Import",
        docPath: "./Users/Import Users.md",
        permissions: ["import users"],
        tenant: true,
        numId: 69,
      },
      component: () => import("@/views/users/UserImport"),
    },
    {
      path: "/learners",
      name: "users.learners",
      meta: {
        title: "Students",
        docPath: "./Users/Students.md",
        permissions: ["view learners"],
        tenant: true,
        numId: 70,
      },
      component: () => import("@/views/users/learners/Index"),
    },
    {
      path: "/learners/edit/:id",
      name: "users.learners.edit",
      props: true,
      meta: {
        title: "Student",
        docPath: "./Users.md",
        highlight: "users.learners",
        permissions: ["view learners"],
        tenant: true,
        numId: 71,
      },
      component: () => import("@/views/users/learners/Edit"),
    },
    {
      path: "/campuses",
      name: "admin.campuses.dashboard",
      props: true,
      meta: {
        title: "Campus Administration",
        docPath: "./Admin/Campus/Campus Information.md",
        tabDocPaths: {
          "campus-information": "./Admin/Campus/Campus Information.md",
          notifications: "./Admin/Campus/Notifications.md",
          "manage-academics": "./Admin/Campus/Academics.md",
          services: "./Admin/Campus/Services.md",
          packages: "./Admin/Campus/Packages.md",
          terms: "./Admin/Campus/Terms.md",
          "sports-houses": "./Admin/Campus/Sports Houses.md",
          "helpdesk-users": "./Admin/Campus/Help Desk.md",
        },
        permissions: ["edit campuses", "edit phases", "edit stages", "edit subjects", "edit extramurals"],
        tenant: true,
        numId: 72,
      },
      component: () => import("@/views/campuses/Dashboard.vue"),
    },
    {
      path: "/global",
      name: "admin.tenants.dashboard",
      props: true,
      meta: {
        title: "Global Administration",
        docPath: "./Admin/Global/Admissions.md",
        tabDocPaths: {
          admissions: "./Admin/Global/Admissions.md",
          programmes: "./Admin/Global/Programmes.md",
          "report-templates": "./Admin/Global/Report Templates.md",
          settings: "./Admin/Global/Settings.md",
          leave: "./Admin/Global/Leave.md",
        },
        permissions: [
          "edit application-stages",
          "edit subjects",
          "edit report-templates",
          "edit settings",
          "edit leave-types",
          "edit course-scopes",
        ],
        tenant: true,
        numId: 73,
      },
      component: () => import("@/views/tenants/Dashboard.vue"),
    },
    {
      path: "/wizard",
      name: "admin.wizard.dashboard",
      props: true,
      meta: {
        title: "New Tenant Wizard",
        permissions: ["create campuses"],
        tenant: true,
        numId: 74,
      },
      component: () => import("@/views/wizard/Dashboard.vue"),
    },
    {
      path: "/tenants",
      name: "tenants.index",
      props: true,
      meta: {
        title: "Tenants",
        permissions: ["view tenants"],
        central: true,
        numId: 75,
      },
      component: () => import("@/views/tenants/Index.vue"),
    },
    {
      path: "/tenants/edit/:id",
      name: "tenants.edit",
      props: true,
      meta: {
        title: "Tenants",
        highlight: "tenants.index",
        permissions: ["edit tenants"],
        central: true,
        numId: 76,
      },
      component: () => import("@/views/tenants/Edit.vue"),
    },
    {
      path: "/enrolments",
      name: "students.enrolments.dashboard",
      meta: {
        title: "Enrolments",
        permissions: ["view enrolments"],
        docPath: "./Students/Enrolments.md",
        tenant: true,
        numId: 77,
      },
      component: () => import("@/views/enrolments/Dashboard.vue"),
    },
    {
      path: "/attendances",
      name: "attendances.index",
      meta: {
        title: "Attendances",
        docPath: "./Attendances/Attendances.md",
        permissions: [
          "view all service attendances",
          "view all extramural attendances",
          "view all homeclass attendances",
          "view all subject attendances",
          "view attendances",
        ],
        tenant: true,
        numId: 78,
      },
      component: () => import("@/views/attendances/Index.vue"),
    },
    {
      path: "/applications",
      name: "students.applications.index",
      meta: {
        title: "Applications",
        docPath: "./Students/Applications.md",
        permissions: ["view applications"],
        tenant: true,
        numId: 79,
      },
      component: () => import("@/views/applications/Index.vue"),
    },
    {
      path: "/applications/edit/:id",
      name: "students.applications.edit",
      props: true,
      meta: {
        title: "Application",
        docPath: "./Operations/Applications.md",
        permissions: ["edit applications"],
        tenant: true,
        numId: 80,
      },
      component: () => import("@/views/applications/Edit.vue"),
    },
    {
      path: "/roles",
      name: "operations.roles.index",
      meta: {
        title: "Roles",
        docPath: "./Operations/Roles.md",
        permissions: ["view roles"],
        central: true,
        tenant: true,
        numId: 81,
      },
      component: () => import("@/views/roles/Index"),
    },
    {
      path: "/support",
      name: "operations.support.index",
      meta: {
        title: "Support Tickets",
        permissions: ["view support-tickets"],
        central: true,
        numId: 82,
      },
      component: () => import("@/views/support/Index"),
    },
    {
      path: "/helpdesk",
      name: "operations.helpdesk.index",
      meta: {
        title: "Helpdesk",
        permissions: ["be helpdesk user"],
        tenant: true,
        allowChatBubble: false,
        numId: 83,
      },
      component: () => import("@/views/chat/Helpdesk"),
    },
    {
      path: "/updates",
      name: "operations.updates.index",
      meta: {
        title: "What's new",
        permissions: ["view support-tickets"],
        central: true,
        numId: 84,
      },
      component: () => import("@/views/releases/Index"),
    },
    {
      path: "/activities",
      name: "operations.activities.index",
      meta: {
        title: "Activities",
        docPath: "./Operations/Activities.md",
        permissions: ["view tasks"],
        tenant: true,
        numId: 85,
      },
      component: () => import("@/views/activities/Index"),
    },
    {
      meta: {
        allowChatBubble: false,
        title: "Apply",
        allow: true,
        tenant: true,
        numId: 86,
      },
      props: true,
      beforeEnter: (to, from, next) => {
        if (to.params.tenant) {
          next(); // <-- everything good, proceed
        } else {
          next({ name: "login" }); // <-- redirect to setup
        }
      },
      path: "/apply/:tenant",
      name: "apply",
      component: () => import(/* webpackChunkName: "full-page" */ "@/views/apply/PublicApply"),
    },
    {
      meta: {
        title: "Register",
        allow: true,
        tenant: true,
        numId: 87,
      },
      props: false,
      beforeEnter: (to, from, next) => {
        if (
          Object.hasOwnProperty.call(to.query, "email") &&
          Object.hasOwnProperty.call(to.query, "key") &&
          Object.hasOwnProperty.call(to.query, "tenant")
        ) {
          next(); // <-- everything good, proceed
        } else {
          next({ name: "login" }); // <-- redirect to setup
        }
      },
      path: "/register",
      name: "register",
      component: () => import(/* webpackChunkName: "full-page" */ "@/views/invites/Register"),
    },
    {
      path: "/g/",
      name: "guardian-home",
      meta: {
        role: "guardian",
        tenant: true,
        numId: 88,
      },
      component: () => import(/* webpackChunkName: "full-page" */ "@/views/layouts/PassThrough.vue"),
      children: [
        {
          meta: {
            title: "Home",
            docPath: "./Home.md",
            tenant: true,
            numId: 89,
          },
          path: "",
          component: () => import("@/views/dashboard/Staff"),
          beforeEnter: (to, from, next) => {
            if (Store.state.auth.canAccessGuardianHome === true) {
              return next();
            }
            Store.dispatch("loader/hide");
            next("/g/my-profile");
          },
          name: "guardian-home",
        },
        {
          meta: {
            title: "My Profile",
            docPath: "./My Profile.md",
            tenant: true,
            numId: 90,
          },
          path: "my-profile",
          name: "guardian-profile",
          component: () => import("@/views/g/MyProfile"),
        },
        {
          path: "academy/my-videos",
          name: "academy.my-videos",
          meta: {
            title: "GenEx Academy",
            tenant: true,
            numId: 91,
          },
          component: () => import("@/views/academy/TenantIndex"),
        },
        {
          path: "my-wards/:id",
          name: "guardian-wards.view",
          props: true,
          meta: {
            title: "My Wards",
            docPath: "./My Students.md",
            tenant: true,
            numId: 92,
          },
          component: () => import("@/views/g/wards/Edit.vue"),
        },
        {
          path: "chat",
          name: "guardian-chat.index",
          meta: {
            title: "Chat",
            docPath: "./Announcements.md",
            tenant: true,
            allowChatBubble: false,
            numId: 93,
          },
          component: () => import("@/views/chat/Index"),
        },
        {
          meta: {
            title: "Progress",
            docPath: "./Progress.md",
            tabDocPaths: {
              subjects: "./Progress.md",
            },
            tenant: true,
            numId: 94,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (!Store.state.user.has_unpaid_fees) {
              return next();
            }
            Store.dispatch("loader/hide");
            next("/g/my-profile");
          },
          path: "progress/:id",
          name: "guardian-progress",
          component: () => import("@/views/g/progress/Index.vue"),
        },
        {
          meta: {
            title: "Apply",
            docPath: "./My Applications.md",
            tenant: true,
            numId: 95,
          },
          path: "applications/create",
          name: "guardian-application.create",
          component: () => import("@/views/g/applications/Apply"),
        },
        {
          meta: {
            title: "Applications",
            docPath: "./My Applications.md",
            tenant: true,
            numId: 96,
          },
          path: "applications",
          name: "guardian-applications.index",
          component: () => import("@/views/g/applications/Index"),
        },
      ],
    },
    {
      path: "/full-page",
      component: () => import(/* webpackChunkName: "full-page" */ "@/views/layouts/FullPage.vue"),
      children: [
        {
          meta: {
            allowChatBubble: false,
            title: "Learner Reflection",
            allow: true,
            tenant: true,
            numId: 98,
          },
          path: "/learner-reflection",
          name: "learner-reflection",
          component: () => import(/* webpackChunkName: "full-page" */ "@/views/learnerReflections/Index.vue"),
        },
        {
          meta: {
            allowChatBubble: false,
            title: "Login",
            allow: true,
            central: true,
            tenant: true,
            numId: 99,
          },
          props: true,
          path: "/login/:tenant",
          name: "LoginTenant",
          component: () => import(/* webpackChunkName: "full-page" */ "@/views/auth/Login.vue"),
        },
        {
          meta: {
            allowChatBubble: false,
            title: "Login",
            allow: true,
            central: true,
            tenant: true,
            numId: 100,
          },
          path: "/login",
          name: "login",
          component: () => import(/* webpackChunkName: "full-page" */ "@/views/auth/Login.vue"),
        },
        {
          meta: {
            allowChatBubble: false,
            title: "Reset Password",
            allow: true,
            central: true,
            tenant: true,
            numId: 101,
          },
          path: "/forgot-password",
          name: "forgot-password",
          component: () => import(/* webpackChunkName: "full-page" */ "@/views/auth/ForgotPassword"),
        },
        {
          meta: {
            allowChatBubble: false,
            title: "Reset Password",
            allow: true,
            central: true,
            tenant: true,
            numId: 101,
          },
          path: "/forgot-password/:tenant",
          props: true,
          name: "forgot-password",
          component: () => import(/* webpackChunkName: "full-page" */ "@/views/auth/ForgotPassword"),
        },
        {
          meta: {
            allowChatBubble: false,
            title: "New Password",
            allow: true,
            central: true,
            tenant: true,
            numId: 102,
          },
          path: "/password-reset/:token",
          props: true,
          name: "new-password",
          component: () => import(/* webpackChunkName: "full-page" */ "@/views/auth/NewPassword"),
        },
      ],
      meta: {
        numId: 97,
      },
    },
    {
      name: "dashboard.my-dashboard",
      path: "/dashboard/my-dashboard",
      meta: {
        allow: false,
        //docPath: "./Dashboard/Enrolments.md",
        highlight: "my dashboard",
        title: "My Dashboard",
        tenant: true,
        permissions: ["view analytics"],
        numId: 118,
      },
      component: () => import("@/views/dashboard/MyDashboard"),
    },
  ],
});

handlebreadCrumbs(router.options.routes);

export default router;
