import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import CourseScope from "@/models/sis/CourseScope";
import ClassGroup from "@/models/sis/ClassGroup";
import Stage from "@/models/sis/Stage";
import SubjectConfig from "@/models/sis/SubjectConfig";
import Campus from "@/models/sis/Campus";
import AssessmentSubmission from "@/models/sis/AssessmentSubmission";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Folder from "@/models/sis/Folder";
import Enrolment from "@/models/sis/Enrolment";

/**
 * A subject
 * @property {number} id
 * @property {string} name - The name of the subject
 * @property {string} name - The name of the subject with other details
 * @property {string} code - The code of the subject
 * @property {number} campus_id - The id of the campus this subject belongs to
 * @property {number} stage_id - The id of the stage this subject belongs to
 * @property {number} ordinality - The order to display this subject
 */
export default class Subject extends GenexSISModel {
  static entity = "App\\Models\\Subject";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      long_name: this.attr(""),
      code: this.attr(null),
      class_group_id: this.attr(null),
      class_group: this.belongsTo(ClassGroup, "class_group_id"),
      subject_id: this.attr(null),
      user_ids: this.attr(() => []),
      users: this.hasManyBy(User, "user_ids"),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      course_scope_id: this.attr(null),
      course_scope: this.belongsTo(CourseScope, "course_scope_id"),
      stage_id: this.attr(null),
      stage: this.belongsTo(Stage, "stage_id"),
      report_description: this.attr(null),
      ordinality: this.attr(0),
      has_attendances: this.attr(0),
      is_for_programmes: this.attr(null),
      provider_code: this.attr(null),
      is_selected: this.attr(0),
      class_groups: this.hasMany(ClassGroup, "subject_id"),
      year: this.attr(null),
      teacher_id: this.attr(null),
      teacher: this.belongsTo(User, "teacher_id"),
      config: this.hasOne(SubjectConfig, "subject_id"),
      assessment_submissions: this.hasMany(AssessmentSubmission, "subject_id"),
      folders: this.morphMany(Folder, "folderable_id", "folderable_type"),
      enrolment_ids: this.attr(() => []),
      enrolments: this.hasManyBy(Enrolment, "enrolment_ids"),
    };
  }

  /**
   * Returns all subjects
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.stage_id]
   * @param {string} [query.search]
   * @param {string} mode - how to persist the response
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll(
    { page = 1, limit = 15 },
    query = {},
    relationships = [],
    mode = "insertOrUpdate",
    clearPrimaryModelOnly = false,
  ) {
    return this.api().get(`/subjects`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (clearPrimaryModelOnly) {
          this.deleteAll();
        }
        return normalize(data);
      },
    });
  }

  /**
   * Returns all subjects for a user
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.stage_id]
   * @param {string} [query.search]
   * @param {number} [user_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllByUser({ page = 1, limit = 15 }, relationships = [], user_id) {
    return this.api().get(`/users/${user_id}/subjects`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns a subject by its id
   * @function
   * @param {number} id The id of the subject
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/subjects/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Store a new subject
   * @function
   * @param {Object} subject - The subject object
   * @param {string} subject.name - The name of the subject
   * @param {string} subject.code - The code of the subject
   * @param {number} subject.campus_id - The id of the campus the subject is associated with
   * @param {number} subject.stage_id - The id of the stage the subject is associated with
   * @returns {Promise<Response>} - The newly created subject
   */

  static Store(subject) {
    return this.api().post(`/subjects`, subject, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing subject
   * @function
   * @param {Object} subject - The subject object
   * @param {string} subject.name - The name of the subject
   * @param {string} subject.code - The code of the subject
   * @param {number} subject.campus_id - The id of the campus the subject is associated with
   * @param {number} subject.stage_id - The id of the stage the subject is associated with
   * @param {string} subject.id - The id of the subject
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created subject
   */

  static Update(subject, saved) {
    return this.api().patch(`/subjects/${subject.id}`, subject, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing subject
   * @function
   * @param {number} subject_id - The id of the subject
   * @returns {Promise<Response>} - The newly created subject
   */

  static Delete(subject_id) {
    return this.api().delete(`/subjects/${subject_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: subject_id,
    });
  }
}
