import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A learning-stream
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class LearningStream extends GenexSISModel {
  static entity = "learning-streams";

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      name: this.attr(null),
      code: this.attr(null),
    };
  }

  /**
   * Returns all learning streams
   * @function
   * @returns {Promise<Response>}
   */

  static FetchAll() {
    return this.api().get(`/learning-streams`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(stream => {
          return { ...stream, ...stream.attributes };
        });
      },
    });
  }
}
