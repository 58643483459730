import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * The totals for a subjects grades by term
 * @property {number} term_id
 * @property {number} report_id
 * @property {number} subject_id

 * word
 */
export default class ReportSubjectGradeTotals extends GenexSISModel {
  static entity = "reportSubjectGradeTotals";
  static primaryKey = "term_id";

  static fields() {
    return {
      term_id: this.attr(null),
      markgroup_totals: this.attr(),
      markgroups: this.attr(null),
      finals: this.attr(null),
    };
  }

  /**
   * Returns totals
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} subject_id - The id of the subject
   * @param {number} class_group_id - The id of the class
   * @returns {Promise<Response>} the Topic
   */

  static FetchTotals(report_id, subject_id, class_group_id) {
    return this.api().get(`reports/${report_id}/subjects/${subject_id}/totals`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        class_group_id: class_group_id,
      },
      persistBy: "create",
    });
  }
}
