import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Media from "@/models/sis/Media";
import ReportTemplateLayout from "@/models/sis/ReportTemplateLayout";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A Report Template
 * @property {number} id

 */
export default class ReportTemplate extends GenexSISModel {
  static entity = "report-templates";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      creator_id: this.attr(null),
      images: this.attr(() => []),
      description: this.attr(null),
      layout_id: this.attr(null),
      layout: this.belongsTo(ReportTemplateLayout, "template_id"),
      subjects_per_page: this.attr(null),
      character_limit: this.attr(1200),
      colours: this.attr(() => {}),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr(() => []),
      created_at: this.attr(null),
      display_absenteeism: this.attr(false),
      matrix_label: this.attr(null),
      matrix_options: this.attr(() => []),
    };
  }

  /**
   * Returns all Report Templates
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Array<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, relationships = []) {
    return this.api().get(`/report-templates`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(template => {
          let config = JSON.parse(template.attributes.config);
          template.attributes.colours = config.colours;
          template.attributes.images = config.images;
          template.matrix_label = config.matrix_label;
          template.matrix_options = config.matrix_options;
          if (relationships.includes("media")) {
            if (relationships.includes("media")) {
              if (Object.prototype.hasOwnProperty.call(template.attributes, "media")) {
                template.attributes.media.map(item => {
                  Object.assign(item, item.attributes);
                  item.mediable_type = "report-templates";
                  item.mediable_id = template.id;
                });
              }
            }
          }
          return { ...template, ...template.attributes };
        });
      },
    });
  }

  /**
   * Returns a Report Template
   * @function
   * @param {number} id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/report-templates/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        let config = JSON.parse(data.attributes.config);
        data.attributes.colours = config.colours;
        data.attributes.images = config.images;
        data.attributes.matrix_label = config.matrix_label;
        data.attributes.matrix_options = config.matrix_options;
        if (relationships.includes("media")) {
          if (relationships.includes("media")) {
            if (Object.prototype.hasOwnProperty.call(data.attributes, "media")) {
              data.attributes.media.map(item => {
                Object.assign(item, item.attributes);
                item.mediable_type = "report-templates";
                item.mediable_id = data.id;
              });
            }
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a report template
   * @function
   * @param {Object} template - The template object
   * @returns {Promise<Response>} - The newly created template
   */

  static Store(template) {
    return this.api().post(`/report-templates`, template, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        let config = JSON.parse(data.attributes.config);
        data.attributes.colours = config.colours;
        data.attributes.images = config.images;
        data.attributes.matrix_label = config.matrix_label;
        data.attributes.matrix_options = config.matrix_options;
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing template
   * @function
   * @param {Object} template - The template object
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The updated templates
   */

  static Update(template, saved) {
    return this.api().patch(`/report-templates/${template.id}`, template, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        let config = JSON.parse(data.attributes.config);
        data.attributes.colours = config.colours;
        data.attributes.images = config.images;
        data.attributes.matrix_label = config.matrix_label;
        data.attributes.matrix_options = config.matrix_options;
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing template
   * @function
   * @param {number} template_id - The id of the template
   * @returns {Promise<Response>}
   */

  static Delete(template_id) {
    return this.api().delete(`/report-templates/${template_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: template_id,
    });
  }
}
