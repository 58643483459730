import { GenexSISModel } from "@/models/sis/GenexSISModel";
import CourseScope from "@/models/sis/CourseScope";
import Unit from "@/models/sis/Unit";
import Phase from "@/models/sis/Phase";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Year from "./sis/Year";

export default class TaskSheet extends GenexSISModel {
  static entity = "task_sheets";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      year: this.attr(null),
      yearModel: this.belongsTo(Year, "year"),
      quarter: this.attr(null),
      order: this.attr(null),
      creator_id: this.attr(null),
      phase_id: this.attr(null),
      phase: this.belongsTo(Phase, "phase_id"),
      course_scope_id: this.attr(null),
      course_scope: this.belongsTo(CourseScope, "course_scope_id"),
      course_scope_topic_unit_ids: this.attr(() => []),
      course_scope_topic_units: this.hasManyBy(Unit, "course_scope_topic_unit_ids"),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.api().get(`/task-sheets`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      persistBy,
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/task-sheets/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static Store(taskSheet) {
    return this.api().post(`/task-sheets`, taskSheet, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(taskSheet, relationships = []) {
    return this.api().patch(`/task-sheets/${taskSheet.id}`, taskSheet, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      update: taskSheet,
    });
  }

  static Delete(id) {
    return this.api().delete(`/task-sheets/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
