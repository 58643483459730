import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A user
 * @property {number} id
 * @property {number} type_id 1=FINANCE
 * @property {number} user_id
 * @property {number} campus_id
 * @property {boolean} gets_email
 * @property {boolean} gets_task
 * @property {Array.<Object>} wards
 */
export default class UserNotification extends GenexSISModel {
  static entity = "user-notifications";

  static fields() {
    return {
      id: this.attr(null),
      type_id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      campus_id: this.attr(null),
      gets_email: this.attr(0),
      gets_task: this.attr(0),
      related_model_id: this.attr(null),
    };
  }

  /**
   * Returns all user-notifications
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.user_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/user-notifications`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(record => {
          if (relationships.includes("user")) {
            if (Object.prototype.hasOwnProperty.call(record.attributes, "user")) {
              if (record.attributes.user !== null) {
                Object.assign(record.attributes.user, record.attributes.user.attributes);
              }
            }
          }
          return { ...record, ...record.attributes };
        });
      },
    });
  }

  /**
   * Returns a user-notification
   * @function
   * @param {number} [id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */
  static FetchById(id, relationships = []) {
    return this.api().get(`/user-notifications/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "user")) {
            if (data.attributes.user !== null) {
              Object.assign(data.attributes.user, data.attributes.user.attributes);
            }
          }
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Creates a user-notification
   * @function
   * @param {Object} notification
   * @returns {Promise<Response>}
   */
  static Store(notification) {
    return this.api().post(`/user-notifications`, notification, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Updates a user-notification
   * @function
   * @param {Object} notification
   * @returns {Promise<Response>}
   */
  static Update(notification) {
    return this.api().patch(`/user-notifications/${notification.id}`, notification, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('user')) {
        //     Object.assign(note.attributes.user, note.attributes.user.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Deletes a user-notification
   * @function
   * @param {number} id
   * @returns {Promise<Response>}
   */
  static Delete(id) {
    return this.api().delete(`/user-notifications/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
