import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import Media from "@/models/sis/Media";
import User from "@/models/sis/User";
import Tenant from "@/models/sis/Tenant";
import { format } from "date-fns";
import { SupportModule } from "@/store/modules/Support";

/**
 * A support ticket
 * @property {string} description
 * @property {string} category
 * @property {string} page_url
 * @property {number} tenant_id
 * @property {number} campus_id
 * @property {string} status
 * @property {description} status
 * @property {number} user_id
 * @property {Array<Object>} media
 * @property {Array<Object>} support_agent_id
 */
export default class SupportTicket extends GenexSISModel {
  static entity = "tickets";

  get formatted_date() {
    return format(new Date(this.created_at), "yyyy-MM-dd hh:mm");
  }

  static fields() {
    return {
      id: this.attr(null),
      category: this.attr(null),
      url: this.attr(null),
      tenant_id: this.attr("", value => (value === null ? value : parseInt(value))),
      tenant: this.belongsTo(Tenant, "tenant_id"),
      campus_id: this.attr("", value => (value === null ? value : parseInt(value))),
      tenant_campus: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      status_id: this.attr(null),
      status: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      description: this.attr(null),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      reference: this.attr(null),
      created_at: this.attr(null),
      user_name: this.attr(null),
      completed_at: this.attr(null),
      support_agent_id: this.attr(null),
      support_agent: this.belongsTo(User, "user_id"),
    };
  }

  /**
   * Returns all Support Tickets
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.campus_id] - search by campus_id
   * @param {number} [query.user_id] - search by user
   * @param {number} [query.tenant_id] - search by tenant
   * @param {string} [query.date] - search by date
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the CourseScopes
   */
  static FetchAll({ page = 1, limit = 20 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("SupportTicket.FetchAll", `/tickets`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(ticket => {
          if (relationships.includes("user")) {
            if (ticket.attributes.user !== null) {
              Object.assign(ticket.attributes.user, ticket.attributes.user.attributes);
            }
          }
          if (relationships.includes("campus")) {
            Object.assign(ticket.attributes.campus, ticket.attributes.campus.attributes);
          }
          if (relationships.includes("tenant")) {
            Object.assign(ticket.attributes.tenant, ticket.attributes.tenant.attributes);
          }
          if (relationships.includes("media")) {
            ticket.attributes.media.map(item => {
              Object.assign(item, item.attributes);
            });
          }
          ticket.status =
            SupportModule.state.statuses.find(status => status.id === ticket.attributes.status_id).name ?? "Unknown";
          return { ...ticket, ...ticket.attributes };
        });
      },
    });
  }

  /**
   * Returns a Support Tickets
   * @function
   * @param {number} id - id of the ticket
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the CourseScopes
   */
  static FetchById(id, relationships = []) {
    return this.GenexAxiosGet("SupportTicket.FetchById", `/tickets/${id}`, {
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (Object.prototype.hasOwnProperty.call(data.attributes, "user")) {
          if (data.attributes.user !== null) {
            Object.assign(data.attributes.user, data.attributes.user.attributes);
          }
        }
        if (relationships.includes("campus")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "campus")) {
            Object.assign(data.attributes.campus, data.attributes.campus.attributes);
          }
        }
        if (relationships.includes("tenant")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "tenant")) {
            Object.assign(data.attributes.tenant, data.attributes.tenant.attributes);
          }
        }
        if (relationships.includes("media")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "media")) {
            data.attributes.media.map(item => {
              Object.assign(item, item.attributes);
            });
          }
        }

        data.status =
          SupportModule.state.statuses.find(status => status.id === data.attributes.status_id).name ?? "Unknown";

        return { ...data, ...data.attributes };
      },
    });
  }

  static Store(ticket) {
    return this.GenexAxiosPost("SupportTicket.Store", `/tickets`, ticket, {
      dataTransformer: ({ data: { data } }) => {
        data.status =
          SupportModule.state.statuses.find(status => status.id === data.attributes.status_id).name ?? "Unknown";

        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(ticket, saved = true) {
    return this.GenexAxiosPatch("SupportTicket.Update", `/tickets/${ticket.id}`, ticket, {
      dataTransformer: ({ data: { data } }) => {
        data.status =
          SupportModule.state.statuses.find(status => status.id === data.attributes.status_id).name ?? "Unknown";

        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  static Delete(ticket_id) {
    return this.GenexAxiosDelete("SupportTicket.Delete", `/tickets/${ticket_id}`, {
      delete: ticket_id,
    });
  }
}
