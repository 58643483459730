<template>
  <form @submit.prevent="submit">
    <ValidationObserver ref="observer">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? "Create a new" : "Edit" }} Folder</p>
        </header>
        <section class="modal-card-body">
          <b-field label="Name">
            <b-input v-model="folder.name" placeholder="Folder name" required></b-input>
          </b-field>
          <b-field label="View Permissions">
            <ValidationProvider v-slot="{ errors }" rules="required|min-items:1">
              <b-dropdown v-model="folder.permissions_ids" append-to-body aria-role="list" expanded multiple>
                <b-button slot="trigger" class="select" expanded> Select</b-button>

                <b-dropdown-item
                  :disabled="hasNoPermissions([`${edit ? 'edit' : 'create'} folders for global`])"
                  :value="'view folders for global'"
                  aria-role="listitem"
                  required>
                  <span>Global / Tenant</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :disabled="hasNoPermissions([`${edit ? 'edit' : 'create'} staff folders`])"
                  :value="'view staff folders'"
                  aria-role="listitem"
                  required>
                  <span>Staff</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="hasNoPermissions([`${edit ? 'edit' : 'create'} hr folders`])"
                  :value="'view hr folders'"
                  aria-role="listitem"
                  required>
                  <span>HR</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="hasNoPermissions([`${edit ? 'edit' : 'create'} finance folders`])"
                  :value="'view finance folders'"
                  aria-role="listitem"
                  required>
                  <span>Finance</span>
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="hasNoPermissions([`${edit ? 'edit' : 'create'} guardian folders`])"
                  :value="'view guardian folders'"
                  aria-role="listitem"
                  required>
                  <span>Guardian</span>
                </b-dropdown-item>
              </b-dropdown>
              <span class="help is-danger">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </b-field>

          <b-field v-if="showCampusDropdown" label="campus">
            <b-select v-model="folder.campus_id" expanded placeholder="Select a campus" required>
              <option v-for="campus in campuses" :key="campus.id" :value="campus.id">
                {{ campus.name }}
              </option>
            </b-select>
          </b-field>
        </section>
        <footer class="modal-card-foot">
          <button :icon-right="edit === false ? 'add' : 'import'" class="button" type="is-primary">Save</button>
        </footer>
      </div>
    </ValidationObserver>
  </form>
</template>

<script>
  import Folder from "@/models/sis/Folder";
  import Permission from "@/models/sis/Permission";
  import { extend, ValidationObserver, ValidationProvider } from "vee-validate";
  import { required } from "vee-validate/dist/rules";
  import Campus from "@/models/sis/Campus";

  extend("required", {
    ...required,
    message: "This field is required",
  });

  extend("min-items", {
    validate(value, args) {
      return value.length >= args.length;
    },
    params: ["length"],
  });
  export default {
    name: "FolderForm",

    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data() {
      return {
        folder: {
          name: this.name,
          ordinality: 0,
          permissions_ids: [],
          parent_id: this.parentId,
          campus_id: null,
          folderable_id: 1,
          folderable_type: "InfoHub",
        },
        loadingData: false,
      };
    },
    computed: {
      campuses() {
        return Campus.query()
          .get()
          .filter(campus => {
            return campus.name.length > 0;
          });
      },
      showCampusDropdown() {
        return !this.folder.permissions_ids.includes("view folders for global");
      },
    },
    mounted() {
      this.$store.dispatch("loader/show");
      Campus.FetchAll({ page: 1, limit: 100 }).then(() => {
        this.$store.dispatch("loader/hide");
      });

      if (this.edit) {
        this.folder = JSON.parse(JSON.stringify(this.folderObject));
      }
    },
    watch: {
      "folder.permissions_ids"(newVal) {
        if (newVal.includes("view folders for global")) {
          this.folder.campus_id = null;
        }
      },
    },
    methods: {
      submit() {
        let submitObject = JSON.parse(JSON.stringify(this.folder));
        submitObject.permissions_ids = submitObject.permissions_ids.map(permissionName => {
          return Permission.query().where("name", permissionName).first().id;
        });
        if (this.edit) {
          Folder.Update(
            {
              name: submitObject.name,
              ordinality: submitObject.ordinality,
              id: submitObject.id,
              permissions: submitObject.permissions_ids,
              parent_id: submitObject.parent_id,
              campus_id: submitObject.campus_id,
              folderable_id: submitObject.folderable_id,
              folderable_type: submitObject.folderable_type,
            },
            true,
          )
            .then(() => {
              this.$buefy.snackbar.open(`Folder updated!`);
              this.$emit("close");
            })
            .catch(err => {
              this.handleError(err);
            });
        } else {
          Folder.Store({
            name: submitObject.name,
            ordinality: submitObject.ordinality,
            permissions: submitObject.permissions_ids,
            parent_id: submitObject.parent_id,
            campus_id: submitObject.campus_id,
            folderable_id: submitObject.folderable_id,
            folderable_type: submitObject.folderable_type,
          })
            .then(() => {
              this.$buefy.snackbar.open(`Folder created!`);
              this.$emit("close");
            })
            .catch(err => {
              this.handleError(err);
            });
        }
      },
    },
    props: {
      folderObject: {
        type: Object,
        default() {
          return null;
        },
      },
      parentId: {
        type: Number,
        default() {
          return null;
        },
      },
      name: {
        type: String,
        default() {
          return "";
        },
      },

      edit: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
  };
</script>
