import User from "./User";
import ClassGroup from "./ClassGroup";
import { normalize } from "@/utils/data";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import JournalFeedback from "@/models/sis/JournalFeedback";
import Activity from "@/models/sis/Activity";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class JournalEntry extends GenexSISModel {
  static entity = "journal-entries";

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(""),
      content: this.attr(""),
      status_id: this.attr(null),
      class_group_id: this.attr(null),
      student_id: this.attr(null),
      status: this.attr(null),
      published_at: this.attr(null),
      is_published: this.attr(null),
      student: this.belongsTo(User, "student_id"),
      class_group: this.belongsTo(ClassGroup, "class_group_id"),
      journal_feedback_ids: this.attr([]),
      journal_feedback: this.hasManyBy(JournalFeedback, "journal_feedback_ids"),
      activity_ids: this.attr([]),
      activities: this.hasManyBy(Activity, "activity_ids"),
    };
  }

  /**
   * Returns all journal-entries
   * @param {Object} pagination - Pagination parameters
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {string} [mode="insertOrUpdate"] - How to persist the response
   * @returns {Promise<Response>} - Promise containing the journal-entries
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get("/journal-entries", {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns a single journal by ID
   * @param {number} id - The ID of the journal
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} - Promise containing the journal
   */
  static FetchById(id, relationships = []) {
    return this.api().get(`/journal-entries/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Stores a new journal
   * @param {Object} journal - The journal object to store
   * @returns {Promise<Response>} - Promise containing the newly created journal
   */
  static Store(journal) {
    return this.api().post("/journal-entries", journal, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Updates an existing journal
   * @param {Object} journal - The journal object to update
   * @returns {Promise<Response>} - Promise containing the updated journal
   */
  static Update(journal) {
    return this.api().patch(`/journal-entries/${journal.id}`, journal, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Deletes an existing journal
   * @param {number} journal_id - The ID of the journal to delete
   * @returns {Promise<Response>} - Promise representing the delete operation
   */
  static Delete(journal_id) {
    return this.api().delete(`/journal-entries/${journal_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
    });
  }
}
