import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An event type
 * @property {number} id
 * @property {string} name
 */
export default class EventType extends GenexSISModel {
  static entity = "event-types";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
    };
  }

  /**
   * Returns all event types
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?string} [query.name]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/event-types`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(eventType => {
          return { ...eventType, ...eventType.attributes };
        });
      },
    });
  }
}
