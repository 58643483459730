<template>
  <div id="app" style="margin-bottom: 70px">
    <nav-bar v-if="authenticated" ref="navbar" />
    <aside-menu v-if="authenticated && type === 'staff'" ref="staff-menu" :menu="menu" :show-menu-expand="true" />
    <aside-menu
      v-if="authenticated && type === 'guardian'"
      ref="guardian-menu"
      :menu="guardianMenu"
      :show-menu-expand="true" />
    <BugBanner v-if="authenticated" />
    <router-view id="app-view" />
    <aside-right :docs-type="$store.state.auth.type" />
    <b-loading
      ref="pageLoader"
      :active.sync="loading"
      :can-cancel="false"
      :class="{ opaque: opaque }"
      :is-full-page="true" />
  </div>
</template>

<script>
  import NavBar from "@/components/layout/NavBar";
  import AsideMenu from "@/components/layout/AsideMenu";
  import TenantReleaseModal from "@/components/releases/TenantReleaseModal";
  import { mapGetters, mapState } from "vuex";
  import Profile from "@/models/sis/Profile";
  import AsideRight from "@/components/layout/AsideRight";
  import BugBanner from "@/components/global/AlertBanner.vue";
  import User from "@/models/sis/User";
  import Setting from "@/models/sis/Setting";
  import Release from "@/models/sis/Release";
  import HelpdeskUser from "@/models/sis/HelpdeskUser";
  import { useFeatureFlag } from "@/compositions/useFeatureFlag";
  import Year from "./models/sis/Year";

  export default {
    components: {
      BugBanner,
      AsideRight,
      AsideMenu,
      NavBar,
    },
    setup() {
      const progressTrackerFlag = useFeatureFlag("progress-tracker");

      return {
        progressTrackerFlag,
      };
    },
    data() {
      return {
        hasProfile: true,
        guardianWards: null,
        isDragging: false,
        mouseX: 0,
        isMouseDown: false,
        dragDirection: 0,
        isMenuOpen: false,
      };
    },
    computed: {
      ...mapState("user", ["last_name", "first_name", "avatar", "id", "uuid", "organisations"]),
      ...mapState("loader", ["loading", "overlay", "opaque"]),
      ...mapState("size", ["height", "width"]),
      ...mapState("popout", ["top", "display"]),
      ...mapState("auth", ["authenticated", "type"]),
      ...mapState("release", ["releaseWindowOpen"]),
      ...mapGetters("tenant", ["faviconUrl", "defaultFavIconUrl", "isWhiteLabeled"]),
      ...mapGetters("dates", ["currentYear", "years", "previousYears", "application-years"]),
      user() {
        return User.query().whereId(this.id).exists()
          ? User.query().whereId(this.id).first() ?? {
              first_name: "",
              last_name: "",
              avatar: "",
              id: "",
              uuid: "",
            }
          : {
              first_name: this.first_name,
              last_name: this.last_name,
              avatar: this.avatar,
              id: this.id,
              uuid: this.uuid,
            };
      },
      isComsUser() {
        return this.organisations.length === 1 && this.organisations[0].name === "COMS" ? true : false;
      },
      allowChatBubble() {
        let result = true;
        if (typeof this.$route !== "undefined" && typeof this.$route.meta !== "undefined") {
          if (Object.keys(this.$route.meta).length) {
            if (typeof this.$route.meta.allowChatBubble !== "undefined" && this.$route.meta.allowChatBubble === false) {
              result = false;
            }
          } else {
            result = false;
          }
        }
        return result;
      },
      menu() {
        return [
          {
            label: "Home",
            icon: "home",
            name: "home",
            to: "/",
            central: true,
            tenant: true,
            showOnApp: true,
          },
          {
            label: "Dashboard",
            icon: "element",
            name: "dashboard",
            central: false,
            tenant: true,
            showOnApp: false,
            permissions: ["view analytics"],
            menu: [
              {
                label: "My Dashboard",
                to: "/dashboard/my-dashboard",
                name: "dashboard.my-dashboard",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "Enrolments",
                to: "/dashboard/enrolments",
                name: "dashboard.enrolments",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "Applications",
                to: "/dashboard/applications",
                name: "dashboard.applications",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "Engagements",
                to: "/dashboard/engagements",
                name: "dashboard.engagements",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "Human Resources",
                to: "/dashboard/hr-dashboard",
                name: "dashboard.hr-dashboard",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "Reviews",
                to: "/dashboard/reviews",
                name: "dashboard.reviews",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "LMS",
                to: "/dashboard/lms-widgets",
                name: "dashboard.LmsWidgets",
                central: false,
                tenant: true,
                showOnApp: false,
              },
            ],
          },
          {
            label: "Academics",
            icon: "teacher",
            name: "academics",
            central: this.isComsUser ? false : true, //disabling menu for COMS users
            tenant: true,
            showOnApp: false,
            permissions: ["view lesson-plans", "view reports", "view course-scopes", "view objectives"],
            menu: [
              {
                label: "Progress Tracker",
                to: "/progress-tracker",
                name: "/progress-tracker",
                permissions: ["view lesson-plans"],
                central: false,
                tenant: true,
                showOnApp: false,
                hide: !this.progressTrackerFlag,
              },
              {
                label: "Subjects",
                to: "/lesson-plans/subjects",
                name: "academics.lesson-plans.index",
                permission: "view lesson-plans",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              {
                label: "Reports",
                to: "/reports",
                name: "academics.reports.index",
                permission: "view reports",
                central: false,
                tenant: true,
                showOnApp: false,
              },
              // ToDo: uncomment this when "SIS: Data Analysis v2" card is complete (https://trello.com/c/UM4uhTL8/1725-sis-data-analysis-v2)
              // {
              //   label: "Analysis",
              //   to: "/analysis",
              //   name: "academics.analysis.index",
              //   permission: "view analytics",
              //   central: false,
              //   tenant: true,
              // },
              {
                label: "Courses",
                to: "/courses",
                name: "academics.courses.index",
                central: true,
                tenant: false,
                showOnApp: false,
                permission: "view course-scopes",
              },
              {
                label: "Objective Manager",
                to: "/objectives",
                name: "academics.objectives.index",
                central: true,
                tenant: false,
                showOnApp: false,
                permission: "view objectives",
              },
            ],
          },
          {
            label: "Tenants",
            icon: "bank",
            name: "tenants",
            central: true,
            tenant: false,
            showOnApp: false,
            permissions: ["view tenants"],
            menu: [
              {
                label: "Index",
                to: "/tenants",
                name: "tenants.index",
                permission: "view tenants",
                central: true,
                tenant: false,
                showOnApp: false,
              },
            ],
          },
          {
            label: "Students",
            icon: "user-octagon",
            name: "students",
            central: false,
            tenant: true,
            showOnApp: false,
            permissions: ["process applications", "view enrolments"],
            menu: [
              {
                label: "Applications",
                to: "/applications",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "students.applications.index",
                permission: "process applications",
              },
              {
                label: "Enrolments",
                to: "/enrolments",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "students.enrolments.dashboard",
                permission: "view enrolments",
              },
            ],
          },
          {
            label: "Users",
            icon: "profile-user",
            central: true,
            tenant: true,
            showOnApp: false,
            permissions: ["view users", "view learners", "view guardians"],
            name: "users",
            menu: [
              {
                label: "Staff",
                permission: "view users",
                name: "users.staff",
                central: true,
                tenant: true,
                showOnApp: false,
                to: "/staff",
              },
              {
                label: "Guardians",
                permission: "view guardians",
                name: "users.guardians",
                central: false,
                tenant: true,
                showOnApp: false,
                to: "/guardians",
              },
              {
                label: this.$tc("Learner", 2),
                permission: "view learners",
                name: "users.learners",
                to: "/learners",
                central: false,
                showOnApp: false,
                tenant: true,
              },
              {
                label: "Import Users",
                permission: "import users",
                name: "users.import",
                to: "/user-import",
                central: false,
                showOnApp: false,
                tenant: true,
              },
              {
                label: "User Invites",
                permission: "view invites",
                name: "users.invite",
                to: "/invites",
                central: false,
                showOnApp: false,
                tenant: true,
              },
            ],
          },
          {
            label: "My HomeClasses",
            to: "/my-classes",
            central: false,
            tenant: true,
            showOnApp: true,
            name: "classes.index",
            icon: "box",
            permission: "view homeclass",
          },
          {
            label: "Attendances",
            to: "/attendances",
            central: false,
            tenant: true,
            showOnApp: true,
            name: "attendances.index",
            icon: "task-square",
            permissions: [
              "view all service attendances",
              "view all extramural attendances",
              "view all homeclass attendances",
              "view all subject attendances",
              "view attendances",
            ],
          },
          {
            label: "Human Resources",
            icon: "user-tick",
            central: false,
            tenant: true,
            showOnApp: false,
            permissions: ["manage leave requests", "administrate incidents"],
            name: "human-resources",
            menu: [
              {
                label: "Leave Requests",
                to: "/leave",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "human-resources.leave.index",
                permission: "manage leave requests",
              },
              {
                label: "Incidents",
                central: false,
                tenant: true,
                showOnApp: false,
                permission: "administrate incidents",
                name: "human-resources.incidents.index",
                to: "/incidents",
              },
            ],
          },
          {
            label: "Operations",
            icon: "global",
            name: "operations",
            central: this.isComsUser ? false : true, //disabling menu for COMS users
            tenant: true,
            showOnApp: false,
            permissions: [
              "view tasks",
              "administrate announcements",
              "create announcements",
              "view roles",
              "view exports",
              "view support-tickets",
              "view exports",
              ...(this.showHelpdesk ? ["be helpdesk user"] : []),
            ],
            menu: [
              {
                label: "Activities",
                to: "/activities",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "operations.activities.index",
                permission: "view tasks",
              },
              {
                permissions: ["administrate announcements", "create announcements"],
                label: "Announcements",
                to: "/announcements",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "operations.announcements.index",
              },
              ...(this.showHelpdesk
                ? [
                    {
                      label: "Helpdesk",
                      to: "/helpdesk",
                      central: false,
                      tenant: true,
                      showOnApp: false,
                      name: "operations.helpdesk.index",
                      permissions: ["be helpdesk user"],
                    },
                  ]
                : []),
              {
                permissions: ["view support-tickets"],
                label: "Support",
                to: "/support",
                central: true,
                tenant: false,
                showOnApp: false,
                name: "operations.support.index",
              },
              {
                label: "Updates",
                to: "/updates",
                central: true,
                tenant: false,
                showOnApp: false,
                name: "operations.updates.index",
              },
              {
                label: "Roles",
                to: "/roles",
                central: true,
                tenant: true,
                showOnApp: false,
                name: "operations.roles.dashboard",
                permission: "view roles",
              },
              {
                label: "Exports",
                to: "/exports",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "operations.exports.index",
                permission: "view exports",
              },
              {
                label: "Meetings",
                central: false,
                tenant: true,
                showOnApp: false,
                permission: "administrate meetings",
                name: "operations.meetings.index",
                to: "/meetings",
              },
            ],
          },
          {
            label: "Admin",
            icon: "bank",
            name: "admin",
            central: false,
            tenant: true,
            showOnApp: false,
            permissions: [
              "edit campuses",
              "edit phases",
              "edit stages",
              "edit subjects",
              "edit extramurals",
              "edit terms",
              "edit services",
              "edit application-stages",
              "edit subjects",
              "edit report-templates",
              "edit settings",
              "edit leave-types",
              "edit course-scopes",
            ],

            menu: [
              {
                label: "Campus",
                to: "/campuses",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "admin.campuses.dashboard",
                permissions: [
                  "edit campuses",
                  "edit phases",
                  "edit stages",
                  "edit subjects",
                  "edit extramurals",
                  "edit terms",
                  "edit services",
                ],
              },
              {
                label: "Global",
                to: "/global",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "admin.tenants.dashboard",
                permissions: [
                  "edit application-stages",
                  "edit subjects",
                  "edit report-templates",
                  "edit settings",
                  "edit leave-types",
                  "edit course-scopes",
                ],
              },
              ...(this.showWizard
                ? [
                    {
                      label: "Wizard",
                      to: "/wizard",
                      central: false,
                      tenant: true,
                      showOnApp: false,
                      name: "admin.wizard.dashboard",
                      permissions: ["create campuses"],
                    },
                  ]
                : []),
            ],
          },
          {
            label: "GenEx Academy",
            icon: "video-octagon",
            name: "academy",
            central: this.isComsUser ? false : true, //disabling menu for COMS users
            tenant: false,
            showOnApp: false,
            menu: [
              {
                label: "Video Content",
                to: "/academy/my-videos",
                central: false,
                tenant: true,
                showOnApp: false,
                name: "academy.my-videos",
              },
              {
                label: "Video Content",
                to: "/academy/videos",
                central: true,
                tenant: false,
                showOnApp: false,
                name: "academy.videos",
              },
            ],
          },
        ];
      },
      showHelpdesk() {
        if (this.hasPermission(["be helpdesk user"])) {
          return HelpdeskUser.query().where("user_id", this.id).exists();
        }
        return false;
      },
      showWizard() {
        if (Setting.query().where("key", "wizard_step").exists()) {
          if (parseInt(Setting.query().where("key", "wizard_step").first()?.value) < 4) {
            return true;
          }
        }
        return false;
      },
      guardianMenu() {
        return [
          ...(this.$store.state.auth.canAccessGuardianHome
            ? [
                {
                  label: "Home",
                  icon: "home",
                  name: "guardian-home",
                  central: false,
                  tenant: true,
                  showOnApp: true,
                  to: "/g/",
                },
              ]
            : []),
          ...(this.hasProfile
            ? [
                {
                  label: "My Profile",
                  icon: "profile-user",
                  central: false,
                  showOnApp: true,

                  tenant: true,
                  name: "guardian-profile",
                  to: "/g/my-profile",
                },
              ]
            : []),
          ...[
            {
              label: "My Applications",
              central: false,
              tenant: true,
              showOnApp: true,
              icon: "applications",
              name: "guardian-applications",
              to: "/g/applications",
            },
            {
              label: `My ${this.$tc("Learner", 2)}`,
              icon: "teacher",
              name: "guardian-wards",
              central: false,
              tenant: true,
              showOnApp: true,
              menu: this.guardianWards
                ? this.guardianWards.map(ward => {
                    return {
                      label: ward.first_name,
                      name: `guardian-wards.${ward.id}`,
                      central: false,
                      showOnApp: true,
                      tenant: true,
                      to: `/g/my-wards/${ward.id}`,
                    };
                  })
                : [],
            },
            ...(!this.$store.state.user.has_unpaid_fees
              ? [
                  {
                    label: `Progress`,
                    icon: "teacher",
                    name: "guardian-progress",
                    central: false,
                    tenant: true,
                    showOnApp: true,
                    menu: this.guardianWards
                      ? this.guardianWards.map(ward => {
                          return {
                            label: ward.first_name,
                            name: `guardian-progress.${ward.id}`,
                            central: false,
                            showOnApp: true,
                            tenant: true,
                            to: `/g/progress/${ward.id}`,
                          };
                        })
                      : [],
                  },
                ]
              : []),
            {
              label: "GenEx Academy",
              icon: "video-octagon",
              name: "academy",
              central: true,
              showOnApp: true,
              tenant: false,
              menu: [
                {
                  label: "Video Content",
                  to: "/g/academy/my-videos",
                  central: false,
                  tenant: true,
                  showOnApp: true,

                  name: "academy.my-videos",
                },
              ],
            },
          ],
        ];
      },
    },
    watch: {
      faviconUrl(newVal) {
        if (newVal) {
          this.setFavIcon();
        }
      },
      id(newVal) {
        HelpdeskUser.FetchAll({ page: 1, limit: 999 }, { user_id: newVal });
        Year.FetchAll({ page: 1, limit: 999 });
      },
      allowChatBubble(newVal) {
        if (newVal === true && this.$store.state.chat.chatScriptLoaded) {
          this.delayedLaunch();
        } else if (newVal === false) {
          this.$store.dispatch("chat/removeChatBubble");
        }
      },
      isAsideMobileExpanded() {
        this.isMenuOpen = this.isAsideMobileExpanded;
      },
      type(newVal) {
        if (newVal === "guardian") {
          User.FetchById(parseInt(this.$store.state.user.id), ["wards"])
            .then(response => {
              if (response.response.data.data.attributes.wards.length > 0) {
                this.guardianWards = response.entities.users
                  .filter(user => user.id !== parseInt(this.$store.state.user.id))
                  .map(ward => {
                    return {
                      id: ward.id,
                      first_name: ward.first_name,
                    };
                  });
              }
            })
            .catch(err => {
              this.handleError(err);
            });
        }
      },
      authenticated(newVal) {
        if (newVal) {
          if (!this.$cookies.isKey(`is_fresh_login`)) {
            this.$cookies.set(`is_fresh_login`, false);
            if (!this.$cookies.isKey(`update_on_next_login`)) {
              this.$cookies.set("update_on_next_login", true);
              this.openWhatsNewModal();
            } else if (this.$cookies.get("update_on_next_login") === "true") {
              this.openWhatsNewModal();
            }
          }
        }
      },
      dragDirection() {
        if (this.dragDirection == 1) {
          document.dispatchEvent(new Event("touchdragright"));
        } else if (this.dragDirection == -1) {
          document.dispatchEvent(new Event("touchdragleft"));
        }
      },
      releaseWindowOpen(newVal) {
        if (newVal === true) {
          this.$buefy.modal.open({
            parent: this,
            props: {
              inModal: true,
              type: this.type,
            },
            component: TenantReleaseModal,
            hasModalCard: true,
            fullScreen: false,
            trapFocus: true,
            canCancel: ["x"],
            onCancel: () => {
              this.$store.dispatch("release/closeReleaseWindow");
            },
            events: {
              close: () => {
                this.$store.dispatch("release/closeReleaseWindow");
              },
            },
          });
        }
      },
      $route(to, from) {
        let from_suffix = "";
        if (from.query["tab"] !== undefined) {
          from_suffix = "?tab=" + from.query["tab"];
        }
        let to_suffix = "";
        if (to.query["tab"] !== undefined) {
          to_suffix = "?tab=" + to.query["tab"];
        }

        if (to.name + to_suffix !== from.name + from_suffix) {
          this.track("pageView." + to.name + to_suffix, {});
        }
      },
    },
    created() {
      this.setFavIcon();
      document.addEventListener("touchdragleft", () => {
        this.closeMenu();
      });
      document.addEventListener("touchdragright", () => {
        this.openMenu();
      });
      document.addEventListener("touchmove", this.trackDragging);
      document.addEventListener("touchend", this.stopDragging);
      if (this.id) {
        HelpdeskUser.FetchAll({ page: 1, limit: 999 }, { user_id: this.id });
      }
    },

    methods: {
      getFavIconUrl() {
        if (!this.isWhiteLabeled) {
          return this.defaultFavIconUrl;
        }
        return this.faviconUrl;
      },
      async setFavIcon() {
        const url = this.getFavIconUrl();
        const exists = await this.checkFavIconExists(url);
        document.getElementById("favicon").href = exists ? url : this.defaultFavIconUrl;
      },
      checkFavIconExists(url) {
        return new Promise(resolve => {
          let img = new Image();
          img.onload = () => {
            resolve(true);
          };
          img.onerror = () => {
            resolve(false);
          };
          img.src = url;
        });
      },
      delayedLaunch() {
        if (this.$store.getters["tenant/isCentral"]) {
          return;
        }
        if (
          !this.user.first_name.length ||
          this.user.uuid === null ||
          typeof this.$store.state.chat.chat_app_id !== "string"
        ) {
          setTimeout(() => {
            this.delayedLaunch();
          }, 300);
          return;
        }
        this.launch();
      },
      async launch() {
        if (!this.allowChatBubble) {
          return;
        }
        try {
          // eslint-disable-next-line no-undef
          await CometChatWidget.init({
            appID: this.$store.state.chat.chat_app_id,
            appRegion: this.$store.state.chat.chat_app_region,
            authKey: this.$store.state.chat.chat_app_auth_key,
          });
        } catch (err) {
          return;
        }

        // eslint-disable-next-line no-undef
        const user = new CometChatWidget.CometChat.User(this.user.uuid);
        user.setName(this.user.first_name + " " + this.user.last_name);
        user.setAvatar(this.user.avatar);
        if (this.$store.state.auth.type === "guardian") {
          user.setLink(`https://${process.env.VUE_APP_BASE_URL}/guardians/edit/${this.user.id}`);
        } else {
          user.setLink(`https://${process.env.VUE_APP_BASE_URL}/staff/edit/${this.user.id}`);
        }
        // eslint-disable-next-line no-undef
        await CometChatWidget.createOrUpdateUser(user);
        // eslint-disable-next-line no-undef
        CometChatWidget.login({
          uid: this.user.uuid,
        }).then(() => {
          // eslint-disable-next-line no-undef
          CometChatWidget.launch({
            widgetID: this.$store.state.chat.chat_app_widget_id,
            docked: "true",
            alignment: "right", //left or right
            roundedCorners: "true",
            height: "450px",
            width: "800px",
          }).then(() => {
            // eslint-disable-next-line no-undef
            CometChatWidget.on("openChat", () => {
              window.addEventListener("keydown", e => {
                if (e.key === "Escape") {
                  // eslint-disable-next-line no-undef
                  CometChatWidget.openOrCloseChat(false);
                }
              });
            });
            // eslint-disable-next-line no-undef
            CometChatWidget.on("closeChat", () => {
              window.removeEventListener("keydown", e => {
                if (e.key === "Escape") {
                  // eslint-disable-next-line no-undef
                  CometChatWidget.openOrCloseChat(false);
                }
              });
            });
          });
        });
      },
      openWhatsNewModal() {
        Release.FetchAll(
          {
            page: this.page,
            limit: this.limit,
            order_direction: "desc",
            order_by: "release_date",
          },
          this.filters,
          ["user"],
        ).then(result => {
          if (result.entities.releases?.length) {
            this.$store.dispatch("release/openReleaseWindow");
          }
        });
      },
      closeMenu() {
        if (this.isMenuOpen) {
          this.$refs.navbar.menuToggleMobile();
        }
      },
      openMenu() {
        if (!this.isMenuOpen) {
          this.$refs.navbar.menuToggleMobile();
        }
      },
      stopDragging() {
        if (this.isDragging) {
          this.isDragging = false;
          this.dragDirection = 0;
        }
      },
      trackDragging(e) {
        let xpos = e.changedTouches[0].clientX;
        var clientWidth = document.documentElement.clientWidth;
        if (!this.isDragging) {
          if (
            (this.isInsideThreshold(xpos, 0, 50) && !this.isMenuOpen) ||
            (this.isInsideThreshold(xpos, clientWidth - 175, clientWidth) && this.isMenuOpen)
          ) {
            this.isDragging = true;
            this.mouseX = xpos;
          }
        }
        if (this.isDragging) {
          let delta = xpos - this.mouseX;
          if (delta > 5) {
            this.dragDirection = 1;
          } else if (delta < -5) {
            this.dragDirection = -1;
          } else {
            this.dragDirection = 0;
          }
        }
      },
      isInsideThreshold(cursorX, thresholdStart = 0, thresholdEnd = 40) {
        return cursorX > thresholdStart && cursorX < thresholdEnd;
      },
    },
    mounted() {
      if (this.authenticated && !this.$store.getters["tenant/isCentral"]) {
        Year.FetchAll({ page: 1, limit: 999 });
      }
      this.$store.commit();
      let cometScript = document.createElement("script");
      cometScript.setAttribute("src", "https://widget-js.cometchat.io/v3/cometchatwidget.js");
      document.head.appendChild(cometScript);
      cometScript.addEventListener("load", () => {
        this.$store.state.chat.chatScriptLoaded = true;
        if (this.allowChatBubble) {
          this.delayedLaunch();
        }
      });
      if (this.authenticated && this.type == "guardian") {
        Profile.FetchById(this.user.id).then(() => {
          this.hasProfile = true;
        });
      }
      window.addEventListener("resize", () => {
        this.$store.dispatch("size/setHeight", window.innerHeight);
        this.$store.dispatch("size/setWidth", window.innerWidth);
      });
    },
    beforeDestroy() {
      window.removeEventListener("keydown", e => {
        if (e.key === "Escape") {
          // eslint-disable-next-line no-undef
          CometChatWidget.openOrCloseChat(false);
        }
      });
      window.removeEventListener("resize", () => {
        this.$store.dispatch("size/setHeight", window.innerHeight);
        this.$store.dispatch("size/setWidth", window.innerWidth);
      });
    },
  };
</script>
