import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An Attendance record
 * @property {number} id
 * @property {number} user_id - The id of the user this attendance record belongs to
 * @property {number} user - the related user that this attendance record belongs to
 * @property {object} attendances - attendances for this attendance record
 */
export default class AttendanceRecord extends GenexSISModel {
  static entity = "attendanceRecord";
  static primaryKey = "user_id";

  static fields() {
    return {
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      attendances: this.attr({}),
    };
  }

  /**
   * Fetch Attendance Records
   * @function
   * @param {Object} filters - The attendance record filters
   * @param {string} filters.start_date - the start date of attendances that you want to fetch
   * @param {string} filters.end_date - the end date of attendances that you want to fetch
   * @param {number} filters.users[] - Array of Id's of students that you want to fetch
   * @param {number} filters.phase_id - The id of the phase that students are in
   * @param {number} filters.stage_id - The id of the stage that students are in
   * @param {number} filters.homeclass_id - The id of the homeclass that students are in
   * @param {number} filters.class_id - The id of the class that students are in
   */
  static FetchAll(filters = {}) {
    return this.api().get(`/attendances/records`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...filters,
      },
      dataTransformer: ({ data: { data: data } }) => {
        const attendances = Object.keys(data).map(key => {
          return {
            user_id: key,
            attendances: data[key],
          };
        });

        return [...attendances];
      },
    });
  }
}
