<template>
  <div class="listitem announcement-item">
    <article class="media mb-2">
      <div class="box large-box media-content is-relative has-pointer pb-4">
        <div class="columns is-fullwidth is-vcentered is-mobile">
          <div class="content column">
            <h3 class="is-capitalized is-size-5 mt-0">{{ release.title }}</h3>
            <div class="content" v-html="release.content"></div>
            <div class="is-flex is-justify-content-end is-mobile has-dropdown is-flex-wrap-wrap pt-3">
              <div class="is-flex is-align-items-flex-center mr-4">
                <div class="level-item">
                  <p v-if="showDates" class="subtitle is-7 mb-0">Release on {{ formatDate(release.release_date) }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>

<script>
  import clip from "text-clipper";
  import { format } from "date-fns";

  export default {
    name: "ReleaseListItem",
    props: {
      cropLength: {
        type: Number,
        default() {
          return 50;
        },
      },
      inModal: {
        type: Boolean,
        default() {
          return false;
        },
      },
      showDates: {
        type: Boolean,
        default() {
          return true;
        },
      },
      showStart: {
        type: Boolean,
        default() {
          return true;
        },
      },
      release: {
        type: Object,
        default: () => Object,
      },
    },
    data() {
      return {};
    },
    mounted() {},
    computed: {},
    methods: {
      formatDate(date) {
        if (date === null) {
          return null;
        }
        return format(new Date(date), "EEEE, do 'of' MMMM yyyy");
      },
      clipContent(text) {
        if (text) {
          return clip(text, this.cropLength, {
            html: true,
          });
        }
        return "";
      },
    },
  };
</script>
