import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";

export default class LMSLessonReflections extends GenexLMSModel {
  static entity = "lms-lesson-reflections";

  /**
   *
   * @returns {{data: *, percentage: *, students: *, slug: *}}
   */
  static fields() {
    return {
      slug: this.attr(null),
      data: this.attr(null),
      percentage: this.attr(null),
      students: this.attr(null),
    };
  }

  /**
   *
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @param persistBy
   * @returns {*}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.apiWithHeaders().get(`/dashboards/lesson-reflections`, {
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data.chart_data.datasets);
      },
    });
  }
}
