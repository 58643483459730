import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class CampusUser extends GenexSISModel {
  static entity = "campusUser";

  static primaryKey = ["campus_id", "user_id"];

  static fields() {
    return {
      campus_id: this.attr(null),
      user_id: this.attr(null),
    };
  }
}
