import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";

export default class UserTaskSheet extends GenexSISModel {
  static entity = "task_sheet_users";

  static primaryKey = ["task_sheet_id", "user_id"];

  static fields() {
    return {
      task_sheet_id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      completed_task_count: this.attr(null),
      task_count: this.attr(null),
      progress_percent: this.attr(null),
    };
  }
}
