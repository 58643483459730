<template>
  <div>
    <div class="header-section is-flex">
      <p v-if="title" class="card-header-title is-capitalized">
        {{ title }}
      </p>
      <b-field>
        <b-field class="mr-2" expanded>
          <b-input v-model="searchHold" v-debounce:300ms="getFilteredNotes" placeholder="Search"></b-input>
        </b-field>
        <b-field>
          <b-button @click="clearSearch">Clear</b-button>
        </b-field>
      </b-field>
    </div>

    <div ref="tabs" class="py-6">
      <div class="">
        <NoteForm
          :is-central="isCentral"
          :is_internal="isInternal"
          :model_id="parseInt(model_id)"
          :model_type="model"
          :permissions="{
            can_create: canCreate || canCreateComputed,
          }"
          :placeholder="placeholder"
          :required="required"
          :submit_text="submit_text"
          @noteSubmitted="clearSearch"></NoteForm>
      </div>
    </div>
    <div class="">
      <transition mode="in-out" name="fade">
        <PerfectScrollbarWrapper
          ref="notesScrollWrapper"
          :class="{ 'conversation-mode': conversationMode }"
          class="notes-wrapper"
          infinite-wrapper>
          <div v-for="note in notes" :key="note.id">
            <NoteListItem
              :conversation-mode="conversationMode"
              :is-central="isCentral"
              :model_id="parseInt(model_id)"
              :model_type="model"
              :note="note"
              :permissions="{
                can_delete: hasPermission(['delete notes']) && !disable_delete,
                can_edit: hasPermission(['edit notes']) && !disabled_edit,
              }"
              :showTypes="showTypes"></NoteListItem>
          </div>
          <infinite-loading
            v-if="conversationMode"
            :identifier="infiniteId"
            forceUseInfiniteWrapper
            spinner="waveDots"
            @infinite="infiniteHandler">
            <template #no-more>
              <span></span>
            </template>
          </infinite-loading>
          <b-pagination
            v-if="!conversationMode"
            :current="page"
            :per-page="limit"
            :range-after="2"
            :range-before="2"
            :total="meta.total"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="margin-top"
            @change-page="setPage"></b-pagination>
        </PerfectScrollbarWrapper>
      </transition>
    </div>
  </div>
</template>

<script>
  import NoteForm from "@/components/notes/NoteForm";
  import NoteListItem from "@/components/notes/NoteListItem";
  import Note from "@/models/sis/Note";
  import InfiniteLoading from "vue-infinite-loading";
  import PerfectScrollbarWrapper from "@/components/scrollbar/PerfectScrollbarWrapper";

  export default {
    name: "NotesPanel",

    components: {
      NoteForm,
      NoteListItem,
      InfiniteLoading,
      PerfectScrollbarWrapper,
    },
    data() {
      return {
        page: 1,
        limit: 20,
        create: false,
        loading: false,
        loaded: false,
        chatFetch: null,
        infiniteId: new Date(),
        notesHold: null,
        search: null,
        searchHold: null,
        meta: {
          total: 20,
        },
      };
    },
    props: {
      conversationMode: {
        type: Boolean,
        default() {
          return false;
        },
      },
      isCentral: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canCreate: {
        type: Boolean,
        default() {
          return false;
        },
      },
      disable_delete: {
        type: Boolean,
        default() {
          return false;
        },
      },
      disabled_edit: {
        type: Boolean,
        default() {
          return false;
        },
      },
      isInternal: {
        type: Boolean,
        default() {
          return false;
        },
      },
      required: {
        type: Boolean,
        default() {
          return false;
        },
      },
      title: {
        type: String,
        default() {
          return null;
        },
      },
      placeholder: {
        type: String,
        default() {
          return "An optional description";
        },
      },
      submit_text: {
        type: String,
        default() {
          return "Submit Note";
        },
      },
      showTypes: {
        type: Boolean,
        default() {
          return true;
        },
      },
      model: {
        type: String,
        default() {
          return "user";
        },
      },
      fetchOnMount: {
        type: Boolean,
        default() {
          return true;
        },
      },
      canEdit: {
        type: Boolean,
        default() {
          return false;
        },
      },
      model_id: {
        type: Number,
        required: true,
      },
    },
    methods: {
      setPage(pageNumber, reload = false) {
        this.$store.dispatch("loader/show");
        this.notesHold = this.notes;
        if (reload) {
          Note.deleteAll();
        }
        if (reload) {
          this.infiniteId = new Date();
          this.$refs.notesScrollWrapper.psUpdate();
        }
        this.page = pageNumber;
        return Note.FetchAllByModel(
          { page: pageNumber, limit: this.limit },
          {
            is_internal: this.isInternal ? 1 : 0,
            ...this.filters,
          },
          ["user"],
          {
            id: this.model_id,
            type: this.model,
          },
          this.isCentral,
        ).then(
          ({
            response: {
              data: { meta, data },
            },
          }) => {
            this.meta = meta;
            this.$store.dispatch("loader/hide");
            this.notesHold = null;
            if (reload) {
              this.$refs.notesScrollWrapper.psUpdate();
              this.$refs.notesScrollWrapper.scrollToTop();
            }
            return Promise.resolve(data);
          },
        );
      },
      getFilteredNotes(text) {
        this.search = text;
      },
      infiniteHandler($state) {
        return this.setPage(this.page).then(data => {
          if (data.length > 0) {
            this.page++;
            $state.loaded();
            return;
          }
          $state.complete();
        });
      },
      clearSearch() {
        this.search = null;
        this.searchHold = null;
      },
    },
    watch: {
      filters() {
        this.setPage(this.page, true);
      },
    },
    computed: {
      canCreateComputed() {
        return this.hasPermission(["create notes"]);
      },
      notes_count() {
        return Note.query()
          .where("is_internal", this.isInternal ? 1 : 0)
          .where("model", this.model)
          .where("model_id", this.model_id)
          .count();
      },
      notes() {
        if (this.notesHold === null) {
          if (this.conversationMode) {
            return Note.query()
              .where("is_internal", this.isInternal ? 1 : 0)
              .where("model", this.model)
              .where("model_id", this.model_id)
              .with("user")
              .orderBy("created_at", "desc")
              .get();
          }
          return Note.query()
            .where("is_internal", this.isInternal ? 1 : 0)
            .where("model", this.model)
            .where("model_id", this.model_id)
            .with("user")
            .orderBy("created_at", "desc")
            .offset((this.page - 1) * this.limit)
            .limit(this.limit)
            .get();
        }
        return this.notesHold;
      },
      filters() {
        return {
          ...(this.search
            ? {
                search: this.search,
              }
            : {}),
        };
      },
    },

    mounted() {
      this.loading = true;

      this.setPage(1);
      if (this.conversationMode) {
        this.chatFetch = setInterval(() => {
          Note.FetchAllByModel(
            { page: 1, limit: 30 },
            {
              order_by: "created_at",
              order_direction: "desc",
              is_internal: this.isInternal ? 1 : 0,
            },
            ["user"],
            {
              id: this.model_id,
              type: this.model,
            },
            this.isCentral,
          );
        }, 10000);
      }
    },
    beforeDestroy() {
      clearInterval(this.chatFetch);
    },
  };
</script>
