export const ProfileModule = {
  namespaced: true,

  state: {
    student_types: [
      {
        id: 1,
        name: "Full-Time",
      },
      {
        id: 2,
        name: "Part-Time",
      },
      {
        id: 3,
        name: "Distance-Based",
      },
    ],
    finance_types: [
      {
        id: 1,
        name: "Standard",
      },
      {
        id: 2,
        name: "Scholarship",
      },
      {
        id: 3,
        name: "Bursary",
      },
      {
        id: 4,
        name: "Staff Student",
      },
      {
        id: 5,
        name: "Exchange Student",
      },
      {
        id: 6,
        name: "Unique-Rate",
      },
    ],
  },
};
