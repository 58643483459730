import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A covidAssessment
 * @property {number} id
 * @property {number} user_id - The id of the user this covid-assessment belongs to
 * @property {number} temperature -  The temperature in degrees of the individual
 * @property {string} taken_on -  The date the assessment was taken
 * @property {Object} symptoms - A key-value (symptom: boolean) object of the symptoms
 */
export default class CovidAssessment extends GenexSISModel {
  static entity = "covidAssessments";

  get hasSymptoms() {
    return Object.values(this.symptoms).some(symptom => symptom === true);
  }

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      symptoms: this.attr({}),
      taken_on: this.attr(null),
      temperature: this.attr(null),
      created_at: this.attr(null),
    };
  }

  /**
   * Returns all covid-assessments
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.user_id]
   * @param {string} [query.date]
   * @param {array<number>} [query.user_ids]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/covid-assessments`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(covidAssessment => {
          if (relationships.includes("user")) {
            Object.assign(covidAssessment.attributes.user, covidAssessment.attributes.user.attributes);
          }

          return { ...covidAssessment, ...covidAssessment.attributes };
        });
      },
    });
  }

  /**
   * Returns a covid-assessments by its id
   * @function
   * @param {number} id The id of the covidAssessment
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/covid-assessments/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          Object.assign(data.attributes.user, data.attributes.user.attributes);
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new covid-assessment
   * @function
   * @param {Object} assessment - The covid-assessment object
   * @param {number} assessment.user_id - The id of the user this covid-assessment belongs to
   * @param {number} assessment.temperature -  The temperature in degrees of the individual
   * @param {string} assessment.taken_on -  The date the assessment was taken
   * @param {Object} assessment.symptoms - A key-value (symptom: boolean) object of the symptoms
   * @returns {Promise<Response>} - The newly created covid-assessment
   */

  static Store(assessment) {
    return this.api().post(`/covid-assessments`, assessment, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store new covid-assessments
   * @function
   * @param {Array<Object>} assessments - The covid-assessment object
   * @returns {Promise<Response>} - The newly created covid-assessment
   */

  static StoreBulk(assessments) {
    return this.api().post(
      `/covid-assessments/bulk-insert`,
      { data: assessments },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        save: false,
      },
    );
  }

  /**
   * Update an existing covid-assessment
   * @function
   * @param {Object} assessment - The covid-assessment object
   * @param {number} assessment.id - The id of the covid-assessment
   * @param {number} assessment.user_id - The id of the user this covid-assessment belongs to
   * @param {number} assessment.temperature -  The temperature in degrees of the individual
   * @param {string} assessment.taken_on -  The date the assessment was taken
   * @param {Object} assessment.symptoms - A key-value (symptom: boolean) object of the symptoms
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created covid-assessment
   */

  static Update(assessment, saved) {
    return this.api().patch(`/covid-assessments/${assessment.id}`, assessment, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing covid-assessment
   * @function
   * @param {number} assessment_id - The id of the covid-assessment
   */

  static Delete(assessment_id) {
    return this.api().delete(`/covid-assessments/${assessment_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: assessment_id,
    });
  }
}
