export const MeetingModule = {
  namespaced: true,

  state: {
    statuses: [
      { id: 0, name: "Scheduled" },
      { id: 1, name: "Confirmed" },
      { id: 2, name: "Cancelled" },
      { id: 3, name: "In Progress" },
      { id: 4, name: "Concluded" },
    ],
    meetingLengths: [
      {
        type: "minutes",
        amount: 15,
      },
      {
        type: "minutes",
        amount: 30,
      },
      {
        type: "minutes",
        amount: 45,
      },
      {
        type: "hours",
        amount: 1,
      },
      {
        type: "hours",
        amount: 2,
      },
      {
        type: "hours",
        amount: 3,
      },
      {
        type: "hours",
        amount: 4,
      },
    ],
  },
};
