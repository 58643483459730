import VueCookies from "vue-cookies";
import { format } from "date-fns";

export const TenantModule = {
  namespaced: true,

  state: {
    //change to use central without needing the url
    forceCentral: process.env.VUE_APP_FORCE_CENTRAL === "1",
    storage: VueCookies.get("tenant_storage") || null,
    uuid: VueCookies.get("tenant_uuid") || null,
    id: VueCookies.get("tenant_id") || null,
    school_name: VueCookies.get("tenant_school_name") || "GenEx",
    is_white_labeled: VueCookies.get("tenant_is_white_labeled") || "0",
  },
  getters: {
    isCentral: state => {
      const isCentral = state.forceCentral || window.location.hostname.includes("sis-central");
      if (isCentral) {
        localStorage.setItem("isCentral", "1");
      } else {
        localStorage.removeItem("isCentral");
      }
      return isCentral;
    },
    defaultFavIconUrl: () => {
      return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-04.svg";
    },
    faviconUrl: state => {
      if (state.storage) {
        return (
          `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/images/site-logo-favicon.svg` +
          "?cache-bust=" +
          format(new Date(), "yyyy-MM-dd-HH")
        );
      }
      return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-04.svg";
    },
    collapsedLogoUrl: state => {
      if (state.storage) {
        return (
          `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/images/site-logo-collapsed.svg` +
          "?cache-bust=" +
          format(new Date(), "yyyy-MM-dd-HH")
        );
      }
      return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-04.svg";
    },
    expandedLogoUrl: state => {
      if (state.storage) {
        return (
          `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/images/site-logo-expanded.svg` +
          "?cache-bust=" +
          format(new Date(), "yyyy-MM-dd-HH")
        );
      }
      return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-03.svg";
    },
    applyImage: state => {
      if (state.storage) {
        return (
          `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/images/apply-image.jpg` +
          "?cache-bust=" +
          format(new Date(), "yyyy-MM-dd-HH")
        );
      }
      return "https://generation-sis-assets.s3-eu-west-1.amazonaws.com/images/sis-image-gs.jpg";
    },
    applyLogoUrl: state => {
      if (state.storage) {
        return (
          `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/images/apply-logo.svg` +
          "?cache-bust=" +
          format(new Date(), "yyyy-MM-dd-HH")
        );
      }
      return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/images/genex-logo-02.svg";
    },
    applicationTAndC: state => {
      if (state.storage) {
        return `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/documents/application-terms-and-conditions.pdf`;
      }
      return null;
    },
    altApplicationTAndC: state => {
      if (state.storage) {
        return `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/documents/alternative-qualification-application-terms-and-conditions.pdf`;
      }
      return null;
    },
    privacyPolicy: state => {
      if (state.storage) {
        return `https://${state.storage.bucket}.${state.storage.driver}.${state.storage.region}.amazonaws.com/dynamic-assets/documents/privacy-policy.pdf`;
      }
      return "https://generation-sis-assets.s3.eu-west-1.amazonaws.com/downloads/GenEx+Privacy+Policy+.pdf";
    },
    releasesLogoUrl: () => {
      return "https://genex-web-assets.s3.eu-west-1.amazonaws.com/GenEx_WN_Graphic.svg";
    },
    isWhiteLabeled: state => {
      return +state.is_white_labeled === 1;
    },
  },
};
