import { GenexSISModel } from "@/models/sis/GenexSISModel";
import DashboardAdmissionsWaitingStage from "@/models/sis/DashboardAdmissionsWaitingStage";
import DashboardAdmissionsNewMonth from "@/models/sis/DashboardAdmissionsNewMonth";
import DashboardAdmissionsWithdrawn from "@/models/sis/DashboardAdmissionsWithdrawn";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of dashboard data
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardAdmissions extends GenexSISModel {
  static entity = "dashboard-admissions";

  static fields() {
    return {
      current_enroled: this.attr(null),
      waiting: this.hasManyBy(DashboardAdmissionsWaitingStage, "stage_ids"),
      stage_ids: this.attr([]),
      withdrawn: this.hasManyBy(DashboardAdmissionsWithdrawn, "reason_ids"),
      reason_ids: this.attr([]),
      new: this.hasManyBy(DashboardAdmissionsNewMonth, "new_months"),
      new_months: this.attr([]),
    };
  }

  /**
   * Returns the admissions items for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @param {?number} query.enrolment_type
   * @returns {Promise<Response>}
   */

  static Fetch(query) {
    return this.api().get(`/dashboard/admissions`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        if (!Array.isArray(data.waiting)) {
          data.waiting = Object.keys(data.waiting).map(key => {
            return {
              stage_id: key,
              count: data.waiting[key],
            };
          });
        }
        if (!Array.isArray(data.new)) {
          let months = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ];

          data.new = months.map(month => {
            if (Object.keys(data.new).includes(month)) {
              return {
                month: month,
                counts: data.new[month],
              };
            } else {
              return {
                month: month,
                counts: {},
              };
            }
          });
          if (!Array.isArray(data.withdrawn)) {
            data.withdrawn = Object.keys(data.withdrawn).map(key => {
              return {
                reason_id: +key,
                count: data.withdrawn[key],
              };
            });
          }
        }
        return data;
      },
    });
  }
}
