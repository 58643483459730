import { computed, isReactive, isRef, ref, unref, watch } from "vue";
import { isFunction, isUndefined } from "lodash";

export default function useDependencies(dependencies) {
  const hasDependencies = ref(checkDependencies(dependencies));
  const hasChanged = ref(true);
  const oldDependencyValues = ref({});

  // Function to check if all dependencies exist
  function checkDependencies(dependencies) {
    if (!dependencies) return true;
    return Object.keys(dependencies).every(key => {
      const value = unref(dependencies[key]);
      if (Array.isArray(value)) {
        return value.length > 0;
      }
      if (isUndefined(value)) {
        return false;
      }
      return !!value;
    });
  }

  const checkDependencyChanges = () => {
    const changed = Object.keys(dependencies).some(key => {
      const value = unref(dependencies[key]);
      const oldValue = oldDependencyValues.value[key];
      return value !== oldValue;
    });

    oldDependencyValues.value = Object.keys(dependencies).reduce((acc, key) => {
      acc[key] = unref(dependencies[key]);
      return acc;
    }, {});

    return changed;
  };

  Object.keys(dependencies).forEach(key => {
    if (isRef(dependencies[key]) || isReactive(dependencies[key]) || isFunction(dependencies[key])) {
      watch(dependencies[key], () => {
        hasDependencies.value = checkDependencies(dependencies);
        hasChanged.value = hasChanged.value || checkDependencyChanges();
      });
    }
  });

  oldDependencyValues.value = Object.keys(dependencies).reduce((acc, key) => {
    acc[key] = unref(dependencies[key]);
    return acc;
  }, {});

  const shouldRun = computed(() => {
    return hasDependencies.value && hasChanged.value;
  });

  return {
    hasDependencies,
    hasChanged,
    shouldRun,
    oldDependencyValues,
  };
}
