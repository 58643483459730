<template>
  <aside
    v-show="isAsideVisible"
    :class="{ 'is-expanded': isAsideExpanded }"
    class="aside is-placed-left"
    @mouseleave="$store.dispatch('popout/setDisplay', false)">
    <div ref="container" class="aside-container is-flex is-flex-direction-column" @mouseenter="psUpdate">
      <router-link :class="{ 'menu-image-collapsed': collapsedMenuImage }" :to="{ name: 'home' }" class="menu-image">
        <div class="image">
          <img
            :src="collapsedMenuImage ? collapsedLogoUrl : expandedLogoUrl"
            alt="GENEX: Generational Education Exchange" />
        </div>
      </router-link>
      <div
        :class="{ 'has-power-container': showMenuExpand }"
        class="menu is-menu-main fast"
        @animationend="
          hasFadeIn = false;
          hasFadeOut = false;
        ">
        <aside-menu-list :has-fade-in="hasFadeIn" :has-fade-out="hasFadeOut" :menu="menu" @menu-click="menuClick" />
      </div>

      <div v-if="showMenuExpand" class="menu fast has-power-container">
        <ul class="is-relative menu-list" @click.prevent="menuToggle">
          <li>
            <a class="level pl-5 aside-menu-item is-mobile aside-top-level-item is-relative has-icon" title="Home">
              <div class="level-left no-overflow">
                <div class="level-item no-overflow">
                  <span :class="{ 'is-expanded': isAsideExpanded }" class="icon has-text-grey power-icon-container">
                    <b-icon v-if="!computeExpanded" icon="arrow-right-2" />
                    <b-icon v-if="computeExpanded" icon="arrow-left-2" />
                  </span>
                  <span class="animated fadeIn menu-item-label is-hidden-touch" style="font-size: 0.7rem">
                    Powered by <span class="has-text-dark">GENEX</span>
                  </span>
                </div>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </aside>
</template>

<script>
  import { mapGetters, mapState } from "vuex";
  import PerfectScrollbar from "perfect-scrollbar";
  import AsideMenuList from "@/components/layout/AsideMenuList";

  export default {
    name: "AsideMenu",
    components: {
      AsideMenuList,
    },
    props: {
      menu: {
        type: Array,
        default: () => [],
      },
      showMenuExpand: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        hasFadeOut: false,
        hasFadeIn: false,
      };
    },
    computed: {
      ...mapState("menu", ["isAsideVisible", "isAsideExpanded", "isAsideMobileExpanded"]),
      ...mapGetters("tenant", ["collapsedLogoUrl", "expandedLogoUrl"]),
      collapsedMenuImage() {
        if (this.$store.getters["size/viewType"] === "mobile") {
          return false;
        }
        if (this.$store.getters["size/viewType"] === "tablet") {
          return !this.isAsideMobileExpanded;
        }
        return !this.isAsideExpanded;
      },

      computeExpanded() {
        if (this.$store.getters["size/viewType"] === "mobile" || this.$store.getters["size/viewType"] === "tablet") {
          return this.isAsideMobileExpanded;
        } else {
          return this.isAsideExpanded;
        }
      },
      menuToggleIcon() {
        return this.isAsideExpanded ? "backburger" : "forwardburger";
      },
    },
    created() {
      if (this.isAsideMobileExpanded) {
        window.addEventListener("click", this.forceClose);
      }
    },
    mounted() {
      this.ps = new PerfectScrollbar(this.$refs.container);
    },
    beforeDestroy() {
      window.removeEventListener("click", this.forceClose);
    },
    methods: {
      forceClose(e) {
        if (
          this.computeExpanded &&
          (this.$store.getters["size/viewType"] === "mobile" || this.$store.getters["size/viewType"] === "tablet")
        ) {
          if (!this.$el.contains(e.target)) {
            this.menuToggle();
          }
        }
      },
      mobileToggle() {
        this.$store.dispatch("menu/asideMobileStateToggle").then(() => {
          this.psUpdate();
        });
      },
      menuToggle() {
        if (this.$store.getters["size/viewType"] === "mobile" || this.$store.getters["size/viewType"] === "tablet") {
          this.mobileToggle();
        } else {
          this.$store.dispatch("menu/asideStateToggle").then(() => {
            this.psUpdate();
          });
        }
      },
      menuClick(item) {
        if (this.ps && item && item.menu) {
          this.ps.update();
        }
      },
      psUpdate() {
        if (this.ps) {
          this.ps.update();
        }
      },
    },
    watch: {
      isAsideExpanded(newVal) {
        if (newVal) {
          this.hasFadeIn = true;
        } else {
          this.hasFadeOut = true;
        }
      },
      isAsideMobileExpanded(newVal) {
        if (newVal === true) {
          setTimeout(() => {
            window.addEventListener("click", this.forceClose);
          }, 50);
        } else {
          window.removeEventListener("click", this.forceClose);
        }
      },
    },
  };
</script>
