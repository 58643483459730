function handlebreadCrumbs(parentRoutes) {
  try {
    for (const route of parentRoutes) {
      const breadCrumbAncestry = [{ path: route.path, breadcrumb_name: route.meta?.breadcrumb_name, order: 0 }];
      if (route.meta && route.meta.breadcrumb_name) route.meta.breadcrumbs_ancestry_elaborated = breadCrumbAncestry;
      if (route.children) parseChildRoutes(route);
    }
  } catch (error) {
    return;
  }
}

function parseChildRoutes(route) {
  let breadCrumbAncestry = [];

  for (const childRoute of route.children) {
    if (childRoute.meta?.breadcrumbs_ancestry_elaborated) return;
    const recursedAncestry = createBreadCrumbAncestryForChild(childRoute, route.children, route.path);

    if (route.meta?.breadcrumb_name) {
      const baseBreadCrumb = {
        path: route.path,
        breadcrumb_name: route.meta?.breadcrumb_name,
        order: 0,
      };
      breadCrumbAncestry = [baseBreadCrumb, ...recursedAncestry];
    } else {
      breadCrumbAncestry = recursedAncestry;
    }
    if (typeof childRoute.meta !== "undefined") {
      childRoute.meta.breadcrumbs_ancestry_elaborated = breadCrumbAncestry;
    } else {
      childRoute.meta = {
        breadcrumbs_ancestry_elaborated: breadCrumbAncestry,
      };
    }
  }
}

function createBreadCrumbAncestryForChild(childRoute, children, parentPath) {
  let childrenCount = children.length;

  let ancestry = [{ path: childRoute.path, breadcrumb_name: childRoute.meta?.breadcrumb_name, order: childrenCount }];

  // Keep adding breadcrumbs until we have reached the parentPath
  while (childRoute.meta?.breadcrumbs_parent !== parentPath) {
    const parentRoute = findParentRoute(childRoute, children);
    if (!parentRoute) break;
    childrenCount = childrenCount - 1;
    const breadCrumb = {
      path: parentRoute.path,
      breadcrumb_name: parentRoute.meta?.breadcrumb_name,
      order: childrenCount,
    };
    ancestry.push(breadCrumb);
    childRoute = parentRoute;
  }

  return ancestry.sort((a, b) => a.order - b.order);
}

function findParentRoute(route, parentRoutes) {
  const parentRoute = parentRoutes.find(parentRoute => parentRoute.path === route.meta?.breadcrumbs_parent);
  return parentRoute;
}

export { handlebreadCrumbs };
