import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Role from "@/models/sis/Role";
import RoleUser from "@/models/sis/RoleUser";
import Extramural from "@/models/sis/Extramural";
import Campus from "@/models/sis/Campus";
import Enrolment from "@/models/sis/Enrolment";
import Profile from "@/models/sis/Profile";
import Attendance from "@/models/sis/Attendance";
import Service from "@/models/sis/Service";
import SportsHouse from "@/models/sis/SportsHouse";
import EnrolmentStatus from "./EnrolmentStatus";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import ClassGroup from "@/models/sis/ClassGroup";

/**
 * A user
 * @property {number} id
 * @property {string} uuid
 * @property {string} first_name
 * @property {string} last_name
 * @property {string} title
 * @property {string} email
 * @property {string} password - Creation only
 * @property {Array.<Object>} roles
 * @property {Array.<Object>} campuses
 * @property {Array.<Object>} extramurals
 * @property {Array.<Object>} guardians
 * @property {Array.<Object>} wards
 */
export default class User extends GenexSISModel {
  static entity = "users";

  static fields() {
    return {
      id: this.attr(null),
      uuid: this.attr(null),
      first_name: this.attr(null),
      profile: this.hasOne(Profile, "user_id"),
      avatar: this.attr("https://via.placeholder.com/300x300.png?text=Placeholder"),
      last_name: this.attr(null),
      full_name: this.attr(null),
      title: this.attr(null),
      email: this.attr(null),
      attendances: this.hasMany(Attendance, "user_id"),
      campus_ids: this.attr(() => []),
      primary_campus_id: this.attr(null),
      primary_campus: this.belongsTo(Campus, "primary_campus_id"),
      campuses: this.hasManyBy(Campus, "campus_ids"),
      extramurals: this.hasMany(Extramural, "user_id"),
      lms_user_id: this.attr(null),
      roles: this.belongsToMany(Role, RoleUser, "user_id", "role_id"),
      guardian_ids: this.attr(() => []),
      guardians: this.hasManyBy(User, "guardian_ids"),
      ward_ids: this.attr(() => []),
      wards: this.hasManyBy(User, "ward_ids"),
      enrolments: this.hasMany(Enrolment, "user_id"),
      primary_contact_number: this.attr(""),
      secondary_contact_number: this.attr(""),
      cellphone_number: this.attr(""),
      service_ids: this.attr(() => []),
      services: this.hasManyBy(Service, "service_ids"),
      enrolment_status_id: this.attr(null),
      enrolment_status: this.belongsTo(EnrolmentStatus, "enrolment_status_id"),
      sports_house_ids: this.attr(() => []),
      sports_house: this.hasManyBy(SportsHouse, "sports_house_ids"),
      accepted_terms_at: this.attr(null),
      involvement_id: this.attr(null),
      report_is_blacklist: this.attr(null),
      deleted_at: this.attr(null),
      is_default_primary: this.attr(0),
      active: this.attr(1),
      has_unpaid_fees: this.attr(0),
      class_group_ids: this.attr(() => []),
      class_groups: this.hasManyBy(ClassGroup, "class_group_ids"),
      student_number: this.attr(null),
      organisations: this.attr(() => []),
    };
  }

  static mutators() {
    return {
      avatar(value) {
        if (value == null) {
          return "https://via.placeholder.com/300x300.png?text=Placeholder";
        }
        return value;
      },
      primary_contact_number(value) {
        if (value == null) {
          return "";
        }
        return value;
      },
      secondary_contact_number(value) {
        if (value == null) {
          return "";
        }
        return value;
      },
      cellphone_number(value) {
        if (value == null) {
          return "";
        }
        return value;
      },
    };
  }

  /**
   * Returns all users
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.phase_id]
   * @param {string} [query.user_role]
   * @param {string} [query.without_role]
   * @param {Array<string>} [query.without_roles]
   * @param {number} [query.user_permission]
   * @param {string} [query.search]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {Boolean} save
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate", save = true) {
    return this.GenexAxiosGet("User.FetchAll", `/users`, {
      save: save,
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static SearchAll({ page = 1, limit = 15 }, query = {}, relationships = [], save = true) {
    return this.GenexAxiosGet("User.FetchAll", `/users/search`, {
      save: save,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(user => {
          if (relationships.includes("guardians")) {
            user.attributes.guardians.map(guardian => {
              Object.assign(guardian, guardian.attributes);
            });
          }
          if (relationships.includes("wards")) {
            user.attributes.wards.map(ward => {
              Object.assign(ward, ward.attributes);
            });
          }
          if (relationships.includes("profile")) {
            if (user.attributes.profile) {
              Object.assign(user.attributes.profile, user.attributes.profile.attributes);
            } else {
              user.attributes.profile = null;
            }
          }
          if (relationships.includes("sports_house")) {
            if (user.attributes.sports_house.length > 0) {
              user.attributes.sports_house = user.attributes.sports_house[0];
              Object.assign(user.attributes.sports_house, user.attributes.sports_house.attributes);
            } else {
              user.attributes.sports_house = null;
            }
          }
          return { ...user, ...user.attributes };
        });
      },
    });
  }

  /**
   * Returns all users
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.attendance_type]
   * @param {string} [query.date]
   * @param {number} [query.related_entity_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAttendanceUsers({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet(
      "User.FetchAttendanceUsers",
      `/attendances/${query.attendance_type}/${query.related_entity_id}/users`,
      {
        params: {
          ...{
            page: page,
            limit: limit,
            with: relationships,
            ...(query !== {} ? query : {}),
          },
        },
        dataTransformer: ({ data: { data } }) => {
          return data.map(user => {
            if (relationships.includes("attendances")) {
              user.attributes.attendances.map(attendances => {
                Object.assign(attendances, attendances.attributes);
              });
            }
            if (Object.prototype.hasOwnProperty.call(user.attributes, "enrolments")) {
              user.attributes.enrolments.map(enrolment => {
                Object.assign(enrolment, enrolment.attributes);
              });
            }
            return { ...user, ...user.attributes };
          });
        },
      },
    );
  }

  /**
   * Returns all for a report_subject
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.class_group_id]
   * @param {number} [report_id]
   * @param {number} [report_subject_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchReportSubjectUsers(
    { page = 1, limit = 15 },
    query = {},
    relationships = [],
    report_id,
    report_subject_id,
  ) {
    return this.GenexAxiosGet(
      "User.FetchReportSubjectUsers",
      `/reports/${report_id}/subjects/${report_subject_id}/users`,
      {
        params: {
          ...{
            page: page,
            limit: limit,
            with: relationships,
            ...(query !== {} ? query : {}),
          },
        },
        dataTransformer: ({ data: { data } }) => {
          return data.map(user => {
            return { ...user, ...user.attributes };
          });
        },
      },
    );
  }

  /**
   * Returns all for a report_extramural
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {number} [report_id]
   * @param {number} [report_extramural_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchReportExtramuralUsers({ page = 1, limit = 15 }, relationships = [], report_id, report_extramural_id) {
    return this.GenexAxiosGet(
      "User.FetchReportExtramuralUsers",
      `/reports/${report_id}/extramurals/${report_extramural_id}/users`,
      {
        params: {
          ...{
            page: page,
            limit: limit,
            with: relationships,
          },
        },
        dataTransformer: ({ data: { data } }) => {
          return data.map(user => {
            return { ...user, ...user.attributes };
          });
        },
      },
    );
  }

  /**
   * Returns a user by its id
   * @function
   * @param {number} id The id of the user
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.GenexAxiosGet("Users.Get", `/users/${id}`, {
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Store a new user
   * @function
   * @param {Object} user - The user object
   * @param {string} user.name - The name of the user
   * @param {number} user.campus_id - The id of the campus the user is associated with
   * @param {number} user.phase_id - The id of the phase the user is associated with
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(user) {
    return this.GenexAxiosPost("User.Store", `/users`, user, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Accept terms
   * @function
   * @param {number} user_id - The id of the user
   * @returns {Promise<Response>} - The newly created user
   */

  static Accept(user_id) {
    return this.GenexAxiosPost(
      "User.Accept",
      `/users/${user_id}/accept-terms`,
      {},
      {
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Check Auth
   * @function
   * @returns {Promise<Response>} - The newly created user
   */

  static Check() {
    return this.GenexAxiosGet("User.Check", `/user?with[]=permissions&with[]=roles`, {
      save: false,
    });
  }

  /**
   * Check Guardian Home Access
   * @function
   * @returns {Promise<Response>} - The newly created user
   */

  static CheckGuardianHomeAccess() {
    return this.GenexAxiosGet("User.CheckGuardianHomeAccess", `/user/has-guardian-home-access`, {
      save: false,
    });
  }

  /**
   * Update an existing user
   * @function
   * @param {Object} user - The user object
   * @param {string} user.name - The name of the user
   * @param {number} user.campus_id - The id of the campus the user is associated with
   * @param {number} user.phase_id - The id of the phase the user is associated with
   * @param {string} user.id - The id of the user
   * @param {number} user.primary_campus_id - The id of the primary campus the user is associated with
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created user
   */

  static Update(user, saved) {
    return this.GenexAxiosPatch("User.Update", `/users/${user.id}`, user, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing user
   * @function
   * @param {number} user_id - The id of the user
   * @returns {Promise<Response>} - The newly created user
   */

  static Delete(user_id) {
    return this.GenexAxiosDelete("User.Delete", `/users/${user_id}`, {
      delete: user_id,
    });
  }

  /**
   * Change a user's password
   * @function
   * @param {number} user_id
   * @param {string} password
   * @param {string} password_confirmation
   * */
  static changePassword(user_id, password, password_confirmation) {
    return this.GenexAxiosPut(
      ".changePassword",
      `/users/${user_id}/password`,
      {
        password: password,
        password_confirmation: password_confirmation,
      },
      {
        save: false,
      },
    );
  }

  /**
   * Attach profile picture
   * @function
   * @param {number} user_id - The id of the user
   * @param {File} image
   * @returns {Promise<Response>} - The newly created user
   */

  static Attach(user_id, image) {
    return this.GenexAxiosPost("User.Attach", `/users/${user_id}/attach-avatar`, image);
  }

  /**
   * Get learners associated with a lessonPlan
   * @function
   * @param {number} lesson_plan_id - The id of the lessonPlan
   */
  static FetchLearners(lesson_plan_id) {
    return this.GenexAxiosGet("User.FetchLearners", `/lesson-plans/${lesson_plan_id}/users`, {
      dataTransformer: ({ data: { data } }) => {
        if (data) {
          return data.map(user => {
            return { ...user, ...user.attributes };
          });
        }
        return [];
      },
    });
  }

  /**
   * Get learners associated with a lessonPlan
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.class_group_id]
   * @param {number} [lesson_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * */
  static FetchLearnersForLesson({ page = 1, limit = 15 }, relationships = [], lesson_id, query) {
    return this.GenexAxiosGet("User.FetchLearnersForLesson", `/lessons/${lesson_id}/users`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
          ...(query !== {} ? query : {}),
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(user => {
          return { ...user, ...user.attributes };
        });
      },
    });
  }

  /**
   * Get learners associated with a report
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.home_class_id] - id of the homeclass
   * @param {number} [query.class_group_id] - id of the classgroup
   * @param {number} report_id - The id of the report
   */
  static FetchByReportId({ page = 1, limit = 15 }, query = {}, report_id = null) {
    return this.GenexAxiosGet("User.FetchByReportId", `/reports/${report_id}/users`, {
      params: {
        ...{
          page: page,
          limit: limit,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(user => {
          return { ...user, ...user.attributes };
        });
      },
    });
  }

  /**
   * Get Users associated with a course scope to filter lesson plans
   * @function
   * @param {number} course_scope_id - The id of the lessonPlan
   */
  static FetchLessonPlanUsers(course_scope_id) {
    return this.GenexAxiosGet("User.FetchLessonPlanUsers", `/course-scopes/${course_scope_id}/lesson-plan-users`, {
      dataTransformer: ({ data: { data } }) => {
        if (data) {
          return data.map(user => {
            return { ...user, ...user.attributes };
          });
        }
        return [];
      },
    });
  }

  /**
   *
   * @param page
   * @param limit
   * @param relationships
   * @param tenantId
   * @param filters
   * @param order_by
   * @returns {Promise<Response>}
   * @constructor
   */
  static FetchTenantUsers({ page = 1, limit = 15 }, relationships = [], tenantId, filters, order_by) {
    return this.GenexAxiosGet("User.FetchTenantUsers", `/tenants/${tenantId}/users`, {
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
          order_by: order_by,
        },
        ...filters,
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(user => {
          return { ...user, ...user.attributes };
        });
      },
    });
  }

  /**
   *
   * @param tenantId
   * @param userId
   * @returns {Promise<Response>}
   * @constructor
   */
  static LoginAsUser(tenantId, userId) {
    return this.GenexAxiosPost("User.LoginAsUser", `/tenants/${tenantId}/users/${userId}/sis-sign-in`, null, {
      save: false,
      baseURL: process.env.VUE_APP_CENTRAL_ONLY_API_URL,
    });
  }

  /**
   *
   * @param userId
   * @returns {*}
   * @constructor
   */
  static LoginIntoLMS(userId) {
    return this.GenexAxiosPost("LMS.LogIntoLMS", `/users/${userId}/lms-passwordless-auth`, null, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      save: false,
    });
  }

  /**
   *
   * @param userId
   * @param overrideToken
   * @returns {*}
   * @constructor
   */
  static LoginIntoLMSWithTenantEndpoint(userId, overrideToken) {
    return this.api().post(`/users/${userId}/lms-passwordless-auth`, null, {
      baseURL: process.env.VUE_APP_TENANT_API_URL,
      headers: {
        ...GenexHelperMixins.methods.DefaultSISHeaders(),
        Authorization: "Bearer " + overrideToken,
      },
      save: false,
    });
  }

  /**
   *
   * @param tenantId
   * @param userId
   * @returns {*}
   * @constructor
   */
  static async CentralLoginIntoLMS(tenantId, userId) {
    try {
      return this.LoginAsUser(tenantId, userId).then(async response => {
        let {
          response: { data },
        } = response;
        if (!data) return new Error("Login As User Failed");
        return await this.LoginIntoLMSWithTenantEndpoint(userId, data.access_token);
      });
    } catch (e) {
      return e;
    }
  }

  /**
   * Get Current User
   */
  static FetchCurrentUser(relationships) {
    return this.GenexAxiosGet("User.FetchCurrentUser", `/user`, {
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
