import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
// import ClassGroup from "@/models/ClassGroup";
import LMSForumPost from "@/models/lms/LMSForumPost";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import LMSForum from "@/models/lms/LMSForum";

export default class LMSForumTopic extends GenexLMSModel {
  static entity = "lms-forum-topics";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      name: this.attr(""),
      parent_id: this.attr(null),
      forum_id: this.attr(null),
      forum: this.belongsTo(LMSForum, "forum_id"),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      posts: this.hasMany(LMSForumPost, "forum_topic_id"),
      post_ids: this.attr([]),
    };
  }

  static FetchById(id, relationships = [], with_count) {
    return this.api().get(`/forum-topics/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        with: relationships,
        with_count: with_count,
      },

      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static Store(entity) {
    return this.api().post(`/forum-topics`, entity, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static Delete(id) {
    return this.api().delete(`/forum-topics/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
    });
  }
}
