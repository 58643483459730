import Router from "@/router/index";
import Store from "@/store/index";
import User from "../../models/sis/User";
import GenexUtils from "@/utils/GenexUtils";
import LMSUser from "@/models/lms/LMSUser";

let AUTH_ROUTES = [
  "login",
  "forgot-password",
  "new-password",
  "register",
  "apply",
  "learner-reflection",
  "LoginTenant",
];

export default Router.beforeEach((to, from, next) => {
  if (Store.state.auth.authenticated) {
    if (Store.state.auth.accessToken) {
      User.Check().catch(() => {
        GenexUtils.logoutAndPurgeUser();
      });
    }
    next();
  } else {
    if (AUTH_ROUTES.includes(to.name)) {
      next();
    } else {
      if (Store.state.auth.accessToken) {
        User.Check()
          .then(({ response }) => {
            GenexUtils.saveAuthDetails(response);

            Store.dispatch("auth/updateAuthenticated", true).then(() => {
              return next();

              // Router.replace(sessionStorage.getItem("redirectPath") || "/");
            });
          })
          .catch(() => {
            GenexUtils.logoutAndPurgeUser();
          });

        LMSUser.FetchMe().then(() => {
          const lmsUserDetails = LMSUser.query().first();
          Store.state.auth.lmsUserDetails = lmsUserDetails;
          GenexUtils.setCookie("lmsUserDetails", lmsUserDetails, 1);
        });
      } else {
        sessionStorage.setItem("redirectPath", to.fullPath);
        next({
          path: "/login",
        });
        // next(new RouterError('Unauthenticated', 401))
      }
    }
  }
});
