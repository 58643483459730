import { GenexSISModel } from "@/models/sis/GenexSISModel";
import CalendarEvent from "@/models/sis/CalendarEvent";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * Audiences
 * @property {string} audience -
 * @property {number} event_id -
 * @property {number} audience_id -
 */

export default class CalendarEventAudience extends GenexSISModel {
  static entity = "calendar-event-audience";

  static fields() {
    return {
      event_id: this.attr(null),
      audience_id: this.attr(null),
      event: this.belongsTo(CalendarEvent, "event_id"),
    };
  }

  // ToDo - Add the endpoints once available
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], save = true) {
    return this.api().get(``, {
      save: save,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }
}
