import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class RoleUser extends GenexSISModel {
  static entity = "roleUser";

  static primaryKey = ["role_id", "user_id"];

  static fields() {
    return {
      role_id: this.attr(null),
      user_id: this.attr(null),
    };
  }
}
