import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Phase from "@/models/sis/Phase";
import Stage from "@/models/sis/Stage";
import Subject from "@/models/sis/Subject";
import User from "@/models/sis/User";
import Media from "@/models/sis/Media";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class Campus extends GenexSISModel {
  static entity = "campuses";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      application_emails: this.attr(null),
      head_user: this.belongsTo(User, "head_user_id"),
      head_user_id: this.attr(null),
      ordinality: this.attr(0),
      has_helpdesk: this.attr(0),
      helpdesk_uuid: this.attr(null),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      is_open_for_enrolment: this.attr(0),
      phases: this.hasMany(Phase, "campus_id"),
      stages: this.hasMany(Stage, "campus_id"),
      subjects: this.hasMany(Subject, "campus_id"),
      max_capacity: this.attr(null),
      physical_address: this.attr(() => ({
        line_1: "",
        line_2: "",
        city: "",
        province: "",
        postal_code: "",
        country: "",
      })),
    };
  }

  static mutators() {
    return {
      physical_address(value) {
        if (value == null) {
          return {
            line_1: "",
            line_2: "",
            city: "",
            province: "",
            postal_code: "",
            country: "",
          };
        }
        return value;
      },
      ordinality(value) {
        if (value == null) {
          return 0;
        }
        return value;
      },
    };
  }

  /**
   * Returns all campuses
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {string} [persistBy=insertOrUpdate] - How to persist
   * @returns {Promise<Response>} All the campuses
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.api().get(`/campuses`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: persistBy,
      persistOptions: {
        update: [Object.prototype.hasOwnProperty.call(query, "is_master") ? "campuses" : ""],
      },
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(campus => {
          if (relationships.includes("media")) {
            if (relationships.includes("media")) {
              if (Object.prototype.hasOwnProperty.call(campus.attributes, "media")) {
                campus.attributes.media.map(item => {
                  Object.assign(item, item.attributes);
                  item.mediable_type = "campuses";
                  item.mediable_id = campus.id;
                });
              }
            }
          }
          if (relationships.includes("phases")) {
            let {
              attributes: { phases },
            } = campus;
            phases.map(phase => {
              if (relationships.includes("phases.stages")) {
                let {
                  attributes: { stages },
                } = phase;
                stages.map(stage => {
                  Object.assign(stage, stage.attributes);
                });
              }
              Object.assign(phase, phase.attributes);
            });
          }
          return { ...campus, ...campus.attributes };
        });
      },
    });
  }

  /**
   * Returns a campus by its id
   * @function
   * @param {number} id The id of the campus
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} The campus
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/campuses/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("media")) {
          if (relationships.includes("media")) {
            if (Object.prototype.hasOwnProperty.call(data.attributes, "media")) {
              data.attributes.media.map(item => {
                Object.assign(item, item.attributes);
                item.mediable_type = "campuses";
                item.mediable_id = data.id;
              });
            }
          }
        }
        if (relationships.includes("phases")) {
          let {
            attributes: { phases },
          } = data;
          phases.map(phase => {
            if (relationships.includes("phases.stages")) {
              let {
                attributes: { stages },
              } = phase;
              stages.map(stage => {
                Object.assign(stage, stage.attributes);
              });
            }
            Object.assign(phase, phase.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new campus
   * @function
   * @param {Object} campus - The campus object
   * @param {string} campus.name - The name of the campus
   * @param {number} campus.ordinality - The order of the campus
   * @param {Object} [campus.physical_address] The address of the campus
   * @param {string} [campus.physical_address.line_1] The street address
   * @param {string} [campus.physical_address.line_2] The apartment number
   * @param {string} [campus.physical_address.city] The town/city
   * @param {string} [campus.physical_address.province] The province/state
   * @param {string} [campus.physical_address.province] The province/state
   * @param {string} [campus.physical_address.postal_code] The post code
   * @param {number} [campus.physical_address.country] The country
   * @returns {Promise<Response>} - The newly created campus
   */

  static Store(campus) {
    return this.api().post(`/campuses`, campus, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a campus
   * @function
   * @param {Object} campus - The campus object
   * @param {string} campus.name - The name of the campus
   * @param {number} campus.id - The id of the campus
   * @param {number} campus.ordinality - The order of the campus
   * @param {Object} [campus.physical_address] The address of the campus
   * @param {string} [campus.physical_address.line_1] The street address
   * @param {string} [campus.physical_address.line_2] The apartment number
   * @param {string} [campus.physical_address.city] The town/city
   * @param {string} [campus.physical_address.province] The province/state
   * @param {string} [campus.physical_address.province] The province/state
   * @param {string} [campus.physical_address.postal_code] The post code
   * @param {number} [campus.physical_address.country] The country
   * @returns {Promise<Response>} - The updated campus
   */

  static Update(campus) {
    return this.api().patch(`/campuses/${campus.id}`, campus, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a campus
   * @function
   * @param {number} id - The id of the campus
   */

  static Delete(id) {
    return this.api().delete(`/campuses/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
