export default {
  methods: {
    /*
     * Used to check if the user has the according permissions
     * Can toggle verbosity for clearer results
     */
    hasPermission(permissions, verbose = false) {
      const cleanPermissions = this.formatToArray(permissions);
      const response = this.sortPermissions([...cleanPermissions]);
      const result = response.missingPermissions.length === 0;

      return verbose ? { result, ...response } : result;
    },

    /*
     * Used to check if we have at least one of the permissions present
     * Can toggle verbosity for clearer results
     */
    hasAnyPermissions(permissions, verbose = false) {
      const cleanPermissions = this.formatToArray(permissions);
      const response = this.sortPermissions([...cleanPermissions]);

      // If the hasPermission list has anything, we are cleared to go ahead
      const result = response.hasPermissions.length > 0;
      return verbose ? { result, ...response } : result;
    },

    /*
     * Used to check if we have exactly none of the permissions present
     * Can toggle verbosity for clearer results
     */
    hasNoPermissions(permissions, verbose = false) {
      const cleanPermissions = this.formatToArray(permissions);
      const response = this.sortPermissions([...cleanPermissions]);

      // If the hasPermission list has nothing, we are cleared to go ahead
      const result = response.hasPermissions.length === 0;
      return verbose ? { result, ...response } : result;
    },

    /*
     * Accepts multiple parameters to check if permissions are present
     * and returns the permissions that aren't present
     */
    sortPermissions(permissions) {
      // Categorise permissions as present and not present
      const missingPermissions = [];
      const hasPermissions = [];

      permissions.forEach(permission => {
        if (this.$store.getters["entities/user-permissions/find"](permission)) {
          hasPermissions.push(permission);
        } else {
          missingPermissions.push(permission);
        }
      });

      return {
        hasPermissions,
        missingPermissions,
      };
    },

    /*
     * Sanity check to make sure the data passed, is an array
     */
    formatToArray(permissions) {
      return Array.isArray(permissions) ? permissions : [permissions];
    },
  },
};
