var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAccess)?_c('li',{ref:"theElement",class:{
    'is-active': _vm.isDropdownActive,
    'menu-list-container': _vm.hasDropdown,
  },on:{"mouseenter":_vm.broadcastPosition}},[(_vm.componentIs === 'router-link')?_c('router-link',{staticClass:"aside-top-level-item is-relative",class:{ 'has-icon': !!_vm.item.icon, open: _vm.is_open },attrs:{"title":_vm.item.label,"to":_vm.itemTo,"exact-active-class":"is-active"},on:{"click":_vm.menuClick},scopedSlots:_vm._u([{key:"default",fn:function({ isExactActive }){return [_c('a',{staticClass:"level pl-5 aside-menu-item is-mobile",class:{ 'is-active': isExactActive || _vm.force_active },on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.linkClick(_vm.itemTo)}}},[_c('div',{staticClass:"level-left no-overflow"},[_c('div',{staticClass:"level-item no-overflow"},[(_vm.item.icon)?_c('b-icon',{class:{ 'has-update-mark': _vm.item.updateMark, 'is-tiny': _vm.isSub },attrs:{"icon":_vm.item.icon,"pack":isExactActive || _vm.force_active ? 'filled' : 'outline',"type":isExactActive || _vm.force_active ? 'is-primary' : 'is-grey',"custom-size":"default"}}):_vm._e(),(_vm.item.label)?_c('span',{staticClass:"animated fadeIn",class:{
              'menu-item-label': !!_vm.item.icon,
              'pl-6': !_vm.item.icon,
              'is-hidden-touch': !_vm.$store.state.menu.isAsideMobileExpanded,
              'has-text-primary': isExactActive || _vm.force_active,
            }},[_vm._v(_vm._s(_vm.item.label))]):_vm._e()],1)])])]}}],null,false,1207292679)}):_vm._e(),(_vm.componentIs === 'a')?_c('a',{staticClass:"is-relative",class:{ 'has-icon': !!_vm.item.icon, open: _vm.is_open },attrs:{"href":_vm.itemHref,"title":_vm.item.label,"exact-active-class":"is-active"},on:{"click":_vm.menuClick}},[_c('div',{staticClass:"level is-mobile pl-5 aside-menu-item",class:{ 'is-active': _vm.force_active }},[_c('div',{staticClass:"level-left no-overflow"},[_c('div',{staticClass:"level-item no-overflow"},[(_vm.item.icon)?_c('b-icon',{class:{ 'has-update-mark': _vm.item.updateMark },attrs:{"icon":_vm.item.icon,"pack":_vm.force_active ? 'filled' : 'outline',"type":_vm.force_active ? 'is-primary' : 'is-grey',"custom-size":"default"}}):_vm._e(),(_vm.item.label)?_c('span',{staticClass:"animated fadeIn",class:{
              'menu-item-label': !!_vm.item.icon,
              'is-hidden-touch': !_vm.$store.state.menu.isAsideMobileExpanded,
              'has-text-primary': _vm.force_active,
            }},[_vm._v(_vm._s(_vm.item.label))]):_vm._e()],1)]),_c('div',{staticClass:"level-right animated fadeIn",class:{
          'is-hidden-desktop': !_vm.$store.state.menu.isAsideExpanded,
          'is-hidden-touch': !_vm.$store.state.menu.isAsideMobileExpandeds,
        }},[_c('div',{staticClass:"level-item"},[_c('b-icon',{staticClass:"is-tiny",attrs:{"icon":_vm.hasDropdown && _vm.isDropdownActive ? 'arrow-up' : 'arrow-down',"type":"is-grey"}})],1)])])]):_vm._e(),(_vm.hasDropdown)?_c('aside-menu-list',{attrs:{"isSubmenuList":true,"menu":_vm.item.menu}}):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }