export const StaffProfileModule = {
  namespaced: true,

  state: {
    employment_types: [
      {
        id: 1,
        name: "Full-Time",
        contract_type: "Permanent",
      },
      {
        id: 2,
        name: "Part-Time",
        contract_type: "Fixed-Term",
      },
    ],
    employment_contract_types: [
      {
        id: 1,
        name: "Permanent",
      },
      {
        id: 2,
        name: "Fixed-Term",
      },
    ],
  },
};
