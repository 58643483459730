import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Video from "@/models/sis/Video";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A media item
 * @property {number} id
 */
export default class VideoCategory extends GenexSISModel {
  static entity = "video_categories";

  static fields() {
    return {
      id: this.attr(null),
      description: this.attr(null),
      name: this.attr(null),
      order: this.attr(null),
      videos: this.hasMany(Video, "category_id"),
      slug: this.attr(null),
      order_index: this.attr(0),
      moved: this.attr(0),
    };
  }

  /**
   * Returns all video-categories
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {boolean} isCentral - Whether to use the central api
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, isCentral = true) {
    return this.api().get(`/video-categories`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),

      params: {
        ...{
          page: page,
          limit: limit,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(videoCategory => {
          if (videoCategory.attributes.order) {
            videoCategory.attributes.order_index = videoCategory.attributes.order;
          }
          return { ...videoCategory, ...videoCategory.attributes };
        });
      },
    });
  }

  /**
   * Returns a category by its id
   * @function
   * @param {number} id The id of the user
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/video-categories/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (data.attributes.order) {
          data.attributes.order_index = data.attributes.order;
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new video category
   * @function
   * @param {Object} category - The category object
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(category) {
    return this.api().post(`/video-categories`, category, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        if (data.attributes.order) {
          data.attributes.order_index = data.attributes.order;
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing video category
   * @function
   * @param {Object} category - The video category object
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created video
   */

  static Update(category, saved) {
    return this.api().patch(`/video-categories/${category.id}`, category, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing video
   * @function
   * @param {number} category_id - The id of the category
   * @returns {Promise<Response>} - The newly created video
   */

  static Delete(category_id) {
    return this.api().delete(`/video-categories/${category_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: category_id,
    });
  }
}
