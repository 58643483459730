import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A department
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class Department extends GenexSISModel {
  static entity = "departments";

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      name: this.attr(null),
    };
  }

  /**
   * Returns all departments
   * @function
   * @returns {Promise<Response>}
   */

  static FetchAll() {
    return this.api().get(`/departments`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(department => {
          return { ...department, ...department.attributes };
        });
      },
    });
  }
}
