import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import ClassGroup from "@/models/sis/ClassGroup";
import LMSForumTopic from "@/models/lms/LMSForumTopic";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LMSForum extends GenexLMSModel {
  static entity = "lms-forums";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      group_id: this.attr(null),
      group: this.belongsTo(ClassGroup, "group_id"),
      name: this.attr(""),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      topics: this.hasMany(LMSForumTopic, "forum_id"),
      topic_ids: this.attr([]),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/forums`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(attendance => {
          return { ...attendance, ...attendance.attributes };
        });
      },
    });
  }

  static FetchById(forumId, relationships = []) {
    return this.api().get(`/forums/${forumId}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },

      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static Store(entity) {
    return this.api().post(`/forums`, entity, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(entity) {
    return this.api().patch(`/forums/${entity.id}`, entity, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }
}
