import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Unit from "@/models/sis/Unit";
import User from "./User";

export default class UserUnit extends GenexSISModel {
  static entity = "UserUnit";

  static primaryKey = ["user_id", "unit_id"];

  static fields() {
    return {
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      unit_id: this.attr(null),
      unit: this.belongsTo(Unit, "unit_id"),
      status_id: this.attr(null),
    };
  }
}
