import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";

export default class LMSCourseProgress extends GenexLMSModel {
  static entity = "lms-course-progress";

  /**
   *
   * @returns {{completed_at: *, course_id: *, updated_at: *, user_id: *, created_at: *, completed: *, user: *, percent_complete: *}}
   */
  static fields() {
    return {
      user_id: this.attr(null),
      first_name: this.attr(null),
      last_name: this.attr(null),
      lms_user_id: this.attr(null),
      course_progress: this.attr(null),
    };
  }

  /**
   *
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @param persistBy
   * @returns {Promise|Promise<any>|*}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 999 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.apiWithHeaders().get(`/dashboards/course-progress`, {
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
