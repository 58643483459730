import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Stage from "@/models/sis/Stage";

/**
 * A collection of dashboard waiting stages
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardAdmissionsWaitingStage extends GenexSISModel {
  static entity = "dashboard-admissions-stage";
  static primaryKey = "stage_id";

  static fields() {
    return {
      stage: this.belongsTo(Stage, "stage_id"),
      stage_id: this.attr(null),
      count: this.attr(null),
    };
  }
}
