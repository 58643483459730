import { GenexSISModel } from "@/models/sis/GenexSISModel";
import ApplicationStatus from "@/models/sis/ApplicationStatus";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An application stage
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class ApplicationStage extends GenexSISModel {
  static entity = "application_stages";

  static fields() {
    return {
      id: this.attr(null, value => parseInt(value)),
      isDirty: this.attr(false),
      name: this.attr(null),
      order: this.attr(null),
      in_status_order: this.attr(null),
      status: this.belongsTo(ApplicationStatus, "status_id"),
      status_id: this.attr(null),
      victory_status_id: this.attr(null),
      guardian_string: this.attr(null),
      colour: this.attr(null, value => (value === null ? "#00729AFF" : value)),
      updated_at: this.attr(0),
      auto_enrolment_enabled: this.attr(false),
      automated_email_for_guardian: this.attr(false),
    };
  }

  /**
   * Returns all application stages
   * @function
   * @returns {Promise<Response>}
   */

  static FetchAll() {
    return this.api().get(`/application-stages`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(stage => {
          stage.isDirty = false;
          return { ...stage, ...stage.attributes };
        });
      },
    });
  }

  /**
   * Store a new Application Stage
   * @function
   * @param {Object} stage - The stage object
   * @returns {Promise<Response>} - The newly created stage
   */

  static Store(stage) {
    return this.api().post(`/application-stages`, stage, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        data.updated_at = Date.now();
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing Application Stage
   * @function
   * @param {Object} Stage - The application stage
   * @returns {Promise<Response>} - The newly created class
   */

  static Update(Stage) {
    return this.api().patch(`/application-stages/${Stage.id}`, Stage, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        data.isDirty = false;
        data.updated_at = Date.now();

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing Application Stage
   * @function
   * @param {Object} Stages - The application stage
   * @returns {Promise<Response>} - The newly created class
   */

  static BulkUpdate(Stages) {
    return this.api().patch(`/application-stages/bulk`, Stages, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(stage => {
          stage.isDirty = false;
          return { ...stage, ...stage.attributes };
        });
      },
    });
  }

  /**
   * Delete an existing stage
   * @function
   * @param {number} stage_id - The id of the stage
   * @returns {Promise<Response>} - The newly created stage
   */

  static Delete(stage_id) {
    return this.api().delete(`/application-stages/${stage_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: stage_id,
    });
  }
}
