import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Term from "@/models/sis/Term";
import ReportSubject from "@/models/sis/ReportSubject";
import Phase from "@/models/sis/Phase";
import User from "@/models/sis/User";
import Stage from "@/models/sis/Stage";
import ReportTemplate from "@/models/sis/ReportTemplate";

/**
 * A report
 * @property {number} id
 * @property {string} name - The name of the report
 * @property {number} term_ids - The term the report focuses on
 * @property {array<number>} term_ids - The ids of the terms
 * @property {array<Object>} terms - The terms
 * @property {number} year - The year of the report
 * @property {string} start - The start of the report
 * @property {string} end - The end of the report
 * @property {number} status_id - The status
 * @property {number} campus_id - the campus the report is on
 * @property {number} template_id - the template of the report  1 High School 2 Primary School 3 SI
 * @property {array<Object>} subjects - the report subjects


 */
export default class Report extends GenexSISModel {
  static entity = "reports";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      term_ids: this.attr([]),
      terms: this.hasManyBy(Term, "term_ids"),
      term_id: this.attr(null),
      term: this.belongsTo(Term, "term_id"),
      year: this.attr(null),
      start: this.attr(null),
      end: this.attr(null),
      campus_id: this.attr(null),
      status_id: this.attr(null),
      subjects: this.hasMany(ReportSubject, "report_id"),
      report_user_comments_count: this.attr(0),
      users_count: this.attr(0),
      phase_id: this.attr(null),
      phase: this.belongsTo(Phase, "phase_id"),
      stage_ids: this.attr([]),
      stages: this.hasManyBy(Stage, "stage_ids"),
      school_level: this.attr(null),
      template_id: this.attr(null),
      template: this.belongsTo(ReportTemplate, "template_id"),
      has_summary_comment: this.attr(0),
      introductory_paragraph: this.attr(""),
      has_mark_table: this.attr(0),
      is_saved_to_users: this.attr(0),
      is_final: this.attr(0),
      report_user_ids: this.attr([]),
      users: this.hasManyBy(User, "report_user_ids"),
      is_legacy: this.attr(0),
    };
  }

  /**
   * Returns all reports
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.term_id] - search by term_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {Array.<string>} [count=[]] - Relationships to count
   * @returns {Promise<Response>} All the Reports
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], count = []) {
    return this.GenexAxiosGet("Report.FetchAll", `/reports`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
          with_count: count,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(report => {
          if (relationships.includes("terms")) {
            if (Object.prototype.hasOwnProperty.call(report.attributes, "terms")) {
              report.attributes.terms.map(term => {
                Object.assign(term, term.attributes);
              });
            }
          }
          if (relationships.includes("users")) {
            if (Object.prototype.hasOwnProperty.call(report.attributes, "users")) {
              report.attributes.users.map(user => {
                Object.assign(user, user.attributes);
              });
            }
          }
          if (Object.prototype.hasOwnProperty.call(report.attributes, "phase")) {
            if (report.attributes.phase !== null) {
              Object.assign(report.attributes.phase, report.attributes.phase.attributes);
            }
          }
          if (Object.prototype.hasOwnProperty.call(report.attributes, "stages")) {
            report.attributes.stages.map(stage => {
              Object.assign(stage, stage.attributes);
            });
          }
          return { ...report, ...report.attributes };
        });
      },
    });
  }

  /**
   * Returns a Report
   * @function
   * @param {number} report_id - The id of the report
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(report_id, relationships = []) {
    return this.GenexAxiosGet("Report.FetchById", `/reports/${report_id}`, {
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("users")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "users")) {
            data.attributes.users.map(user => {
              Object.assign(user, user.attributes);
            });

            if (data.attributes.users.length === 0) {
              data.attributes.report_user_ids = [];
            }
          }
        }
        if (Object.prototype.hasOwnProperty.call(data.attributes, "stages")) {
          data.attributes.stages.map(stage => {
            Object.assign(stage, stage.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new report
   * @function
   * @param {Object} report - The report object
   * @returns {Promise<Response>} - The newly created report
   */

  static Store(report) {
    return this.GenexAxiosPost("Report.Store", `/reports`, report, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Recreate reports
   * @function
   * @param {number} report_id - The report
   * @returns {Promise<Response>} - The newly created report
   */

  static ReSave(report_id) {
    return this.GenexAxiosPost(
      "Report.ReSave",
      `/reports/${report_id}/re-save`,
      {},
      {
        persisBy: "update",
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Recreate a report
   * @function
   * @param {number} report_id - The report
   * @param {number} user_id - The user
   * @returns {Promise<Response>} - The newly created report
   */

  static ReSaveSingle(report_id, user_id) {
    return this.GenexAxiosPost(
      "Report.ReSaveSingle",
      `/reports/${report_id}/re-save`,
      { user_id: user_id },
      {
        persisBy: "update",
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Update an report
   * @function
   * @param {Object} report - The report object
   * @returns {Promise<Response>} - The updated report
   */

  static Update(report) {
    return this.GenexAxiosPatch("Report.Update", `/reports/${report.id}`, report, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an report
   * @function
   * @param {number} id - The id of the report
   */

  static Delete(id) {
    return this.GenexAxiosDelete("Report.Delete", `/reports/${id}`, {
      delete: id,
    });
  }

  /**
   * Download an individual user's report
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} user_id - The id of the user
   */

  static DownloadUserReport(report_id, user_id) {
    return this.GenexAxiosGet("Report.DownloadUserReport", `/reports/${report_id}/users/${user_id}/download-report`, {
      responseType: "arraybuffer",
      save: false,
    });
  }

  /**
   * Download a home class report
   * @function
   * @param {number} report_id - The id of the report
   * @param {Array<number>} home_class_ids - The id of the home class
   * $param {number} user_id - The id of the user
   */

  static DownloadHomeClassReport(report_id, home_class_ids, user_id) {
    return this.GenexAxiosGet("Report.DownloadHomeClassReport", `/reports/${report_id}/download-reports`, {
      params: {
        home_class_ids: home_class_ids,
        user_id: user_id,
      },
      save: false,
    });
  }

  /**
   * Download all reports
   * @function
   * @param {number} report_id - The id of the report
   */

  static DownloadAllReports(report_id) {
    return this.GenexAxiosGet("Report.DownloadAllReports", `/reports/${report_id}/download-reports`, {
      save: false,
    });
  }

  static DownloadSubjectReport(args) {
    return this.GenexAxiosGet("Report.DownloadSubjectReport", `/exports/report-subjects`, {
      params: {
        ...args,
      },
      save: false,
    });
  }
}
