import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A withdrawal reason
 * @property {number} id
 */
export default class WithdrawalReason extends GenexSISModel {
  static entity = "withdrawal-reasons";

  static fields() {
    return {
      id: this.attr(null),
      reason: this.attr(null),
      description: this.attr(null),
      type_id: this.attr(null),
    };
  }

  /**
   * Returns all reasons
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}) {
    return this.api().get(`/withdrawal-reasons`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
        },
        ...(Object.keys(query).length > 0 ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(reason => {
          return { ...reason, ...reason.attributes };
        });
      },
    });
  }

  /**
   * Returns a specific reason
   * @function
   * @param {number} id - The  id
   * @returns {Promise<Response>}
   */

  static FetchById(id) {
    return this.api().get(`/withdrawal-reasons/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new withdrawal reason
   * @function
   * @param {Object} reason - The reason object
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(reason) {
    return this.api().post(`/withdrawal-reasons`, reason, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a reason
   * @function
   * @param {Object} reason - The note object
   * @returns {Promise<Response>} - The newly created user
   */

  static Update(reason) {
    return this.api().patch(`/withdrawal-reasons${reason.id}`, reason, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a reason
   * @function
   * @param {number} id - The id of the note
   * @returns {Promise<Response>}
   */

  static Delete(id) {
    return this.api().delete(`/withdrawal-reasons${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
