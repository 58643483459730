import Vue from "vue";
import Vuex from "vuex";
import VuexORM from "@vuex-orm/core";
import VuexORMAxios from "@vuex-orm/plugin-axios";
import axios from "axios";
import VuexORMSearch from "@vuex-orm/plugin-search";
import { AuthModule } from "@/store/modules/Auth";
import { LoaderModule } from "@/store/modules/Loader";
import { MenuModule } from "@/store/modules/Menu";
import { ToastModule } from "@/store/modules/Toast";
import { UserModule } from "@/store/modules/User";
import { SizeModule } from "@/store/modules/Size";
import { PopoutModule } from "@/store/modules/Popout";
import { CampusModule } from "@/store/modules/Campus";
import { ApplicationModule } from "@/store/modules/Application";
import { AttendanceModule } from "@/store/modules/Attendance";
import { DateSelectModule } from "@/store/modules/Dates";
import { AssessmentModule } from "@/store/modules/Assessment";
import { ReportModule } from "@/store/modules/Report";
import { StaffProfileModule } from "@/store/modules/StaffProfile";
import { LeaveRequestModule } from "@/store/modules/LeaveRequest";
import { ServiceModule } from "@/store/modules/Service";
import { ProgressTableModule } from "@/store/modules/ProgressTable";
import { IncidentModule } from "@/store/modules/Incident";
import { AnnouncementModule } from "@/store/modules/Announcement";
import { CalendarModule } from "@/store/modules/Calendar";
import { DocumentationModule } from "@/store/modules/Documentation";
import { ProfileModule } from "@/store/modules/Profile";
import { CovidAssessmentModule } from "@/store/modules/CovidAssessment";
import { TenantModule } from "@/store/modules/Tenant";
import { SupportModule } from "@/store/modules/Support";
import { ReleaseModule } from "@/store/modules/Release";
import { MeetingModule } from "@/store/modules/Meeting";
import { database } from "./database";
import { ChatModule } from "@/store/modules/Chat";
import { ForumModule } from "@/store/modules/ForumModule";
import { LessonPlanAiModule } from "@/store/modules/LessonPlanAi";
import { widgetsModule } from "@/store/modules/widgets";

VuexORM.use(VuexORMAxios, { axios });
VuexORM.use(VuexORMSearch, {});

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    loader: LoaderModule,
    menu: MenuModule,
    toast: ToastModule,
    user: UserModule,
    size: SizeModule,
    popout: PopoutModule,
    campus: CampusModule,
    application: ApplicationModule,
    attendance: AttendanceModule,
    dates: DateSelectModule,
    assessments: AssessmentModule,
    reports: ReportModule,
    announcements: AnnouncementModule,
    calendar: CalendarModule,
    staffProfile: StaffProfileModule,
    profile: ProfileModule,
    leave: LeaveRequestModule,
    incidents: IncidentModule,
    services: ServiceModule,
    documentation: DocumentationModule,
    covid: CovidAssessmentModule,
    progressTable: ProgressTableModule,
    tenant: TenantModule,
    support: SupportModule,
    release: ReleaseModule,
    meeting: MeetingModule,
    chat: ChatModule,
    forum: ForumModule,
    LessonPlanAiModule,
    widgets: widgetsModule,
  },
  plugins: [VuexORM.install(database)],
});
