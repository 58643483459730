import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Term from "@/models/sis/Term";
import Subject from "@/models/sis/Subject";
import User from "@/models/sis/User";
import ClassGroup from "@/models/sis/ClassGroup";
import AssessmentSubmission from "@/models/sis/AssessmentSubmission";
import { normalize } from "@/utils/data";
import FileType from "@/models/sis/FileType";

/**
 * A assessment
 * @property {number} id
 * @property {string} name - The name of the assessment
 * @property {number} term_id - The id of the term
 * @property {number} class_group_id - The id of the class
 * @property {number} subject_id - The id of the assessment if
 * @property {string} assessment_date - The date of the assessment
 * @property {number} type_id - The type of the assessment 1=task 2=project 3=formal 4=exam
 * @property {number} total_marks - the total marks available in the assessment
 * @property {array<Object>} terms - the children terms


 */
export default class Assessment extends GenexSISModel {
  static entity = "assessments";

  static STATUSES = {
    1: "Not Started",
    2: "Submitted",
    3: "In progress",
    4: "Reviewed",
    6: "Returned to Student",
    7: "Resubmitted",
  };

  static statusesByName = Object.entries(Assessment.STATUSES).reduce((acc, [key, value]) => {
    acc[value] = key;
    return acc;
  });

  static getStatusName(statusId) {
    return Assessment.STATUSES[statusId];
  }

  static getStatusId(statusName) {
    return Assessment.statusesByName[statusName];
  }

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      term_id: this.attr(null),
      class_group_id: this.attr(null),
      class_group: this.belongsTo(ClassGroup, "class_group_id"),
      term: this.belongsTo(Term, "term_id"),
      assessment_date: this.attr(() => new Date()),
      total_marks: this.attr(null),
      include_mark: this.attr(true),
      type_id: this.attr(null),
      assessment_type: this.attr("N/A"),
      subject_id: this.attr(null),
      subject: this.belongsTo(Subject, "subject_id"),
      campus_id: this.attr(null),
      markgroup_id: this.attr(null),
      is_global: this.attr(null),
      teacher_id: this.attr(null),
      teacher: this.belongsTo(User, "teacher_id"),
      weighting: this.attr(null),
      weighting_valid: this.attr(false),
      description: this.attr(null),
      brief: this.attr(null),
      task_status: this.attr(null),
      assessment_submissions: this.hasMany(AssessmentSubmission, "assessment_id"),
      file_limit: this.attr(null),
      file_type_ids: this.attr([]),
      file_types: this.hasManyBy(FileType, "file_type_ids"),
      lms_assessment_id: this.attr(null),
      allow_resubmission: this.attr(0),
    };
  }

  /**
   * Returns all Assessments
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.type_id] - search by type_id
   * @param {number} [query.term_id] - search by term_id
   * @param {number} [query.subject_id] - search by subject_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {?string} [persistBy]
   * @returns {Promise<Response>} All the CourseScopeTopics
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.GenexAxiosGet("Assessment.FetchAll", `/assessments`, {
      persistBy: persistBy,

      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns an Assessment
   * @function
   * @param {number} assessment_id - The id of the assessment
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(assessment_id, relationships = [], persistBy = "insertOrUpdate") {
    return this.GenexAxiosGet("Assessment.FetchById", `/assessments/${assessment_id}`, {
      persistBy: persistBy,
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Store a new assessment
   * @function
   * @param {Object} assessment - The assessment object
   * @returns {Promise<Response>} - The newly created assessment
   */

  static Store(assessment) {
    return this.GenexAxiosPost("Assessment.Store", `/assessments`, assessment, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an assessment
   * @function
   * @param {Object} assessment - The assessment object
   * @returns {Promise<Response>} - The updated assessment
   */

  static Update(assessment) {
    return this.GenexAxiosPatch("Assessment.Update", `/assessments/${assessment.id}`, assessment, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an assessment
   * @function
   * @param {number} id - The id of the assessment
   */

  static Delete(id) {
    return this.GenexAxiosDelete("Assessment.Delete", `/assessments/${id}`, {
      delete: id,
    });
  }

  static formatType(type) {
    const types = {
      1: "Task",
      2: "Project",
      3: "Formal",
      4: "Exam",
      5: "LMS Task",
    };
    return types[type];
  }
}
