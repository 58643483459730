import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class DashboardAttendancesState extends GenexSISModel {
  static entity = "dashboard-attendances-state";

  static fields() {
    return {
      campus: this.attr(""),
      type_id: this.attr(null),
      id: this.attr(null),
      name: this.attr(""),
      states: this.attr(() => []),
      // // states: this.hasManyBy(Topic, 'topic_ids'),
    };
  }

  static Fetch(query) {
    return this.api().get(`/dashboard/attendances-state`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data;
      },
    });
  }
}
