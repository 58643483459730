import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Permission from "@/models/sis/Permission";
import PermissionRole from "@/models/sis/PermissionRole";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A role
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class Role extends GenexSISModel {
  static entity = "roles";

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      name: this.attr(""),
      system_defined: this.attr(null),
      permissions: this.belongsToMany(Permission, PermissionRole, "role_id", "permission_id"),
    };
  }

  /**
   * Returns all roles
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {?string} [persistBy]
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, relationships = [], query = {}, persistBy = "insertOrUpdate") {
    return this.api().get(`/roles`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: persistBy,

      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(role => {
          if (relationships.includes("permissions")) {
            role.attributes.permissions.map(permission => {
              Object.assign(permission, permission.attributes);
            });
          }
          return { ...role, ...role.attributes };
        });
      },
    });
  }

  /**
   * Returns a role
   * @function
   * @param {number} id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/roles/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("permissions")) {
          data.attributes.permissions.map(permission => {
            Object.assign(permission, permission.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new role
   * @function
   * @param {Object} role - The role object
   * @param {string} role.name - The name of the role
   * @param {array} role.permissions - The permission ids of the role
   * @returns {Promise<Response>} - The newly created role
   */

  static Store(role) {
    return this.api().post(`/roles`, role, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        data.attributes.permissions.map(permission => {
          Object.assign(permission, permission.attributes);
        });
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing role
   * @function
   * @param {Object} role - The role object
   * @param {string} role.name - The name of the role
   * @param {array} role.permissions - The permission ids of the role
   * @param {string} role.id - The id of the role
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created role
   */

  static Update(role, saved) {
    return this.api().patch(`/roles/${role.id}`, role, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        data.attributes.permissions.map(permission => {
          Object.assign(permission, permission.attributes);
        });
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing role
   * @function
   * @param {number} role_id - The id of the role
   * @returns {Promise<Response>}
   */

  static Delete(role_id) {
    return this.api().delete(`/roles/${role_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: role_id,
    });
  }
}
