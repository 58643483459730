import { GenexSISModel } from "@/models/sis/GenexSISModel";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import User from "../../models/sis/User";
import ObjectiveProgressLog from "../../models/sis/ObjectiveProgressLog";
import Unit from "@/models/sis/Unit";

export default class MontessoriObservation extends GenexSISModel {
  static entity = "MontessoriObservation";

  static categories = [
    { id: 1, name: "Academic" },
    { id: 2, name: "Social" },
    { id: 3, name: "Emotional" },
    { id: 4, name: "Other" },
  ];

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      objective_progress_log_id: this.attr(null),
      objective_progress_log: this.belongsTo(ObjectiveProgressLog, "objective_progress_log_id"),
      observation: this.attr(""),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      task_id: this.attr(null),
      task: this.belongsTo(Unit, "task_id"),
      category_id: this.attr(null),
      category: this.attr(null),
      course_scope_id: this.attr(null),
    };
  }

  static FetchAll(
    { page = 1, limit = 15 },
    query = {},
    relationships = [],
    mode = "insertOrUpdate",
    clearPrimaryModelOnly = false,
  ) {
    return this.api().get(`/montessori-observations`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (clearPrimaryModelOnly) {
          this.deleteAll();
        }
        return normalize(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/montessori-observations/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Store(item) {
    return this.api().post(`/montessori-observations`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(item, saved) {
    return this.api().patch(`/montessori-observations/${item.id}`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  static Delete(item_id) {
    return this.api().delete(`/montessori-observations/${item_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: item_id,
    });
  }
}
