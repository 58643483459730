import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Subject from "@/models/sis/Subject";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A programme
 * @property {number} id
 */
export default class Programme extends GenexSISModel {
  static entity = "programmes";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      provider_code: this.attr(null),
      registration_cost: this.attr(null),
      placement_cost: this.attr(null),
      campus_cost_per_month: this.attr(null),
      campus_cost_per_annum: this.attr(null),
      distance_cost_per_month: this.attr(null),
      distance_cost_per_annum: this.attr(null),
      subject_ids: this.attr([]),
      subjects: this.hasManyBy(Subject, "subject_ids"),
    };
  }

  /**
   * Returns all programmes
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, relationships = []) {
    return this.api().get(`/programmes`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(programme => {
          if (relationships.includes("subjects")) {
            programme.attributes.subjects.map(subject => {
              Object.assign(subject, subject.attributes);
            });
          }
          return { ...programme, ...programme.attributes };
        });
      },
    });
  }

  /**
   * Returns a specific programme
   * @function
   * @param {number} id - The id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/programmes/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("subjects")) {
          data.attributes.subjects.map(subject => {
            Object.assign(subject, subject.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }
}
