import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";
import Course from "@/models/lms/LMSCourses";
import User from "@/models/lms/LMSUser";
import { LMSAssessmentQuestion } from "@/models/lms-central/LMSAssessmentQuestion";
import Module from "@/models/lms/LMSModule";

export class LMSAssessment extends GenexLMSModel {
  static entity = "lms-assessments";

  static fields() {
    return {
      id: this.attr(null),
      course_id: this.attr(null),
      module_id: this.attr(null),
      module: this.belongsTo(Module, "module_id"),
      course: this.belongsTo(Course, "course_id"),
      course_scope_id: this.attr(null),
      title: this.attr(""),
      admin_title: this.attr(""),
      is_checkpoint: this.attr(1),
      marks: this.attr(0),
      max_attempts: this.attr(0),
      description: this.attr(""),
      pass_mark: this.attr(0),
      difficulty_1_count: this.attr(0),
      difficulty_2_count: this.attr(0),
      difficulty_3_count: this.attr(0),
      assessment_questions_count: this.attr(0),
      assessment_questions_ids: this.attr(() => []),
      assessment_questions: this.hasMany(LMSAssessmentQuestion, "assessment_id"),
      assessment_question_ordinalities: this.attr(() => []),
      assessment_questions_learning_objectives_count: this.attr(0),
      assessment_questions_total_marks: this.attr(0),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      creator_id: this.attr(null),
      updater_id: this.attr(null),
      tenant_id: this.attr(null),
      assessment_type_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.apiWithHeaders().get(`assessments`, {
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.apiWithHeaders().get(`assessments/${id}`, {
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Store(data) {
    return this.apiWithHeaders().post(`assessments`, data, {
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  static Update(data) {
    return this.apiWithHeaders().patch(`assessments/${data.id}`, data, {
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  static Delete(id) {
    return this.apiWithHeaders().delete(`assessments/${id}`, {
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      delete: id,
    });
  }
}
