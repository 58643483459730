import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An meeting
 * @property {number} id
 * @property {number} campus_id - The id of the campus this meeting belongs to
 * @property {number} audience_id - 1 = staff, 2 = guardian, 3 = all
 * @property {Array<Object>} models -

 */
export default class Meeting extends GenexSISModel {
  static entity = "meetings";

  static fields() {
    return {
      id: this.attr(null),
      uuid: this.attr(null),
      name: this.attr(null),
      description: this.attr(null),
      start_date_time: this.attr(null),
      end_date_time: this.attr(null),
      is_internal: this.attr(null),
      campus_id: this.attr(null),
      minutes: this.attr(null),
      email_attendees: this.attr(null),
      follow_up_meeting: this.belongsTo(Meeting, "follow_up_meeting_id"),
      follow_up_meeting_id: this.attr(null),
      parent_meeting: this.belongsTo(Meeting, "parent_meeting_id"),
      parent_meeting_id: this.attr(null),
      status: this.attr(null),
      users: this.hasManyBy(User, "user_ids"),
      user_ids: this.attr([]),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      duration: this.attr(null),
    };
  }

  /**
   * Returns all meetings
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} query
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Meeting.FetchAll", `/meetings`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(meeting => {
          if (relationships.includes("creator")) {
            if (Object.prototype.hasOwnProperty.call(meeting.attributes, "creator")) {
              if (meeting.attributes.creator !== null) {
                Object.assign(meeting.attributes.creator, meeting.attributes.creator.attributes);
              }
            }
          }

          if (relationships.includes("users")) {
            meeting.attributes.users.map(user => {
              Object.assign(user, user.attributes);
            });
          }
          if (relationships.includes("users.roles")) {
            meeting.attributes.users.map(user => {
              user.roles.map(role => {
                Object.assign(role, role.attributes);
              });
            });
          }
          return { ...meeting, ...meeting.attributes };
        });
      },
    });
  }

  /**
   * Returns a meeting
   * @function
   * @param {number} [id]
   * @param {?Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, query = {}, relationships = []) {
    return this.GenexAxiosGet("Meeting.FetchById", `/meetings/${id}`, {
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("creator")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "creator")) {
            if (data.attributes.creator !== null) {
              Object.assign(data.attributes.creator, data.attributes.creator.attributes);
            }
          }
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new meeting
   * @function
   * @param {Object} meeting - The meeting object
   * @returns {Promise<Response>} - The newly created meeting
   */

  static Store(meeting) {
    return this.GenexAxiosPost("Meeting.Store", `/meetings`, meeting, {
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing meeting
   * @function
   * @param {Object} meeting - The meeting object
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created application
   */

  static Update(meeting, saved = true) {
    return this.api().put(`/meetings/${meeting.id}`, meeting, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing meeting
   * @function
   * @param {number} meeting_id - The id of the meeting
   * @returns {Promise<Response>} - The status of the delete
   */

  static Delete(meeting_id) {
    return this.GenexAxiosDelete("Meeting.Delete", `/meetings/${meeting_id}`, {
      delete: meeting_id,
    });
  }

  /**
   * Returns a meeting
   * @function
   * @param {number} [id]
   * @param {?Object} [query={}] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static getSuggestedAttendees(query = {}) {
    return this.GenexAxiosGet("Meeting.getSuggestedAttendees", `/meetings/4/suggested-attendees`, {
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data: data } }) => {
        return data;
      },
      save: false,
    });
  }
}
