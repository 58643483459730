<template>
  <div :class="modalSize">
    <GenexForm :inModal="true" :modalHeader="entity.display_name">
      <template v-slot:body>
        <img :src="entity.temporary_url" alt="" class="ml-auto mr-auto is-block" />
        <!--        <iframe :src="entity.temporary_url" frameborder="0" width="100%" height="80vh" style="height: calc(100vh - 231px);"></iframe>-->
      </template>
      <template v-slot:footer>
        <!--      <b-button :loading="loading" native-type="submit" type="is-danger"> Delete </b-button>-->
        <!--      <b-button @click="$emit('close')" type="is-default">Cancel</b-button>-->
        <b-button @click="widthIsLarge = !widthIsLarge"
          >{{ widthIsLarge ? "Expand Width" : "Collapse Width" }}
        </b-button>
        <!--        <pre>widthIsLarge: {{widthIsLarge}}</pre>-->
      </template>
    </GenexForm>
  </div>
</template>

<script>
  import GenexForm from "@/components/global/GenexForm";

  export default {
    name: "MediaPanelModalViewImage",
    components: {
      GenexForm,
    },
    props: {
      entity: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
      return {
        widthIsLarge: true,
      };
    },
    computed: {
      modalSize() {
        let result = "modalIsXXLarge";
        if (this.widthIsLarge) {
          result = "modalIsMedium";
        }
        return result;
      },
    },
    mounted() {
      // if (this.inModal) {
      //   this.makeModalLarge("animation-content");
      // }
      // this.makeModalLarge("animation-content");
    },
  };
</script>
