import { GenexSISModel } from "@/models/sis/GenexSISModel";

export default class PermissionRole extends GenexSISModel {
  static entity = "permissionRole";

  static primaryKey = ["role_id", "permission_id"];

  static fields() {
    return {
      role_id: this.attr(null),
      permission_id: this.attr(null),
    };
  }
}
