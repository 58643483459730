import { format, isValid } from "date-fns";

export const AnnouncementModule = {
  namespaced: true,

  state: {
    audiences: [
      { name: "All", id: 3, can_multiple: true },
      { name: "Staff", id: 1, can_multiple: true },
      {
        name: "Guardian",
        id: 2,
        can_multiple: true,
      },
      { name: "Learner", id: 4, can_multiple: false },
    ],
    model_types: [
      {
        name: "Global",
        value: "Global",
        availability: [1, 2, 3],
        filter_availability: [1, 2],
      },
      {
        name: "Campus",
        value: "Campus",
        availability: [1, 2, 3],
        filter_availability: [1, 2],
      },
      {
        name: "Phase",
        value: "Phase",
        availability: [2],
        filter_availability: [2],
      },
      {
        name: "Stage",
        value: "Stage",
        availability: [2],
        filter_availability: [2],
      },
      {
        name: "Subject",
        value: "Subject",
        availability: [1, 2, 3, 4],
        filter_availability: [1, 2, 4],
      },
      {
        name: "HomeClass",
        value: "HomeClass",
        availability: [1, 2, 3],
        filter_availability: [1, 2],
      },
      // {name: 'Department', value: 'Department', availability: [1]},
    ],
    date: new Date(),
    selectedAnnouncementType: "All",
    readFilter: 0,
    savedFilter: "All",
    campus_filter: null,
    loading: false,
  },
  getters: {
    formattedDate: state => {
      if (isValid(new Date(state.date))) {
        return format(state.date, "yyyy-MM-dd");
      }
      return null;
    },
  },
};
