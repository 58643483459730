import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Media from "@/models/sis/Media";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class UserReport extends GenexSISModel {
  static entity = "user-reports";

  static fields() {
    return {
      id: this.attr(null),
      report_id: this.attr(null),
      user_id: this.attr(null),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr(() => []),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, user_id) {
    return this.api().get(`/users/${user_id}/reports?with[]=media`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(report => {
          report.attributes.media.map(item => {
            Object.assign(item, item.attributes);
          });

          return { ...report, ...report.attributes };
        });
      },
    });
  }
}
