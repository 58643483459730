import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A support ticket
 * @property {string} title - The title of the release
 * @property {string} content - The release contents
 * @property {string} release_date - The date of release
 * @property {string} created_at - The date of release
 * @property {number} audience_id - The intended audience id
 * @property {number} creator_id - The creator user id
 */
export default class Release extends GenexSISModel {
  static entity = "releases";

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      content: this.attr(null),
      release_date: this.attr(null),
      created_at: this.attr(null),
      audience_id: this.attr(null),
      creator_id: this.attr(null),
    };
  }

  /**
   * Returns Releases
   * @function
   */

  static FetchAll(
    { page = 1, limit = 15, order_direction = "desc", order_by = "release_date" },
    query = {},
    relationships = [],
  ) {
    return this.api().get(`/releases`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page,
          limit,
          order_direction,
          order_by,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(release => {
          return { ...release, ...release.attributes };
        });
      },
    });
  }

  /**
   * Store a new Release
   * @function
   * @param {Object} release - The release object
   * @returns {Promise<Response>} - The newly created release
   */

  static Store(release) {
    return this.api().post(`/releases`, release, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(release) {
    return this.api().patch(`/releases/${release.id}`, release, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an existing release
   * @function
   * @param {number} release_id - The id of the release
   * @returns {Promise<Response>} - The status of the delete
   */

  static Delete(release_id) {
    return this.api().delete(`/releases/${release_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: release_id,
    });
  }
}
