import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A media item
 * @property {number} id
 */
export default class Media extends GenexSISModel {
  static entity = "media";

  static fields() {
    return {
      type: this.attr("media"),
      id: this.attr(null),
      filename: this.attr(null),
      extension: this.attr(null),
      tag: this.attr(null),
      caption: this.attr(null),
      temporary_url: this.attr(null),
      url: this.attr(null),
      mediable_id: this.attr(null),
      mediable_type: this.attr(null),
      visibility: this.attr(null),
      is_sensitive: this.attr(null),
      display_name: this.attr(""),
      updated_at: this.attr(""),
      created_at: this.attr(""),
      size: this.attr(null),
      aggregate_type: this.attr(""),
    };
  }

  /**
   * Returns all media
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {boolean} isCentral - Whether to use the central api
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {?Object} [query={}] - Query terms for the request
   * @param {string} [query.modelName] - type of the model - plural
   * @param {string} [query.model] - id of the model
   * @param {string} [query.tag] - Tag of the media
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], isCentral = false) {
    return this.api().get(`/media`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),

      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(media => {
          if (Object.keys(query).includes("modelName")) {
            media.mediable_type = query["modelName"] === "leaves" ? "leave" : query["modelName"];
          }
          if (Object.keys(query).includes("model")) {
            media.mediable_id = query["model"];
          }
          if (Object.keys(query).includes("tag")) {
            media.mediable_tag = query["tag"];
          }
          if (Object.keys(query).includes("visibility")) {
            media.mediable_visibility = query["visibility"];
          }
          return { ...media, ...media.attributes };
        });
      },
    });
  }

  /**
   * Returns a specific media
   * @function
   * @param {Number} id
   * @param {boolean} isCentral - Whether to use the central api
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = [], isCentral = false) {
    return this.api().get(`/media/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new media item
   * @function
   * @param {Object} model - the associated entity
   * @param {string} model.model - the associated entity type
   * @param {number} model.model_id - the associated entity id
   * @param {boolean} isCentral - Whether to use the central api
   * @param {FormData} media
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(model, media, isCentral = false) {
    return this.api().post(`/${model.model}/${model.model_id}/media`, media, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      headers: {
        ...GenexHelperMixins.methods.DefaultSISHeaders(),
        "content-type": "multipart/form-data",
      },
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),

      dataTransformer: ({ data: { data } }) => {
        data.mediable_type = model.model === "leaves" ? "leave" : model.model;
        data.mediable_id = +model.model_id;
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a new media item
   * @function
   * @param {boolean} isCentral - Whether to use the central api
   * @param {Object} media
   * @returns {Promise<Response>} - The newly created user
   */

  static Update(media, isCentral = false, saved = true) {
    return this.api().patch(`/media/${media.id}`, media, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Store a new media by file path
   * @function
   * @param {FormData} data
   * @returns {Promise<Response>} - The newly created user
   */

  static StoreByPath(data) {
    return this.api().post(`/media-float`, data, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new media for template
   * @function
   * @param {FormData} data
   * @param {number} template_id
   * @returns {Promise<Response>} - The newly created user
   */

  static StoreForTemplate(template_id, data) {
    return this.api().post(`/report-templates/${template_id}/upload`, data, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a media item
   * @function
   * @param {boolean} isCentral - Whether to use the central api
   * @param {number} id - The id of the media item
   * @returns {Promise<Response>}
   */

  static Delete(id, isCentral = false) {
    return this.api().delete(`/media/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),
      delete: id,
    });
  }
}
