<template>
  <div>
    <b-breadcrumb align="is-left GenexBreadCrumbs">
      <b-breadcrumb-item tag="a" class="py-1">
        <span class="menu-label" @click="click('/')"> Home </span>
      </b-breadcrumb-item>
      <template v-for="match in breadcrumbs">
        <b-breadcrumb-item :key="match.path" tag="a" class="py-1">
          <span class="menu-label" @click="click(match.path)">
            {{ returnPathValue(match.breadcrumb_name) }}
          </span>
        </b-breadcrumb-item>
      </template>
    </b-breadcrumb>
  </div>
</template>

<script>
  export default {
    name: "GenexBreadCrumbs",
    components: {},
    data() {
      return {};
    },
    computed: {
      breadcrumbs() {
        if (this.$route.meta?.breadcrumbs_ancestry) {
          return this.calculateAncestry();
        }
        return this.$route.meta?.breadcrumbs_ancestry_elaborated || [];
      },
    },
    methods: {
      returnPathValue(string = "") {
        let pathValue = string;
        if (pathValue.startsWith(":")) {
          let splitName = pathValue.split(":");
          let paramKey = splitName[1];
          pathValue = this.$route.params[paramKey];
        }
        return pathValue;
      },
      click(path) {
        if (this.$route.meta?.breadcrumbs_ancestry) {
          this.$router.push({ path: path, query: this.$route.query }).catch(() => {});
        } else {
          let splitPath = path.split("/");
          splitPath.forEach((item, index) => {
            splitPath[index] = this.returnPathValue(item);
          });
          this.$router.push({ path: splitPath.join("/"), query: this.$route.query }).catch(() => {});
        }
      },
      resolveRouteByPath(haystack, needle) {
        const findRoute = (routes, path) => {
          for (const route of routes) {
            if (route.path === path) {
              return route;
            }
            if (route.children) {
              const childRoute = findRoute(route.children, path);
              if (childRoute) {
                return childRoute;
              }
            }
          }
          return null;
        };

        return findRoute(haystack, needle);
      },
      getBreadcrumbNameAndUrl(route) {
        let breadcrumb_name = route.meta.breadcrumb_name;
        if (breadcrumb_name.startsWith(":")) {
          const breadcrumb_dynamic_name_key = breadcrumb_name.substring(1);
          const breadcrumb_dynamic_name = this.$route.params[breadcrumb_dynamic_name_key];
          breadcrumb_name = breadcrumb_dynamic_name;
        }

        const path_array_1 = route.path.split("/");
        const path_array_2 = [];
        let path = route.path;

        if (path.includes(":")) {
          path_array_1.forEach(item => {
            if (item.startsWith(":")) {
              const path_element_string_key = item.substring(1);
              const path_element_string = this.$route.params[path_element_string_key];
              item = encodeURIComponent(path_element_string);
              path_array_2.push(item);
            } else {
              path_array_2.push(item);
            }
            path = path_array_2.join("/");
          });
        }

        const breadcrumb = {
          breadcrumb_name: breadcrumb_name,
          path: path,
        };
        return breadcrumb;
      },
      calculateAncestry() {
        const result = [];
        const exactMatch = this.$route.matched[this.$route.matched.length - 1];

        if (exactMatch.meta && exactMatch.meta.breadcrumbs_ancestry) {
          exactMatch.meta.breadcrumbs_ancestry.forEach(item => {
            const route = this.resolveRouteByPath(this.$router.options.routes, item);
            if (route) {
              result.push(this.getBreadcrumbNameAndUrl(route));
            }
          });

          const route = this.resolveRouteByPath(this.$router.options.routes, exactMatch.path);
          if (route) {
            result.push(this.getBreadcrumbNameAndUrl(route));
          }
        }

        return result;
      },
    },
  };
</script>

<style lang="scss">
  .GenexBreadCrumbs {
    background-color: transparent;
    margin-bottom: 0;
    li {
      a {
        padding: 0 0.5em;
        font-size: 0.8em;
        font-weight: 500;
        line-height: 1em;
      }
    }

    :before {
      line-height: 1rem;
    }
  }
</style>
