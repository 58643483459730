<template>
  <aside :class="{ hidden: !isAsideRightVisible }" class="aside is-placed-right is-absolute has-background-white">
    <div v-if="isAsideRightVisible" class="aside-right-nav is-flex is-justify-content-flex-start is-align-items-center">
      <div class="is-flex-grow-1"></div>
      <a class="mr-4 px-4 py-3" @click.prevent="updatesToggle">
        <b-icon :icon="docsMenuToggleIcon" />
      </a>
    </div>
    <div
      ref="container"
      class="aside-container mx-0 my-0 columns"
      style="width: 100%; max-width: 100%; height: 87vh; max-height: 87vh"
      @mouseenter="psUpdate">
      <div
        :class="{
          'is-5': $store.state.documentation.docs_menu_expanded,
          'is-2': !$store.state.documentation.docs_menu_expanded,
        }"
        class="column">
        <DocsIndex :docs="docs"></DocsIndex>
      </div>
      <div class="column">
        <DocsDisplay
          v-if="isDocActive"
          :docs-type="docsType"
          :path="$store.state.documentation.active_doc_document_path"></DocsDisplay>
      </div>
    </div>
  </aside>
</template>

<script>
  import { mapState } from "vuex";
  import PerfectScrollbar from "perfect-scrollbar";
  import DocsIndex from "@/components/siteDocs/DocsIndex";
  import DocsDisplay from "@/components/siteDocs/DocsDisplay";

  export default {
    name: "AsideRight",
    components: { DocsIndex, DocsDisplay },
    data() {
      return {
        docs: [],
      };
    },
    props: {
      docsType: {
        type: String,
        default() {
          return "staff";
        },
      },
    },
    computed: {
      docsMenuToggleIcon() {
        return this.isAsideRightVisible ? "forwardburger" : "backburger";
      },
      ...mapState("menu", ["isAsideRightVisible"]),
      isDocActive() {
        return this.$store.state.documentation.active_doc_document_path !== null;
      },
    },

    watch: {
      isAsideRightVisible(newVal) {
        if (newVal === true) {
          setTimeout(() => {
            window.addEventListener("click", this.forceCloseDocs);
          }, 50);
        }
      },
      docsType() {
        this.createIndex();
      },
    },
    mounted() {
      this.psInit();
      this.createIndex();
    },
    beforeDestroy() {
      window.removeEventListener("click", this.forceCloseDocs);
    },
    methods: {
      forceCloseDocs(e) {
        if (!this.$el.contains(e.target)) {
          document.body.style.position = "";
          document.body.style.top = "";
          this.$store.dispatch("menu/asideRightToggle", false);
          window.removeEventListener("click", this.forceCloseDocs);
        }
      },
      createIndex() {
        let paths;
        if (this.docsType === "staff") {
          paths = require.context("../../assets/docs/staff", true, /^.*\.md$/).keys();
        } else {
          paths = require.context("../../assets/docs/guardian", true, /^.*\.md$/).keys();
        }

        let result = [];
        let level = { result };

        paths.forEach((path, index) => {
          path.split("/").reduce((r, name) => {
            if (!r[name]) {
              r[name] = { result: [] };
              r.result.push({
                name,
                children: r[name].result,
                fullPath: path,
                id: index,
              });
            }

            return r[name];
          }, level);
        });
        this.docs = result[0].children.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.docs.sort((a, b) => {
          if (a.children.length === 0 && b.children.length === 0) {
            return 0;
          }

          return a.children.length > 0 ? -1 : 1;
        });
      },
      updatesToggle() {
        document.body.style.position = "";
        document.body.style.top = "";
        this.$store.dispatch("menu/asideRightToggle");
      },
      psInit() {
        this.ps = new PerfectScrollbar(this.$refs.container);
      },
      psUpdate() {
        if (this.ps) {
          this.ps.update();
        }
      },
    },
  };
</script>
