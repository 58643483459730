import { Model } from "@vuex-orm/core";
import mixpanel from "mixpanel-browser";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export class GenexSISModel extends Model {
  // static transformArray(response) {
  //   let storageArray = [];
  //
  //   if (response.data.data.length > 1) {
  //     response.data.data.forEach(element => {
  //       let target = element;
  //       if (element.attributes.roles !== undefined) {
  //         if (element.attributes.roles.length > 0) {
  //           element.attributes.role = element.attributes.roles[0].attributes.name;
  //         }
  //       }
  //       Object.assign(target, element.attributes);
  //       storageArray.push(target);
  //     });
  //   } else if (response.data.data.length === 1) {
  //     let target = response.data.data[0];
  //     if (target.attributes.roles !== undefined) {
  //       if (target.attributes.roles.length > 0) {
  //         target.attributes.role = target.attributes.roles[0].attributes.name;
  //       }
  //     }
  //     Object.assign(target, target.attributes);
  //     storageArray.push(target);
  //   } else {
  //     return;
  //   }
  //
  //   return storageArray;
  // }

  static transformArrayWithUser(response) {
    let storageArray = [];

    response.data.data.forEach(element => {
      element.attributes.user = {
        ...element.attributes.user,
        ...element.attributes.user.attributes,
      };

      element = {
        ...element,
        ...element.attributes,
      };
      storageArray.push(element);
    });

    return storageArray;
  }

  static GenexAxiosGet(classAndMethodNames, url, config = {}) {
    return new Promise((resolve, reject) => {
      this.api()
        .get(url, {
          ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
          ...config,
        })
        .then(response => {
          // mixpanel.track("crudAction." + classAndMethodNames + ".get");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static GenexAxiosPost(classAndMethodNames, url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.api()
        .post(url, data, {
          ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
          ...config,
        })
        .then(response => {
          mixpanel.track("crudAction." + classAndMethodNames + ".post");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static GenexAxiosPut(classAndMethodNames, url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.api()
        .put(url, data, {
          ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
          ...config,
        })
        .then(response => {
          mixpanel.track("crudAction." + classAndMethodNames + ".put");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static GenexAxiosPatch(classAndMethodNames, url, data = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.api()
        .patch(url, data, {
          ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
          ...config,
        })
        .then(response => {
          mixpanel.track("crudAction." + classAndMethodNames + ".patch");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static GenexAxiosDelete(classAndMethodNames, url, config = {}) {
    return new Promise((resolve, reject) => {
      this.api()
        .delete(url, {
          ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
          ...config,
        })
        .then(response => {
          mixpanel.track("crudAction." + classAndMethodNames + ".delete");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static GenexVuexUpdate(className, payload) {
    return new Promise((resolve, reject) => {
      this.dispatch("update", payload)
        .then(response => {
          mixpanel.track("crudAction." + className + ".update.post");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  static GenexVuexInsert(className, payload) {
    // await ApplicationStage.update({ data: makeDirty });

    // this.dispatch("update", payload);
    return new Promise((resolve, reject) => {
      this.dispatch("insert", payload)
        .then(response => {
          mixpanel.track("crudAction." + className + ".insert.post");
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
}
