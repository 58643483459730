import { onMounted, ref, unref, watch } from "vue";
import { isFunction } from "lodash";

const waiting = [];

export const useMathJax = (content, elRef) => {
  const hasMath = ref(false);
  const loading = ref(true);

  const startup = async () => {
    if (unref(elRef)) {
      loading.value = true;
      await window.MathJax.startup.promise;
      const unrefContent = isFunction(content) ? content() : unref(content);
      const text = unref(unrefContent);
      unref(elRef).innerHTML = text?.length > 0 ? text : "No content";
      window.MathJax.typeset([unref(elRef)]);
      loading.value = false;
    }
  };

  onMounted(async () => {
    if (!window.MathJax) {
      loading.value = true;
      window.MathJax = {
        tex: {
          inlineMath: [["`", "`"]],
          displayMath: [["$$", "$$"]],
        },
        startup: {
          typeset: false,
          pageReady() {
            waiting.forEach(waiter => {
              waiter();
            });
            startup();
          },
        },
      };
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = "https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-chtml-full.min.js";
      script.async = true;
      document.head.appendChild(script);
    } else {
      waiting.push(startup);
    }
  });

  watch(content, value => {
    if (!window.MathJax.typeset) {
      return;
    }
    loading.value = true;
    unref(elRef).innerHTML = value;
    window.MathJax.typeset([unref(elRef)]);
    hasMath.value = unref(elRef).innerHTML !== value;
    loading.value = false;
  });
  watch(elRef, value => {
    if (!window.MathJax.typeset) {
      return;
    }
    if (value) {
      loading.value = true;
      const unrefContent = isFunction(content) ? content() : unref(content);
      unref(elRef).innerHTML = unrefContent;
      window.MathJax.typeset([unref(elRef)]);
      hasMath.value = unref(elRef).innerHTML !== unrefContent;
      loading.value = false;
    }
  });

  return {
    hasMath,
    loading,
  };
};
