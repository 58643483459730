import { GenexSISModel } from "@/models/sis/GenexSISModel";
import UserTaskSheet from "@/models/sis/UserTaskSheet";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class CourseScopeTaskSheet extends GenexSISModel {
  static entity = "course-scopes-task-sheets";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      task_sheet_id: this.attr(null),
      course_scope_id: this.attr(null),
      task_count: this.attr(null),
      user_task_sheets: this.hasMany(UserTaskSheet, "task_sheet_id"),
    };
  }

  static FetchAll({ page = 1, limit = 60 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.api().get(`/task-sheets/user-task-sheets`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      persistBy,
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/task-sheets/user-task-sheets/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static Store(taskSheet) {
    return this.api().post(`/task-sheets/user-task-sheets`, taskSheet, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Update(taskSheet, relationships = []) {
    return this.api().patch(`/task-sheets/user-task-sheets/${taskSheet.id}`, taskSheet, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      update: taskSheet,
    });
  }

  static Delete(id) {
    return this.api().delete(`/task-sheets/user-task-sheets/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
