import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class DashboardAttendancesType extends GenexSISModel {
  static entity = "dashboard-attendances-type";

  static fields() {
    return {
      // type_id: this.attr(null),
      // name: this.attr(""),
      // attendances_taken: this.attr(null),
      // outstanding_attendances: this.attr(null),

      campus: this.attr(""),
      type: this.attr(""),
      id: this.attr(null),
      name: this.attr(""),
      completed: this.attr(null),
      incomplete: this.attr(null),
      completed_percentage: this.attr(null),
    };
  }

  static Fetch(query) {
    return this.api().get(`dashboard/attendances-type`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data;
      },
    });
  }
}
