import { GenexSISModel } from "@/models/sis/GenexSISModel";
import { ProgressYear } from "@/models/sis/ProgressYear";

export class ProgressPhase extends GenexSISModel {
  static entity = "progress-phases";

  static primaryKey = "uid";

  static fields() {
    return {
      id: this.attr(null),
      uid: this.uid(),
      name: this.attr(null),
      year: this.attr(null),
      progress_percent: this.attr(null),
      expected_progress_percent: this.attr(null),
      course_scope_id: this.attr(null),
      years_ids: this.attr(() => []),
      years: this.hasManyBy(ProgressYear, "years_ids"),
    };
  }
}
