import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Role from "@/models/sis/Role";
import VideoCategory from "@/models/sis/VideoCategory";
import User from "@/models/sis/User";
import { format } from "date-fns";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A video item
 * @property {number} id
 */
export default class Video extends GenexSISModel {
  static entity = "videos";

  get formatted_date() {
    return format(new Date(this.created_at), "yyyy-MM-dd hh:mm");
  }

  static fields() {
    return {
      id: this.attr(null),
      roles: this.hasManyBy(Role, "role_ids"),
      role_ids: this.attr([]),
      description: this.attr(null),
      src: this.attr(null),
      category_id: this.attr(null),
      category: this.belongsTo(VideoCategory, "category_id"),
      order: this.attr(null),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      created_at: this.attr(null),
      order_index: this.attr(0),
      moved: this.attr(0),
    };
  }

  /**
   * Returns all videos
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {boolean} isCentral - Whether to use the central api
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {?Object} [query={}] - Query terms for the request
   * @param {string} [query.modelName] - type of the model - plural
   * @param {string} [query.model] - id of the model
   * @param {string} [query.tag] - Tag of the media
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], isCentral = true) {
    return this.api().get(`/videos`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      ...(isCentral ? { baseURL: process.env.VUE_APP_CENTRAL_API_URL } : {}),

      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(video => {
          if (relationships.includes("creator")) {
            if (video.attributes.creator !== null) {
              Object.assign(video.attributes.creator, video.attributes.creator.attributes);
            }
          }
          if (video.attributes.order) {
            video.attributes.order_index = video.attributes.order;
          }
          return { ...video, ...video.attributes };
        });
      },
    });
  }

  /**
   * Returns a video by its id
   * @function
   * @param {number} id The id of the user
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/videos/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("creator")) {
          if (data.attributes.creator !== null) {
            Object.assign(data.attributes.creator, data.attributes.creator.attributes);
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new video
   * @function
   * @param {Object} video - The video object
   * @returns {Promise<Response>} - The newly created video
   */

  static Store(video) {
    return this.api().post(`/videos`, video, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing video
   * @function
   * @param {Object} video - The video object
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created video
   */

  static Update(video, saved) {
    return this.api().patch(`/videos/${video.id}`, video, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing video
   * @function
   * @param {number} video_id - The id of the video
   * @returns {Promise<Response>} - The newly created video
   */

  static Delete(video_id) {
    return this.api().delete(`/videos/${video_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: video_id,
    });
  }
}
