<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? "Create a new" : "Edit" }} Document</p>
      </header>
      <section class="modal-card-body">
        <b-field label="Name">
          <b-input v-model="document.name" placeholder="Document name" required></b-input>
        </b-field>
      </section>
      <footer class="modal-card-foot">
        <button :icon-right="edit === false ? 'add' : 'import'" class="button" type="is-primary">Save</button>
      </footer>
    </div>
  </form>
</template>

<script>
  import Document from "@/models/sis/Document";
  import Permission from "@/models/sis/Permission";

  export default {
    name: "DocumentForm",
    data() {
      return {
        document: {
          name: this.name,
          ordinality: 0,
          folder_id: this.parentId,
          status_id: 1,
        },
        loadingData: false,
      };
    },
    computed: {
      permissions() {
        return Permission.query()
          .where(permission => {
            return permission.name.includes("document");
          })
          .get();
      },
    },
    mounted() {
      this.loadingData = true;
      Permission.FetchAll({ page: 1, limit: 999 }).then(() => {
        this.loadingData = false;
      });
      if (this.edit) {
        this.document = this.documentObject;
      }
    },
    methods: {
      submit() {
        if (this.edit) {
          Document.Update(
            {
              name: this.document.name,
              ordinality: this.document.ordinality,
              id: this.document.id,
              folder_id: this.document.folder_id,
              status_id: this.document.status_id,
            },
            true,
          )
            .then(() => {
              this.$buefy.snackbar.open(`Document updated!`);
              this.$emit("close");
            })
            .catch(err => {
              this.handleError(err);
            });
        } else {
          Document.Store({
            name: this.document.name,
            ordinality: this.document.ordinality,
            folder_id: this.document.folder_id,
            status_id: this.document.status_id,
          })
            .then(({ entities: { documents } }) => {
              this.$buefy.snackbar.open(`Document created!`);
              this.$emit("document-created", documents[0].id);
              this.$emit("close");
            })
            .catch(err => {
              this.handleError(err);
            });
        }
      },
    },
    props: {
      documentObject: {
        type: Object,
        default() {
          return null;
        },
      },
      parentId: {
        type: Number,
        default() {
          return null;
        },
      },
      name: {
        type: String,
        default() {
          return "";
        },
      },

      edit: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
  };
</script>
