import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class DashboardApplicationToEnrolmentTotals extends GenexSISModel {
  static entity = "dashboard-application-to-enrolment-totals";

  static fields() {
    return {
      applications: this.attr(null),
      enrolments: this.attr(null),
    };
  }

  static Fetch(query) {
    return this.api().get(`/dashboard/application-enrolment-totals`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { totals } }) => {
        return totals;
      },
    });
  }
}
