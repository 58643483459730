import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An Attendance record
 * @property {number} attendance_name - The name of the attendable
 * @property {object} attendances - attendances for this attendance record
 */
export default class StudentAttendanceRecord extends GenexSISModel {
  static entity = "studentAttendanceRecord";

  static fields() {
    return {
      attendance_name: this.attr(null),
      attendances: this.attr({}),
    };
  }

  /**
   * Fetch Attendance Records
   * @function
   * @param {Object} filters - The attendance record filters
   * @param {string} filters.start_date - the start date of attendances that you want to fetch
   * @param {string} filters.end_date - the end date of attendances that you want to fetch
   * @param {number} filters.user_id - Array of Id's of students that you want to fetch
   */
  static FetchAll(filters = {}) {
    return this.api().get(`/attendances/records`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...filters,
      },
      dataTransformer: ({ data: { data: data } }) => {
        const flattenedData = [];

        for (const collection in data) {
          const attendableCollection = data[collection];

          for (const row in attendableCollection) {
            const attendanceRow = attendableCollection[row];
            flattenedData.push(attendanceRow);
          }
        }

        return flattenedData.map(record => {
          const firstItem = record[Object.keys(record)[0]];

          return {
            attendance_name: firstItem.attendable_model ? firstItem.attendable_model.name : "",
            attendances: record,
          };
        });
      },
    });
  }
}
