import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A user
 * @property {number} id

 */
export default class UserImport extends GenexSISModel {
  static entity = "user-imports";

  static fields() {
    return {
      id: this.uid(),
      response: this.attr(() => {
        return {};
      }),
    };
  }

  /**
   * Upload a staff import batch
   * @function
   * @param {FormData} csv - The import object
   * @returns {Promise<Response>} - Any errors
   */

  static UploadStaff(csv) {
    return this.api().post(`/imports/staff`, csv, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      saved: false,
    });
  }

  /**
   * Upload a staff import batch
   * @function
   * @param {FormData} csv - The import object
   * @returns {Promise<Response>} - Any errors
   */

  static UploadStudents(csv) {
    return this.api().post(`/imports/students`, csv, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      saved: false,
    });
  }
}
