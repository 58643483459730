import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A enrolment
 * @property {number} id
 * @property {number} campus_id - The id of the campus this enrolment belongs to
 * @property {number} enrolment_count - The count of the active enrolments up to a certain date
 */
export default class Enrolment extends GenexSISModel {
  static entity = "date-enrolments";

  static fields() {
    return {
      id: this.attr(null),
      campus_id: this.attr(null),
      enrolment_count: this.attr(null),
    };
  }

  /**
   * Returns all enrolments
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.enrolment_count]
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/dashboard/enrolments/future`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
    });
  }
}
