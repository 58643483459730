import VueCookies from "vue-cookies";

let ACCESS_TOKEN;
let TENANT_ID;

ACCESS_TOKEN = VueCookies.get("access_token") || null;
TENANT_ID = VueCookies.get("tenant_id") || null;

export const AuthModule = {
  namespaced: true,

  state: {
    type: "staff",
    authenticated: false,
    canSwitch: false,
    accessToken: ACCESS_TOKEN,
    tenantId: TENANT_ID,
    lmsTenantId: null,
    needs_to_accept: false,
    terms_updated: false,
    auth_interceptor: null,
    tenant_interceptor: null,
    canAccessGuardianHome: false, //TODO Reset to default
    lmsAccessToken: "",
  },

  actions: {
    login({ commit }, access_token) {
      commit("SET_ACCESS_TOKEN", access_token);
    },
    updateAuthenticated({ commit }, status) {
      commit("SET_AUTHENTICATED", status);
    },
    updateGuardianHomeAccess({ commit }, status) {
      commit("SET_GUARDIAN_HOME_ACCESS", status);
    },
    updateType({ commit }, status) {
      commit("SET_TYPE", status);
    },
    updateSwitch({ commit }, bool) {
      commit("SET_CAN_SWITCH", bool);
    },
    setTenantId({ commit }, tenant_id) {
      commit("SET_TENANT_ID", tenant_id);
    },
  },

  mutations: {
    SET_AUTHENTICATED(state, status) {
      state.authenticated = status;
    },
    SET_ACCESS_TOKEN(state, access_token) {
      state.accessToken = access_token;

      VueCookies.set("access_token", access_token);
    },
    SET_GUARDIAN_HOME_ACCESS(state, status) {
      state.canAccessGuardianHome = status;
    },
    SET_TYPE(state, status) {
      state.type = status;
    },
    SET_CAN_SWITCH(state, bool) {
      state.canSwitch = bool;
    },
    SET_TENANT_ID(state, tenant_id) {
      VueCookies.set("tenant_id", tenant_id);
      state.tenantId = tenant_id;
    },
  },
};
