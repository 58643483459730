import LMSForum from "@/models/lms/LMSForum";
import LMSForumTopic from "@/models/lms/LMSForumTopic";
import LMSForumPost from "@/models/lms/LMSForumPost";

import Router from "@/router/index";

export const ForumModule = {
  namespaced: true,
  state: {
    forum: {},
    errors: [],
    topics: [],
    openTopic: {},
    openTopicId: false,
    hasLoaded: false,
    expandForumDropdown: false,
    chosenGroupId: null,
    gettingData: false,
    chosenTopicId: null,
    chosenPostId: null,
    chosenCommentId: null,
    chosenPost: {
      id: false,
    },
  },

  getters: {},
  mutations: {
    //synchronous
    TOGGLE_FORUM_DROPDOWN(state) {
      state.expandForumDropdown = !state.expandForumDropdown;
    },
    SET_FORUM_DROPDOWN(state, payload) {
      state.expandForumDropdown = payload;
    },
    SET_LOADED(state, payload) {
      state.hasLoaded = payload;
    },
    SET_ERRORS: (state, errors) => {
      state.errors = errors;
    },
    SET_FORUM: (state, forum) => {
      state.forum = forum;
    },
    SET_TOPIC(state, payload) {
      state.openTopicId = payload.id;
      state.openTopic = payload;
    },
    SET_TOPICS(state, payload) {
      state.topics = payload;
    },
    SET_OPEN_TOPIC(state, payload) {
      state.openTopicId = payload;
    },
    SET_OPEN_TOPIC_ID(state, payload) {
      state.openTopicId = payload;
    },
    REMOVE_OPEN_TOPIC(state, payload) {
      if (state.openTopics.includes(payload)) {
        state.openTopics.splice(state.openTopics.indexOf(payload));
      }
    },
  },

  actions: {
    RESET_FORUM({ commit }) {
      commit("SET_FORUM", {});
      commit("SET_OPEN_TOPIC", {});
      commit("SET_OPEN_TOPIC_ID", false);
    },
    goToForum({ state }, payload) {
      LMSForum.getForums(payload).then(result => {
        if (result.data.forums.length == 0) {
          alert("This group has no forum");
        }
        Router.push(`/forum/${result.data.forums[0].id}`);
        state.expandForumDropdown = false;
      });
    },
    toggleForumDropdown({ commit }) {
      commit("TOGGLE_FORUM_DROPDOWN");
    },
    showForumDropdown({ commit }) {
      commit("SET_FORUM_DROPDOWN", true);
    },
    hideForumDropdown({ commit }) {
      commit("SET_FORUM_DROPDOWN", false);
    },
    openTopic({ commit }, payload) {
      commit("SET_OPEN_TOPIC", payload);
    },
    closeTopic({ commit }, payload) {
      commit("REMOVE_OPEN_TOPIC", payload);
    },
    getTopic({ commit }, payload) {
      LMSForumPost.deleteAll();
      this.dispatch("loader/show", "Loading Topic");
      LMSForumTopic.FetchById(payload, ["posts", "posts.user"], ["posts.comments"]).then(response => {
        const topic = response.response.data.data;
        commit("SET_TOPIC", topic);
        this.dispatch("loader/hide");
      });
    },
    //asynchronous
    async GET_FORUM({ commit, state }, payload) {
      commit("SET_LOADED", false);
      return LMSForum.getForum(payload)
        .then(async function (res) {
          await commit("SET_FORUM", res.data.forum);
          await commit("GET_TOPICS", state.forum.id);
        })
        .catch(function () {})
        .then(() => {
          commit("SET_LOADED", true);
        });
    },
    async GET_TOPICS({ commit }, forumId) {
      commit("SET_LOADED", false);
      return LMSForum.FetchById(forumId, ["topics"])
        .then(async function (res) {
          let topics = [];
          const topicsUnrefined = res.response.data.data.attributes.topics;
          for (const topicIndex in topicsUnrefined) {
            topics[topicIndex] = Object.assign(topicsUnrefined[topicIndex], topicsUnrefined[topicIndex].attributes);
          }
          await commit("SET_TOPICS", topics);
        })
        .catch(function () {})
        .then(() => {
          commit("SET_LOADED", true);
        });
    },
    GET_FORUM_FOR_GROUP({ commit, dispatch, state }, lms_group_id) {
      LMSForum.deleteAll();
      LMSForumTopic.deleteAll();
      if (lms_group_id) {
        commit("SET_LOADED", false);
        this.dispatch("loader/show", "Loading forum");
        return LMSForum.FetchAll(
          { page: 1, limit: 200 },
          {
            group_id: lms_group_id,
          },
        )
          .then(async function (res) {
            if (res.response.data.data.length) {
              await commit("SET_FORUM", res.response.data.data[0]);
              await dispatch("GET_TOPICS", state.forum.id);
            }

            this.dispatch("loader/hide");
          })
          .catch(() => {
            commit("SET_LOADED", true);
            this.dispatch("loader/hide");
          });
      }
    },
    GET_FORUM_FOR_COURSE({ commit, dispatch, state }, course_scope_code) {
      LMSForum.deleteAll();
      LMSForumTopic.deleteAll();
      if (course_scope_code) {
        commit("SET_LOADED", false);
        this.dispatch("loader/show", "Loading forum");
        return LMSForum.FetchAll(
          { page: 1, limit: 200 },
          {
            course_scope_code: course_scope_code,
          },
        )
          .then(async function (res) {
            if (res.response.data.data.length) {
              await commit("SET_FORUM", res.response.data.data[0]);
              await dispatch("GET_TOPICS", state.forum.id);
            }

            this.dispatch("loader/hide");
          })
          .catch(() => {
            commit("SET_LOADED", true);
            this.dispatch("loader/hide");
          });
      }
    },
    ADD_POST_TO_TOPIC({ dispatch }, payload) {
      return LMSForumPost.addPost(payload)
        .then(function (res) {
          dispatch("ADD_POST_TO_DOCUMENT", res.data.data);
        })
        .catch(function () {});
    },
  },
};
