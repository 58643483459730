import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Course from "@/models/lms/LMSCourses";
import Module from "@/models/lms/LMSModule";

export default class LMSLesson extends GenexLMSModel {
  static entity = "lms-lesson";

  static fields() {
    return {
      type: this.attr("lessons"),
      id: this.attr(null),
      order: this.attr(null),
      module_id: this.attr(null),
      module: this.belongsTo(Module, "module_id"),
      course_id: this.attr(null),
      course: this.belongsTo(Course, "course_id"),
      is_articulate_rise_lesson: this.attr(false),
      articulate_rise_url: this.attr(null),
      name: this.attr(null),
      content: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      completed: this.attr(0),
      previous_lesson: this.attr(null),
      next_lesson: this.attr(null),
      media: this.attr(null),
      media_ids: this.attr(() => []),
      video_url: this.attr(""),
      further_reading: this.attr(""),
      module_entity: this.attr(null),
    };
  }

  /**
   *
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @param persistBy
   * @returns {Promise | Promise<any>}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.api().get(`/lessons`, {
      persistBy: persistBy,
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });

    // return this.apiWithHeaders().get(`/lessons`, {
    //     persistBy: persistBy,
    //     params: {
    //         ...{
    //             page: page,
    //             limit: limit,
    //             with: relationships,
    //         },
    //         ...(query !== {} ? query : {}),
    //     },
    //     dataTransformer: ({ data: { data } }) => {
    //         return normalize(data);
    //     },
    //
    // });
  }
}
