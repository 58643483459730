import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import ProgressType from "@/models/sis/ProgressType";
import ILPObjective from "@/models/sis/ILPObjective";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPStudentProgress extends GenexSISModel {
  static entity = "ILPStudentProgresses";

  static fields() {
    return {
      id: this.uid(),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      progress: this.attr(null),
      progress_logs: this.attr(null),
      objective_type_id: this.attr(null),
      progress_type: this.belongsTo(ProgressType, "objective_type_id"),
      is_dirty: this.attr(false),
      rollBack_ids: this.attr([]),
      objective_parent_id: this.attr(null),
      objective: this.belongsTo(ILPObjective, "objective_parent_id"),
      last_updated_at: this.attr(null),
    };
  }

  static mutators() {
    return {
      progress(value) {
        if (value) {
          return value;
        }

        return null;
      },
    };
  }
}
