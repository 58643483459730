export default {
  methods: {
    getIfMatchesAllChecks(item, filters) {
      for (let key in filters) {
        if (filters[key] !== null && item[key] !== filters[key]) {
          return false;
        }
      }
      return true;
    },
    getIfMatchesAllChecksStrict(item, filters, requiredFilters = []) {
      for (let key in filters) {
        if (filters[key] !== null && item[key] !== filters[key]) {
          return false;
        } else if (filters[key] == null && requiredFilters.includes(key)) {
          return false;
        }
      }
      return true;
    },
  },
};
