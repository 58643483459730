import VueCookies from "vue-cookies";

export const ChatModule = {
  namespaced: true,
  state: {
    allowChatBubble: false,
    chatScriptLoaded: false,
    // chat_app_id: VueCookies.get('selected_campus_id') || null,
    chat_app_id: VueCookies.get("chat_app_id") || null,
    chat_app_region: VueCookies.get("chat_app_region") || null,
    chat_app_auth_key: VueCookies.get("chat_app_auth_key") || null,
    chat_app_widget_id: VueCookies.get("chat_app_widget_id") || null,
  },
  actions: {
    setChatDetails({ commit }, chatObject) {
      commit("SET_CHAT_APP_ID", chatObject.app_id);
      commit("SET_CHAT_APP_REGION", chatObject.region);
      commit("SET_CHAT_APP_AUTH_KEY", chatObject.auth_key);
      commit("SET_CHAT_APP_WIDGET_ID", chatObject.widget_id);
    },
    removeChatBubble() {
      let bubble = document.getElementById("cometchat__widget");
      if (bubble === null) {
        return;
      }
      bubble.parentNode.removeChild(bubble);
    },
  },
  mutations: {
    SET_CHAT_APP_ID(state, id) {
      VueCookies.set("chat_app_id", id);
      state.chat_app_id = id;
    },
    SET_CHAT_APP_REGION(state, region) {
      VueCookies.set("chat_app_region", region);
      state.chat_app_region = region;
    },
    SET_CHAT_APP_AUTH_KEY(state, auth_key) {
      VueCookies.set("chat_app_auth_key", auth_key);
      state.chat_app_auth_key = auth_key;
    },
    SET_CHAT_APP_WIDGET_ID(state, widget_id) {
      VueCookies.set("chat_app_widget_id", widget_id);
      state.chat_app_widget_id = widget_id;
    },
  },
};
