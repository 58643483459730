<template>
  <div>
    <template v-if="disabled">
      <slot></slot>
    </template>
    <template v-else>
      <b-tooltip :label="label" :position="position">
        <slot></slot>
      </b-tooltip>
    </template>
  </div>
</template>

<script>
  export default {
    name: "GenexDisableableTooltip",
    props: {
      label: {
        type: String,
        default() {
          return "";
        },
      },
      position: {
        type: String,
        default() {
          return "is-top";
        },
      },
      disabled: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
  };
</script>

<style scoped></style>
