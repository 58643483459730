import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of dashboard data
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class AttendanceDashboard extends GenexSISModel {
  static entity = "attendance-dashboard";

  static fields() {
    return {
      attendable_id: this.attr(null),
      attendable_type: this.attr(null),
      name: this.attr(null),
      type_id: this.attr(null),
      users_count: this.attr(null),
      attendances_present: this.attr(0),
      attendances_absent: this.attr(0),
    };
  }

  /**
   * Returns the dashboard
   * @function
   * @param {Object} query
   * @param {number} query.date
   * @param {number} query.campus_id
   * @returns {Promise<Response>}
   */

  static Fetch(query) {
    return this.api().get(`/my-attendances`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        return data.map(dashboard => {
          return { ...dashboard, ...dashboard.attributes };
        });
      },
    });
  }
}
