import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A sports house
 * @property {number} id
 * @property {string} name - The name of the stage
 * @property {number} campus_id - The id of the campus this stage belongs to
 */
export default class SportsHouse extends GenexSISModel {
  static entity = "sportsHouses";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
    };
  }

  /**
   * Returns all sports houses
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/sports-houses`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(house => {
          if (relationships.includes("campus")) {
            Object.assign(house.attributes.campus, house.attributes.campus.attributes);
          }
          return { ...house, ...house.attributes };
        });
      },
    });
  }

  /**
   * Returns a Sports house by its id
   * @function
   * @param {number} id The id of the house
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/sports-houses/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("campus")) {
          Object.assign(data.attributes.campus, data.attributes.campus.attributes);
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new Sports House
   * @function
   * @param {Object} sports_house - The sports_house object
   * @param {string} sports_house.name - The name of the sports_house
   * @param {number} sports_house.campus_id - The id of the campus the sports_house is associated with
   * @returns {Promise<Response>} - The newly created sports_house
   */

  static Store(sports_house) {
    return this.api().post(`/sports-houses`, sports_house, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing sports_house
   * @function
   * @param {Object} sports_house - The sports_house object
   * @param {string} sports_house.name - The name of the sports_house
   * @param {number} sports_house.campus_id - The id of the campus the sports_house is associated with
   * @param {string} sports_house.id - The id of the sports_house
   * @param {boolean} saved - Whether or not to persist the response
   * @returns {Promise<Response>} - The newly created sports_house
   */

  static Update(sports_house, saved) {
    return this.api().patch(`/sports-houses/${sports_house.id}`, sports_house, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing sports_house
   * @function
   * @param {number} sports_house_id - The id of the sports_house
   * @returns {Promise<Response>} - The newly created sports_house
   */

  static Delete(sports_house_id, force = false) {
    let params = "";
    if (force == true) {
      params = "?force=1";
    }
    return this.api().delete(`/sports-houses/${sports_house_id}` + params, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: sports_house_id,
    });
  }
}
