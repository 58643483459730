export const PopoutModule = {
  namespaced: true,
  /**
   * @property {number|null} top
   */
  state: {
    top: null,
    positioned_top: null,
    display: false,
    bottom: null,
    direction: "desc",
    item: null,
    height: 300,
  },

  actions: {
    setPosition({ commit, rootState, state }, payload) {
      commit("SET_HEIGHT", payload);
      if (state.height + state.top > rootState.size.height) {
        commit("SET_DIRECTION", "asc");
        commit("SET_POSITIONED_TOP", state.bottom - state.height);
      } else {
        commit("SET_DIRECTION", "desc");
        commit("SET_POSITIONED_TOP", state.top);
      }
    },
    setItem({ commit }, payload) {
      commit("SET_ITEM", payload);
    },
    setBottom({ commit }, payload) {
      commit("SET_BOTTOM", payload);
    },
    setTop({ commit }, payload) {
      commit("SET_TOP", payload);
    },
    setDisplay({ commit, rootState }, payload) {
      if (rootState.size.viewType === "mobile") {
        return false;
      } else {
        commit("SET_DISPLAY", payload);
      }
    },
    setHeight({ commit }, payload) {
      commit("SET_HEIGHT", payload);
    },
  },
  mutations: {
    SET_POSITIONED_TOP(state, payload) {
      state.positioned_top = payload;
    },
    SET_HEIGHT(state, payload) {
      state.height = payload;
    },
    SET_BOTTOM(state, payload) {
      state.bottom = payload;
    },
    SET_ITEM(state, payload) {
      state.item = payload;
    },
    SET_TOP(state, payload) {
      state.top = payload;
    },
    SET_DISPLAY(state, payload) {
      state.display = payload;
    },
    SET_DIRECTION(state, payload) {
      state.direction = payload;
    },
  },
};
