<template>
  <div>
    <CampusHeader
      v-if="!tenantMode"
      :can-all="true"
      :can-create="false"
      :can-select="true"
      :create-text="'New Ticket'"
      :header-text="'Support Tickets'"
      :manage-campuses="false"
      hide-tabs />
    <section :class="{ 'section pt-4 box': !tenantMode, 'p-0': tenantMode }">
      <b-field v-if="!tenantMode" group-multiline grouped>
        <b-select v-model="tenant_filter" expanded placeholder="Tenant">
          <option :value="null">All</option>
          <option v-for="tenant in tenants" :key="tenant.id" :value="tenant.id">
            {{ tenant.school_name }}
          </option>
        </b-select>
        <b-select v-model="category_filter" expanded placeholder="Category">
          <option :value="null">All</option>

          <option v-for="category of this.$store.state.support.categories" :key="category" :value="category">
            {{ category }}
          </option>
        </b-select>
        <b-select v-model="status_filter" expanded placeholder="Status">
          <option :value="null">All</option>

          <option v-for="status of this.$store.state.support.statuses" :key="status.id" :value="status.id">
            {{ status.name }}
          </option>
        </b-select>
        <!--            <b-select v-model="statusFilter" expanded-->
        <!--                      placeholder="Request Status">-->
        <!--              <option :value="null">All</option>-->
        <!--              <option v-for="status in $store.state.leave.statuses" :key="status.id" :value="status.id">-->
        <!--                {{ status.name }}-->
        <!--              </option>-->
        <!--            </b-select>-->

        <p class="control is-expanded">
          <b-button expanded type="is-primary" @click="clear()"> Clear</b-button>
        </p>
      </b-field>

      <hr v-if="!tenantMode" />
      <b-table
        :bordered="false"
        :data="tickets"
        :default-sort="['created_at', 'desc']"
        :hoverable="true"
        :loading="loadingData"
        :scrollable="true"
        :striped="true"
        backend-sorting
        class="margin-top is-clickable is-fullwidth"
        @click="viewTicket"
        @sort="onSort">
        <template #empty>
          <div class="has-text-centered">
            Do you need some assistance? Log a support ticket and we'll be in touch to help resolve it.
          </div>
        </template>
        <b-table-column v-slot="props" :sortable="tickets.length > 0" field="id" label="ID" numeric width="40"
          >{{ props.row.id }}
        </b-table-column>
        <b-table-column v-slot="props" :sortable="tickets.length > 0" field="reference" label="Reference"
          >{{ props.row.reference }}
        </b-table-column>
        <b-table-column v-slot="props" :sortable="tickets.length > 0" field="status_id" label="Status"
          >{{ props.row.status }}
        </b-table-column>
        <b-table-column v-slot="props" :sortable="tickets.length > 0" field="category" label="Category"
          >{{ props.row.category }}
        </b-table-column>
        <b-table-column v-slot="props" :sortable="tickets.length > 0" field="created_at" label="Logged At"
          >{{ props.row.formatted_date }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :sortable="tickets.length > 0"
          :visible="!tenantMode"
          field="tenant_id"
          label="Tenant"
          >{{ props.row.tenant.school_name }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :sortable="tickets.length > 0"
          :visible="!tenantMode"
          field="user_name"
          label="Name"
          >{{ props.row.user_name }}
        </b-table-column>
        <b-table-column v-slot="props" :visible="!tenantMode" label="Campus"
          >{{ props.row.tenant_campus ? props.row.tenant_campus.attributes.name : "None" }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :sortable="tickets.length > 0"
          :visible="!tenantMode"
          field="campus_id"
          label="Campus ID"
          >{{ props.row.campus_id }}
        </b-table-column>
        <b-table-column
          v-slot="props"
          :sortable="tickets.length > 0"
          :visible="!tenantMode"
          field="user_id"
          label="User ID"
          >{{ props.row.user_id }}
        </b-table-column>

        <b-table-column v-slot="props" :visible="!tenantMode" centered custom-key="actions" label="">
          <div v-on:click.stop>
            <b-dropdown v-if="canDelete" append-to-body aria-role="list" position="is-bottom-left">
              <template #trigger>
                <b-icon icon="more" />
              </template>
              <b-dropdown-item v-if="canDelete" aria-role="listitem" @click="startDelete(props.row)"
                >Delete
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </b-table-column>
      </b-table>
      <b-pagination
        :current="page"
        :per-page="limit"
        :range-after="2"
        :range-before="2"
        :total="meta.total"
        aria-current-label="Current page"
        aria-next-label="Next page"
        aria-page-label="Page"
        aria-previous-label="Previous page"
        class="mt-4"
        v-on:change="setPage"></b-pagination>
    </section>
  </div>
</template>

<script>
  import SupportTicket from "@/models/sis/SupportTicket";
  import SupportTicketForm from "@/components/support/SupportTicketForm";
  import CampusHeader from "@/components/panelled-dash/CampusHeader";
  import Tenant from "@/models/sis/Tenant";

  export default {
    name: "SupportTicketsTable",
    components: {
      CampusHeader,
    },
    data() {
      return {
        search: null,
        loadingFilter: false,
        supportTicketsDataHold: null,
        meta: Object,
        page: 1,
        use_modal: false,
        limit: 20,
        loadingData: false,
        searchHold: null,
        campus_filter: null,
        user_id: this.tenantMode ? this.$store.state.user.id : null,
        tenant_filter: null,
        category_filter: null,
        status_filter: null,
        order_by: "created_at",
        order_direction: "desc",
      };
    },
    props: {
      tenantMode: {
        type: Boolean,
        default() {
          return true;
        },
      },
      canDelete: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canEdit: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canCreate: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      onSort(field, order) {
        this.order_by = field;
        this.order_direction = order;
      },

      clear() {
        this.tenant_filter = null;
        this.category_filter = null;
        this.status_filter = null;
      },
      statusName(id) {
        return this.$store.state.support.statuses.find(status => status.id === id).name ?? "Unknown";
      },
      viewTicket(ticket) {
        if (this.tenantMode) {
          this.setQuery({ ticket_id: ticket.id });

          this.$store.dispatch("support/startEdit", ticket);
        } else {
          this.loadingData = true;

          SupportTicket.FetchById(ticket.id, ["campus", "tenant", "user"]).then(({ entities: { tickets } }) => {
            this.loadingData = false;
            this.setQuery({ ticket_id: ticket.id });
            this.$buefy.modal.open({
              parent: this,
              props: {
                inModal: true,
                tenantMode: false,
                ticket: tickets[0] ?? {},
                edit: true,
              },
              component: SupportTicketForm,
              hasModalCard: true,
              fullScreen: false,
              trapFocus: true,
              canCancel: ["x"],
              onCancel: () => {
                this.$store.state.support.supportWindowOpen = false;
                this.removeQuery(["ticket_id"]);
              },
              events: {
                close: () => {
                  this.$store.state.support.supportWindowOpen = false;
                  this.removeQuery(["ticket_id"]);
                },
              },
            });
          });
        }
      },
      startDelete(ticket) {
        this.$buefy.dialog.confirm({
          title: `Deleting Ticket`,
          confirmText: `Delete ticket`,
          hasIcon: true,
          type: "is-danger",
          message: `Are you sure you want to delete this ticket?`,
          onConfirm: () =>
            SupportTicket.Delete(ticket.id)
              .then(() => {
                this.$buefy.snackbar.open(`Support Ticket deleted!`);
              })
              .catch(err => {
                this.handleError(err);
              }),
        });
      },

      viewSportsHouse(ticket) {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: true,
            ticket: ticket,
            campus_id: this.$store.state.campus.selected_campus_id,
          },
          component: SupportTicketForm,
          hasModalCard: true,
          trapFocus: true,
          canCancel: ["x"],
        });
      },
      getFilteredServices(text) {
        this.search = text;
      },
      setPage(pageNumber) {
        this.loadingData = true;
        this.supportTicketsDataHold = this.tickets;
        SupportTicket.deleteAll();
        this.page = pageNumber;
        SupportTicket.FetchAll(
          {
            page: this.page,
            limit: this.limit,
          },
          this.filters,
        ).then(
          ({
            response: {
              data: { meta = { total: 10 } },
            },
          }) => {
            this.meta = meta;
            this.loadingData = false;
            this.supportTicketsDataHold = null;
          },
        );
      },
    },
    watch: {
      filters() {
        this.setPage(this.page);
      },
    },
    computed: {
      tenants() {
        return Tenant.query().orderBy("name").get();
      },
      filters() {
        return {
          ...(this.search
            ? {
                search: this.search,
              }
            : {}),

          ...(this.campus_filter ? { campus_id: this.campus_filter } : {}),
          ...(this.tenant_filter ? { tenant_id: this.tenant_filter } : {}),
          ...(this.category_filter ? { category: this.category_filter } : {}),
          ...(this.status_filter ? { status_id: this.status_filter } : {}),

          ...(this.user_id ? { user_id: this.user_id } : {}),
          ...(this.order_by ? { order_by: this.order_by } : {}),
          ...(this.order_direction ? { order_direction: this.order_direction } : {}),
        };
      },
      tickets() {
        if (this.supportTicketsDataHold === null) {
          return SupportTicket.query().with("tenant").orderBy(this.order_by, this.order_direction).get();
        } else {
          return this.supportTicketsDataHold;
        }
      },
    },
    async mounted() {
      this.loadingData = true;
      if (!this.tenantMode) {
        await Tenant.FetchAll({ page: 1, limit: 999 });
      }
      this.setPage(this.page, true);
      if (!this.tenantMode && this.$route.query.ticket_id) {
        this.viewTicket({ id: this.$route.query.ticket_id });
      }
    },
  };
</script>
