import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Media from "@/models/sis/Media";
import User from "@/models/sis/User";
import Note from "@/models/sis/Note";
import { format } from "date-fns";

/**
 * An incident
 * @property {number} id
 * @property {number} campus_id
 * @property {number} category_id
 * @property {number} charge_id
 * @property {number} status_id
 * @property {number} disciplinary_action_id
 * @property {string} incident_date
 * @property {string} created_at
 * @property {Object} kpis
 * @property {Array<{user_id:number,involvement_id:number}>} users
 */
export default class Incident extends GenexSISModel {
  static entity = "incidents";

  static fields() {
    return {
      id: this.attr(null),
      campus_id: this.attr(null),
      charge_id: this.attr(null),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      disciplinary_action_id: this.attr(null),
      category_id: this.attr(null),
      status_id: this.attr(null),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      notes: this.hasManyBy(Note, "notes_ids"),
      notes_ids: this.attr([]),
      users: this.attr([]),
      kpis: this.attr({}),
      incident_date: this.attr(null),
      created_at: this.attr(null),
    };
  }

  /**
   * Returns all incidents
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.creator]
   * @param {number} [query.campus]
   * @param {number} [query.category]
   * @param {number} [query.charge]
   * @param {number} [query.disciplinary_action]
   * @param {number} [query.status]
   * @param {number} [query.order_by]
   * @param {number} [query.order_direction]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Incident.FetchAll", `/incidents`, {
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(incident => {
          if (relationships.includes("creator")) {
            if (incident.attributes.creator !== null) {
              Object.assign(incident.attributes.creator, incident.attributes.creator.attributes);
            }
          }
          if (relationships.includes("users")) {
            if (Object.prototype.hasOwnProperty.call(incident.attributes, "users")) {
              incident.attributes.users.map(item => {
                Object.assign(item, item.attributes);
                item.user_id = item.id;
              });
            }
          }
          incident.attributes.created_at = format(new Date(incident.attributes.created_at), "yyyy-MM-dd");

          return { ...incident, ...incident.attributes };
        });
      },
    });
  }

  /**
   * Returns an incident
   * @function
   * @param {number} id - Which incident to retrieve
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.GenexAxiosGet("Incident.FetchById", `/incidents/${id}`, {
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        data.attributes.created_at = format(new Date(data.attributes.created_at), "yyyy-MM-dd");
        if (relationships.includes("users")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "users")) {
            data.attributes.users.map(item => {
              Object.assign(item, item.attributes);
              item.user_id = item.id;
            });
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new incident
   * @function
   * @param {Object} incident - The incident object
   * @returns {Promise<Response>} - The newly created request
   */

  static Store(incident) {
    return this.GenexAxiosPost("Incident.Store", `/incidents`, incident, {
      dataTransformer: ({ data: { data } }) => {
        data.attributes.created_at = format(new Date(data.attributes.created_at), "yyyy-MM-dd");
        if (Object.prototype.hasOwnProperty.call(data.attributes, "users")) {
          data.attributes.users.map(item => {
            Object.assign(item, item.attributes);
            item.user_id = item.id;
          });
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing incident
   * @function
   * @param {Object} incident - The incident object
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static Update(incident, saved) {
    return this.GenexAxiosPatch("Incident.Update", `/incidents/${incident.id}`, incident, {
      dataTransformer: ({ data: { data } }) => {
        data.attributes.created_at = format(new Date(data.attributes.created_at), "yyyy-MM-dd");
        if (Object.prototype.hasOwnProperty.call(data.attributes, "users")) {
          data.attributes.users.map(item => {
            Object.assign(item, item.attributes);
            item.user_id = item.id;
          });
        }
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing incident
   * @function
   * @param {number} incident_id - The id of the incident
   */

  static Delete(incident_id) {
    return this.GenexAxiosDelete("Incident.Delete", `/incidents/${incident_id}`, {
      delete: incident_id,
    });
  }
}
