<template>
  <section class="hero campus-header is-light is-large mb-3">
    <div v-if="showHead" class="hero-head">
      <!--      desktop start-->
      <nav class="navbar pl-4 ml-3 has-text-grey-darker">
        <div class="is-flex is-fullwidth is-justify-content-space-between is-align-items-center">
          <div :class="{ 'skeleton-container': !loaded }" class="is-flex">
            <template v-if="hasBreadCrumbs">
              <GenexBreadCrumbs />
            </template>
            <template v-else>
              <p v-if="loaded" class="is-size-3 has-text-grey-darker">
                {{ showCampus ? CampusData.name : "" }}{{ showCampus && headerText ? " - " : ""
                }}{{ headerText ? `${headerText}` : "" }}
              </p>

              <div v-else style="width: 100%">
                <b-skeleton :animated="true" width="100%"></b-skeleton>
                <b-skeleton :animated="true" width="100%"></b-skeleton>
              </div>
            </template>
            <!--            Todo: Previous non functional Hamburger menu code to be updated-->
            <!--            <a-->
            <!--              :class="{ 'is-hidden-touch': !showMobile }"-->
            <!--              aria-expanded="false"-->
            <!--              aria-label="menu"-->
            <!--              class="navbar-burger"-->
            <!--              role="button"-->
            <!--              @click="showMenu = !showMenu">-->
            <!--              <span aria-hidden="true"></span>-->
            <!--              <span aria-hidden="true"></span>-->
            <!--              <span aria-hidden="true"></span>-->
            <!--            </a>-->
          </div>
          <div id="navbarMenuHeroB" :class="{ 'is-active': showMenu }" class="is-flex">
            <div class="  ">
              <div class="">
                <div class="field is-grouped is-grouped-multiline">
                  <slot></slot>
                  <b-field v-if="canCreate">
                    <b-button
                      icon-left="add"
                      class="has-drop-shadow"
                      data-cy-campus-header="Create New Button"
                      outlined
                      type="is-primary"
                      @click="startCreating">
                      {{ createText }}
                    </b-button>
                  </b-field>

                  <b-field v-if="CampusesData.length > 1 && canSelect && loaded" type="is-primary">
                    <b-select
                      v-model="CampusSelection"
                      class="is-borderless has-text-primary"
                      data-cy-campus-header="Select a Campus"
                      placeholder="Select a campus">
                      <option v-if="canAll" value="All">All</option>
                      <option v-for="campus in CampusesData" :key="campus.id" :value="campus.id">
                        {{ campus.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field v-if="canSelectYear && !isCentral" class="mr-0" type="is-primary">
                    <b-select
                      v-model="year"
                      class="is-borderless has-text-primary"
                      data-cy-campus-header="Select Year"
                      placeholder="Year">
                      <option v-for="year of $store.getters['dates/years']" :key="year.id" :value="year.id">
                        {{ year.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field v-if="canSelectClassGroups" type="is-primary">
                    <!--                    style="width: 200px"-->
                    <SelectClassGroup
                      v-model="ClassGroupSelection"
                      :optionsAllowAll="canAllClassGroups"
                      :filters="{ subject_id: classGroupSubjectId }"
                      search-enabled
                      forceDefault />
                  </b-field>
                  <b-field
                    v-if="
                      canSelectHomeClasses &&
                      $store.state.campus.loadedHomeClasses &&
                      (HomeClasses.length > 1 || canAllHomeClasses)
                    "
                    grouped
                    type="is-primary">
                    <b-field>
                      <b-select
                        v-model="HomeClassSelection"
                        class="is-borderless has-text-primary"
                        placeholder="Select a homeclass">
                        <option v-if="canAllHomeClasses" :value="null">All</option>
                        <option v-for="HomeClass in HomeClasses" :key="HomeClass.id" :value="HomeClass.id">
                          {{ HomeClass.name }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field>
                      <b-button
                        v-if="HomeClassSelection"
                        type="is-primary"
                        @click="downloadHomeClassReports(HomeClassSelection)">
                        Download homeclass
                      </b-button>
                    </b-field>
                  </b-field>

                  <b-field v-if="canSelectWards && Wards.length > 1" type="is-primary">
                    <b-select
                      v-model="WardSelection"
                      class="is-borderless has-text-primary"
                      placeholder="Select a ward">
                      <option v-for="Ward in Wards" :key="Ward.id" :value="Ward.id">
                        {{ Ward.first_name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field v-if="closeButton" class="">
                    <b-button icon-right="cancel" type="is-danger" @click="$emit('close')"> Close</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <!--      mobile start-->
      <nav
        v-if="showMobile"
        :class="{
          'is-hidden-desktop': !showMobile,
          'mb-3': canCreate || (canSelect && loaded) || canSelectClassGroups,
        }">
        <p v-if="loaded" class="navbar-item is-size-3 is-size-4-mobile has-text-grey-darker" style="max-width: 100%">
          {{ showCampus ? CampusData.name : "" }}{{ showCampus && headerText ? " - " : ""
          }}{{ headerText ? `${headerText}` : "" }}
        </p>

        <div>
          <b-field class="unset-group-multiline-margin-bottom" group-multiline grouped position="is-right">
            <slot></slot>
            <b-field v-if="canCreate">
              <b-button
                icon-left="add"
                class="has-drop-shadow"
                data-cy-campus-header="Create New Button - Mobile"
                outlined
                type="is-primary"
                @click="startCreating">
                {{ createText }}
              </b-button>
            </b-field>
            <b-field v-if="CampusesData.length > 1 && canSelect && loaded" type="is-primary">
              <b-select
                v-model="CampusSelection"
                class="is-borderless has-text-primary"
                data-cy-campus-header="Select a Campus - Mobile"
                placeholder="Select a campus">
                <option v-if="canAll" value="All">All</option>
                <option v-for="campus in CampusesData" :key="campus.id" :value="campus.id">
                  {{ campus.name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-if="canSelectWards && Wards.length > 1" type="is-primary">
              <b-select v-model="WardSelection" class="is-borderless has-text-primary" placeholder="Select a ward">
                <option v-for="Ward in Wards" :key="Ward.id" :value="Ward.id">
                  {{ Ward.first_name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-if="canSelectYear && !isCentral" type="is-primary">
              <b-select v-model="$store.state.dates.year" class="is-borderless has-text-primary" placeholder="Year">
                <option v-for="year of $store.getters['dates/years']" :key="year.id" :value="year">
                  {{ year.name }}
                </option>
              </b-select>
            </b-field>

            <b-field v-if="canSelectClassGroups" type="is-primary">
              <SelectClassGroup
                v-model="ClassGroupSelection"
                :optionsAllowAll="canAllClassGroups"
                :filters="{ subject_id: classGroupSubjectId }"
                :forceSelectFirstClassGroup="forceSelectFirstClassGroup"
                search-enabled
                forceDefault />
            </b-field>
            <b-field type="is-primary">
              <slot name="selects"></slot>
            </b-field>
            <b-field
              v-if="
                canSelectHomeClasses &&
                $store.state.campus.loadedHomeClasses &&
                (HomeClasses.length > 1 || canAllHomeClasses)
              "
              type="is-primary">
              <b-select
                v-model="HomeClassSelection"
                class="is-borderless has-text-primary"
                placeholder="Select a homeclass">
                <option v-if="canAllHomeClasses" :value="null">All</option>
                <option v-for="HomeClass in HomeClasses" :key="HomeClass.id" :value="HomeClass.id">
                  {{ HomeClass.name }}
                </option>
              </b-select>
            </b-field>
          </b-field>
        </div>
      </nav>
    </div>
  </section>
</template>

<script>
  import Campus from "@/models/sis/Campus";
  import HomeClass from "@/models/sis/HomeClass";
  import User from "@/models/sis/User";
  import GenexBreadCrumbs from "@/components/global/GenexBreadCrumbs";
  import SelectClassGroup from "@/components/global/selects/sis/SelectClassGroup.vue";
  import GenexHelperMixins from "@/mixins/GenexHelperMixins";
  import Year from "../../models/sis/Year";

  export default {
    name: "CampusHeader",
    components: {
      GenexBreadCrumbs,
      SelectClassGroup,
    },
    data() {
      return {
        loaded: false,
        showMenu: false,
      };
    },
    watch: {
      manageClassGroups(newValue) {
        if (newValue !== true) {
          this.removeQuery(["class_group_id"]);
        }
      },
      "$route.params.class_group_id": {
        handler: function (class_group_id) {
          if (class_group_id) {
            this.ClassGroupSelection = class_group_id;
          }
        },
        deep: true,
        immediate: true,
      },
    },
    created() {
      if (this.manageCampuses && !this.$store.getters["tenant/isCentral"]) {
        Campus.FetchAll({ page: 1, limit: 100 }).then(response => {
          if (response.response.data.data.length > 0) {
            let campusId = parseInt(
              this.$store.state.campus.selected_campus_id !== null
                ? this.$store.state.campus.selected_campus_id
                : response.response.data.data[0].id,
            );
            if (this.$route.query.campus_id) {
              campusId = parseInt(this.$route.query.campus_id);
            }
            if (!Campus.query().whereId(parseInt(campusId)).exists()) {
              campusId = response.response.data.data[0].id;
            }
            this.$store.dispatch("campus/selectCampus", campusId);
            Campus.FetchById(campusId, ["phases"]).then(() => {
              this.loaded = true;
              this.$emit("loaded");
            });
          } else {
            this.noCampuses = true;
            this.$emit("loaded");
            this.loaded = true;
          }
        });
      } else {
        if (!this.noCampusOverride) {
          this.loaded = true;
        }
      }
      if (Year.all().length == 0 || this.$store.getters["dates/years"].length == 0) {
        Year.FetchAll({ page: 1, limit: 999 }).then(() => {
          if (this.canSelectYear && !this.isCentral) {
            let year = this.year ?? this.$store.getters["dates/currentYear"].id;
            if (
              !year &&
              Year.query()
                .where("start", val => new Date(val) <= new Date())
                .where("end", val => new Date(val) >= new Date())
                .exists()
            ) {
              year = Year.query()
                .where("start", val => new Date(val) <= new Date())
                .where("end", val => new Date(val) >= new Date())
                .first().id;
            }
            if (this.$route.query.year) {
              year = this.$store.getters["dates/years"].find(val => val.id == this.$route.query.year);
              if (year) {
                year = year.id;
              } else {
                year = this.$store.getters["dates/currentYear"].id;
              }
            }
            this.year = year;
          }
        });
      } else {
        if (this.canSelectYear && !this.isCentral) {
          let year = this.year ?? this.$store.getters["dates/currentYear"].id;
          if (
            !year &&
            Year.query()
              .where("start", val => new Date(val) <= new Date())
              .where("end", val => new Date(val) >= new Date())
              .exists()
          ) {
            year = Year.query()
              .where("start", val => new Date(val) <= new Date())
              .where("end", val => new Date(val) >= new Date())
              .first().id;
          }
          if (this.$route.query.year) {
            year = this.$store.getters["dates/years"].find(val => val.id == this.$route.query.year);
            if (year) {
              year = year.id;
            } else {
              year = this.$store.getters["dates/currentYear"].id;
            }
          }
          this.year = year;
        }
      }
    },
    computed: {
      routeQueryClassGroupId() {
        return GenexHelperMixins.methods.getNumberFromRouteQuery(this.$route, "class_group_id");
      },
      hasBreadCrumbs() {
        let result = false;
        var ExactMatch = this.$route.matched[this.$route.matched.length - 1];

        if (ExactMatch.meta) {
          const ExactMatchMeta = ExactMatch.meta;
          if (
            typeof ExactMatchMeta.breadcrumbs_ancestry !== "undefined" ||
            typeof ExactMatchMeta.breadcrumbs_ancestry_elaborated !== "undefined"
          ) {
            result = true;
          }
        }
        return result;
      },
      year: {
        get() {
          return this.$store.state.dates.year;
        },
        set(newVal) {
          this.$store.state.dates.year = newVal;
          this.setQuery({ year: newVal });
        },
      },
      Wards() {
        return User.query().whereId(parseInt(this.$store.state.user.id)).with("wards").first()?.wards ?? Array;
      },
      HomeClasses() {
        return HomeClass.query().get();
      },
      CampusesData() {
        if (this.manageCampuses) {
          return Campus.query()
            .where(campus => {
              if (this.showNotOpenForEnrolment === false) {
                return campus.is_open_for_enrolment === 1;
              } else {
                return true;
              }
            })
            .where("name", value => {
              return value !== "";
            })
            .orderBy("ordinality")
            .get();
        }
        return this.campuses;
      },
      CampusData() {
        if (this.manageCampuses) {
          return Campus.query().whereId(this.$store.state.campus.selected_campus_id).first();
        }
        return this.campus;
      },
      CampusSelection: {
        get() {
          return this.CampusData?.id;
        },
        set(id) {
          this.$emit("campus-selected", id);
          if (id !== "All") {
            if (this.manageCampuses) {
              this.setQuery({ campus_id: id });
              this.$store.dispatch("campus/selectCampus", id);
            }
          } else {
            this.removeQuery(["campus_id"]);
            this.$store.dispatch("campus/setAll", true);
          }
        },
      },
      ClassGroupSelection: {
        get() {
          return this.routeQueryClassGroupId;
        },
        set(id) {
          this.setQuery({ class_group_id: id });
        },
      },
      WardSelection: {
        get() {
          return this.$store.state.campus.selected_ward_id;
        },
        set(id) {
          this.$emit("ward-selected", id);
          if (this.canSelectWards) {
            this.setQuery({ ward_id: id });
            this.$store.dispatch("campus/selectWard", id);
          }
        },
      },
      HomeClassSelection: {
        get() {
          return this.$store.state.campus.selected_home_class_id;
        },
        set(id) {
          this.$emit("home-class-selected", id);
          if (this.canSelectHomeClasses) {
            this.$store.dispatch("campus/selectHomeClass", id);
          }
        },
      },
      isCentral() {
        return this.$store.getters["tenant/isCentral"];
      },
    },
    props: {
      canAllClassGroups: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canAllHomeClasses: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canAll: {
        type: Boolean,
        default() {
          return false;
        },
      },
      showMobile: {
        type: Boolean,
        default() {
          return false;
        },
      },

      showHead: {
        type: Boolean,
        default() {
          return true;
        },
      },
      showCampus: {
        type: Boolean,
        default() {
          return false;
        },
      },
      noCampusOverride: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canSelect: {
        type: Boolean,
        default() {
          return true;
        },
      },
      canSelectClassGroups: {
        type: Boolean,
        default() {
          return false;
        },
      },
      forceSelectFirstClassGroup: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canSelectWards: {
        type: Boolean,
        default() {
          return false;
        },
      },

      canSelectHomeClasses: {
        type: Boolean,
        default() {
          return false;
        },
      },
      canSelectYear: {
        type: Boolean,
        default() {
          return false;
        },
      },
      closeButton: {
        type: Boolean,
        default() {
          return false;
        },
      },
      headerText: {
        type: String,
        required: false,
      },
      createText: {
        type: String,
        default() {
          return "New Campus";
        },
      },
      manageCampuses: {
        type: Boolean,
        default() {
          return false;
        },
      },
      manageClassGroups: {
        type: Boolean,
        default() {
          return false;
        },
      },
      manageHomeClasses: {
        type: Boolean,
        default() {
          return false;
        },
      },
      classGroupSubjectId: {
        type: Number,
        default() {
          return null;
        },
      },
      canCreate: {
        type: Boolean,
        default() {
          return false;
        },
      },
      campuses: {
        type: Array,
        default() {
          return [{ name: "No Campus", id: 1 }];
        },
      },
      campus: {
        type: Object,
        default() {
          return { name: "No Campus", id: 1 };
        },
      },
      showNotOpenForEnrolment: { type: Boolean, default: true, required: false },
    },
    methods: {
      startCreating() {
        this.$emit("start-creating");
      },
      downloadHomeClassReports(homeclassId) {
        const homeclassName = this.HomeClasses.filter(homeClass => homeClass.id === homeclassId)[0].name;
        this.$emit("download-home-class-reports", {
          id: homeclassId,
          name: homeclassName,
        });
      },
    },
    mounted() {
      if (this.manageCampuses) {
        this.$router.push({
          query: { ...this.$route.query, campus_id: this.$store.state.campus.selected_campus_id },
          params: this.$route.params,
        });
      }
    },
  };
</script>

<style scoped></style>
