import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import LMSForumTopic from "@/models/lms/LMSForumTopic";
import LMSForumComment from "@/models/lms/LMSForumComment";
import LMSUser from "@/models/lms/LMSUser";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LMSForumPost extends GenexLMSModel {
  static entity = "lms-forum-posts";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      title: this.attr(""),
      content: this.attr(""),
      user_id: this.attr(null),
      user: this.belongsTo(LMSUser, "user_id", "id"),
      forum_topic_id: this.attr(null),
      forum_topic: this.belongsTo(LMSForumTopic, "forum_topic_id"),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      created_at_human: this.attr(""),
      comments_count: this.attr(null),
      comments: this.hasMany(LMSForumComment, "forum_post_id"),
    };
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/forum-posts/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }

  static Store(entity) {
    return this.api().post(`/forum-posts`, entity, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Delete(postId) {
    return this.api().delete(`/forum-posts/${postId}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
    });
  }
}
