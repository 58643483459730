<script setup>
  import { ref } from "vue";
  import { useMathJax } from "@/compositions/useMathjax";

  const props = defineProps({
    content: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: null,
    },
    hideIfNoMath: {
      type: Boolean,
      default: false,
    },
    showAsCard: {
      type: Boolean,
      default: true,
    },
  });

  const view = ref(null);

  const { hasMath, loading } = useMathJax(() => props.content, view);
</script>

<template>
  <div v-if="content">
    <b-loading :is-full-page="false" :active="loading && !hideIfNoMath"></b-loading>
    <div
      ref="view"
      :class="{ box: showAsCard, 'medium-box': showAsCard, 'p-2': true }"
      v-show="!hideIfNoMath || hasMath" />
  </div>
</template>

<style scoped></style>
