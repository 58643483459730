import { GenexSISModel } from "@/models/sis/GenexSISModel";
import ILPStudentProgress from "@/models/sis/ILPStudentProgress";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPObjective extends GenexSISModel {
  static entity = "ILPObjectives";

  static fields() {
    return {
      id: this.uid(),
      name: this.attr(null),
      objective_id: this.attr(null),
      student_progress: this.hasMany(ILPStudentProgress, "objective_parent_id"),
    };
  }
}
