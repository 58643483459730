import mixpanel from "mixpanel-browser";

export default {
  methods: {
    track(name, params = {}) {
      return mixpanel.track(name, params);
    },
    resetTracking() {
      return mixpanel.reset();
    },
  },
};
