import { computed, inject, onBeforeMount, ref } from "vue";
import { useCurrent } from "@/compositions/useCurrent";
import { toNumber } from "lodash";

export const useFeatureFlag = featureFlagName => {
  const flags = inject("featureFlags");
  const flag = computed(() => flags[featureFlagName]);
  const show = ref(false);
  const { tenant_id } = useCurrent();

  onBeforeMount(() => {
    if (!flag.value) {
      return;
    }

    if ("tenant_ids" in flag.value) {
      if (flag["tenant_ids"] !== "all" && !flag.value?.["tenant_ids"].includes(toNumber(tenant_id.value))) {
        return;
      }
    }

    show.value = true;
  });

  return show;
};
