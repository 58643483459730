import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A application
 * @property {number} id
 * @property {string} type
 * @property {string} name
 */
export default class ApplicationStatus extends GenexSISModel {
  static entity = "application-statuses";

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      name: this.attr(null),
    };
  }

  /**
   * Returns all application statuses
   * @function
   * @returns {Promise<Response>}
   */

  static FetchAll() {
    return this.api().get(`/application-statuses`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(status => {
          return { ...status, ...status.attributes };
        });
      },
    });
  }
}
