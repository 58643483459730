import { GenexSISModel } from "./GenexSISModel";
import Phase from "@/models/sis/Phase";
import Stage from "@/models/sis/Stage";
import Subject from "@/models/sis/Subject";
import User from "@/models/sis/User";
import Media from "@/models/sis/Media";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class CampusPublic extends GenexSISModel {
  static entity = "campusesPublic";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      application_emails: this.attr(null),
      head_user: this.belongsTo(User, "head_user_id"),
      head_user_id: this.attr(null),
      ordinality: this.attr(0),
      has_helpdesk: this.attr(0),
      helpdesk_uuid: this.attr(null),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      is_open_for_enrolment: this.attr(0),
      phases: this.hasMany(Phase, "campus_id"),
      stages: this.hasMany(Stage, "campus_id"),
      subjects: this.hasMany(Subject, "campus_id"),
      max_capacity: this.attr(null),
      physical_address: this.attr(() => ({
        line_1: "",
        line_2: "",
        city: "",
        province: "",
        postal_code: "",
        country: "",
      })),
    };
  }

  /**
   * Fetch all campuses
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @returns {*}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/public/campuses`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return GenexHelperMixins.methods.NormalizeRecursive(data);
      },
    });
  }
}
