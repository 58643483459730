import { GenexSISModel } from "@/models/sis/GenexSISModel";
import ILPObjective from "@/models/sis/ILPObjective";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILPUnit extends GenexSISModel {
  static entity = "ILPUnits";

  static fields() {
    return {
      id: this.uid(),
      name: this.attr(null),
      objectives: this.hasManyBy(ILPObjective, "objective_ids"),
      objective_ids: this.attr(() => []),
      isOpen: this.attr(false),
    };
  }
}
