import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Grade from "@/models/sis/Grade";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A user for the marksheet
 * @property {number} id
 */
export default class MarksheetUser extends GenexSISModel {
  static entity = "marksheetUsers";
  static primaryKey = "user_id";

  static fields() {
    return {
      user_id: this.attr(null),
      class_group_id: this.attr(null),
      first_name: this.attr(null),
      last_name: this.attr(null),
      user: this.attr({
        first_name: null,
        last_name: null,
      }),
      // userAssessments: this.attr({}),
      grades: this.hasMany(Grade, "user_id"),
      // assessment_ids:this.attr([])
    };
  }

  /**
   * Returns all marksheet users
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.subject_id] - search by subject_id
   * @param {number} [query.class_group_id] - search by class_group_id
   * @param {number} [query.term_id] - search by term_id
   * @param {number} [query.year] - search by term_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the Reports
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/marksheet-users`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      persistBy: "create",
      dataTransformer: ({ data }) => {
        return Object.values(data).map(user => {
          user.grades = Object.values(user.userAssessments).map(assessment => {
            return {
              user_id: user.user_id,
              id: assessment.grade_id,
              assessment_id: assessment.assessment_id,
              total: assessment.grade_total,
              absent_reason_id: assessment.absent_reason,
              is_absent: assessment.is_absent,
            };
          });
          // user.assessment_ids = user.userAssessments.map(assessment => assessment.assessment_id)
          // user.userAssessments = user.userAssessments.reduce((obj, item) => (obj[item.assessment_id] = item, obj), {});
          user.class_group_id = user.user.class_group_id;

          return { ...user, ...user.user };
        });
      },
    });
  }

  /**
   * Returns a final group
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} id - The id of the group
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(report_id, id, relationships = []) {
    return this.api().get(`/reports/${report_id}/final-groups/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new final group
   * @function
   * @param {Object} finalGroup - The finalGroup object
   * @returns {Promise<Response>} - The newly created group
   */

  static Store(finalGroup) {
    return this.api().post(`/reports/${finalGroup.report_id}/final-groups`, finalGroup, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a final-group
   * @function
   * @param {Object} finalGroup - The finalGroup object
   * @returns {Promise<Response>} - The updated report
   */

  static Update(finalGroup) {
    return this.api().patch(`/reports/${finalGroup.report_id}/final-groups/${finalGroup.id}`, finalGroup, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a final group
   * @function
   * @param {Object} finalGroup - The finalGroup object
   */

  static Delete(finalGroup) {
    return this.api().delete(`/reports/${finalGroup.report_id}/final-groups/${finalGroup.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: finalGroup.id,
    });
  }
}
