import Assessment from "./Assessment";
import User from "./User";
import { normalize } from "@/utils/data";
import AssessmentSubmission from "@/models/sis/AssessmentSubmission";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class AssessmentFeedback extends GenexSISModel {
  static entity = "assessmentFeedbacks";

  static fields() {
    return {
      id: this.attr(null),
      content: this.attr(""),
      assessment_id: this.attr(null),
      assessment_submission_id: this.attr(null),
      creator_id: this.attr(null),
      published_at: this.attr(null),
      is_published: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      assessment: this.belongsTo(Assessment, "assessment_id"),
      assessment_submission: this.belongsTo(AssessmentSubmission, "assessment_submission_id"),
      creator: this.belongsTo(User, "creator_id"),
    };
  }

  /**
   * Returns all AssessmentFeedback
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @param mode
   * @returns {*}
   * @constructor
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get(`/assessment-feedback`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Returns a single AssessmentFeedback
   * @param id
   * @param relationships
   * @returns {*}
   * @constructor
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/assessment-feedback/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   *Stores a new AssessmentFeedback
   * @returns {*}
   * @constructor
   * @param feedback
   */

  static Store(feedback) {
    return this.api().post(`/assessment-feedback`, feedback, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  /**
   * Updates an existing AssessmentFeedback
   * @param feedback
   * @returns {*}
   * @constructor
   */

  static Update(feedback) {
    return this.api().patch(`/assessment-feedback/${feedback.id}`, feedback, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  /**
   * Deletes an existing AssessmentFeedback
   */
  static Delete(feedback_id) {
    return this.api().delete(`/assessment-feedback/${feedback_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: feedback_id,
    });
  }
}
