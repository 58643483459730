import VueCookies from "vue-cookies";
import moment from "moment/moment";

export default {
  methods: {
    isMobileMixin() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    NormalizeRecursive(value) {
      const result = this.NormalizeRecursiveChild(value, null, "attributes");
      return result;
    },
    NormalizeRecursiveChild(value, parent, keyToFlatten) {
      let result = value;
      if (typeof value === "object" && !Array.isArray(value) && value !== null) {
        let flattened = value;
        if (value[keyToFlatten] !== undefined) {
          const valueToFlatten = value[keyToFlatten];
          Object.assign(value, valueToFlatten);
          flattened = value;
        }
        result = {};
        Object.keys(flattened).map(key => {
          if (key !== keyToFlatten) {
            result[key] = this.NormalizeRecursiveChild(flattened[key], value, keyToFlatten);
          }
        });
      } else if (Array.isArray(value)) {
        result = [];
        value.map((value2, key) => {
          result[key] = this.NormalizeRecursiveChild(value2, value, keyToFlatten);
        });
      }
      return result;
    },
    DefaultSISHeaders() {
      const accessToken = VueCookies.get("access_token");
      const tenantId = VueCookies.get("tenant_id");

      let defaultHeaders = {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "Content-Type": "application/json",
      };

      if (tenantId) {
        defaultHeaders["X-Tenant"] = tenantId;
      }

      if (accessToken) {
        defaultHeaders["Authorization"] = "Bearer " + accessToken;
      }

      return defaultHeaders;
    },
    DefaultSISHeadersAndBaseUrl() {
      return {
        baseURL: localStorage.getItem("isCentral")
          ? process.env.VUE_APP_CENTRAL_API_URL
          : process.env.VUE_APP_TENANT_API_URL,
        headers: {
          ...this.DefaultSISHeaders(),
        },
      };
    },
    DefaultLMSHeaders() {
      const lmsAccessToken = VueCookies.get("lmsAccessToken");
      const lmsTenantId = VueCookies.get("lmsTenantId");

      let defaultHeaders = {
        "X-Requested-With": "XMLHttpRequest",
        Accept: "application/json",
        "Content-Type": "application/json",
      };
      if (lmsAccessToken) {
        defaultHeaders["X-Is-LMS-Request"] = 1;
        defaultHeaders["Authorization"] = "Bearer " + lmsAccessToken;
      }

      if (lmsTenantId) {
        defaultHeaders["X-Tenant"] = lmsTenantId;
      }

      return defaultHeaders;
    },
    DefaultLMSHeadersAndBaseUrl() {
      const result = {
        baseURL: process.env.VUE_APP_LMS_API_BASE_URL + "/api/v4",
        headers: {
          ...this.DefaultLMSHeaders(),
        },
      };
      return result;
    },
    collectionCheckValueMatchesField(collection, value, field) {
      const check = collection.filter(item => {
        return item[field] == value;
      }, value);
      return check.length > 0;
    },
    collectionCheckArraysFirstValueMatchesField(collection, array, field) {
      if (array.length !== 0) {
        const collectionCheckValueMatchesField = this.collectionCheckValueMatchesField(collection, array[0], field);
        if (collectionCheckValueMatchesField) {
          return true;
        }
      }
      return false;
    },
    getNumberFromRouteQuery(route, key) {
      let result = null;
      if (route?.query && route.query[key] !== undefined && !isNaN(route.query[key])) {
        result = +route.query[key];
      }
      return result;
    },
    getHumanReadableDate(theMoment) {
      let value = null;
      const updateMoment = moment(new Date(theMoment));
      const currMoment = moment();
      const diff = moment.duration(updateMoment.diff(currMoment));
      if (diff.asWeeks() > -1) {
        value = updateMoment.fromNow();
      } else {
        value = updateMoment.format("llll");
      }
      value = value.charAt(0).toUpperCase() + value.slice(1);
      return value;
    },
  },
};
