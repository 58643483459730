import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Service from "@/models/sis/Service";
import Campus from "@/models/sis/Campus";
import Phase from "@/models/sis/Phase";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A package
 * @property {Number} id
 * @property {Number} campus_id
 * @property {String} name - The name of the package
 * @property {String} description - The description of the package
 * @property {Array.<Object>} services - Services contained in the package
 * @property {Array.<Number>} service_ids - The relational array of ids
 * @property {Boolean} is_open - Whether this service can be signed up to in the applications flow
 */
export default class Package extends GenexSISModel {
  static entity = "packages";

  static fields() {
    return {
      id: this.attr(null),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      name: this.attr(""),
      description: this.attr(""),
      cost: this.attr(null),
      services: this.hasManyBy(Service, "service_ids"),
      service_ids: this.attr([]),
      phases: this.hasManyBy(Phase, "phase_ids"),
      phase_ids: this.attr([]),
      is_open: this.attr(0),
      year: this.attr(null),
      ordinality: this.attr(0),
      order_index: this.attr(0),
      moved: this.attr(0),
    };
  }

  /**
   * Returns all packages
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.campus_id]
   * @param {string} [query.search]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 9999 }, query = {}, relationships = []) {
    return this.api().get(`/packages`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(item => {
          if (relationships.includes("media")) {
            item.attributes.media.map(item => {
              Object.assign(item, item.attributes);
            });
          }

          if (relationships.includes("services")) {
            item.attributes.services.map(service => {
              if (relationships.includes("services.media")) {
                if (Object.prototype.hasOwnProperty.call(service.attributes, "media")) {
                  service.attributes.media.map(item => {
                    Object.assign(item, item.attributes);
                  });
                }
              }
              service.in_package = 1;
              Object.assign(service, service.attributes);
            });
          }
          if (relationships.includes("phases")) {
            if (Object.prototype.hasOwnProperty.call(item.attributes, "phases")) {
              item.attributes.phases.map(phase => {
                Object.assign(phase, phase.attributes);
              });
            }
          }

          if (item.attributes.ordinality) {
            item.attributes.order_index = item.attributes.ordinality;
          }
          return { ...item, ...item.attributes };
        });
      },
    });
  }

  /**
   * Store a new package
   * @function
   * @param {Object} item - The package object
   * @param {string} item.name - The name of the package
   * @param {number} item.campus_id - The id of the campus the package is associated with
   * @param {string} item.description
   * @param {Boolean} item.is_open
   * @param {Array<number>} item.services
   * @returns {Promise<Response>} - The newly created service
   */

  static Store(item) {
    return this.api().post(`/packages`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Order the subjects
   * @function
   * @param {number} package_id - The package
   * @param {Array} packages - The ordering objects
   * @returns {Promise<Response>} - The newly ordered packages
   */

  static Order(packages) {
    return this.api().patch(
      `/packages/ordinality`,
      { packages: packages },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        persistBy: "update",
        dataTransformer: ({ data: { data } }) => {
          return data.map(item => {
            return {
              id: item.id,
              ordinality: item.attributes.ordinality,
              order_index: item.attributes.ordinality,
            };
          });
        },
      },
    );
  }

  /**
   * Unsubscribe a package
   * @function
   * @param {number} user_id - The user_id
   * @param {number} package_id - The id of the package
   * @param {Object} item - The package object
   * @param {?string} item.unsubscribe_at - The date to unsubscribe at
   * @param {Boolean} item.force
   * @returns {Promise<Response>} - The unsubscribed package
   */

  static Unsubscribe(user_id, package_id, item) {
    return this.api().post(`/users/${user_id}/packages/${package_id}/unsubscribe`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a  package
   * @function
   * @param {Object} item - The package object
   * @param {string} item.name - The name of the package
   * @param {number} item.campus_id - The id of the campus the package is associated with
   * @param {number} item.id - The id of the package
   * @param {string} item.description
   * @param {Boolean} item.is_open
   * @param {Array<number>} item.services
   * @returns {Promise<Response>} - The newly created service
   */

  static Update(item) {
    return this.api().patch(`/packages/${item.id}`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Returns a package by its id
   * @function
   * @param {Object} item
   * @param {number} item.id The id of the package
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(item, relationships = []) {
    return this.api().get(`/packages/${item.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("media")) {
          data.attributes.media.map(item => {
            Object.assign(item, item.attributes);
          });
        }

        if (relationships.includes("services")) {
          data.attributes.services.map(service => {
            if (relationships.includes("services.media")) {
              service.attributes.media.map(item => {
                Object.assign(item, item.attributes);
              });
            }
            Object.assign(service, service.attributes);
          });
        }
        if (relationships.includes("phases")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "phases")) {
            data.attributes.phases.map(phase => {
              Object.assign(phase, phase.attributes);
            });
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an existing package
   * @function
   * @param {number} package_id - The id of the package
   * @returns {Promise<Response>} - service
   */

  static Delete(package_id) {
    return this.api().delete(`/packages/${package_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: package_id,
    });
  }
}
