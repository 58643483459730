<template>
  <b-menu-item :active="isActive" :expanded="isExpanded" @click="toggle">
    <template #label="props">
      <div :class="{ handle: canEdit }" class="is-flex is-align-items-center">
        <div class="is-flex is-align-items-center mr-auto">
          <b-icon :icon="props.expanded ? 'folder-open' : 'folder'" class="mr-1"></b-icon>
          <!--          </div>-->
          <!--          <div class="level-item item-name-container">-->
          <span class="has-text-black is-flex is-align-items-center">{{ item.name }}</span>
        </div>

        <!-- Right side -->

        <b-dropdown
          v-if="hasPermission(['create folders']) || hasPermission(['create documents'])"
          ref="info-hub-dropdown"
          :position="'is-bottom-left'"
          append-to-body
          aria-role="list"
          @active-change="onActiveChange"
          @click.native.stop>
          <div slot="trigger" class="">
            <b-icon icon="more-vertical"></b-icon>
          </div>

          <b-dropdown-item v-if="hasPermission(['create folders'])" aria-role="listitem" @click="startCreateFolder">
            <div class="media">
              <b-icon icon="folder-add" class="media-left"></b-icon>
              <div class="media-content">
                <h3>Create a folder</h3>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="hasPermission(['edit folders'])" aria-role="listitem" @click="startDeleteFolder">
            <div class="media">
              <b-icon icon="folder-cross" class="media-left"></b-icon>
              <div class="media-content">
                <h3>Delete</h3>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="hasPermission(['delete folders'])" aria-role="listitem" @click="startEditFolder">
            <div class="media">
              <b-icon icon="folder-edit" class="media-left"></b-icon>
              <div class="media-content">
                <h3>Edit</h3>
              </div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="hasPermission(['create documents'])" @click="startCreateDocument">
            <div class="media">
              <b-icon icon="document-edit" class="media-left"></b-icon>
              <div class="media-content">
                <h3>Create a document</h3>
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <!--          <div class="level-item">-->
      <!--            <b-icon :icon="props.expanded ? 'menu-down' : 'menu-up'" class="is-pulled-right"></b-icon>-->

      <!--          </div>-->
    </template>
    <draggable
      v-model="orderedItems"
      :group="{ name: 'documentation', put: canEdit, pull: canEdit }"
      handle=".handle"
      style="min-height: 30px"
      tag="div">
      <component
        v-bind:is="item.component"
        v-for="item in orderedItems"
        :key="item.id + item.component"
        :item="item"></component>
      <!--    <b-menu-item icon="account" label="Users"></b-menu-item>-->
      <!--    <b-menu-item icon="cellphone-link">-->
      <!--      <template #label>-->
      <!--        Devices-->
      <!--        <b-dropdown aria-role="list" class="is-pulled-right" position="is-bottom-left">-->
      <!--          <template #trigger>-->
      <!--          TEST ICON WHEN YOU UNCOMMENT -->
      <!--            <b-icon icon="more-vertical"></b-icon>-->
      <!--          </template>-->
      <!--          <b-dropdown-item aria-role="listitem">Action</b-dropdown-item>-->
      <!--          <b-dropdown-item aria-role="listitem">Another action</b-dropdown-item>-->
      <!--          <b-dropdown-item aria-role="listitem">Something else</b-dropdown-item>-->
      <!--        </b-dropdown>-->
      <!--      </template>-->
      <!--    </b-menu-item>-->
    </draggable>
  </b-menu-item>
</template>

<script>
  import Folder from "@/models/sis/Folder";
  import Document from "@/models/sis/Document";
  import FolderForm from "@/components/documents/FolderForm";
  import DocumentForm from "@/components/documents/DocumentForm";
  import DocumentMenuItem from "@/components/documents/DocumentMenuItem";
  import draggable from "vuedraggable";

  export default {
    name: "FolderMenuItem",

    data() {
      return {
        loading: false,
      };
    },
    components: { DocumentMenuItem, draggable },
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      toggle() {
        this.$store.state.documentation.active_folder = this.item.id;
        this.isExpanded = !this.isExpanded;
      },
      deleteFolder(id) {
        Folder.Delete(id)
          .then(() => {
            this.$buefy.snackbar.open(`folder deleted!`);
          })
          .catch(err => {
            this.handleError(err);
          });
      },
      startDeleteFolder() {
        // TO DO: - One needs to be able to delete all sub folders from parent folder.
        this.$buefy.dialog.confirm({
          title: `Deleting folder`,
          confirmText: `Delete folder`,
          hasIcon: true,
          type: "is-danger",
          message: `Are you sure you want to delete this folder?`,
          onConfirm: () => {
            this.deleteFolder(this.item.id);
          },
        });
      },
      startCreateFolder() {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            parentId: this.item.id,
          },
          component: FolderForm,
          hasModalCard: false,
          trapFocus: true,
          canCancel: ["x"],
        });
      },
      startCreateDocument() {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: false,
            parentId: this.item.id,
          },
          component: DocumentForm,
          hasModalCard: false,
          trapFocus: true,
          canCancel: ["x"],
          events: {
            "document-created": id => {
              this.$store.dispatch("documentation/startEditing", id);
            },
          },
        });
      },
      startEditFolder() {
        this.$buefy.modal.open({
          parent: this,
          props: {
            inModal: true,
            edit: true,
            folderObject: this.item,
          },
          component: FolderForm,
          hasModalCard: false,
          trapFocus: true,
          canCancel: ["x"],
        });
      },
    },
    watch: {
      isExpanded(val) {
        if (val === true) {
          this.loading = true;
          Folder.FetchAll({ page: 1, limit: 999 }, { parent: this.item.id }, ["documents"]).then(() => {
            this.loading = false;
          });
        }
      },
    },
    computed: {
      types() {
        return this.item.permissions_ids.map(name => {
          return name.substr(name.indexOf(" ") + 1);
        });
      },
      canEdit() {
        return this.types.some(type => {
          return this.hasPermission(["edit " + type]);
        });
      },
      isExpanded: {
        get() {
          return this.$store.state.documentation.expanded_folders.includes(this.item.id);
        },
        set(newVal) {
          if (newVal === true) {
            this.$store.dispatch("documentation/expandFolder", this.item.id);
          } else {
            this.$store.dispatch("documentation/collapseFolder", this.item.id);
          }
        },
      },
      isActive() {
        return this.$store.state.documentation.active_folder === this.item.id;
      },
      documents() {
        return Document.query()
          .where("folder_id", this.item.id)
          .where(document => {
            if (this.$store.state.documentation.show_published) {
              if (document.status_id === 2) {
                return true;
              }
            }
            return !!(
              this.$store.state.documentation.show_drafts &&
              document.status_id === 1 &&
              this.hasPermission(["create documents"])
            );
          })
          .get();
      },
      childFolders() {
        return Folder.query().where("parent_id", this.item.id).with("permissions").get();
      },
      orderedItems: {
        get() {
          let items = [...this.documents, ...this.childFolders];
          items.sort((a, b) => a.ordinality - b.ordinality);
          return items;
        },
        set(evt) {
          let promises = [];

          evt.map((currentelement, index) => {
            currentelement.ordinality = index;

            if (currentelement.type === "folders") {
              currentelement.parent_id = this.item.id;
              Folder.update(currentelement);
              promises.push(
                Folder.Update({
                  name: currentelement.name,
                  id: currentelement.id,
                  parent_id: this.item.id,
                  ordinality: currentelement.ordinality,
                }),
              );
            }
            if (currentelement.type === "documents") {
              currentelement.folder_id = this.item.id;
              Document.update(currentelement);
              promises.push(
                Document.Update({
                  name: currentelement.name,
                  id: currentelement.id,
                  folder_id: this.item.id,
                  ordinality: currentelement.ordinality,
                }),
              );
            }
          });
          if (promises.length > 0) {
            this.loading = true;
            Promise.all(promises).then(() => {
              this.loading = false;
            });
          }
        },
      },
    },
    mounted() {},
  };
</script>
