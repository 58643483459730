import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import Role from "@/models/sis/Role";
import Invite from "@/models/sis/Invite";
// import Unit from "@/models/Unit";
// import UnitObjective from "@/models/UnitObjective";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An invite user
 * @property {number} id
 */
export default class InviteUser extends GenexSISModel {
  static entity = "invite_users";

  static fields() {
    return {
      id: this.attr(null),
      email: this.attr(null),
      invite_id: this.attr(null),
      invite: this.belongsTo(Invite, "invite_id"),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      is_accepted: this.attr(null),
      role: this.belongsTo(Role, "role_id"),
      role_id: this.attr(null),
    };
  }

  /**
   * Returns all Invite Users
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} query
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the CourseScopeTopics
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/invite-users`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(object => {
          if (relationships.includes("user")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "user")) {
              if (object.attributes.user !== null) {
                Object.assign(object.attributes.user, object.attributes.user.attributes);
              }
            }
          }
          if (relationships.includes("role")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "role")) {
              if (object.attributes.role !== null) {
                Object.assign(object.attributes.role, object.attributes.role.attributes);
              }
            }
          }
          if (relationships.includes("invite")) {
            if (Object.prototype.hasOwnProperty.call(object.attributes, "invite")) {
              if (object.attributes.invite !== null) {
                Object.assign(object.attributes.invite, object.attributes.invite.attributes);
              }
            }
          }

          return { ...object, ...object.attributes };
        });
      },
    });
  }

  /**
   * Returns an Invite User
   * @function
   * @param {number} id - The id of the Invite User
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/invite-users/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "user")) {
            if (data.attributes.user !== null) {
              Object.assign(data.attributes.user, data.attributes.user.attributes);
            }
          }
        }
        if (relationships.includes("role")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "role")) {
            if (data.attributes.role !== null) {
              Object.assign(data.attributes.role, data.attributes.role.attributes);
            }
          }
        }
        if (relationships.includes("invite")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "invite")) {
            if (data.attributes.invite !== null) {
              Object.assign(data.attributes.invite, data.attributes.invite.attributes);
            }
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new inviteUser
   * @function
   * @param {Object} inviteUser - The inviteUser object
   * @returns {Promise<Response>} - The newly created inviteUser
   */

  static Store(inviteUser) {
    return this.api().post(`/invite-users`, inviteUser, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update a inviteUser
   * @function
   * @param {Object} inviteUser - The inviteUser object
   * @returns {Promise<Response>} - The updated inviteUser
   */

  static Update(inviteUser) {
    return this.api().patch(`/invite-users/${inviteUser.id}`, inviteUser, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an inviteUser
   * @function
   * @param {number} id - The id of the inviteUser
   */

  static Delete(id) {
    return this.api().delete(`/invite-users/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
