import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";

export default class LMSLessonReflections extends GenexLMSModel {
  static entity = "lms-courses";

  /**
   *
   * @returns {{id: *, display_name: *, modules: *}}
   */
  static fields() {
    return {
      id: this.attr(null),
      display_name: this.attr(null),
      course_scope_code: this.attr(null),
      modules: this.attr(null),
      percentage: this.attr(null),
    };
  }

  /**
   *
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @param persistBy
   * @returns {Promise|Promise<any>|*}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 999 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.apiWithHeaders().get(`/courses`, {
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static FetchById(courseId, query = {}, relationships = []) {
    return this.apiWithHeaders().get(`/courses/${courseId}`, {
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
