import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An ILP progress type
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ProgressType extends GenexSISModel {
  static entity = "ProgressTypes";

  static primaryKey = "id";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      ordinality: this.attr(null),
      symbol: this.attr(null),
    };
  }

  /**
   * Returns all progress types
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/progress-type`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(type => {
          return { ...type, ...type.attributes };
        });
      },
    });
  }
}
