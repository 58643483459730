import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * The grades for users by assessment by term
 * @property {number} term_id
 * @property {number} report_id
 * @property {number} subject_id
 * @property {number} lowest
 * @property {number} highest
 * @property {string} symbol
 * word
 */
export default class ReportSubjectAssessmentGrades extends GenexSISModel {
  static entity = "reportSubjectAssessmentGrades";
  static primaryKey = "term_id";

  static fields() {
    return {
      term_id: this.attr(null),
      assessments: this.attr(null),
    };
  }

  /**
   * Returns totals
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} subject_id - The id of the subject
   * @param {number} class_group_id - The id of the class
   * @param include_mark
   * @returns {Promise<Response>} the Topic
   */

  static FetchAll(report_id, subject_id, class_group_id, include_mark) {
    return this.api().get(`reports/${report_id}/subjects/${subject_id}/assessments-grades`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        class_group_id,
        include_mark,
      },
      persistBy: "create",
      dataTransformer: ({ data }) => {
        let assessmentGrades = data.map(term => {
          let termAssessments = {};
          termAssessments.assessments = term.assessments.map(assessment => {
            let response = "N/A";
            switch (assessment.type_id) {
              case 1:
                response = "Task";
                break;
              case 2:
                response = "Project";
                break;
              case 3:
                response = "Formal";
                break;
              case 4:
                response = "Exam";
                break;
            }
            assessment.assessment_type = response;
            return assessment;
          });

          termAssessments.term_id = term.term_id;
          return termAssessments;
        });
        return assessmentGrades;
      },
    });
  }
}
