import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";

/**
 * A Report Template
 * @property {number} id

 */
export default class ReportTemplateLayout extends GenexSISModel {
  static entity = "report-template-layouts";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      description: this.attr(null),
    };
  }

  /**
   * Returns all Report Template Layouts
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }) {
    return this.api().get(`/report-template-layouts`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
