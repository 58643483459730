<template>
  <form @submit.prevent="submit">
    <b-field>
      <GenexMathEditor
        :model-id="form?.id"
        :model="model_type"
        v-model="form.note"
        :disabled="!permissions.can_create"
        :placeholder="this.placeholder" />
    </b-field>
    <b-field>
      <b-button
        :disabled="!permissions.can_create || disabled_as_required"
        :loading="savingNote"
        class="margin-top"
        type="is-primary"
        @click="saveNote"
        >{{ submit_text }}
      </b-button>
    </b-field>

    <b-loading :active.sync="savingNote" :is-full-page="false"></b-loading>
  </form>
</template>

<script>
  import Note from "@/models/sis/Note";
  import GenexMathEditor from "@/components/froala/GenexMathEditor.vue";

  export default {
    name: "NoteForm",
    components: { GenexMathEditor },
    props: {
      isCentral: {
        type: Boolean,
        default() {
          return false;
        },
      },
      is_internal: {
        type: Boolean,
        default() {
          return false;
        },
      },
      required: {
        type: Boolean,
        default() {
          return false;
        },
      },
      submit_text: {
        type: String,
        default() {
          return "Submit Note";
        },
      },
      model_type: { type: String, required: true },
      placeholder: {
        type: String,
        default() {
          return "An optional description";
        },
      },
      model_id: { type: Number, required: true },
      permissions: {
        type: Object,
        default: () => ({
          can_delete: false,
          can_create: false,
          can_edit: false,
        }),
      },
      action: {
        type: String,
        default: "create",
      },
    },
    mounted() {},
    data() {
      return {
        loaded: false,
        savingNote: false,
        form: {
          note: "",
          is_internal: this.is_internal ? 1 : 0,
          user_id: this.$store.state.user.id,
        },
      };
    },
    computed: {
      disabled_as_required() {
        return this.required && this.form.note.length === 0;
      },
    },
    // watch: {
    //   activity() {
    //     this.form = this.activity;
    //   },
    // },
    methods: {
      submit() {
        this.saveNote();
      },
      saveNote() {
        this.$emit("noteSubmitted");
        this.savingNote = true;
        setTimeout(() => {
          Note.Store(this.form, { type: this.model_type, id: this.model_id }, this.isCentral)
            .then(() => {
              this.form = {
                note: "",
                is_internal: this.is_internal ? 1 : 0,
                user_id: this.$store.state.user.id,
              };
              this.savingNote = false;
              this.$buefy.snackbar.open("Note created!");
            })
            .catch(err => {
              this.handleError(err);
              this.savingNote = false;
            });
        }, 300);
      },
      // saveEdit() {
      //   ApplicationNote.update({
      //     where: this.noteBeingEdited.id,
      //     data: {note: this.noteBeingEdited.note},
      //   });
      //   ApplicationNote.updateRemote(this.noteBeingEdited);
      //   this.noteBeingEdited = Object;
      // },
      // editNote(note) {
      //   if (
      //       (note.user_id == this.$store.state.user.id ||
      //           this.administrate_notes == true) &&
      //       this.edit_notes
      //   ) {
      //     this.noteBeingEdited = note;
      //     return;
      //   }
      //   this.$store.dispatch("toast/createToast");
      // },
    },
  };
</script>
