import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of dashboard data
 * @property {number} id unique identifier
 * @property {string} campus_id - id of the campus
 * @property {string} campus - the campus
 * @property {string} applications - number of applications
 * @property {Array<number>} enrolments - number of enrolments
 * @property {Array<Object>} conversion - conversion rate
 */
export default class DashboardApplicationToEnrolment extends GenexSISModel {
  static entity = "dashboard-application-to-enrolment";

  static fields() {
    return {
      id: this.uid(),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      applications: this.attr(null),
      enrolments: this.attr(null),
      conversion: this.attr(null),
    };
  }

  /**
   * Returns the lesson plan engagement subjects for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @returns {Promise<Response>}
   */

  static Fetch(query) {
    return this.api().get(`/dashboard/conversions-by-campus`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { conversion_by_campus } }) => {
        return conversion_by_campus;
      },
    });
  }
}
