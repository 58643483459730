import { GenexSISModel } from "@/models/sis/GenexSISModel";
import WithdrawalReason from "@/models/sis/WithdrawalReason";

/**
 * A collection of dashboard waiting stages
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardAdmissionsWithdrawn extends GenexSISModel {
  static entity = "dashboard-admissions-withdrawn";
  static primaryKey = "reason_id";

  static fields() {
    return {
      reason: this.belongsTo(WithdrawalReason, "reason_id"),
      reason_id: this.attr(null),
      count: this.attr(null),
    };
  }
}
