import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import Subject from "@/models/sis/Subject";
import Campus from "@/models/sis/Campus";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import Folder from "@/models/sis/Folder";
import JournalEntry from "@/models/sis/JournalEntry";
import Enrolment from "@/models/sis/Enrolment";

/**
 * A classGroup
 * @property {number} id
 * @property {string} name - The name of the classGroup
 * @property {number} subject_id - The id of the subject the class is associated with
 * @property {Array<number>} teachers_ids - The id of the teachers associated with this class
 * @property {number} campus_id - The id of the campus this class belongs to
 */
export default class ClassGroup extends GenexSISModel {
  static entity = "classGroups";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      subject_id: this.attr(null),
      subject: this.belongsTo(Subject, "subject_id"),
      teachers_ids: this.attr(() => []),
      teachers: this.hasManyBy(User, "teachers_ids"),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      primary_teacher_id: this.attr(null),
      primary_teacher: this.belongsTo(User, "primary_teacher_id"),
      lms_group_id: this.attr(""),
      journal_entries: this.hasMany(JournalEntry, "class_group_id"),
      enrolment_ids: this.attr(() => []),
      enrolments: this.hasManyBy(Enrolment, "enrolment_ids"),
      folders: this.morphMany(Folder, "folderable_id", "folderable_type"),
    };
  }

  /**
   * Returns all classGroups
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {number} [query.subject_id]
   * @param {string} [query.search]
   * @param {string} mode - how to persist the response
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get(`/class-groups`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        const result = data.map(classGroup => {
          const result = GenexHelperMixins.methods.NormalizeRecursive(classGroup);
          if (result) {
            if (Object.prototype.hasOwnProperty.call(result, "teachers")) {
              result.teachers.map(teacher => {
                if (teacher.is_default_primary === 1) {
                  result.primary_teacher_id = teacher.id;
                }
              });
            }
          }
          return result;
        });
        return result;
      },
    });
  }

  /**
   * Returns a classGroup by its id
   * @function
   * @param {number} id The id of the classGroup
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/class-groups/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        let result = GenexHelperMixins.methods.NormalizeRecursive(data);
        if (result) {
          if (Object.prototype.hasOwnProperty.call(result, "teachers")) {
            result.teachers.map(teacher => {
              if (teacher.is_default_primary === 1) {
                result.primary_teacher_id = teacher.id;
              }
            });
          }
        }
        return result;
      },
    });
  }

  /**
   * Store a new classGroup
   * @function
   * @param {Object} classGroup - The class object
   * @param {string} classGroup.name - The name of the classs
   * @param {number} classGroup.campus_id - The id of the campus the class is associated with
   * @param {number} classGroup.subject_id - The id of the subject the class is associated with
   * @returns {Promise<Response>} - The newly created class
   */

  static Store(classGroup) {
    return this.api().post(`/class-groups`, classGroup, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing classGroup
   * @function
   * @param {Object} classGroup - The class object
   * @param {string} classGroup.name - The name of the classs
   * @param {number} classGroup.campus_id - The id of the campus the class is associated with
   * @param {number} classGroup.subject_id - The id of the subject the class is associated with
   * @returns {Promise<Response>} - The newly created class
   */

  static Update(classGroup) {
    return this.api().patch(`/class-groups/${classGroup.id}`, classGroup, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete an existing classGroup
   * @function
   * @param {number} classGroup_id - The id of the classGroup
   * @returns {Promise<Response>} -
   */

  static Delete(classGroup_id) {
    return this.api().delete(`/class-groups/${classGroup_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: classGroup_id,
    });
  }
}
