import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A grade
 * @property {number} id
 * @property {number} assessment_id - The id of the assessment this grade is marking
 * @property {number} user_id - -The user that is being marked
 * @property {number} total - the number of marks that the grade scored
 * @property {number} is_absent
 * @property {number} absent_reason_id - ABSENT_REASON_SICK =1 ABSENT_REASON_FAMILY =2 ABSENT_REASON_SUSPENDED =3 ABSENT_REASON_LATE_ENROLMENT=4;
 */
export default class Grade extends GenexSISModel {
  static entity = "grades";
  static primaryKey = ["user_id", "assessment_id"];

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      assessment_id: this.attr(null),
      total: this.attr(null),
      is_absent: this.attr(0),
      absent_reason_id: this.attr(null),
    };
  }

  /**
   * Returns all grades
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.type_id] - the type of the assessment cass=1 exam =2
   * @param {number} [query.term_id]
   * @param {Array.<number>} [query.assessment_id]
   * @param {?string} [persistBy]

   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.api().get(`/grades`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: persistBy,

      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(grade => {
          if (relationships.includes("user")) {
            Object.assign(grade.attributes.user, grade.attributes.user.attributes);
          }
          return { ...grade, ...grade.attributes };
        });
      },
    });
  }

  /**
   * Returns a grade by its id
   * @function
   * @param {number} id The id of the grade
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/grades/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        with: relationships,
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("users")) {
          data.attributes.users.map(user => {
            Object.assign(user, user.attributes);
          });
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new grade
   * @function
   * @param {Object} grade - The grade object
   * @param {number} grade.user_id - The id of the user the grade is associated with
   * @param {number} grade.assessment_id - The id of the assessment the grade is associated with
   * @param {string} grade.total - The total marks of the grade
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static Store(grade) {
    return this.api().post(`/grades`, grade, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing grade
   * @function
   * @param {Object} grade - The grade object
   * @param {number} grade.id - The id of the grade
   * @param {number} grade.user_id - The id of the user the grade is associated with
   * @param {number} grade.assessment_id - The id of the assessment the grade is associated with
   * @param {string} grade.total - The total marks of the grade
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static Update(grade, saved) {
    return this.api().patch(`/grades/${grade.id}`, grade, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Delete an existing grade
   * @function
   * @param {Object} grade - The grade
   */

  static Delete(grade) {
    return this.api().delete(`/grades/${grade.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: () => {
        return {
          id: null,
          user_id: grade.user_id,
          assessment_id: grade.assessment_id,
          total: null,
          is_absent: null,
          absent_reason_id: null,
        };
      },
    });
  }
}
