import { GenexSISModel } from "@/models/sis/GenexSISModel";
import ProgressType from "./ProgressType";
import ObjectiveProgress from "./ObjectiveProgress";

export default class ObjectiveProgressLog extends GenexSISModel {
  static entity = "ObjectiveProgressLog";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      objective_progress_id: this.attr(null),
      objective_progress: this.belongsTo(ObjectiveProgress, "objective_progress_id"),
      old_progress_type_id: this.attr(null),
      new_progress_type_id: this.attr(null),
      new_progress_type: this.belongsTo(ProgressType, "new_progress_type_id"),
      creator_id: this.attr(null),
      created_at: this.attr(""),
      updated_at: this.attr(""),
    };
  }
}
