import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of staff totals across campuses
 * @property {number} id
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardRatio extends GenexSISModel {
  static entity = "dashboard-ratio";

  static fields() {
    return {
      id: this.attr(null),
      educator_id: this.attr(null),
      class_group_id: this.attr(null),
      stage_name: this.attr(null),
      class_group_name: this.attr(null),
      enrolment_count: this.attr(null),
    };
  }

  /**
   * Returns the admissions items for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @param {?number} query.enrolment_type
   * @returns {Promise<Response>}
   */
  static Fetch(query) {
    return this.api().get(`/dashboard/ratio`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        const items = data?.educators ?? {};
        return Object.values(items).flat();
      },
    });
  }
}
