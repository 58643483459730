import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export class LMSAssessmentUser extends GenexLMSModel {
  static entity = "lms-assessments-user";

  static fields() {
    return {
      type: this.attr(""),
      id: this.attr(null),
      assessment: this.attr(null),
      course: this.attr(null),
      user_id: this.attr(null),
      assessment_id: this.attr(null),
      course_id: this.attr(null),
      module_id: this.attr(null),
      attempt_number: this.attr(null),
      total_marks: this.attr(null),
      correct_answer_count: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      is_completed: this.attr(null),
      userAssessmentAnswers: this.attr(() => []),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], deleteAllBeforePersist = false) {
    return this.api().get(`user-assessments`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (deleteAllBeforePersist) {
          this.deleteAll();
        }
        return normalize(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`user-assessments/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Store(data) {
    return this.api().post(`user-assessments`, data, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Update(data) {
    return this.api().patch(`user-assessments/${data.id}`, data, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      save: true,
    });
  }

  static Delete(id) {
    return this.api().delete(`user-assessments/${id}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
      delete: id,
    });
  }
}
