import VueCookies from "vue-cookies";
import { SnackbarProgrammatic as Snackbar } from "buefy";

export const SupportModule = {
  namespaced: true,

  state: {
    file: null,
    mediaRecorder: null,
    supportWindowOpen: false,
    editingTicket: false,
    activeSupportTab: 0,
    issue: {
      id: null,
      tenant_id: null,
      campus_id: null,
      category: null,
      url: null,
      description: null,
      media: null,
      user_id: null,
      status_id: 1,
    },
    statuses: [
      { name: "Logged", id: 1 },
      { name: "In Progress", id: 2 },
      { name: "Escalated", id: 4 },
      { name: "Resolved", id: 3 },
    ],
    categories: ["Error", "Page not responding", "Need assistance", "Other"],
  },

  actions: {
    startEdit({ commit }, ticket) {
      commit("OPEN_WINDOW");
      commit("SET_ISSUE", ticket);
      commit("SET_TAB", 0);
    },
    setNew({ commit, rootState }) {
      commit("CLEAR_ISSUE");
      commit("SET_NEW_ISSUE", rootState.user.id);
      commit("SET_TAB", 0);
    },
    openSupportWindow({ commit, rootState }, isNew = true) {
      if (isNew) {
        commit("SET_NEW_ISSUE", rootState.user.id);
      }
      commit("OPEN_WINDOW");
    },
    closeSupportWindow({ commit }, clear = true) {
      if (clear) {
        commit("CLEAR_ISSUE");
      }
      commit("CLOSE_WINDOW");
    },
    async startCapture({ dispatch, state }) {
      let stream = await navigator.mediaDevices
        .getDisplayMedia({
          audio: true,
          video: { mediaSource: "screen" },
        })
        .catch(() => {
          return Promise.reject();
        });
      // let mimeType = 'video/webm';
      Snackbar.open({
        message: "Recording",
        type: "is-warning",
        actionText: "Stop",
        queue: false,
        indefinite: true,
        onAction: () => {
          state.mediaRecorder.stop();
        },
      });
      dispatch("createRecorder", stream);
    },
    createRecorder({ dispatch, state }, stream) {
      // the stream data is stored in this array
      let recordedChunks = [];

      const mediaRecorder = new MediaRecorder(stream);

      mediaRecorder.ondataavailable = function (e) {
        if (e.data.size > 0) {
          recordedChunks.push(e.data);
        }
      };
      mediaRecorder.onstop = () => {
        dispatch("saveFile", recordedChunks);
        recordedChunks = [];
        stream.getTracks().forEach(track => track.stop());
      };
      mediaRecorder.start(200); // For every 200ms the stream data will be stored in a separate chunk.
      state.mediaRecorder = mediaRecorder;
    },
    saveFile({ state }, recordedChunks) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      // let filename = `${rootState.user.id}-${format(new Date(),"yyyy-MM-dd'_'hh:mm")}`
      // let downloadLink = document.createElement('a');
      // downloadLink.href = URL.createObjectURL(blob);
      // downloadLink.download = `${filename}.webm`;
      //
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // URL.revokeObjectURL(blob); // clear from memory
      // document.body.removeChild(downloadLink);
      state.file = blob;
      state.supportWindowOpen = false;
      state.supportWindowOpen = true;
    },
  },
  mutations: {
    OPEN_WINDOW(state) {
      state.supportWindowOpen = true;
    },
    CLOSE_WINDOW(state) {
      state.supportWindowOpen = false;
    },
    SET_NEW_ISSUE(state, rootUser) {
      state.issue.url = window.location.href;
      state.issue.tenant_id = parseInt(VueCookies.get("tenant_id"));
      state.issue.campus_id = parseInt(VueCookies.get("selected_campus_id"));
      state.issue.status_id = 1;
      state.issue.user_id = rootUser;
    },
    CLEAR_ISSUE(state) {
      state.editingTicket = false;
      Object.keys(state.issue).forEach(i => (state.issue[i] = null));
    },
    SET_ISSUE(state, ticket) {
      state.editingTicket = true;
      state.issue = ticket;
    },
    SET_TAB(state, tab) {
      state.activeSupportTab = tab;
    },
  },
};
