import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import LMSForumSubComment from "@/models/lms/LMSForumSubComment";
import LMSUser from "@/models/lms/LMSUser";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class LMSForumComment extends GenexLMSModel {
  static entity = "lms-forum-comments";

  static fields() {
    return {
      id: this.attr(null),
      type: this.attr(""),
      user_id: this.attr(null),
      creator: this.belongsTo(LMSUser, "user_id", "id"),
      created_at: this.attr(""),
      updated_at: this.attr(""),
      created_at_human: this.attr(""),
      body: this.attr(""),
      forum_post_id: this.attr(null),
      forum_comment_id: this.attr(null),
      sub_comments: this.hasMany(LMSForumSubComment, "forum_comment_id"),
    };
  }

  static Store(entity) {
    return this.api().post(`/forum-comments`, entity, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  static Delete(commentId) {
    return this.api().delete(`/forum-comments/${commentId}`, {
      ...GenexHelperMixins.methods.DefaultLMSHeadersAndBaseUrl(),
    });
  }
}
