import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import CourseScope from "@/models/sis/CourseScope";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A reportExtrural
 * @property {number} id
 * @property {boolean} requires_educator_comment - The if an educator should comment
 * @property {number} display_type_id - How to display the reportExtramurals marks. PERCENTAGE = 1 SYMBOL = 2 INDIVIDUAL_MARKS = 3; NONE = 4


 */
export default class ReportExtramural extends GenexSISModel {
  static entity = "reportExtramurals";
  static primaryKey = "report_extramural_id";

  static fields() {
    return {
      id: this.attr(null),
      report_extramural_id: this.attr(null),
      name: this.attr(""),
      teachers_ids: this.attr(() => []),
      teachers: this.hasManyBy(User, "teachers_ids"),
      campus_id: this.attr(null),
      course_scope_id: this.attr(null),
      course_scope: this.belongsTo(CourseScope, "course_scope_id"),
      stage_id: this.attr(null),
      report_extramural_ordinality: this.attr(0),
      order_index: this.attr(0),
      moved: this.attr(0),
      has_attendances: this.attr(0),
      description: this.attr(null),
      report_description_override: this.attr(null),
      // display_type_id: this.attr(null),
      requires_educator_comment: this.attr(0),
      report_extramural_comments_count: this.attr(0),
      // educator_name:this.attr(null),
    };
  }

  /**
   * Returns all reportExtramurals
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [report_id] - the parent report_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {Array.<string>} [count=[]] - Relationships to count
   * @returns {Promise<Response>} All the Reports
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], report_id, count = []) {
    return this.api().get(`/reports/${report_id}/extramurals`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
          with_count: count,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(extramural => {
          if (relationships.includes("teachers")) {
            extramural.attributes.teachers.map(teacher => {
              Object.assign(teacher, teacher.attributes);
            });
          }
          if (relationships.includes("users")) {
            extramural.attributes.users.map(user => {
              Object.assign(user, user.attributes);
            });
          }
          if (relationships.includes("course_scope")) {
            if (Object.prototype.hasOwnProperty.call(extramural.attributes, "course_scope"))
              if (extramural.attributes.course_scope !== null) {
                Object.assign(extramural.attributes.course_scope, extramural.attributes.course_scope.attributes);
              }
          }
          if (extramural.attributes.report_extramural_ordinality) {
            extramural.attributes.order_index = extramural.attributes.report_extramural_ordinality;
          }
          return { ...extramural, ...extramural.attributes };
        });
      },
    });
  }

  /**
   * Returns a reportExtramural
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} extramural_id - The id of the extramural
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(report_id, extramural_id, relationships = []) {
    return this.api().get(`/reports/${report_id}/extramurals/${extramural_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("teachers")) {
          data.attributes.teachers.map(teacher => {
            Object.assign(teacher, teacher.attributes);
          });
        }
        if (relationships.includes("users")) {
          data.attributes.users.map(user => {
            Object.assign(user, user.attributes);
          });
        }
        if (relationships.includes("course_scope")) {
          data.attributes.teachers.map(course_scope => {
            Object.assign(course_scope, course_scope.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new reportExtramural
   * @function
   * @param {Object} reportExtramural - The reportExtramural object
   * @returns {Promise<Response>} - The newly created report
   */

  static Store(reportExtramural) {
    return this.api().post(
      `/reports/${reportExtramural.report_id}/extramurals/${reportExtramural.id}`,
      reportExtramural,
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Order the extramurals
   * @function
   * @param {number} report_id - The report of the extramurals
   * @param {Array} report_extramurals - The ordering objects
   * @returns {Promise<Response>} - The newly ordered extramurals
   */

  static Order(report_id, report_extramurals) {
    return this.api().patch(
      `/reports/${report_id}/extramurals/ordinality`,
      { report_extramurals: report_extramurals },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        persistBy: "update",
        dataTransformer: ({ data: { data } }) => {
          return data.map(extramural => {
            return {
              report_extramural_id: extramural.id,
              report_extramural_ordinality: extramural.attributes.ordinality,
              order_index: extramural.attributes.ordinality,
            };
          });
        },
      },
    );
  }

  /**
   * Update an reportExtramural
   * @function
   * @param {Object} reportExtramural - The reportExtramural object
   * @returns {Promise<Response>} - The updated report
   */

  static Update(reportExtramural) {
    return this.api().patch(
      `/reports/${reportExtramural.report_id}/extramurals/${reportExtramural.id}`,
      reportExtramural,
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  /**
   * Delete a reportExtramural
   * @function
   * @param {Object} reportExtramural - The reportExtramural
   */

  static Delete(reportExtramural) {
    return this.api().delete(`/reports/${reportExtramural.report_id}/extramurals/${reportExtramural.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: reportExtramural.report_extramural_id,
    });
  }
}
