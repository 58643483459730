import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of dashboard data
 * @property {number} id
 * @property {string} type - the type of the entity
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardEnrolments extends GenexSISModel {
  static entity = "dashboard-enrolments";

  static fields() {
    return {
      enrolments_by_status_count: this.attr(() => []),
      enrolments_by_campus_by_status_count: this.attr(() => []),
      future_dated_enrolments_by_campus_by_future_status_count: this.attr(() => []),
    };
  }

  static mutators() {
    return {
      enrolments_by_status_count(value) {
        if (Array.isArray(value)) {
          return null;
        }
        return value;
      },
      enrolments_by_campus_by_status_count(value) {
        if (Array.isArray(value)) {
          return null;
        }
        return value;
      },
    };
  }

  /**
   * Returns the enrolments items for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @param {?number} query.enrolment_type
   * @returns {Promise<Response>}
   */

  static Fetch(query) {
    return this.api().get(`/dashboard/enrolments`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        return data;
      },
    });
  }
}
