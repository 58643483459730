import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 *
 * @property {number} id
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */

export default class DashboardEnrolmentsStudentServices extends GenexSISModel {
  static entity = "dashboard-enrolments-student-services";

  static fields() {
    return {
      total: this.attr(null),
      id: this.attr(null),
      name: this.attr(null),
      percent_population: this.attr(null),
    };
  }

  /**
   *
   * @param query
   * @returns {*}
   * @constructor
   */
  static Fetch(query) {
    return this.api().get(`/dashboard/student-services`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: query,
      dataTransformer: ({ data }) => {
        // const items = data?.educators ??{}
        // return Object.values(data).flat()
        // return Object.values(data)
        return data.map(entity => {
          return entity;
        });
      },
    });
  }
}
