import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import User from "@/models/sis/User";
import Media from "@/models/sis/Media";
import AnnouncementModel from "@/models/sis/AnnouncementModel";
import Department from "@/models/sis/Department";

/**
 * An announcement
 * @property {number} id
 * @property {number} campus_id - The id of the campus this announcement belongs to
 * @property {number} audience_id - 1 = staff, 2 = guardian, 3 = all
 * @property {Array<Object>} models -
 * @property {string} title
 * @property {string} audience
 * @property {string} content
 * @property {string} start_date
 * @property {string} end_date
 * @property {boolean} is_pinned - whether to pin the announcement to the top of the list
 * @property {string} payment_responsibility
 * @property {string} age_human_readable
 */
export default class Announcement extends GenexSISModel {
  static entity = "announcements";

  static fields() {
    return {
      id: this.attr(null),
      title: this.attr(null),
      content: this.attr(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      campus_id: this.attr(null),
      audience_id: this.attr(null),
      description: this.attr(null),
      is_pinned: this.attr(0),
      audience: this.attr(null),
      is_read: this.attr(0),
      is_saved: this.attr(0),
      models: this.hasMany(AnnouncementModel, "announcement_id"),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, "creator_id"),
      created_at: this.attr(null),
      media: this.hasManyBy(Media, "media_ids"),
      media_ids: this.attr([]),
      departments: this.belongsTo(Department, "filters.department_ids"),
      old_pinned_announcements: this.attr([]),
      archive_days: this.attr(null),
      is_archived: this.attr(null),
      remaining_days: this.attr(null),
      filters: this.attr({
        department_ids: [],
      }),
    };
  }

  static mutators() {
    return {
      filters(value) {
        if (value == null) {
          return {
            department_ids: null,
          };
        }
        return value;
      },
    };
  }

  /**
   * Returns all announcements for the user
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {number} user_id - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?string} [query.date]
   * @param {number} [query.user_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllByUser({ page = 1, limit = 15 }, query = {}, relationships = [], user_id) {
    return this.GenexAxiosGet("Announcement.FetchAllByUser", `/users/${user_id}/announcements`, {
      persistBy: "insertOrUpdate",
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(announcement => {
          if (relationships.includes("media")) {
            if (relationships.includes("media")) {
              if (Object.prototype.hasOwnProperty.call(announcement.attributes, "media")) {
                announcement.attributes.media.map(item => {
                  Object.assign(item, item.attributes);
                  item.mediable_type = "announcements";
                  item.mediable_id = announcement.id;
                });
              }
            }
          }
          if (Object.prototype.hasOwnProperty.call(announcement.attributes, "models")) {
            announcement.attributes.models.map(item => {
              Object.assign(item, item.attributes);

              if (item.model_type !== "Global") {
                item.model_type = item.model_type.slice(item.model_type.lastIndexOf("\\") + 1);
              }
            });
            if (announcement.attributes.models.length === 0) {
              announcement.attributes.models = [
                {
                  model_id: null,
                  model_type: "Global",
                  announcement_id: announcement.id,
                },
              ];
            }
          }

          if (relationships.includes("campus")) {
            if (announcement.attributes.campus !== null) {
              Object.assign(announcement.attributes.campus, announcement.attributes.campus.attributes);
            }
          }
          if (relationships.includes("creator")) {
            if (relationships.includes("creator")) {
              if (Object.prototype.hasOwnProperty.call(announcement.attributes, "creator")) {
                if (announcement.attributes.creator !== null) {
                  Object.assign(announcement.attributes.creator, announcement.attributes.creator.attributes);
                }
              }
            }
          }

          return { ...announcement, ...announcement.attributes };
        });
      },
    });
  }

  /**
   * Returns all announcements
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?string} [query.date]
   * @param {number} [query.creator]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.GenexAxiosGet("Announcement.FetchAll", `/announcements`, {
      persistBy: "insertOrUpdate",
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(announcement => {
          if (relationships.includes("media")) {
            if (relationships.includes("media")) {
              if (Object.prototype.hasOwnProperty.call(announcement.attributes, "media")) {
                announcement.attributes.media.map(item => {
                  Object.assign(item, item.attributes);
                  item.mediable_type = "announcements";
                  item.mediable_id = announcement.id;
                });
              }
            }
          }
          if (Object.prototype.hasOwnProperty.call(announcement.attributes, "models")) {
            announcement.attributes.models.map(item => {
              Object.assign(item, item.attributes);

              if (item.model_type !== "Global") {
                item.model_type = item.model_type.slice(item.model_type.lastIndexOf("\\") + 1);
              }
            });
            if (announcement.attributes.models.length === 0) {
              announcement.attributes.models = [
                {
                  model_id: null,
                  model_type: "Global",
                  announcement_id: announcement.id,
                },
              ];
            }
          }
          if (relationships.includes("campus")) {
            if (announcement.attributes.campus !== null) {
              Object.assign(announcement.attributes.campus, announcement.attributes.campus.attributes);
            }
          }
          if (relationships.includes("creator")) {
            if (Object.prototype.hasOwnProperty.call(announcement.attributes, "creator")) {
              if (announcement.attributes.creator !== null) {
                Object.assign(announcement.attributes.creator, announcement.attributes.creator.attributes);
              }
            }
          }

          return { ...announcement, ...announcement.attributes };
        });
      },
    });
  }

  /**
   * Returns an announcements
   * @function
   * @param {number} [id]
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?string} [query.date]
   * @param {number} [query.creator]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchById(id, query = {}, relationships = []) {
    return this.GenexAxiosGet("Announcement.FetchById", `/announcements/${id}`, {
      persistBy: "insertOrUpdate",
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("media")) {
          if (relationships.includes("media")) {
            if (Object.prototype.hasOwnProperty.call(data.attributes, "media")) {
              data.attributes.media.map(item => {
                Object.assign(item, item.attributes);
                item.mediable_type = "announcements";
                item.mediable_id = data.id;
              });
            }
          }
        }
        if (Object.prototype.hasOwnProperty.call(data.attributes, "models")) {
          data.attributes.models.map(item => {
            Object.assign(item, item.attributes);

            if (item.model_type !== "Global") {
              item.model_type = item.model_type.slice(item.model_type.lastIndexOf("\\") + 1);
            }
          });
          if (data.attributes.models.length === 0) {
            data.attributes.models = [
              {
                model_id: null,
                model_type: "Global",
                announcement_id: data.id,
              },
            ];
          }
        }
        if (relationships.includes("campus")) {
          if (data.attributes.campus !== null) {
            Object.assign(data.attributes.campus, data.attributes.campus.attributes);
          }
        }
        if (relationships.includes("creator")) {
          if (Object.prototype.hasOwnProperty.call(data.attributes, "creator")) {
            if (data.attributes.creator !== null) {
              Object.assign(data.attributes.creator, data.attributes.creator.attributes);
            }
          }
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new announcement
   * @function
   * @param {Object} announcement - The announcement object
   * @returns {Promise<Response>} - The newly created announcement
   */

  static Store(announcement) {
    return this.GenexAxiosPost("Announcement.Store", `/announcements`, announcement, {
      save: false,
      dataTransformer: ({ data: { data } }) => {
        if (Object.prototype.hasOwnProperty.call(data.attributes, "models")) {
          data.attributes.models.map(item => {
            Object.assign(item, item.attributes);

            if (item.model_type !== "Global") {
              item.model_type = item.model_type.slice(item.model_type.lastIndexOf("\\") + 1);
            }
          });
          if (data.attributes.models.length === 0) {
            data.attributes.models = [
              {
                model_id: null,
                model_type: "Global",
                announcement_id: data.id,
              },
            ];
          }
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Update an existing announcement
   * @function
   * @param {Object} announcement - The announcement object
   * @param {boolean} saved - Whether to persist the response
   * @returns {Promise<Response>} - The newly created application
   */

  static Update(announcement, saved = true) {
    return this.GenexAxiosPatch("Announcement.Update", `/announcements/${announcement.id}`, announcement, {
      dataTransformer: ({ data: { data } }) => {
        if (Object.prototype.hasOwnProperty.call(data.attributes, "models")) {
          data.attributes.models.map(item => {
            Object.assign(item, item.attributes);
            if (item.model_type === null) {
              item.model_type = "Global";
            }
            if (item.model_type === "") {
              item.model_type = "Global";
            }
            if (item.model_type !== "Global") {
              item.model_type = item.model_type.slice(item.model_type.lastIndexOf("\\") + 1);
            }
          });
        }
        return { ...data, ...data.attributes };
      },
      save: saved,
    });
  }

  /**
   * Mark an announcement as read
   * @function
   * @param {number} user_id - The id of the reading user
   * @param {number} announcement_id - The id of the announcement
   * @param {number} is_read - Confirm the announcement is read
   * @param {number} is_saved - Save the announcement for later
   * @returns {Promise<Response>} - The newly read announcement
   */

  static MarkRead(user_id, announcement_id, is_read = 1, is_saved = 0) {
    return this.GenexAxiosPost(
      "Announcement.MarkRead",
      `/announcement-user`,
      {
        user_id: user_id,
        announcement_id: announcement_id,
        is_read: is_read,
        is_saved: is_saved,
      },
      {
        persistBy: "update",
        dataTransformer: () => {
          return { id: announcement_id, is_read: 1, is_saved: is_saved };
        },
      },
    );
  }

  /**
   * Mark an announcement as not saved
   * @function
   * @param {number} user_id - The id of the reading user
   * @param {number} announcement_id - The id of the announcement
   * @param {number} is_saved - Save the announcement for later
   * @returns {Promise<Response>} - The newly read announcement
   */

  static RemoveFromSavedAnnouncements(user_id, announcement_id, is_saved = 0) {
    return this.GenexAxiosPatch(
      "Announcement.RemoveFromSavedAnnouncements",
      `announcement-user/announcement/${announcement_id}/user/${user_id}`,
      {
        user_id: user_id,
        announcement_id: announcement_id,
        is_saved: is_saved,
        is_read: 1,
      },
      {
        persistBy: "update",
        dataTransformer: () => {
          return { id: announcement_id, is_saved: is_saved };
        },
      },
    );
  }

  /**
   * Mark an announcement as  saved
   * @function
   * @param {number} user_id - The id of the reading user
   * @param {number} announcement_id - The id of the announcement
   * @param {number} is_saved - Save the announcement for later
   * @returns {Promise<Response>} - The newly read announcement
   */

  static SaveAnnouncement(user_id, announcement_id, is_saved = 1) {
    return this.GenexAxiosPatch(
      "Announcement.SaveAnnouncement",
      `announcement-user/announcement/${announcement_id}/user/${user_id}`,
      {
        user_id: user_id,
        announcement_id: announcement_id,
        is_saved: is_saved,
        is_read: 1,
      },
      {
        persistBy: "update",
        dataTransformer: () => {
          return { id: announcement_id, is_saved: is_saved };
        },
      },
    );
  }

  /**
   * Mark an announcement as unread
   * @function
   * @param {number} user_id - The id of the reading user
   * @param {number} announcement_id - The id of the announcement
   * @returns {Promise<Response>} - The newly unread announcement
   */

  static MarkUnread(user_id, announcement_id) {
    return this.GenexAxiosDelete(
      "Announcement.MarkUnread",
      `announcement-user/announcement/${announcement_id}/user/${user_id}`,
      {
        persistBy: "update",
        dataTransformer: () => {
          return { id: announcement_id, is_read: 0 };
        },
      },
    );
  }

  /**
   * Delete an existing announcement
   * @function
   * @param {number} announcement_id - The id of the announcement
   * @returns {Promise<Response>} - The status of the delete
   */

  static Delete(announcement_id) {
    return this.GenexAxiosDelete("Announcement.Delete", `/announcements/${announcement_id}`, {
      delete: announcement_id,
    });
  }
}
