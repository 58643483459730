import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A report group for the final report
 * @property {number} id
 * @property {string} name - The name of the group
 * @property {number} report_id - The id of the parent report
 * @property {number} subject_id - the id of the related subject
 * @property {number} class_group_id - the id of the related class group
 * @property {number} type_id - TYPE_PREVIOUS_REPORT_MARK_GROUP = 1; TYPE_PREVIOUS_REPORT_TOTAL = 2; TYPE_CURRENT_REPORT_MARK_GROUP = 3;
 * @property {number} weighting - the weighting
 */
export default class ReportFinalGroupOption extends GenexSISModel {
  static entity = "reportFinalGroupOptions";
  static primaryKey = ["name", "report_id"];

  static fields() {
    return {
      name: this.attr(""),
      report_id: this.attr(null),
      type_id: this.attr(null),
      type_name: this.attr(null),
      mark_group_id: this.attr(null),
    };
  }

  /**
   * Returns all options for a given report
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.class_group_id] - search by class_group_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {number} report_id - The id of the parent report
   * @param {number} subject_id - The id of the parent subject
   * @returns {Promise<Response>} All the options
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], report_id, subject_id) {
    return this.api().get(`/reports/${report_id}/subjects/${subject_id}/final-group-options`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        return data;
      },
    });
  }
}
