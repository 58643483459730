import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize } from "@/utils/data";
import ObjectiveProgressLog from "./ObjectiveProgressLog";
import Objective from "@/models/sis/Objective";

export default class ObjectiveProgress extends GenexSISModel {
  static entity = "ObjectiveProgress";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      latest_objective_progress_log_id: this.attr(null),
      latest_objective_progress_log: this.belongsTo(ObjectiveProgressLog, "latest_objective_progress_log_id"),
      user_id: this.attr(null),
      objective_id: this.attr(null),
      progress_type_id: this.attr(null),
      content_type_id: this.attr(null),
      montessori_task_id: this.attr(null),
      progress: this.attr(null),
      objective: this.belongsTo(Objective, "objective_id"),
    };
  }

  static FetchAll(
    { page = 1, limit = 15 },
    query = {},
    relationships = [],
    persistBy = "insertOrUpdate",
    clearPrimaryModelOnly = false,
  ) {
    page;
    return this.api().get(`/objective-progress`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: persistBy,
      params: {
        ...{
          // page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (clearPrimaryModelOnly) {
          this.deleteAll();
        }
        return normalize(data);
      },
    });
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/objective-progress/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Store(item) {
    return this.api().post(`/objective-progress`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Update(item) {
    return this.api().patch(`/objective-progress/${item.id}`, item, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }

  static Delete(id) {
    return this.api().delete(`/objective-progress/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
