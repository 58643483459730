import { GenexLMSModel } from "@/models/lms/GenexLMSModel";
import { normalize } from "@/utils/data";

export default class LMSCourseModuleProgress extends GenexLMSModel {
  static entity = "lms-course-module-progress";

  /**
   *
   * @returns {{course_id: *, completed_at: *, module_id: *, updated_at: *, user_id: *, module: *, created_at: *, course: *, completed: *, lesson_users: *, percent_complete: *}}
   */
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      progress: this.attr(null),
      modules: this.attr(null),
    };
  }

  /**
   *
   * @param page
   * @param limit
   * @param query
   * @param relationships
   * @param persistBy
   * @returns {*}
   * @constructor
   */
  static FetchAll({ page = 1, limit = 999 }, query = {}, relationships = [], persistBy = "insertOrUpdate") {
    return this.apiWithHeaders().get(`/dashboards/course-module-progress`, {
      persistBy: persistBy,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return normalize(data);
      },
    });
  }
}
