import { Model } from "@vuex-orm/core";
import { DefaultLMSHeadersAndBaseUrl } from "@/models/lms/LMSHeaders";

export class GenexLMSModel extends Model {
  static apiWithHeaders() {
    this.apiConfig = {
      ...DefaultLMSHeadersAndBaseUrl(),
    };
    return this.api();
  }
}
