import { keyedObjToObjArray } from "@/utils/data";
import { capitalize } from "lodash";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An Alert
 * @property {number} duration - The duration of the alert
 * @property {string} message - The message of the alert
 * @property {number} audience_id - The id of the target audience
 * @property {boolean} is_lms - The toggle for LMS
 * @property {number} tenant_ids - The tenant id
 * @property {object} tenant_ids.* - The ???
 * @property {number} creator_id - The creator id
 */

export default class AlertAudience extends GenexSISModel {
  static entity = "alerts_audience";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
    };
  }

  /**
   * Returns Alerts
   * @function
   */
  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], mode = "insertOrUpdate") {
    return this.api().get(`/alerts/audiences`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: mode,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        const arr = keyedObjToObjArray(data, "name");
        return arr.map(item => {
          return {
            ...item,
            name: capitalize(item.name),
          };
        });
      },
    });
  }
}
