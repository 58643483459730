<template>
  <div ref="perfectScrollContainer" class="is-fullwidth is-relative perfect-scroll-container" @mouseenter="psUpdate">
    <slot></slot>
  </div>
</template>

<script>
  import PerfectScrollbar from "perfect-scrollbar";

  export default {
    name: "PerfectScrollbarWrapper",
    methods: {
      psInit() {
        this.ps = new PerfectScrollbar(this.$refs.perfectScrollContainer);
      },
      psUpdate() {
        if (this.ps) {
          this.ps.update();
        }
      },
      scrollToTop() {
        this.$refs.perfectScrollContainer.scrollTop = 0;
      },
      scrollHorizontalTo(position = 0) {
        this.$refs.perfectScrollContainer.scrollLeft = position;
      },
    },
    mounted() {
      this.psInit();
    },
  };
</script>
