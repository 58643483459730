import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Phase from "@/models/sis/Phase";
import DashboardEducatorStage from "@/models/sis/DashboardEducatorStage";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of staff totals across campuses
 * @property {number} id
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardEducatorPhase extends GenexSISModel {
  static entity = "dashboard-educator-phase";

  static fields() {
    return {
      id: this.attr(null),
      phase_id: this.attr(null),
      phase: this.belongsTo(Phase, "phase_id"),
      total: this.attr(null),
      percentage_of_total: this.attr(null),
      stages: this.hasManyBy(DashboardEducatorStage, "stage_ids"),
      stage_ids: this.attr(() => []),
    };
  }

  /**
   * Returns the admissions items for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @param {?number} query.enrolment_type
   * @returns {Promise<Response>}
   */
  static Fetch(query) {
    return this.api().get(`/dashboard/educator-phases`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        const items = data?.phases ?? [];
        return Object.values(items).map(phase => {
          phase.stages = Object.values(phase.stages).map(stage => {
            stage.educators = Object.values(stage.educators);
            return stage;
          });
          return phase;
        });
      },
    });
  }
}
