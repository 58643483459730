import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import CourseScope from "@/models/sis/CourseScope";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A reportSubject
 * @property {number} id
 * @property {boolean} requires_educator_comment - The if an educator should comment
 * @property {number} display_type_id - How to display the reportSubjects marks. PERCENTAGE = 1 SYMBOL = 2 INDIVIDUAL_MARKS = 3; NONE = 4
 * @property {number} educator_display_type_id - How to display the reportSubjects educators. None = 1 Primary Educators = 2 All Educators = 3;


 */
export default class ReportSubject extends GenexSISModel {
  static entity = "reportSubjects";
  static primaryKey = "report_subject_id";

  static fields() {
    return {
      id: this.attr(null),
      report_subject_id: this.attr(null),
      name: this.attr(""),
      long_name: this.attr(""),
      code: this.attr(null),
      teacher_id: this.attr(null),
      teacher: this.belongsTo(User, "teacher_id"),
      user_ids: this.attr(() => []),
      users: this.hasManyBy(User, "user_ids"),
      campus_id: this.attr(null),
      course_scope_id: this.attr(null),
      course_scope: this.belongsTo(CourseScope, "course_scope_id"),
      stage_id: this.attr(null),
      report_subject_ordinality: this.attr(0),
      order_index: this.attr(0),
      moved: this.attr(0),
      has_attendances: this.attr(0),
      display_type_id: this.attr(null),
      educator_display_type_id: this.attr(null),
      requires_educator_comment: this.attr(0),
      requires_educator_checkpoints: this.attr(0),
      report_subject_comments_count: this.attr(0),
      report_description: this.attr(null),
      report_description_override: this.attr(null),
      has_checkpoint_comments: this.attr(null),
      checkpoints: this.attr([]),
      // educator_name:this.attr(null),
    };
  }

  /**
   * Returns all reportSubjects
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [report_id] - the parent report_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {Array.<string>} [count=[]] - Relationships to count
   * @returns {Promise<Response>} All the Reports
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], report_id, count = []) {
    return this.api().get(`/reports/${report_id}/subjects`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
          with_count: count,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(subject => {
          if (relationships.includes("teacher")) {
            if (Object.prototype.hasOwnProperty.call(subject.attributes, "teacher")) {
              Object.assign(subject.attributes.teacher, subject.attributes.teacher.attributes);
            }
          }
          if (relationships.includes("users")) {
            subject.attributes.users.map(user => {
              Object.assign(user, user.attributes);
            });
          }
          if (relationships.includes("course_scope")) {
            if (Object.prototype.hasOwnProperty.call(subject.attributes, "course_scope"))
              if (subject.attributes.course_scope !== null) {
                Object.assign(subject.attributes.course_scope, subject.attributes.course_scope.attributes);
              }
          }
          if (subject.attributes.report_subject_ordinality) {
            subject.attributes.order_index = subject.attributes.report_subject_ordinality;
          }
          return { ...subject, ...subject.attributes };
        });
      },
    });
  }

  /**
   * Returns a reportSubject
   * @function
   * @param {number} report_id - The id of the report
   * @param {number} subject_id - The id of the subject
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Topic
   */

  static FetchById(report_id, subject_id, relationships = []) {
    return this.api().get(`/reports/${report_id}/subjects/${subject_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("teacher")) {
          Object.assign(data.attributes.teacher, data.attributes.teacher.attributes);
        }
        if (relationships.includes("course_scope")) {
          data.attributes.teachers.map(course_scope => {
            Object.assign(course_scope, course_scope.attributes);
          });
        }
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Store a new reportSubject
   * @function
   * @param {Object} reportSubject - The reportSubject object
   * @returns {Promise<Response>} - The newly created report
   */

  static Store(reportSubject) {
    return this.api().post(`/reports/${reportSubject.report_id}/subjects/${reportSubject.id}`, reportSubject, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Order the subjects
   * @function
   * @param {number} report_id - The report of the subjects
   * @param {Array} report_subjects - The ordering objects
   * @returns {Promise<Response>} - The newly ordered subjects
   */

  static Order(report_id, report_subjects) {
    return this.api().patch(
      `/reports/${report_id}/subjects/ordinality`,
      { report_subjects: report_subjects },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        persistBy: "update",
        dataTransformer: ({ data: { data } }) => {
          return data.map(subject => {
            return {
              report_subject_id: subject.id,
              report_subject_ordinality: subject.attributes.ordinality,
              order_index: subject.attributes.ordinality,
            };
          });
        },
      },
    );
  }

  /**
   * Update an reportSubject
   * @function
   * @param {Object} reportSubject - The reportSubject object
   * @returns {Promise<Response>} - The updated report
   */

  static Update(reportSubject) {
    return this.api().patch(`/reports/${reportSubject.report_id}/subjects/${reportSubject.id}`, reportSubject, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Delete a reportSubject
   * @function
   * @param {Object} reportSubject - The reportSubject
   */

  static Delete(reportSubject) {
    return this.api().delete(`/reports/${reportSubject.report_id}/subjects/${reportSubject.id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: reportSubject.report_subject_id,
    });
  }
}
