<script setup>
  import Media from "@/models/sis/Media";
  import { computed, onMounted } from "vue";
  import MediaPanelModalViewImage from "@/components/media/MediaPanelModalViewImage.vue";
  import { useBuefy } from "@/compositions/utils/useBuefy";
  import MediaPanelModalRename from "@/components/media/MediaPanelModalRename.vue";
  import { useVueApp } from "@/compositions/useVueApp";
  import { useErrorHandler } from "@/compositions/useErrorHandler";

  const props = defineProps({
    model: {
      type: String,
      required: true,
    },
    model_id: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      default: 30,
    },
    page: {
      type: Number,
      default: 1,
    },
    selectedTag: {
      type: String,
      default: null,
    },
    fetchOnMount: {
      type: Boolean,
      default: true,
    },
    order_by: {
      type: String,
      default: "created_at",
    },
    order_direction: {
      type: String,
      default: "desc",
    },
    isCentral: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
    canDelete: {
      type: Boolean,
      default: true,
    },
  });

  const media = computed(() => {
    return Media.query()
      .where("mediable_type", props.model)
      .where("mediable_id", props.model_id)
      .where(media => {
        if (!props.selectedTag) {
          return true;
        }
        return props.selectedTag === media.tag;
      })
      .offset((props.page - 1) * props.limit)
      .limit(props.limit)
      .get();
  });

  const fetch = () => {
    return Media.FetchAll(
      { page: props.page, limit: props.limit },
      {
        mediable_type: props.model,
        model: props.model_id,
        tag: props.selectedTag,
        order_by: props.order_by,
        order_direction: props.order_direction,
      },
      [],
      props.isCentral,
    );
  };

  if (props.fetchOnMount) {
    onMounted(() => {
      fetch();
    });
  }

  const $buefy = useBuefy();
  const app = useVueApp();

  const { handleError } = useErrorHandler();

  const selectFile = file => {
    if (file.aggregate_type == "image") {
      $buefy.modal.open({
        parent: app,
        props: {
          entity: file,
        },
        component: MediaPanelModalViewImage,
        customClass: "modalEnableWidthChange",
        fullScreen: false,
        trapFocus: true,
        events: {},
      });
    } else {
      window.open(file.temporary_url, "_blank").focus();
    }
  };

  const rename = entity => {
    $buefy.modal.open({
      parent: app,
      props: {
        entityProp: entity,
        isCentral: props.isCentral,
      },
      component: MediaPanelModalRename,
      fullScreen: false,
      trapFocus: true,
    });
  };

  const remove = media => {
    Media.Delete(media.id)
      .then(() => {
        $buefy.snackbar.open("Media item deleted!");
      })
      .catch(err => {
        handleError(err);
      });
  };
  const startDelete = media => {
    $buefy.dialog.confirm({
      title: "Deleting media",
      confirmText: "Delete Media",
      hasIcon: true,
      type: "is-danger",
      message: "Are you sure you want to delete this media item?",
      onConfirm: () => remove(media),
    });
  };
</script>

<template>
  <div class="file-bubbles-section">
    <b-dropdown append-to-body position="is-bottom-left" v-for="file in media" :key="file.id">
      <template #trigger>
        <div class="file-info">
          <div class="file-bubble has-pointer px-5 py-2 mr-2 mt-2">
            <h4>{{ file.display_name }}</h4>
          </div>
        </div>
      </template>
      <b-dropdown-item @click="selectFile(file)"> Open</b-dropdown-item>
      <b-dropdown-item v-if="canEdit" @click="rename(file)"> Rename</b-dropdown-item>
      <b-dropdown-item v-if="canDelete" @click="startDelete(file)"> Delete</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<style scoped>
  .file-bubbles-section {
    display: flex;
    flex-wrap: wrap;
  }

  .file-bubble {
    border-radius: 15px; /* rounded edges */
    border: 1px solid #ccc; /* add border */
  }

  .file-bubble:hover {
    background-color: #ddd; /* add a grey background on hover */
  }

  .file-info {
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
  }
</style>
