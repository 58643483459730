var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"listitem"},[_c('article',{staticClass:"media"},[(_vm.showTypes)?_c('figure',{staticClass:"media-left"},[_c('p',{staticClass:"has-text-centered"},[_c('b-tooltip',{attrs:{"label":_vm.type.name,"append-to-body":"","position":"is-top"}},[_c('b-icon',{staticClass:"icon rounded is-large box is-64x64",attrs:{"icon":_vm.type.icon}})],1)],1)]):_vm._e(),_c('div',{staticClass:"box media-content is-relative",class:{
        'ml-6': _vm.conversationMode && _vm.isOwnNote,
        'mr-6': _vm.conversationMode && !_vm.isOwnNote,
      }},[_c('div',{staticClass:"p-2"},[(_vm.permissions.can_delete || _vm.permissions.can_edit)?_c('b-dropdown',{staticClass:"listitem-dropdown",attrs:{"position":'is-top-left',"append-to-body":"","aria-role":"list"}},[_c('b-button',{staticClass:"mb-4",attrs:{"slot":"trigger","icon-right":"more-vertical","type":"is-primary"},slot:"trigger"}),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.editNote(_vm.note)}}},[_vm._v("Edit")]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.startDelete(_vm.note)}}},[_vm._v("Delete")])],1):_vm._e(),_c('p',{staticClass:"subtitle is-7"},[_vm._v(" created by "),(_vm.note.user)?_c('span',[_vm._v(_vm._s(_vm.note.user.first_name)+" "+_vm._s(_vm.note.user.last_name))]):_c('span',[_vm._v("System")]),_vm._v(" @ "+_vm._s(new Date(_vm.note.created_at).toLocaleString())+" "),(_vm.note.created_at !== _vm.note.updated_at)?_c('span',[_vm._v("edited @ "+_vm._s(new Date(_vm.note.updated_at).toLocaleString()))]):_vm._e()]),(!_vm.noteBeingEdited)?_c('div',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.note.note)}}):_vm._e(),(_vm.noteBeingEdited)?_c('div',[_c('b-field',[_c('GenexMathEditor',{attrs:{"model-id":_vm.note?.id,"model":_vm.model_type,"placeholder":"Note Body"},model:{value:(
                // eslint-disable-next-line
                _vm.note.note
              ),callback:function ($$v) {_vm.$set(// eslint-disable-next-line
                _vm.note, "note", $$v)},expression:"\n                // eslint-disable-next-line\n                note.note\n              "}})],1),_c('b-field',[_c('b-button',{attrs:{"type":"is-primary"},on:{"click":_vm.saveEdit}},[_vm._v("Save")])],1)],1):_vm._e()],1)])]),_c('hr')])
}
var staticRenderFns = []

export { render, staticRenderFns }