import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import ILPTopic from "@/models/sis/ILPTopic";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * An ILP collection
 * @property {Array<Object>} students
 * @property {Array<Object>} topics
 */
export default class ILP extends GenexSISModel {
  static entity = "ILPs";

  static fields() {
    return {
      students: this.hasManyBy(User, "student_ids"),
      student_ids: this.attr(() => []),
      topics: this.hasManyBy(ILPTopic, "topic_ids"),
      topic_ids: this.attr(() => []),
    };
  }

  /**
   * Returns ILP for a subject
   * @function
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.class_group_id]
   * @param {number} [query.subject_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllBySubject(query = {}, relationships = []) {
    return this.api().get(`/ilp/for-subject`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        data.topics = data.topics.filter(topic => {
          return topic.units.length > 0;
        });
        data.topics.map(topic => {
          topic.units = topic.units.filter(unit => {
            return unit.objectives.length > 0;
          });
        });
        return data;
      },
    });
  }

  /**
   * Returns ILP for a learner
   * @function
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.subject_id]
   * @param {number} [user_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllByUser(query = {}, user_id, relationships = []) {
    return this.api().get(`/ilp/for-learner/${user_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        data.topics = data.topics.filter(topic => {
          return topic.units.length > 0;
        });
        data.topics.map(topic => {
          topic.units = topic.units.filter(unit => {
            return unit.objectives.length > 0;
          });
        });
        return data;
      },
    });
  }

  /**
   * Returns ILP for a Lesson
   * @function
   * @param {?Object} [query={}] - Query terms for the request
   * @param {number} [query.lesson_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAllByLesson(query = {}, relationships = []) {
    return this.api().get(`/ilp/for-lesson`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      persistBy: "create",
      params: {
        ...{
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        data.topics = data.topics.filter(topic => {
          return topic.units.length > 0;
        });
        data.topics.map(topic => {
          topic.units = topic.units.filter(unit => {
            return unit.objectives.length > 0;
          });
        });
        return data;
      },
    });
  }

  /**
   * Checks if students are present on the related attendance and performs following actions based on their attendance:
   * 1. All attendances taken and all present - mark all as presented, 200, no absent users, can_mass_invite = 0.
   * 2. All attendance taken, some absent - present get marked as presented, 200, get absent users back, can_mass_invite = 0
   * 3. If some attendaces, 422, can_mass_invite = 0
   * 4. If no attendances, 422 with can_mass_invite = 1.
   * @function
   * @param {number} lesson_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static RecordLesson(lesson_id, relationships = []) {
    return this.api().get(`/lessons/${lesson_id}/progress`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      save: false,
    });
  }

  /**
   * Store progress
   * @function
   * @param {Array<Object>} progress - The progress array
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static SaveProgress(progress) {
    return this.api().post(
      `/objective-progress`,
      { progress: progress },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        save: false,
      },
    );
  }

  /**
   * Store progress
   * @function
   * @returns {Promise<Response>} - The newly created enrolment
   */

  static async rollbackLastChange(user_id, objective_id) {
    return await this.api().post(
      `/objective-progress/rollback-last-progress`,
      {
        progress: [
          {
            user_id: user_id,
            objective_id: objective_id,
          },
        ],
      },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        save: false,
      },
    );
  }
}
