export const CovidAssessmentModule = {
  namespaced: true,

  state: {
    symptoms: [
      { name: "Fever/chills", value: "fever_chills" },
      { name: "Cough", value: "cough" },
      { name: "Sore throat", value: "sore_throat" },
      { name: "Shortness of breath", value: "shortness_of_breath" },
      { name: "Body aches", value: "body_aches" },
      { name: "Redness of eyes", value: "redness_of_eyes" },
      {
        name: "Loss of smell and/or taste",
        value: "loss_of_smell_and_or_taste",
      },
      { name: "Nausea/vomiting/diarrhoea", value: "nausea_vomiting_diarrhoea" },
      { name: "Fatigue/weakness", value: "fatigue_weakness" },
    ],
    empty_object: {
      fever_chills: false,
      cough: false,
      sore_throat: false,
      shortness_of_breath: false,
      body_aches: false,
      redness_of_eyes: false,
      loss_of_smell_and_or_taste: false,
      nausea_vomiting_diarrhoea: false,
      fatigue_weakness: false,
    },
  },
};
