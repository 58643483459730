import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Objective from "@/models/sis/Objective";
import UnitObjective from "@/models/sis/UnitObjective";
import UserUnit from "./UserUnit";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";
import { normalize, transform } from "@/utils/data";
import TaskSheet from "@/models/TaskSheet";

/**
 * A unit
 * @property {number} id
 * @property {string} name - The name of the unit
 * @property {Array.<Object>} objectives - The objectives this unit contains
 */
export default class Unit extends GenexSISModel {
  static entity = "units";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
      type: this.attr("task", () => "task"),
      course_scope_topic_id: this.attr(null),
      equipment_id: this.attr(null),
      order: this.attr(null),
      description: this.attr(""),
      mastery_criteria: this.attr(""),
      objectives: this.belongsToMany(Objective, UnitObjective, "unit_id", "objective_id"),
      user_units: this.hasMany(UserUnit, "unit_id"),
      tasksheet_ids: this.attr(() => []),
      tasksheets: this.hasManyBy(TaskSheet, "tasksheet_ids"),
    };
  }

  /**
   * Returns all Units
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {Object} [query={}] - Query terms for the request
   * @param {number} [query.campus_id] - search by campus_id
   * @param {number} [query.stage_id] - search by stage_id
   * @param {number} [query.phase_id] - search by phase_id
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} All the CourseScopeTopicUnits
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/course-scope-topic-units`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: transform,
    });
  }

  static FetchAllWithUserUnit({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/units/user-units`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...query,
      },
      persistBy: "create",
      dataTransformer: ({ data: { data } }) => {
        const result = normalize(data);
        return result;
      },
    });
  }

  /**
   * Returns a Unit
   * @function
   * @param {number} id - The id of the Unit
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} the Unit
   */

  static FetchById(id, relationships = []) {
    return this.api().get(`/course-scope-topic-units/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: transform,
    });
  }

  /**
   * Store a new unit
   * @function
   * @param {Object} unit - The unit object
   * @returns {Promise<Response>} - The newly created unit
   */

  static Store(unit) {
    return this.api().post(`/course-scope-topic-units`, unit, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: transform,
    });
  }

  /**
   * Update a unit
   * @function
   * @param {Object} unit - The unit object
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>} - The updated unit
   */

  static Update(unit, relationships = []) {
    return this.api().patch(`/course-scope-topic-units/${unit.id}`, unit, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          with: relationships,
        },
      },
      dataTransformer: transform,
    });
  }

  /**
   * Delete a unit
   * @function
   * @param {number} id - The id of the unit
   */

  static Delete(id) {
    return this.api().delete(`/course-scope-topic-units/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: id,
    });
  }
}
