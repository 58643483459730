import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import UserRecordType from "@/models/sis/UserRecordType";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class UserRecord extends GenexSISModel {
  static entity = "user-records";

  static fields() {
    return {
      id: this.attr(null),
      comment: this.attr(null),
      type_id: this.attr(null),
      type: this.belongsTo(UserRecordType, "type_id"),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
      is_new: this.attr(false),
      is_dirty: this.attr(false),
      to_be_deleted: this.attr(false),
      admin_only: this.attr(0),
    };
  }

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = []) {
    return this.api().get(`/user-records`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(record => {
          if (relationships.includes("user")) {
            Object.assign(record.attributes.user, record.attributes.user.attributes);
          }
          return { ...record, ...record.attributes };
        });
      },
    });
  }

  static FetchAllByModel({ page = 1, limit = 15 }, model, model_id, query = {}, relationships = []) {
    return this.api().get(`/${model}/${model_id}/user-records`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(record => {
          if (relationships.includes("user")) {
            Object.assign(record.attributes.user, record.attributes.user.attributes);
          }
          return { ...record, ...record.attributes };
        });
      },
    });
  }

  static FetchById({ page = 1, limit = 15 }, model, model_id, id, query = {}, relationships = []) {
    return this.api().get(`/${model}/${model_id}/user-records/${id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        if (relationships.includes("user")) {
          Object.assign(data.attributes.user, data.attributes.user.attributes);
        }

        return { ...data, ...data.attributes };
      },
    });
  }

  static Store(record, model, model_id) {
    return this.api().post(`/${model}/${model_id}/user-records`, record, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('user')) {
        //     Object.assign(note.attributes.user, note.attributes.user.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  static StoreBulk(records, model, model_id) {
    return this.api().post(
      `/${model}/${model_id}/user-records/bulk-insert`,
      { data: records },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          // if (relationships.includes('user')) {
          //     Object.assign(note.attributes.user, note.attributes.user.attributes)
          // }
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  static UpdateBulk(records, model, model_id) {
    return this.api().put(
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      `/${model}/${model_id}/user-records/bulk-update`,
      { data: records },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          // if (relationships.includes('user')) {
          //     Object.assign(note.attributes.user, note.attributes.user.attributes)
          // }
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  static DeleteBulk(record_ids, model, model_id) {
    return this.api().delete(
      `/${model}/${model_id}/user-records`,
      { data: { user_record_ids: record_ids } },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
      },
    );
  }

  static Update(record, model, model_id) {
    return this.api().put(`/${model}/${model_id}/user-records/${record.id}`, record, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        // if (relationships.includes('user')) {
        //     Object.assign(note.attributes.user, note.attributes.user.attributes)
        // }
        return { ...data, ...data.attributes };
      },
    });
  }

  static Delete(record_id, model, model_id) {
    return this.api().delete(`/${model}/${model_id}/user-records/${record_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: record_id,
    });
  }
}
