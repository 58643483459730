import { GenexSISModel } from "@/models/sis/GenexSISModel";
import Campus from "@/models/sis/Campus";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A collection of staff totals across campuses
 * @property {number} id
 * @property {string} name
 * @property {Array<number>} The ids of the permissions
 * @property {Array<Object>} The permissions
 */
export default class DashboardStaffTotals extends GenexSISModel {
  static entity = "dashboard-staff-total";
  static primaryKey = "campus_id";

  static fields() {
    return {
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      staff_count: this.attr(null),
    };
  }

  /**
   * Returns the admissions items for the dashboard
   * @function
   * @param {Object} query
   * @param {string} query.start_date
   * @param {string} query.end_date
   * @param {?number} query.enrolment_type
   * @returns {Promise<Response>}
   */

  static Fetch(query) {
    return this.api().get(`/dashboard/staff-totals`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      params: {
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data }) => {
        return data?.staff_totals ?? [];
      },
    });
  }
}
