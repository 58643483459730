import Campus from "@/models/sis/Campus";
import { GenexSISModel } from "@/models/sis/GenexSISModel";
import User from "@/models/sis/User";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

/**
 * A user for the helpdesk account
 * @property {number} id
 * @property {Array.<Object>} wards
 */
export default class HelpdeskUser extends GenexSISModel {
  static entity = "helpdesk-users";

  static fields() {
    return {
      id: this.attr(null),
      campus_id: this.attr(null),
      campus: this.belongsTo(Campus, "campus_id"),
      user_id: this.attr(null),
      user: this.belongsTo(User, "user_id"),
    };
  }

  /**
   * Returns all helpdesk users
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {?number} [query.campus_id]
   * @param {?number} [query.user_id]
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @param {Boolean} save
   * @returns {Promise<Response>}
   */

  static FetchAll({ page = 1, limit = 15 }, query = {}, relationships = [], save = true) {
    return this.api().get(`/chat/helpdesk-users`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      save: save,
      params: {
        ...{
          page: page,
          limit: limit,
          with: relationships,
        },
        ...(query !== {} ? query : {}),
      },
      dataTransformer: ({ data: { data } }) => {
        return data.map(user => {
          if (relationships.includes("user")) {
            if (Object.prototype.hasOwnProperty.call(user.attributes, "user")) {
              if (user.attributes.user !== null) {
                Object.assign(user.attributes.user, user.attributes.user.attributes);
              }
            }
          }
          return { ...user, ...user.attributes };
        });
      },
    });
  }

  /**
   * Store a new user
   * @function
   * @param {Object} user - The user object
   * @param {string} user.user_id - The name of the user
   * @param {number} user.campus_id - The id of the campus the user is associated with
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(user) {
    return this.api().post(`/chat/helpdesk-users`, user, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return { ...data, ...data.attributes };
      },
    });
  }

  /**
   * Friend with helpdesk user
   * @function
   * @param {string} current_user - The current user uuid
   * @returns {Promise<Response>} - The newly created user
   */

  static FriendWithHelpdesk(current_user) {
    return this.api().post(
      `/chat/friend-helpdesk-users`,
      { user: current_user },
      {
        ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
        dataTransformer: ({ data: { data } }) => {
          return { ...data, ...data.attributes };
        },
        save: false,
      },
    );
  }

  /**
   * Delete an existing user
   * @function
   * @param {number} user_id - The id of the user
   * @returns {Promise<Response>} - The newly created user
   */

  static Delete(user_id) {
    return this.api().delete(`/chat/helpdesk-users/${user_id}`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      delete: user_id,
    });
  }
}
