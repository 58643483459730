import { GenexSISModel } from "@/models/sis/GenexSISModel";
import GenexHelperMixins from "@/mixins/GenexHelperMixins";

export default class UserPermission extends GenexSISModel {
  static entity = "user-permissions";
  static primaryKey = "name";

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(""),
    };
  }

  static FetchAll() {
    return this.api().get(`/permissions`, {
      ...GenexHelperMixins.methods.DefaultSISHeadersAndBaseUrl(),
      dataTransformer: ({ data: { data } }) => {
        return data.map(permissions => {
          return { ...permissions, ...permissions.attributes };
        });
      },
    });
  }
}
